import { useEffect } from "react";
import classes from "./Layout.module.css";
import { getMainSectionSize } from "../../hooks/layoutDOM";
import { specActions } from "../../store";
import { useDispatch } from "react-redux";

const Layout = (props) => {
  //update main section width

  const dispatch = useDispatch();

  useEffect(() => {
    const modalSpec = getMainSectionSize();
    console.log(modalSpec);

    const updateModalSpec = (value) => {
      dispatch(
        specActions.updateSpec({
          modalSpec: {
            top: value.top,
            left: value.left,
            width: value.width,
            height: value.height,
          },
        })
      );
    };

    window.addEventListener("resize", () => updateModalSpec(modalSpec));
  }, []);

  return (
    <main className={classes.main} id="main-container">
      {props.children}
    </main>
  );
};

export default Layout;
