import { Fragment } from "react";
import McpButtonV1 from "../../UI/button/McpButtonV1";
import FlexTable3Col2 from "../../table/FlexTable3Col2";

const DanhGiaMonTable = (props) => {
  const title = {
    col1: "STT",
    col2: "Thông tin",
    col3: "Công cụ",
  };

  const formatData = props.data.map((item) => {
    return {
      key: item.docID,
      info: [
        item.data.requestName, `ID: ${item.data.requestId}`, `Ngày: ${item.data.requestDate}`],
    };
  });

  const viewComp = (id) => {
    return (
      <div>
        <McpButtonV1
          type="blue"
          onClick={() => props.onView(id)}
          content="Xem"
          btnType="button"
        />
      </div>
    );
  };

  return (
    <Fragment>
      <FlexTable3Col2 title={title} data={formatData} addOnComp={viewComp} />
    </Fragment>
  );
};

export default DanhGiaMonTable;
