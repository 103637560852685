import React, { useState, useEffect } from "react";
import classes from "./ProfileDisplay.module.css";
import { useSelector } from "react-redux";
import { onSnapshot, where, query, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import avatar from "../../assets/images/profile-icon2.png";

const ProfileDisplay = () => {
  const [displayProfile, setDisplayProfile] = useState();

  const currentUser = useSelector((state) => state.auth.userCredential);
  const isLoggedIn = useSelector((state) => state.auth.userIsLoggin);

  useEffect(() => {
    // problem, only few special employee has uid
    // const userRef = doc(db, "employees", currentUser.uid);

    //solution - filter email
    const userRef = query(
      collection(db, "employees"),
      where("email", "==", currentUser.email)
    );

    const syncUser = onSnapshot(userRef, (snapshot) => {
      //onSnapshot alwasy return valid object
      if (!snapshot.empty) {
        let parseData = [];
        snapshot.forEach((item) => parseData.push(item.data()));
        const data = parseData[0];
        let updatedData = {
          Tên: data.familyName + " " + data.name,
          ID: data.id,
          Email: data.email,
          "Sinh nhật": data.dob,
          "Số điện thoại": data.phone,
          "Bộ phận": data.department,
          "Chức vụ": data.role,
          "Ngày bắt đầu làm việc": data.startDate,
        };
        setDisplayProfile(Object.entries(updatedData));
      }
    });

    if (!isLoggedIn) {
      syncUser();
    }
  }, [currentUser, isLoggedIn]);

  let profileContent;

  if (displayProfile) {
    profileContent = displayProfile.map((field) => (
      <div className={classes.row} key={field[0]}>
        <p>
          <span>{field[0] + ": "}</span>
          {field[1] ? field[1] : "Không có thông tin"}
        </p>
      </div>
    ));
  }

  return (
    <div className={classes["profile-wrapper"]}>
      <div className={classes.row}>
        <div>
          <div className={classes.photo}>
            <img src={avatar} alt="avatar" />
          </div>
        </div>
      </div>
      {profileContent}
    </div>
  );
};

export default ProfileDisplay;
