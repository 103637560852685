import ModalWrapperV3 from "../../UI/modals/ModalWrapperV3";
import classes from "./HrProfileModal.module.css";
import { dateConvert } from "../../../hooks/dateConvert";

const HrProfileModal = (props) => {
  // let activationStatus;
  // if(props.data.accActivated === 'true') {
  //     activationStatus = <p className={classes['no-activated']}>Tài khoản đã được kích hoạt;
  //     </p>
  // } else {
  //     activationStatus = <p className={classes['is-activated']}>
  //         Tài khoản chưa được kích hoạt
  //     </p>
  // }

  const dob = dateConvert(props.data.dob);
  const startDate = dateConvert(props.data.startDate);

  return (
    <ModalWrapperV3>
      <div className={classes.profile}>
        <h5>Thông tin cá nhân</h5>
        <p>
          <span>ID:</span>
          {` ${props.data.id}`}
        </p>
        <p>
          <span>Tên:</span>
          {` ${props.data.familyName} ${props.data.name} `}
        </p>
        <p>
          <span>Email:</span>
          {` ${props.data.email}`}
        </p>
        <p>
          <span>Sinh nhật:</span>
          {` ${dob}`}
        </p>
        <p>
          <span>Số điện thoại:</span>
          {` ${props.data.phone}`}
        </p>
        <p>
          <span>Bộ phận:</span>
          {` ${props.data.department}`}
        </p>
        <p>
          <span>Chức vụ:</span>
          {` ${props.data.position}`}
        </p>
        <p>
          <span>Giới tính:</span>
          {` ${props.data.gender}`}
        </p>
        <p>
          <span>Ngày bắt đầu làm việc:</span>
          {` ${startDate}`}
        </p>
      </div>
    </ModalWrapperV3>
  );
};

export default HrProfileModal;
