import React from "react";
import classes from "./TwoColsWrapper.module.css";

const TwoColsWrapper = (props) => {
  return (
    <div className={classes.wrapper}>
      <div>{props.children}</div>
    </div>
  );
};

export default TwoColsWrapper;
