import ModalWrapperV3 from "../../UI/modals/ModalWrapperV3";
import FlexTable3Col from "../../table/FlexTable3Col";
import CloseModal from '../../UI/modals/CloseModal';

/*
  prop list : {
    onClick, 
    data
  }
 */

const MenuModal = (props) => {
  const title = {
    col1: 'STT', 
    col2: 'Thông tin', 
    col3: 'Xóa'
  }

  const data = props.data;
  let convertData = [];
  if (data.length > 0) {
    data.map((item) => {
      convertData.push({
        col1: item.name,
        col2: item.group,
        col3: item.id,
      });
    });
  }

  return (
    <ModalWrapperV3>
      <CloseModal onClose={props.onClose} />
      <FlexTable3Col title={title} data={convertData} onRemove={props.onRemove} />
    </ModalWrapperV3>
  );
};

export default MenuModal;
