import React from 'react'; 
import HinhAnhMonAn from '../../components/hinh_anh_mon_an/HinhAnhMonAn';
import DeXuatDanhGiaTrangTri from '../../components/danh_gia_trang_tri_v2/DeXuatDanhGiaTrangTri';
import TaoInquiry from '../../components/tao-inquiy/TaoInquiry';

const MktSiteMap = [
    {
        name: "Hình ảnh món ăn", 
        path: "hinh anh mon an", 
        element: <HinhAnhMonAn />
    },
    {
        name: "Đề xuất đánh giá trang trí món ăn", 
        path: "de xuat danh gia trang tri mon an", 
        element: <DeXuatDanhGiaTrangTri />
    },
    {
        name: "Tạo inquiry", 
        path: "tao inquiry", 
        element: <TaoInquiry />
    }
]

export default MktSiteMap