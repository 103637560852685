import React, { useState, useEffect, useRef } from "react";
import classes from "./SelectField.module.css";

/* props list : {
  optionsList, 
  id, 
  label, 
  onClick, 
  onClickSub
}
*/

const optionsList = [
  {
    value: "init",
    textContent: "Chọn nhóm món ăn",
    disable: true,
    defaultFunction: null,
  },
  {
    value: "a1",
    textContent: "add new group",
    defaultFunction: "test",
  },
  {
    value: "g1",
    textContent: "group 1",
    defaultFunction: null,
  },
  {
    value: "g2",
    textContent: "group 2",
    defaultFunction: null,
  },
  {
    value: "g3",
    textContent: "group 3",
    defaultFunction: null,
  },
];

const SelectField = (props) => {
  const [isActive, setIsActive] = useState(false);
  const [optionHoverIndex, setOptionHoverIndex] = useState(-1);
  const [optionChecked, setOptionChecked] = useState("init");
  const [optionCheckedText, setOptionCheckedText] =
    useState(props.optionsList[0].textContent);

  const elSelectCustom = useRef();
  const elSelectNative = useRef();
  const optionsWrapper = useRef();

  const selectCustomClasses = isActive
    ? `${classes.selectCustom} ${classes.isActive}`
    : `${classes.selectCustom}`;

  const activeToggle = (e) => {
    e.preventDefault();
    setIsActive((prev) => !prev);
  };

  //listen to Native option change
  const optionCheckedNative = (e) => {
    const selectedIndex = e.target.selectedIndex;
    setOptionHoverIndex(selectedIndex);
    setOptionChecked(e.target.value);
    setOptionCheckedText(e.target[selectedIndex].text);
    props.onClick(e.target.value); 
  };

  //listen to Custom select change
  const optionCheckedCustom = (defaultFnKey, bindFn) => (e) => {
    e.preventDefault();
    //condition to activate create modal
    if (defaultFnKey === "modalFn") {
      bindFn(); 
    }
  
    const optionObjectArray = Array.from(optionsWrapper.current.children);
    const hoverIndex = optionObjectArray.findIndex((object) => {
      return object.attributes[1].value === e.target.getAttribute("data-value");
    });
    setOptionHoverIndex(hoverIndex);
    setOptionCheckedText(e.target.textContent);
    setOptionChecked(e.target.getAttribute("data-value"));

    props.onClick(e.target.getAttribute("data-value"));

    setIsActive((prev) => !prev);
  };

  //useEffect to update native select value
  useEffect(() => {
    elSelectNative.current.value = optionChecked;
  }, [optionChecked]);

  //close custom select list when click outside
  if (isActive) {
    document.addEventListener("click", function (e) {
      const didClickOutisde =
        elSelectCustom.current && !elSelectCustom.current.contains(e.target);
      if (didClickOutisde) {
        setIsActive(false);
      }
    });
  }

  //support key navigation
  const keyHandler = (e) => {
    const optionObjectArray = Array.from(optionsWrapper.current.children);
    if (
      e.key === "ArrowDown" &&
      optionHoverIndex < optionObjectArray.length - 1
    ) {
      setOptionHoverIndex((prev) => prev + 1);
    }
    if (e.key === "ArrowUp" && optionHoverIndex > 1) {
      setOptionHoverIndex((prev) => prev - 1);
    }
    if (e.key === "Escape") {
      setIsActive(false);
    }
    if (e.key === "Enter" || e.key === "Space") {
      setOptionChecked(
        optionObjectArray[optionHoverIndex].getAttribute("data-value")
      );
      setOptionCheckedText(optionObjectArray[optionHoverIndex].textContent);
      props.onClick(optionObjectArray[optionHoverIndex].getAttribute("data-value")); 
      setIsActive(false);
    }
  };

  //update hover and active element style
  useEffect(() => {
    const optionNodeList = optionsWrapper.current.children;
    //reset all options classes
    for (const key in Object.keys(optionNodeList)) {
      optionNodeList[key].classList = `${classes.selectCustomOption}`;
      //add active classes if the element is selected
      if (optionNodeList[key].getAttribute("data-value") === optionChecked) {
        optionNodeList[key].classList.add(`${classes.isActive}`);
      }
    }

    if (optionHoverIndex > 0) {
      const elNewHover =
        optionsWrapper.current.children[optionHoverIndex].classList;
      elNewHover.add(`${classes.isHover}`);
    }
  }, [optionHoverIndex, optionChecked]);

  useEffect(() => {
    //listen and update inital value for parent component
    props.onClick(optionChecked)
  }, [props.onClick]);

  //listen to mouseenter and update hover index
  const mouseHanlder = (e) => {
    const optionObjectArray = Array.from(optionsWrapper.current.children);
    const hoverIndex = optionObjectArray.findIndex((object) => {
      return object.attributes[1].value === e.target.getAttribute("data-value");
    });
    setOptionHoverIndex(hoverIndex);
  };

  return (
    <div className="select">
      <span className={classes.selectLabel} id={props.id}>
        {props.label}
      </span>
      <div className={classes.selectWrapper}>
        <select
          className={`${classes.selectNative} js-selectNative`}
          aria-labelledby={props.id}
          defaultValue={optionChecked}
          onChange={optionCheckedNative}
          ref={elSelectNative}
        >
          {props.optionsList && props.optionsList.map((option) => (
            <option
              value={option.value}
              disabled={option.disable ? true : false}
              key={option.value}
            >
              {option.textContent}
            </option>
          ))}
        </select>

        <div className={selectCustomClasses} ref={elSelectCustom}>
          <div
            className={classes["selectCustom-trigger"]}
            onClick={activeToggle}
            tabIndex={-1}
            onKeyDown={keyHandler}
          >
            {optionCheckedText}
          </div>
          <div
            className={`${classes["customOption-wrapper"]}`}
            ref={optionsWrapper}
          >
            {props.optionsList && props.optionsList.map(
              (option) =>
                option.value && (
                  <div
                    className={classes.selectCustomOption}
                    data-value={option.value}
                    key={option.value}
                    // onClick={option.defaultFunction ? test : optionCheckedCustom}
                    onClick={optionCheckedCustom(
                      option.defaultFunction,
                      props.onClickSub
                    )}
                    // onClick={() => console.log(option.defaultFunction)}
                    // onClick={() => props.onOpenModal(optionChecked)}
                    onMouseEnter={mouseHanlder}
                  >
                    {option.textContent}
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectField;
