import React, { useState, useEffect } from "react";
import PrimaryForm from "../../components/UI/PrimaryForm";
import classes from "./HrDanhSachBoPhan.module.css";
import { db } from "../../firebase/firebase";
import {
  collection,
  setDoc,
  doc,
  serverTimestamp,
  onSnapshot,
  query,
  where,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import {
  capitalizeEveryFirstLetter,
  captializeWhole,
} from "../../hooks/capitalize";
import loader from "../../assets/gif/loader.gif";
import ReactDOM from "react-dom";
import MainBackdrop from "../../components/UI/backdrops/MainBackdrop";
import { SysNor } from "../../components/UI/systemNor/SysNor";
import PermissionErrModal from "../../components/UI/modals/PermissionErrModal";
import EditModal from "../../components/UI/modals/EditModal";
import Loader from "../../components/UI/Loader";

const HrDanhSachBoPhan = () => {
  const [departmentData, setDepartmentData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editingContent, setEditingContent] = useState();
  const [systemErr, setSystemErr] = useState([]);
  const [authorityErr, setAuthorityErr] = useState(false);
  const [deletePermit, setDeletePermit] = useState(false);
  const [editPermit, setEditPermit] = useState(false);
  const [createPermit, setCreatePermit] = useState(false);

  const userIsLoggin = useSelector((state) => state.auth.userIsLoggin);
  const userEmail = useSelector((state) => state.auth.userCredential.email);
  const userDisplayName = useSelector(
    (state) => state.auth.userCredential.displayName
  );
  const userRole = useSelector((state) => state.auth.userCredential.role);
  const userDepartment = useSelector(
    (state) => state.auth.userCredential.department
  );

  useEffect(() => {
    const fetchDepartmentData = onSnapshot(
      collection(db, "department"),
      (snapshot) => {
        setIsLoading(true);
        const department = [];
        snapshot.forEach((doc) => {
          let departmentData = {
            id: doc.id,
            data: doc.data(),
          };
          department.push(departmentData);
        });
        setDepartmentData(department);
        setIsLoading(false);
      }
    );

    if (userIsLoggin !== true) {
      fetchDepartmentData();
    }

    if (userRole === "admin") {
      setDeletePermit(true);
      setCreatePermit(true);
      setEditPermit(true);
    }
  }, [userIsLoggin]);

  // const department = [
  //   {
  //     UID: "KIT111",
  //     data: [
  //       {
  //         name: "Bếp",
  //         code: "KIT",
  //         author: "admin ",
  //         occuredDate: "06082022",
  //       },
  //     ],
  //     isDeleted: {
  //       status: false,
  //       author: "admin",
  //       occuredDate: "",
  //     },
  //   },
  //   {
  //     UID: "FB222",
  //     data: [
  //       {
  //         name: "F&B",
  //         code: "FB",
  //         author: "admin",
  //         occuredDate: "06082022",
  //       },
  //     ],
  //     isDeleted: {
  //       status: false,
  //       author: "admin",
  //       occuredDate: "",
  //     },
  //   },
  // ];

  const departmentField = [
    {
      name: "department",
      label: "Bộ phận",
      id: "department",
      type: "text",
      placeholder: "Tên bộ phận",
      required: true,
    },
    {
      name: "code",
      label: "Mã",
      id: "code",
      type: "text",
      placeholder: "Mã (Viết hoa)",
      required: true,
    },
  ];

  const createHandler = (e) => {
    e.preventDefault();
    if (createPermit) {
      const formData = new FormData(e.target);
      // if (!formData) {
      //   setformError(true);
      // }
      const dataObject = Object.fromEntries(formData.entries());
      let submitData = {
        departmentName: capitalizeEveryFirstLetter(dataObject.department),
        departmentCode: captializeWhole(dataObject.code),
        recordedDate: serverTimestamp(),
        author: {
          email: userEmail,
          displayName: userDisplayName,
        },
      };
      setDoc(doc(collection(db, "department")), submitData);
      e.target.reset();
    } else {
      setAuthorityErr(true);
    }
  };

  const editHandler = (id) => {
    if (editPermit) {
      setIsEditing(true);
      let filteredDepartment = departmentData.filter(
        (department) => department.id === id
      );
      setEditingContent(filteredDepartment);
    } else {
      setAuthorityErr(true);
    }
  };

  const closeModal = () => {
    setIsEditing(false);
  };

  const deleteHandler = async (id) => {
    if (deletePermit) {
      const docRef = doc(db, "department", id);
      try {
        await deleteDoc(docRef);
      } catch (error) {
        setSystemErr((prev) => [...prev, "Đã có lỗi " + error]);
      }
    } else {
      setAuthorityErr(true);
    }
  };

  const closePermissionModal = () => {
    setAuthorityErr(false);
  };

  // const dataSectionContent = (
  //   <div className={classes.flexTable}>
  //     <div className={classes.theader}>
  //       <div>Tên bộ phận</div>
  //       <div>Mã</div>
  //       <div>Công cụ</div>
  //     </div>
  //     {departmentData &&
  //       departmentData.map((dept) => (
  //         <div className={classes.tableRow} key={dept.id}>
  //           <div>{dept.data.departmentName}</div>
  //           <div className={classes["mid-col"]}>{dept.data.departmentCode}</div>
  //           <div className={classes.panel}>
  //             <button className={classes.editBtn}>Sửa</button>
  //             <button
  //               className={classes.deleteBtn}
  //               onClick={() => deleteHandler(dept.id)}
  //             >
  //               Xóa
  //             </button>
  //           </div>
  //         </div>
  //       ))}
  //   </div>
  // );

  // const dataError = (
  //   <p className={classes["error-msg"]}>
  //     Không có dữ liệu, vui lòng kiểm tra lại mạng hoặc nhờ hỗ trợ
  //   </p>
  // );

  const loaderSection = (
    <div className={classes.loader}>
      <img src={loader} alt="loading" />
      <p>Đang tải ...</p>
    </div>
  );

  const DataCheckedSection = () => {
    if (departmentData) {
      return (
        <div className={classes.flexTable}>
          <div className={classes.theader}>
            <div>STT</div>
            <div>Tên bộ phận</div>
            <div>Mã</div>
            <div>Công cụ</div>
          </div>
          {departmentData &&
            departmentData.map((dept, index) => (
              <div className={classes.tableRow} key={dept.id}>
                <div>{index + 1}</div>
                <div>{dept.data.departmentName}</div>
                <div className={classes["mid-col"]}>
                  {dept.data.departmentCode}
                </div>
                <div className={classes.panel}>
                  <button
                    className={classes.editBtn}
                    onClick={() => editHandler(dept.id)}
                  >
                    Sửa
                  </button>
                  <button
                    className={classes.deleteBtn}
                    onClick={() => deleteHandler(dept.id)}
                  >
                    Xóa
                  </button>
                </div>
              </div>
            ))}
        </div>
      );
    } else {
      return (
        <p className={classes["error-msg"]}>
          Không có dữ liệu, vui lòng kiểm tra lại mạng hoặc nhờ hỗ trợ
        </p>
      );
    }
  };

  const sendUpdate = (e) => {
    e.preventDefault();
    const submitData = new FormData(e.target);
    const submitDataObject = Object.fromEntries(submitData.entries());
    let filterData = {
      departmentName: capitalizeEveryFirstLetter(submitDataObject.department),
      departmentCode: captializeWhole(submitDataObject.code),
    };
    try {
      const docRef = doc(db, "department", editingContent[0].id);
      updateDoc(docRef, filterData);
      closeModal();
    } catch (error) {
      setSystemErr((prev) => [...prev, "Đã có lỗi " + error]);
      closeModal();
    }
  };

  // const EditModal = (props) => {
  //   return (
  //     <ModalWrapper>
  //       <div>
  //         <button onClick={closeModal}>X</button>
  //       </div>
  //       <div>
  //         <form onSubmit={sendUpdate}>
  //           <div>
  //             <label htmlFor="department">Bộ phận</label>
  //             <input
  //               id="department"
  //               name="department"
  //               required
  //               defaultValue={props.data[0].data.departmentName}
  //               placeholder={props.data[0].data.departmentName}
  //             />
  //           </div>
  //           <div>
  //             <label htmlFor="">Mã</label>
  //             <input
  //               id="code"
  //               name="code"
  //               required
  //               defaultValue={props.data[0].data.departmentCode}
  //               placeholder={props.data[0].data.departmentCode}
  //             />
  //           </div>
  //           <button type="submit">Lưu</button>
  //           <button type="button">Huỷ</button>
  //         </form>
  //       </div>
  //     </ModalWrapper>
  //   );
  // };

  const formData = [
    {
      label: "Bộ phận",
      name: "department",
      id: "department",
      defaultValue: editingContent
        ? editingContent[0].data.departmentName
        : null,
      placeholder: editingContent
        ? editingContent[0].data.departmentName
        : null,
    },
    {
      label: "Code",
      name: "code",
      id: "code",
      defaultValue: editingContent
        ? editingContent[0].data.departmentCode
        : null,
      placeholder: editingContent
        ? editingContent[0].data.departmentCode
        : null,
    },
  ];

  return (
    <React.Fragment>
      {systemErr && <SysNor data={systemErr} />}
      <div className={classes.deptForm}>
        <PrimaryForm formData={departmentField} onSubmit={createHandler} />
      </div>
      {/* {isLoading && loaderSection}
      {departmentData ? dataSectionContent : dataError} */}
      {isLoading ? loaderSection : <DataCheckedSection />}
      {isEditing ||
        (authorityErr &&
          ReactDOM.createPortal(
            <MainBackdrop />,
            document.getElementById("backdrop-root")
          ))}
      {isEditing &&
        editingContent &&
        ReactDOM.createPortal(
          <EditModal
            formData={formData}
            onClick={closeModal}
            onSubmit={sendUpdate}
            onClose={closeModal}
          />,
          document.getElementById("overlay-root")
        )}
      {/* {isEditing &&
        ReactDOM.createPortal(
          <EditModal data={editingContent} />,
          document.getElementById("overlay-root")
        )} */}
      {authorityErr &&
        ReactDOM.createPortal(
          <PermissionErrModal onClick={closePermissionModal} />,
          document.getElementById("overlay-root")
        )}
    </React.Fragment>
  );
};

export default HrDanhSachBoPhan;
