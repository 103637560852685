import classes from "./SysNorRightFix.module.css";

/*
  This version:
  _Change position to fix and to the right side of vewport 
  _Nortify both error and success
  _props list : {
    data: {
      key, 
      msg, 
      type, 
    }
    onClick

  }

*/

export const SysNorRightFix = (props) => {
  const NorUnit = (props) => {
    // return (
    //   <div className={props.nortMsg ? classes["nor-unit"] : classes["nor-hidden"]}>
    //     <span className={classes.closeBtn} onClick={props.onClick}>
    //       X
    //     </span>
    //     <span className={classes["err-msg"]}>{props.nortMsg}</span>
    //   </div>
    // );

    let classContent;
    if (props.type === "success") {
      classContent = `${classes["nor-unit"]} ${classes.success}`;
    } else {
      classContent = `${classes["nor-unit"]} ${classes.error}`;
    }

    return (
      <div className={props.nortMsg ? classContent : classes["nor-hidden"]}>
        <span className={classes.closeBtn} onClick={props.onClick}>
          X
        </span>
        <span className={classes["err-msg"]}>{props.nortMsg}</span>
      </div>
    );
  };

  return (
    <div className={classes["nortification-container"]}>
      {props.data &&
        props.data.map((nort) => (
          <NorUnit
            onClick={() => props.onClick(nort.key)}
            key={nort.key}
            nortMsg={nort.msg}
            type={nort.type}
          />
        ))}
    </div>
  );
};
