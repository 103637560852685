import classes from "./SysNorV4Center.module.css";

/*
  This version V4 is about change in css style - make the content center
  props list = {
    data {
      key, 
      msg, 
      type
    }
    onClick, 
  }
*/

export const SysNorV4Center = (props) => {


  const NorUnit = (props) => {
    let classContent; 
    if(props.type === 'error') {
      classContent = 'err-msg'
    } 
    if(props.type === 'ok') {
      classContent = 'ok-msg'
    }

    return (
      <div className={props.errorMsg ? `${classes['nor-unit']} ${classes[`${classContent}`]}` : classes["nor-hidden"]}>
        <span className={`${classes['closeBtn']} ${classes[`${classContent}`]}`} onClick={props.onClick}>
          X
        </span>
        <span className={classes[`${props.classStyle}`]}>{props.errorMsg}</span>
      </div>
    );
  };

  // const NorTag = (props) => {
  //   let nortContent; 
  //   if(props.type === 'err') {
  //     nortContent = <NorUnit onClick={() => props.onClick(props.key)} key={props.key} errorMsg = {props.msg} /> 
  //   } else {
  //     nortContent = <NorUnit onClick={() => props.onClick(props.key)} key={props.key} errorMsg = {props.msg} />
  //   }
  // }



  return (
    <div className={classes["nortification-container"]}>
      {props.data &&
        props.data.map((error) => (
            <NorUnit onClick={() => props.onClick(error.key)} key={error.key} errorMsg = {error.msg} type={error.type}/>
        ))}
    </div>
  );
};
