import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useWindowSize, makeSizeRepsonsive } from "../../../hooks/layoutDOM";
import classes from "./ImgModalWrapper.module.css";
import ImgZoomCard from "../card/ImgZoomCard";

/*
  This version allow backdrop to automatically listen to browser sizes change an adjust

  props list : {
    children
  }
*/

const ImgModalWrapper = (props) => {
  const wrapperRef = useRef();

  const windowSize = useWindowSize();
  const navWidth = useSelector((state) => state.spec.nav.width);

  useEffect(() => {
    makeSizeRepsonsive(wrapperRef.current.style);
  }, [windowSize, navWidth]);

  return (
    <div className={classes["modal-wrapper"]} ref={wrapperRef}>
      <ImgZoomCard>{props.children}</ImgZoomCard>
    </div>
  );
};

export default ImgModalWrapper;
