import CancelIcon from "@mui/icons-material/Cancel";
import classes from './CloseModal.module.css'
 

const CloseModal = (props) => {
  return (
    <div className={classes.close}>
      <button type="button" onClick={props.onClose}>
        <CancelIcon sx={{ color: "#cbcbcb" }} />
      </button>
    </div>
  );
};

export default CloseModal;
