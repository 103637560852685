import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Footer from "./components/layout/Footer";
import MainNavigation from "./components/layout/MainNavigation";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./store";
import { auth } from "./firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import Test from "./pages/Test/Test";
import Home from "./pages/home/Home";
import LoginPage from "./pages/auth/LoginPage";
import SignUpPage from "./pages/auth/SignUpPage";
import ResetPass from "./pages/auth/ResetPass";

function App() {
  const userIsLoggin = useSelector((state) => state.auth.userIsLoggin);
  const userProfile = useSelector((state) => state.auth.userCredential);

  console.log(userProfile);
  const dispatch = useDispatch();

  //firebase auth listener
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        //do something
      } else {
        dispatch(
          authActions.logout({
            type: "auto",
          })
        );
      }
    });
  }, [dispatch]);

  return (
    <div className="App">
      <MainNavigation />
      <Routes>
        <Route
          path="/*"
          element={
            userIsLoggin ? <Home /> : <Navigate replace to="/dang-nhap" />
          }
        />
        <Route
          path="/dang-nhap"
          element={userIsLoggin ? <Navigate replace to="/" /> : <LoginPage />}
        />
        <Route
          path="/dang-ky"
          element={userIsLoggin ? <Navigate replace to="/" /> : <SignUpPage />}
        />
        <Route
          path="/cap-mat-khau"
          element={userIsLoggin ? <Navigate replace to="/" /> : <ResetPass />}
        />
        {userIsLoggin && <Route path="/test/*" element={<Test />} />}
      </Routes>
      {!userIsLoggin && <Footer />}
    </div>
  );
}

export default App;
