import classes from "./MobileNav2.module.css";
import navContent from "../../store/navContent";
import MobileSubmenu2 from "./MobileSubmenu2";

const MobileNav2 = (props) => {
  return (
    <div
      className={
        props.state
          ? `${classes.mobileNav}`
          : `${classes.mobileNav} ${classes.hide}`
      }
      ref={props.reference}
    >
      <ul>
        {navContent.map((menu) => (
          <MobileSubmenu2
            key={menu.title}
            data={menu}
            onClick={props.onClick}
          />
        ))}
      </ul>
    </div>
  );
};

export default MobileNav2;
