import IsBuiding from "../404&others/IsBuiding";
import Page404 from "../404&others/404";
import HrDanhSachBoPhan from "./HrDanhSachBoPhan";
import HrChucVuQuyenHan from "./HrChucVuQuyenHan";
import HrHoSoNhanVienV3 from "./HrHoSoNhanVienV3";
import HrEmployeeGroup from "../../components/department/HR/HrEmployeeGroup";
import ViewReviewRequest from "../../components/department/KIT/ViewReviewRequest";
import XemDeXuatDanhGiaTrangTri from "../../components/danh_gia_trang_tri_v2/XemDeXuatDanhGiaTrangTri";

const HrSiteMap = [
  {
    name: "Danh sách bộ phận",
    path: "danh sach bo phan",
    element: <HrDanhSachBoPhan />,
  },
  {
    name: "Vị trí, chức vụ và quyền hạn",
    path: "vi tri chuc vu quyen han",
    element: <HrChucVuQuyenHan />,
  },
  {
    name: "Hồ sơ nhân viên",
    path: "ho so nhan vien",
    element: <HrHoSoNhanVienV3 />,
  },
  {
    name: "Định biên nhân sự",
    path: "dinh bien nhan su",
    element: <IsBuiding />,
  },
  {
    name: "Tạo nhóm nhân sự",
    path: "tao nhom nhan su",
    element: <HrEmployeeGroup />,
  },
  {
    name: "Xem trạng thái đề xuất thử món",
    path: "xem trang thai de xuat thu mon",
    element: <ViewReviewRequest />,
  },
  {
    name: "Xem trạng thái đánh giá trang trí",
    path: "xem trang thai danh gia trang tri",
    element: <XemDeXuatDanhGiaTrangTri />,
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export default HrSiteMap;
