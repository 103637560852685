import { useState, useEffect, useReducer, useRef, useContext } from "react";
import ReactDOM from "react-dom";
import classes from "./ImgUploaderV2.module.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useWindowSize } from "../../hooks/layoutDOM";
import ImgCard from "./ImgCard";
import ImgCardMobile from "./ImgCardMobile";
import MainBackdropV3 from "../UI/backdrops/MainBackdropV3";
import UploadStatusModal from "./UploadStatusModal";
import NortContext from "../hinh_anh_mon_an/NortContext";

/*
  Version control:
  v2:
    1 - change logic for sumbmit funciton
  v1:  
    1 - change design specificly for 'them hinh anh'. 
    2 - logic and conditions
 */

//reducer initial state
const initialFiles = {
  fetchedFiles: [],
  modifiedFiles: [],
};

const fileReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_INPUT":
      const targetFiles = action.payload.file;
      const newTargetList = new DataTransfer();
      const previewArr = [];

      for (let i = 0; i < targetFiles.length; i++) {
        newTargetList.items.add(targetFiles[i]);

        const url = URL.createObjectURL(targetFiles[i]);
        previewArr.push({
          url,
          // name: action.payload.id + "_" + Math.floor(Math.random() * 1000),
          size: targetFiles[i].size,
          type: targetFiles[i].type,
        });
        URL.revokeObjectURL(targetFiles[i]);
        // }
      }

      return {
        fetchedFiles: newTargetList.files,
        modifiedFiles: previewArr,
      };
    case "REMOVE":
      const listAfterRemove = new DataTransfer();
      const previewAfterRemove = [];

      for (let i = 0; i < state.fetchedFiles.length; i++) {
        if (i !== action.payload.removeIndex) {
          listAfterRemove.items.add(state.fetchedFiles[i]);
          const url = URL.createObjectURL(state.fetchedFiles[i]);
          previewAfterRemove.push({
            url,
            name: action.payload.id + "_" + Math.floor(Math.random() * 1000),
            size: state.fetchedFiles[i].size,
            type: state.fetchedFiles[i].type,
          });
          URL.revokeObjectURL(state.fetchedFiles[i]);
        }
      }

      return {
        fetchedFiles: listAfterRemove.files,
        modifiedFiles: previewAfterRemove,
      };
    case "UPLOAD_CLEAR":
      let clearIndexArr = action.payload.indexArr;
      let afterClearList = new DataTransfer();

      for (let i = 0; i < state.fetchedFiles.length; i++) {
        if (clearIndexArr.length > 0) {
          for (let n = 0; n < clearIndexArr.length; n++) {
            if (i !== n) {
              afterClearList.items.add(state.fetchedFiles[i]);
              state.modifiedFiles.splice(i, 1);
            }
          }
        } else {
          afterClearList.items.add(state.fetchedFiles[i]);
        }
      }

      return {
        fetchedFiles: afterClearList.files,
        modifiedFiles: state.modifiedFiles,
      };

    default:
      return {
        fetchedFiles: [],
        modifiedFiles: [],
      };
  }
};

const ImgUploaderV2 = (props) => {
  const [browserSize, setBrowserSize] = useState();
  const [modalIsOn, setModalIsOn] = useState(false);
  const inputRef = useRef();

  const [file, dispatchFile] = useReducer(fileReducer, initialFiles);

  const windowSize = useWindowSize();

  const nortCtx = useContext(NortContext);

  //dish name for image data
  const modifiedName = props.dishData.data.name.replace(/ +/g, "_");
  const date = new Date();
  const idName = `${modifiedName}_${
    props.dishData.data.id
  }_${date.getDate()}${date.getMonth()}${date.getFullYear()}`;

  //preview upload image function
  const fileSelectedHandler = (e) => {
    dispatchFile({
      type: "FETCH_INPUT",
      payload: {
        file: e.target.files,
        id: idName,
      },
    });
    // e.target.value =  '';
  };

  //function to remove individual from FileList

  const removeImg = (index) => {
    dispatchFile({
      type: "REMOVE",
      payload: {
        id: idName,
        removeIndex: index,
      },
    });
  };

  useEffect(() => {
    setBrowserSize(windowSize[0]);
  }, [windowSize]);

  //define upload path
  const formatGroup = props.dishData.data.group.replace(/ +/g, "_");
  const formatOutlet = props.dishData.data.outlet.replace(/ +/g, "_");
  const formatDishName = modifiedName + "_" + props.dishData.data.id;
  const uploadPath = `dishImg/`;

  let listContent;
  if (browserSize <= 768) {
    listContent = (
      <ul className={classes["img-list-mobile"]}>
        {file.modifiedFiles.map((img, index) => (
          <li key={index}>
            <ImgCardMobile data={img} onRemove={() => removeImg(index)} />
          </li>
        ))}
      </ul>
    );
  } else {
    listContent = (
      <ul className={classes["img-list"]}>
        {file.modifiedFiles.map((img, index) => (
          <li key={index}>
            <ImgCard data={img} onRemove={() => removeImg(index)} />
          </li>
        ))}
      </ul>
    );
  }

  //submit function
  const submitHandler = () => {
    if (file.fetchedFiles.length <= 15) {
      setModalIsOn(true);
    } else {
      //nortify error
      nortCtx.onAdd({
        type: "error",
        key: Math.floor(Math.random() * 100000),
        msg: `Bạn chỉ có thể up 15 hình một lần !!`,
      });
    }
  };

  //clear cache input file
  const clearCacheInput = () => {
    inputRef.current.value = "";
  };

  //close modal
  const closeModal = () => {
    setModalIsOn(false);
    clearCacheInput();
  };

  return (
    <div className={classes.section}>
      {modalIsOn &&
        ReactDOM.createPortal(
          <MainBackdropV3
          // onClick={closeModal}
          />,
          document.getElementById("main-backdrop")
        )}
      {modalIsOn &&
        ReactDOM.createPortal(
          <UploadStatusModal
            data={file.fetchedFiles}
            path={uploadPath}
            dishName={idName}
            onRemoveAfterUpload={dispatchFile}
            onClick={closeModal}
            dishData={props.dishData}
            clearInput={clearCacheInput}
          />,
          document.getElementById("main-overlay")
        )}

      <div className={classes.container}>
        <div className={classes["dash-border-wrapper"]}>
          <label className={classes["file-input"]} htmlFor="fileInput">
            <FileUploadIcon className={classes.icon} />
            Chọn hình ảnh để đăng.
            <input
              id="fileInput"
              type="file"
              accept="image/*"
              multiple="multiple"
              onChange={fileSelectedHandler}
              ref={inputRef}
            />
          </label>
        </div>
        <div className={classes["upload-note"]}>
          Hình ảnh cần có tỷ lệ 4:3 và dung lượng không quá 1MB. Định dạng cho
          phép: .JPEG .JPG .PNG .WebP. Tham khảo birme.net hoặc tinypng.com để
          cắt hình.
        </div>
      </div>

      {file.fetchedFiles.length > 0 && (
        <div className={classes["preview-list"]}>
          {listContent}
          <div className={classes.btnBar}>
            <button type="button" onClick={submitHandler}>
              Đăng hình
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImgUploaderV2;
