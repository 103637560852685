import DuLieuMonAn from "../../components/du_lieu_mon_an/DuLieuMonAn";

const SNMgeneralSiteMap = [
  {
    name: "Dữ liệu món ăn",
    path: "du lieu mon an",
    element: <DuLieuMonAn />,
  },
];

export default SNMgeneralSiteMap;
