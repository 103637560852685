import React, { useEffect, useRef, useState } from "react";
import FormWrapper from "../../components/UI/FormWrapper";
import FormTemplate1 from "../../components/form/template/FormTemplate1";
import SingleFieldV3 from "../../components/form/template/SingleFieldV3";
import ModalWrapperV3 from "../../components/UI/modals/ModalWrapperV3";
import { updateDbDoc } from "../../hooks/fetchFirebase";
import { db } from "../../firebase/firebase";
import { doc, serverTimestamp } from "firebase/firestore";
import NortPTag from "../../components/UI/systemNor/NortPTag";

const EditOutletModal = (props) => {
  const [sysNort, setSysNort] = useState();
  const fieldRef = useRef();
  useEffect(() => {
    fieldRef.current.value = props.data[0].data.outlet;
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    const docDbRef = doc(db, "outlet", props.data[0].docID);
    updateDbDoc(docDbRef, {
      outlet: fieldRef.current.value,
      lastUpdated: serverTimestamp(),
    }).then((res) => {
      if (res.code == 0) {
        setSysNort({
          type: "success",
          message: res.message,
        });
      } else {
        setSysNort({
          type: "error",
          message: res.message,
        });
      }
    });
  };

  let nortContent;
  if (sysNort) {
    nortContent = <NortPTag type={sysNort.type}>{sysNort.message}</NortPTag>;
  }

  return (
    <ModalWrapperV3>
      <FormWrapper>
        <FormTemplate1
          id="outletForm"
          onSubmit={submitHandler}
          onCancel={props.onCancel}
        >
          <SingleFieldV3
            label="Tên Outlet"
            id="outlet"
            type="text"
            placeholder="Ví dụ: Coffee Terrace"
            required
            reference={fieldRef}
          />
        </FormTemplate1>
        {nortContent}
      </FormWrapper>
    </ModalWrapperV3>
  );
};

export default EditOutletModal;
