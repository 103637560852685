import { useState, Fragment } from "react";
import AccordionBtnV1 from "../UI/accordion/AccordionBtnV1";
import SelectFieldV2 from "../form/template/SelectFieldV2";
import SelectFieldV3 from "../form/template/SelectFieldV3";
import SearchBar from "../panel/SearchBar";
import SearchUserTable from "../department/KIT/SearchUserTable";
import classes from "./ChonNhomDoiTuong.module.css";
import NhomDoiTuongTable from "./NhomDoiTuongTable";

/*
  props list: {
    group, 
    user
  }
* */

const ChonNhomDoiTuong = (props) => {
  const [audienceIndex, setAudienceIndex] = useState('group');

  const doiTuong = [
    {
      value: "group",
      textContent: "Nhóm",
      disabled: false,
      key: "group",
    },
    {
      value: "individual",
      textContent: "Cá nhân",
      disabled: false,
      key: "individual",
    },
  ];

  const audiencePageSelect = (value) => {
    setAudienceIndex(value);
  };

  let audienceForm;
  switch (audienceIndex) {
    case "group":
      audienceForm = (
        <Fragment>
          <NhomDoiTuongTable data={props.group.data} onAdd={props.group.add}/>
        </Fragment>
      );
      break;
    case "individual":
      audienceForm = (
        <Fragment>
          <SearchBar onSearch={props.user.search} onReset={props.user.reset} />

          <SearchUserTable data={props.user.data} onAdd={props.user.add} />
        </Fragment>
      );
      break;
  }

  return (
    <div>
      <AccordionBtnV1 title="Chọn nhóm đối tượng đánh giá">
        <SelectFieldV3
          id="audienceSelect"
          optionsList={doiTuong}
          onClick={audiencePageSelect}
          defaultOption="group"
          defaultText='Nhóm'
        />
        <div className={classes["form-wrapper"]}>{audienceForm}</div>
      </AccordionBtnV1>
    </div>
  );
};

export default ChonNhomDoiTuong;
