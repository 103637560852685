import classes from "./GMDashBoard.module.css";
import HeadingDep from "../../components/UI/HeadingDep";
import PanelWrapper from "../../components/UI/PanelWrapper";
import FunctionBtn from "../../components/panel/FunctionBtn";
import { capitalizeFirstLetterV2 } from "../../hooks/capitalize";
//import capitalizeFirstLetterV2 from "../../../hooks/"

const GmDashBoard = (props) => {
  const inputArray = props.sitemap;

  //<-- learn from chapGPT, need to understand
  const convertArr = inputArray.reduce((result, item) => {
    const { name, path, element, depCode } = item;

    const existingEntry = result.find((entry) => entry[depCode]);
    if (existingEntry) {
      existingEntry[depCode].push({ name, path, element });
    } else {
      result.push({ [depCode]: [{ name, path, element }] });
    }
    return result;
  }, []);
  //--> end

  const DOMcontent = convertArr.map((obj) => {
    const [prop, array] = Object.entries(obj)[0];
    return (
      <div key={prop}>
        <h4>{capitalizeFirstLetterV2(prop)}</h4>
        {array.length > 0 &&
          array.map((item) => (
            <FunctionBtn
              content={item.name}
              path={item.path.replace(/\s+/g, "-")}
              key={item.path.replace(/\s+/g, "-")}
            />
          ))}
      </div>
    );
  });

  return (
    <div className={classes.container}>
      <HeadingDep content={props.title} />
      <PanelWrapper>{DOMcontent}</PanelWrapper>
    </div>
  );
};

export default GmDashBoard;
