import React from "react";
import { functions } from "../../firebase/firebase";
import { httpsCallable } from "firebase/functions";

const GmTest = () => {
  const callSubmit = httpsCallable(functions, "callFoodRequestSubmit");

  const DATA = [
    {
      id: "00LVI56vlhb8IKs9FSBE",
      name: "test 1",
    },
    {
      id: "00jUzoUerFQfIrnsHf7F",
      name: "test 2",
    },
  ];

  const onClickHandler = () => {
    callSubmit({ data: DATA }).then((res) => {
      console.log(res);
    });
  };

  return (
    <div>
      <p>chuc nang test</p>
      <button onClick={onClickHandler}>Run test</button>
    </div>
  );
};

export default GmTest;
