import classes from "./DishImgTable.module.css";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import McpButtonV1 from "../UI/button/McpButtonV1";
import { db } from "../../firebase/firebase";
import { collection, query, where } from "firebase/firestore";
import { getDbs1 } from "../../hooks/fetchFirebase";

const DishImgTable = (props) => {
  let title;
  if (props.title) {
    title = props.title;
  }

  const selectImg = (dishId) => {
    props.fetchImg([]);
    const colRef = collection(db, "dish_list", dishId, "images");
    const queryRef = query(colRef, where("category", "==", "tham khảo"));
    getDbs1(queryRef).then((res) => {
      const imgData = {
        dishId,
        imgData: res,
      };
      props.fetchImg(imgData);
      props.onViewImg();
    });
  };

  return (
    <div className={classes.container}>
      <div className={`${classes.row} ${classes.header}`}>
        <div>{title.col1}</div>
        <div>{title.col2}</div>
        <div>{title.col3}</div>
      </div>
      {props.data.length > 0 &&
        props.data.map((item, index) => (
          <div className={classes.row} key={item.col3}>
            <div className={classes.index}>{index + 1}</div>
            <div className={classes.info}>
              <p>{item.col1}</p>
              <p>{item.col2}</p>
              <McpButtonV1
                content="Chọn hình"
                type="blue"
                btnType="button"
                onClick={() => selectImg(item.col3)}
              />
            </div>
            <div>
              <button onClick={() => props.onRemove(item.col3)}>
                <RemoveCircleIcon sx={{ color: "#EA1010" }} />
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default DishImgTable;
