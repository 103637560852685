import { useRef, useState, useEffect } from "react";
import FormTemplate1 from "../../components/form/template/FormTemplate1";
import SingleFieldV3 from "../../components/form/template/SingleFieldV3";
import FormWrapper from "../../components/UI/FormWrapper";
import { createDocV2 } from "../../hooks/fetchFirebase";
import { collection, onSnapshot, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useSelector } from "react-redux";
import NortPTag from "../../components/UI/systemNor/NortPTag";
import Loader from "../../components/UI/Loader";
import FlexTableFoodGroup from "./FlexTableFoodGroup";
import { capitalizeEveryFirstLetter } from "../../hooks/capitalize";

const KitNhomMonAn = () => {
  const [formNort, setFormNort] = useState();
  const [foodGroupData, setFoodGroupData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const foodGroupInput = useRef();
  const inputEN = useRef();

  const user = useSelector((state) => state.auth.userCredential);

  useEffect(() => {
    const dataDbRef = collection(db, "food_group");
    const fetchData = onSnapshot(dataDbRef, (snapshot) => {
      if (!snapshot.empty) {
        let dataArr = [];
        snapshot.forEach((item) => {
          const parsedData = item.data();
          dataArr.push({
            docID: item.id,
            data: {
              col1: parsedData.id,
              col2: parsedData.foodGroup,
              col3: parsedData.foodGroupEN,
            },
          });
        });
        setIsLoading(false);
        setFoodGroupData(dataArr);
      } else {
        setIsLoading(false);
      }
    });

    // if(!user.userIsLoggin) {
    //   fetchData();
    // }
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    setFormNort(null);
    const collectionRef = collection(db, "food_group");
    let dataObject = {
      id: Math.floor(Math.random() * 1000),
      // foodGroup: foodGroupInput.current.value,
      // foodGroupEN: inputEN.current.value,
      foodGroup: capitalizeEveryFirstLetter(
        foodGroupInput.current.value.trim()
      ),
      foodGroupEN: capitalizeEveryFirstLetter(inputEN.current.value.trim()),
      createdDate: serverTimestamp(),
      creator: user.email,
    };

    createDocV2(collectionRef, dataObject).then((res) => {
      if (res.code === 0) {
        setFormNort({
          type: "success",
          message: res.message,
        });
        setTimeout(() => {
          setFormNort(null);
        }, 3000);
      } else {
        setFormNort({
          type: "error",
          message: res.message,
        });
        setTimeout(() => {
          setFormNort(null);
        }, 3000);
      }
    });
  };

  let tableContent;
  if (isLoading) {
    tableContent = <Loader />;
  } else {
    tableContent = (
      <FlexTableFoodGroup
        col1="ID"
        col2="Nhóm món ăn"
        col3="Tên Tiếng Anh"
        data={foodGroupData}
        allowType="basic"
        dbCollection="food_group"
        flex="center"
      />
    );
  }

  let formNortContent;
  if (formNort) {
    formNortContent = (
      <NortPTag type={formNort.type}>{formNort.message}</NortPTag>
    );
  }

  return (
    <div>
      <FormWrapper>
        {/* <PrimaryForm formData={outletField} /> */}
        <FormTemplate1 id="foodGroupForm" onSubmit={submitHandler}>
          <SingleFieldV3
            label="Nhóm món ăn"
            id="foodGroup"
            type="text"
            required
            reference={foodGroupInput}
          />
          <SingleFieldV3
            label="Tên Tiếng Anh"
            id="foodGroupEN"
            type="text"
            required
            reference={inputEN}
          />
          {formNortContent}
        </FormTemplate1>
      </FormWrapper>
      {tableContent}
      {/* <FlexTable
        tHeadLeft="Nhóm món ăn"
        tHeadRight="Tên Tiếng Anh"
        data={foodGroupData}
        tdataLeft="name"
        tdataRight="nameEN"
        key="name"
      /> */}
    </div>
  );
};

export default KitNhomMonAn;
