import React from "react";
import classes from "./404.module.css";
import BackHomeBtn from "../../components/UI/BackHomeBtn";

const Page404 = () => {
  return (
    <div className={classes["not-found"]}>
      <h3>404</h3>
      <p className={classes.bold}>Ooops... </p>
      <p className={classes.des}>
        Trang bạn tìm không tồn tại,
        <br /> hãy thử kiểm tra lại đường link
      </p>
      <BackHomeBtn />
    </div>
  );
};

export default Page404;
