import classes from "./ProfilePanel.module.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { auth } from "../../firebase/firebase";
import { signOut } from "firebase/auth";
import { useDispatch } from "react-redux";
import { authActions } from "../../store";
import { useNavigate } from "react-router-dom";

const ProfilePanel = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signOutHandler = (e) => {
    e.preventDefault();
    dispatch(
      authActions.logout({
        type: "manual",
      })
    );
    navigate("/");
  };

  const toSettingHanlder = (e) => {
    navigate("/cai-dat");
    props.onClick();
  };

  const toProfileHanlder = (e) => {
    navigate("/ho-so");
    props.onClick();
  };

  return (
    <div className={`${classes.profilePanel} ${classes.alignLeft}`}>
      <div className={classes.profileMenu}>
        <button onClick={toProfileHanlder}>
          <AccountCircleIcon /> Thông tin cá nhân
        </button>
      </div>
      <div className={classes.profileMenu}>
        <button onClick={toSettingHanlder}>
          <SettingsIcon /> Cài đặt
        </button>
      </div>
      <div className={classes.profileMenu}>
        <button onClick={signOutHandler}>
          <ExitToAppIcon />
          Đăng xuất
        </button>
      </div>
    </div>
  );
};

export default ProfilePanel;
