import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import classes from "./DuLieuFlexTable.module.css";
import demoImg from "../../assets/images/img-placeholder-4.png";
import McpButtonV1 from "../UI/button/McpButtonV1";
import ViewMore from "./ViewMore";
import MainBackdropV3 from "../UI/backdrops/MainBackdropV3";
import DuLieuMonAnContext from "./DuLieuMonAnContext";

/**
 * props list: {data}
 */

const DuLieuFlexTable = (props) => {
  const [imgCount, setImgCount] = useState(1);
  const [onView, setOnView] = useState(false);
  const [viewItem, setViewItem] = useState(null);
  const [modalSelect, setModalSelect] = useState();
  const imgWrapperRef = useRef();
  const containerRef = useRef();

  console.log(props.data)

  //score content
  const getScore = (value) => {
    let scoreContent;
    let classesContent = `${classes["sm-f"]} ${classes.score} `;
    if (value <= 0) {
      classesContent += `${classes.grey}`;
    } else if (value < 3) {
      classesContent += `${classes.red}`;
    } else if (value < 3.5) {
      classesContent += `${classes.gold}`;
    } else if (value <= 5) {
      classesContent += `${classes.green}`;
    }

    scoreContent = (
      <li className={classesContent}>
        <span className={`${classes["list-title"]} ${classes["sm-f"]}`}>
          Điểm nội bộ:{" "}
        </span>
        {value}
      </li>
    );

    return scoreContent;
  };

  //select feature image
  const generateImg = (imgArr) => {
    // console.log(`${name}`);
    let featureArr = [];
    const approveArr = imgArr.filter(
      (item) => item.imgData.category === "Được duyệt"
    );
    const inUseArr = imgArr.filter(
      (item) => item.imgData.category === "Đang áp dụng"
    );

    if (approveArr.length > 0) {
      if (approveArr.length > imgCount) {
        for (let i = 0; i <= imgCount; i++) {
          featureArr[i] = approveArr[i];
        }
      } else if (approveArr.length <= imgCount) {
        for (let i = 0; i < approveArr.length; i++) {
          featureArr[i] = approveArr[i];
        }
        const newCount = imgCount - approveArr.length;

        if (inUseArr.length > 0) {
          let n = 0;
          let i = approveArr.length;
          if (inUseArr.length > newCount) {
            for (i; i <= newCount; i++) {
              featureArr[i] = inUseArr[n];
              n++;
            }
          } else if (inUseArr.length <= newCount) {
            for (i; i <= inUseArr.length; i++) {
              featureArr[i] = inUseArr[n];
              n++;
            }

            for (let ii = 0; ii <= newCount - inUseArr.length; ii++) {
              featureArr[i] = {
                imgData: {
                  URL: demoImg,
                },
              };
              i++;
            }
          }
        } else {
          for (let i = approveArr.length; i <= imgCount; i++) {
            featureArr[i] = {
              imgData: {
                URL: demoImg,
              },
            };
          }
        }
      }
    } else if (inUseArr.length > 0) {
      if (inUseArr.length > imgCount) {
        for (let i = 0; i < imgCount; i++) {
          featureArr[i] = inUseArr[i];
        }
      } else if (inUseArr.length <= imgCount) {
        let i = 0;
        for (i; i < inUseArr.length; i++) {
          featureArr[i] = inUseArr[i];
        }
        const newCount = imgCount - inUseArr.length;
        let n = 0;
        for (n = 0; n < newCount; n++) {
          featureArr[i] = {
            imgData: {
              URL: demoImg,
            },
          };
          i++;
        }
      }
    } else {
      for (let i = 0; i < imgCount; i++) {
        featureArr.push({
          imgData: {
            URL: demoImg,
          },
        });
      }
    }

    const getAlt = (name) => {
      let alt;
      if (name != null) {
        alt = name;
      } else {
        alt = "no-image";
      }
      return alt;
    };

    let imgContent = [];

    for (let i = 0; i < imgCount; i++) {
      imgContent.push(
        <img
          src={featureArr[i].imgData.URL}
          key={i}
          alt={getAlt(featureArr[i].name)}
        />
      );
    }
    return imgContent;
  };

  //render number for image content
  useEffect(() => {
    const containerObserver = new ResizeObserver((el) => {
      let count;
      if (imgWrapperRef.current != null) {
        const wrapperWidth = imgWrapperRef.current.clientWidth;
        if (wrapperWidth > 308) {
          count = wrapperWidth / 154;
          setImgCount(Math.floor(count));
        } else {
          setImgCount(1);
        }
      }
    });
    containerObserver.observe(containerRef.current);
  }, []);

  //modal handlers
  const viewHandler = (item, type) => {
    setOnView(true);
    setViewItem(item);
    setModalSelect(type);
  };

  const closeView = () => {
    setOnView(false);
    setViewItem();
  };

  let tableContent;
  if (props.data.length > 0) {
    tableContent = props.data.map((item) => (
      <div className={classes.row} key={item.docId}>
        <div className={classes.img} ref={imgWrapperRef}>
          {/* <img src={demoImg} /> */}
          <div className={classes["img-row"]}>{generateImg(item.image)}</div>
          <McpButtonV1
            btnType="button"
            content="xem thêm"
            type="blue"
            onClick={() => viewHandler(item, "img")}
          />
        </div>
        <div className={classes.info}>
          <ul>
            <li className={`${classes["sm-f"]} ${classes.green}`}>
              <span className={classes["list-title"]}>Tên món: </span>
              {item.data.name}
            </li>
            {getScore(item.teamScore)}
            <li className={classes["sm-f"]}>
              <span className={`${classes["list-title"]} ${classes["sm-f"]}`}>
                Nhóm:{" "}
              </span>
              {item.data.group}
            </li>
            <li className={classes["sm-f"]}>
              <span className={`${classes["list-title"]} ${classes["sm-f"]}`}>
                Outlet:{" "}
              </span>
              {item.data.outlet}
            </li>
          </ul>
          <div>
            <McpButtonV1
              btnType="button"
              content="thông tin"
              type="blue"
              onClick={() => viewHandler(item, "info")}
            />
            <McpButtonV1
              btnType="button"
              content="công cụ"
              type="green"
              onClick={() => viewHandler(item, "tool")}
            />
          </div>
        </div>
      </div>
    ));
  }

  return (
    <div className={classes.container} ref={containerRef}>
      <DuLieuMonAnContext.Provider value={{ dishId: viewItem ? viewItem.docId : null }}>
        {onView &&
          ReactDOM.createPortal(
            <ViewMore
              data={viewItem}
              onClose={closeView}
              fnType={modalSelect}
            />,
            document.getElementById("main-overlay")
          )}
        {tableContent}
        {onView &&
          ReactDOM.createPortal(
            <MainBackdropV3 onClick={closeView} />,
            document.getElementById("main-backdrop")
          )}
      </DuLieuMonAnContext.Provider>
    </div>
  );
};

export default DuLieuFlexTable;
