import classes from "./LoginPage.module.css";
import TwoColsWrapper from "../../components/UI/TwoColsWrapper";
import LoginFormV4 from "../../components/authentication/LoginFormV4";

const LoginPage = () => {
  return (
    <TwoColsWrapper>
      <div className={classes.wrapper}>
        <div>
          <LoginFormV4 />
        </div>
        <div className={classes.bg} >
        </div>
      </div>
    </TwoColsWrapper>
  );
};

export default LoginPage;
