import { Link } from "react-router-dom";
import classes from './FunctionBtn.module.css';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const FunctionBtn = (props) => {
  return (
    <li className={classes.functionBtn}>
      <Link to={props.path} className={classes.link}>
        <div className={classes.wrapper}>
          <span>{props.content}</span>
          <span className={classes.icon}>
            <ChevronRightIcon />
          </span>
        </div>
      </Link>
    </li>
  );
};

export default FunctionBtn;