import HeadingDep from '../../UI/HeadingDep';
import PanelWrapper from '../../UI/PanelWrapper';
import classes from './DanhGiaDashBoard.module.css';
import FunctionPanel from '../../panel/FunctionPanel';
import DanhGiaSiteMap from '../../../pages/generalFunc/DanhGiaSiteMap';

const DanhGiaDashBoard = () => {
  return (
    <div className={classes.DanhGiaDashBoard}>
      <HeadingDep content='Đánh Giá' />
      <PanelWrapper>
        <FunctionPanel data={DanhGiaSiteMap}/>
      </PanelWrapper>
    </div>
  )
}

export default DanhGiaDashBoard
