import React from 'react';
import classes from './NhomDoiTuongTable.module.css'; 
import NortPTag from '../UI/systemNor/NortPTag';
import AddBtn from '../UI/button/AddBtn';

/*
    props list : {
        data: {
            key, 

        }
    }
 */

const NhomDoiTuongTable = (props) => {
    let headerContent;
    let tableContent;
    if (props.data) {
      if (props.data.length > 0) {
        headerContent = (
          <div className={`${classes.row} ${classes.header}`}>
            <div className={classes.col1}>Thông tin</div>
            <div className={classes["sm-col"]}>Công cụ</div>
          </div>
        );
  
        tableContent = props.data.map((item) => (
          <div className={classes.row} key={item.docId}>
            <div className={classes.col1}>
              <p>
                {item.data.group_name}
              </p>
              <p>{item.data.group_id}</p>
            </div>
            <div className={classes["sm-col"]}>
                <AddBtn onClick={() => props.onAdd(item.docId)}/>
            </div>
          </div>
        ));
      } else if (props.data.length === 0) {
        tableContent = (
          <NortPTag type="error">
            Không tìm thấy được dữ liệu với từ khóa bạn tìm kiếm
          </NortPTag>
        );
      }
    }
  
    return (
      <div className={classes.wrapper}>
        {headerContent}

        {tableContent}
      </div>
    );
  };

export default NhomDoiTuongTable