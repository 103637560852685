import React, {useState} from "react";
import classes from './HrEmployeeGroup.module.css'; 
import CreateEmployeeGroupForm from "./CreateEmployeeGroupForm";
import ViewEmployeeGroupForm from "./ViewEmployeeGroupForm";


const HrEmployeeGroup = () => {
  const [pageSelect, setPageSelect] = useState("create");

  const pageOnChangeHandler = (e) => {
    setPageSelect(e.target.value);
  };

  return (
    <div>
      <div className={classes["page-choice-panel"]}>
        <form>
          <div>
            <input
              type="radio"
              value="create"
              id="createOption"
              name="option"
              checked={pageSelect === "create" ? true : false}
              onChange={pageOnChangeHandler}
            />
            <label htmlFor="createOption">Tạo nhóm mới</label>
          </div>
          <div>
            <input
              type="radio"
              value="view"
              id="viewOption"
              name="option"
              checked={pageSelect === "view" ? true : false}
              onChange={pageOnChangeHandler}
            />
            <label htmlFor="viewOption">Xem nhóm</label>
          </div>
        </form>
      </div>
      {pageSelect === "create" && <CreateEmployeeGroupForm />}
      {pageSelect === "view" && <ViewEmployeeGroupForm />}
    </div>
  );
};

export default HrEmployeeGroup;
