import React, { useState } from "react";
import classes from "./XemDeXuatDanhGiaTrangTri.module.css";
import McpButtonV1 from "../UI/button/McpButtonV1";
import ReactDOM from "react-dom";
import { collection, onSnapshot, doc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import NortPTag from "../UI/systemNor/NortPTag";
import { useEffect } from "react";
import MainBackdropV3 from "../UI/backdrops/MainBackdropV3";
import { getDbs1, deleteDbDoc } from "../../hooks/fetchFirebase";
import { useSelector } from "react-redux";
import DeXuatDetailModal from "./DeXuatDetailModal";

const XemDeXuatDanhGiaTrangTri = () => {
  const [reviewList, setReviewList] = useState([]);
  const [isViewing, setIsViewing] = useState();
  const [viewingData, setViewingData] = useState({});

  const auth = useSelector((state) => state.auth.userCredential);

  useEffect(() => {
    onSnapshot(collection(db, "decor_review_request"), (snapshot) => {
      if (!snapshot.empty) {
        let requestArr = [];
        snapshot.forEach((item) =>
          requestArr.push({
            id: item.id,
            data: item.data(),
          })
        );
        const dupArr = requestArr.slice();
        const sortArr = dupArr.sort((a, b) => {
          // const aDate = Date.parse(a.data.timeCreated);
          // const bDate = Date.parse(b.data.timeCreated);
          const aDate = a.data.timeCreated;
          const bDate = b.data.timeCreated;
          if (bDate > aDate) {
            return 1;
          }
          if (aDate > bDate) {
            return -1;
          }
        });
        // setReviewList(requestArr);
        setReviewList(sortArr);
      } else {
        setReviewList([]);
      }
    });
  }, []);

  let tableContent;
  let tableHeader;

  //active and deactive modal
  const viewHandler = (data, docId) => {
    setIsViewing(true);
    setViewingData({
      data,
      docId,
    });
  };

  const closeView = () => {
    setIsViewing(false);
  };

  //delete request
  const deleteRequest = (docId) => {
    const userCol = collection(db, "employee_users");
    getDbs1(userCol).then((res) => {
      res.forEach((user) => {
        const requestDoc = doc(
          db,
          "employee_users",
          user.docId,
          "decor_review_request",
          docId
        );
        let deleteCheck = 0;
        deleteDbDoc(requestDoc).then((res) => {
          deleteCheck += res.code;
          if (deleteCheck === 0) {
            const docRef = doc(db, "decor_review_request", docId);
            deleteDbDoc(docRef); //may add nortification later
          }
        });
      });
    });
  };

  //tool contents based on screen size
  const ToolContent = (props) => {
    let toolContent;
    toolContent = (
      <div className={classes["tool-bar"]}>
        <McpButtonV1
          content="Xem"
          type="blue"
          btnType="button"
          onClick={() => viewHandler(props.data, props.dataDocId)}
        />
        {auth.position === "Giám Đốc" && (
          <McpButtonV1
            content="Xoá"
            type="red"
            btnType="button"
            onClick={() => deleteRequest(props.dataDocId)}
          />
        )}
      </div>
    );

    return <React.Fragment>{toolContent}</React.Fragment>;
  };

  //table content
  if (reviewList.length > 0) {
    tableHeader = (
      <div className={`${classes.row} ${classes.header}`}>
        <div>STT</div>
        <div>Thông tin</div>
        <div>Công cụ</div>
      </div>
    );

    tableContent = reviewList.map((item, index) => (
      <div className={classes.row} key={item.data.id}>
        <div>{index + 1}</div>
        <div className={classes.info}>
          <p>{item.data.reason}</p>
          <p>{item.data.id}</p>
        </div>
        <ToolContent data={item.data} dataDocId={item.id} />
      </div>
    ));
  } else if (reviewList.length === 0) {
    tableContent = <NortPTag type="error">Không có dữ liệu</NortPTag>;
  }

  return (
    <div className={classes["review-table"]}>
      {tableHeader}
      {tableContent}
      {isViewing &&
        ReactDOM.createPortal(
          <MainBackdropV3 onClick={closeView} />,
          document.getElementById("main-backdrop")
        )}
      {isViewing &&
        ReactDOM.createPortal(
          <DeXuatDetailModal onClose={closeView} data={viewingData} />,
          document.getElementById("main-overlay")
        )}
    </div>
  );
};

export default XemDeXuatDanhGiaTrangTri;
