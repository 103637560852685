import { db } from "../firebase/firebase";
import {
  collection,
  addDoc,
  updateDoc,
  setDoc,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
  getCountFromServer,
} from "firebase/firestore";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase/firebase";

const createDoc = async (refCollection, payload) => {
  const ref = collection(db, refCollection);
  try {
    await addDoc(ref, payload);
    return {
      code: 0,
      message: "Đã tạo",
    };
  } catch (error) {
    return {
      code: 1,
      message: error.message,
    };
  }
};

const createDocV2 = async (ref, payload) => {
  try {
    await addDoc(ref, payload);
    return {
      code: 0,
      message: "Đã tạo",
    };
  } catch (error) {
    return {
      code: 1,
      message: error.message,
    };
  }
};

const deleteDbDoc = async (ref) => {
  try {
    await deleteDoc(ref);
    return {
      code: 0,
      message: "Đã xóa thành công",
    };
  } catch (error) {
    return {
      code: 1,
      message: error.message,
    };
  }
};

const setCusDoc = async (ref, payload) => {
  try {
    await setDoc(ref, payload);
    return {
      code: 0,
      message: "Đã tạo",
    };
  } catch (error) {
    return {
      code: 1,
      message: error.message,
    };
  }
};

const updateDbDoc = async (dbRef, payload) => {
  try {
    await updateDoc(dbRef, payload);
    return {
      code: 0,
      message: "Đã cập nhật thành công",
    };
  } catch (error) {
    return {
      code: 1,
      message: error.message,
    };
  }
};

//full user update function - to copy, create new user doc in version control collection and update new user data
const fullUserUpdateDoc0 = async (id, payload) => {
  try {
    //1-find user in user db and copy
    const userDbRef = collection(db, "employee_users");
    const userQuery = query(userDbRef, where("id", "==", id));
    const userRes = await getDocs(userQuery);
    if (!userRes.empty) {
      let existingData = [];
      let docID;
      userRes.forEach((user) => {
        existingData.push(user.data());
        docID = user.id;
      });
      if (existingData.length < 2) {
        //2-add new doc to version_control subcollection
        const userSubRef = collection(
          db,
          "employee_users",
          `${docID}`,
          "version_control"
        );
        addDoc(userSubRef, existingData[0])
          .then((res) => {
            console.log(docID);
            //3-update existing doc
            const existingRef = doc(db, "employee_users", docID);
            updateDoc(existingRef, payload);
          })
          .catch((err) => {
            throw new Error(err.message);
          });
      } else {
        throw new Error(
          "Phát sinh lỗi hệ thống, vui lòng gửi lỗi và ID của bạn đến kỹ thuật để kiểm tra"
        );
      }
    } else {
      throw new Error(
        "Phát sinh lỗi hệ thống, vui lòng gửi lỗi và ID của bạn đến kỹ thuật để kiểm tra"
      );
    }
  } catch (error) {
    console.log(error.message);
    return {
      code: 1,
      message: error.message,
    };
  }
};

const fullUserUpdateDoc = async (id, payload) => {
  console.log(id, payload);
  try {
    console.log("new update v1");
    //1-find whether user is existed and copy
    const checkUserDb = async () => {
      const userDbRef = collection(db, "employee_users");
      const userQuery = query(userDbRef, where("id", "==", id));
      const userRes = await getDocs(userQuery);
      let existingData = [];
      let docID;
      if (!userRes.empty) {
        userRes.forEach((user) => {
          existingData.push(user.data());
          docID = user.id;
        });
      }
      return {
        existingData,
        docID,
      };
    };

    //2-update doc
    //2-1 add new doc to version_control
    const storeVersion = async () => {
      try {
        const userRes = await checkUserDb();
        console.log(userRes);
        if (userRes.existingData.length < 2) {
          const userSubRef = collection(
            db,
            "employee_users",
            userRes.docID,
            "version_control"
          );
          addDoc(userSubRef, userRes.existingData[0]);
          return {
            status: true,
            docID: userRes.docID,
          };
        } else {
          throw new Error(
            "Phát sinh lỗi hệ thống, vui lòng gửi lỗi và ID của bạn đến kỹ thuật để kiểm tra"
          );
        }
      } catch (error) {
        return error.message;
      }
    };

    //2-2 update exsting doc
    const updateOverwrite = async (payload) => {
      try {
        const storeVersionResult = await storeVersion();
        console.log(storeVersionResult);

        if (storeVersionResult.status) {
          const existingRef = doc(
            db,
            "employee_users",
            storeVersionResult.docID
          );
          updateDoc(existingRef, payload);
        } else {
          throw new Error(storeVersionResult);
        }
        return {
          code: 0,
          message: "Đã cập nhật thành công!",
        };
      } catch (error) {
        return error.message;
      }
    };

    return await updateOverwrite(payload);
  } catch (error) {
    return error.message;
  }
};

const fullUserUpdateDoc2 = async (id, dbID, payload) => {
  try {
    console.log("new update v1");
    //1-find whether user is existed and copy
    const checkUserDb = async () => {
      const userDbRef = collection(db, "employee_users");
      const userQuery = query(userDbRef, where("id", "==", id));
      const userRes = await getDocs(userQuery);
      let existingData = [];
      let docID = id;
      if (!userRes.empty) {
        userRes.forEach((user) => {
          existingData.push(user.data());
          docID = user.id;
        });
      }
      return {
        existingData,
        docID,
      };
    };

    //2-update doc
    //2-1 add new doc to version_control
    const storeVersion = async () => {
      try {
        const userRes = await checkUserDb();
        if (
          userRes.existingData.length > 0 &&
          userRes.existingData.length < 2
        ) {
          const userSubRef = collection(
            db,
            "employee_users",
            userRes.docID,
            "version_control"
          );
          addDoc(userSubRef, userRes.existingData[0]);
          return {
            status: true,
            docID: userRes.docID,
          };
        } else if (userRes.existingData.length < 1) {
          return {
            status: false,
            docID: dbID,
          };
        } else {
          throw new Error(
            "Phát sinh lỗi hệ thống, vui lòng gửi lỗi và ID của bạn đến kỹ thuật để kiểm tra"
          );
        }
      } catch (error) {
        return {
          code: 1,
          message: error.message,
        };
      }
    };

    //2-2 update exsting doc
    const updateOverwrite = async (payload) => {
      console.log("test function run");
      try {
        const storeVersionResult = await storeVersion();
        console.log(storeVersionResult);

        if (storeVersionResult.status == true) {
          console.log("co user -> cap nhat employees va user");
          const existingRef = doc(
            db,
            "employee_users",
            storeVersionResult.docID
          );
          const updateRes = await updateDoc(existingRef, payload);
          return {
            code: 0,
            message: "Đã cập nhật thành công!",
          };
        } else if (storeVersionResult.status == false) {
          // throw new Error(storeVersionResult);
          return {
            code: 0,
            message:
              "Đã cập nhật thành công. Lưu ý: người dùng này chưa kích hoạt tài khoản",
          };
        } else {
          return {
            code: 1,
            message: storeVersionResult.message,
          };
        }
      } catch (error) {
        return {
          code: 1,
          message: error.message,
        };
      }
    };

    return updateOverwrite(payload);
  } catch (error) {
    return error.message;
  }
};

const resetPassDb = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return {
      code: 0,
      message:
        "Hệ thống đã gửi email đặt lại mật khẩu, bạn hãy kiểm tra trong email của mình",
    };
  } catch (error) {
    return {
      code: 1,
      message: `Lỗi hệ thống: ${error.message}`,
    };
  }
};

const countDb = async (ref) => {
  try {
    const snapshot = await getCountFromServer(ref);
    return snapshot.data();
  } catch (err) {
    return err;
  }
};

// this getdbs return array of image data
const getDbs = async (ref) => {
  try {
    const docs = await getDocs(ref);
    // if (docs.length > 0) {
    //   let arr = [];
    //   docs.forEach((item) => arr.push(item.data()));
    //   return arr;
    // } else {
    //   throw new Error("Không có dữ liệu");
    // }

    let arr = [];
    docs.forEach((item) =>
      arr.push({
        imgUID: item.id,
        imgData: item.data(),
      })
    );
    return arr;
  } catch (err) {
    return err;
  }
};

// this getDbs1 return array of doc data
const getDbs1 = async (ref) => {
  try {
    const docs = await getDocs(ref);
    // if (docs.length > 0) {
    //   let arr = [];
    //   docs.forEach((item) => arr.push(item.data()));
    //   return arr;
    // } else {
    //   throw new Error("Không có dữ liệu");
    // }

    let arr = [];
    docs.forEach((item) =>
      arr.push({
        docId: item.id,
        data: item.data(),
      })
    );
    return arr;
  } catch (err) {
    return err;
  }
};

export {
  createDoc,
  createDocV2,
  updateDbDoc,
  setCusDoc,
  fullUserUpdateDoc,
  fullUserUpdateDoc0,
  fullUserUpdateDoc2,
  deleteDbDoc,
  resetPassDb,
  countDb,
  getDbs,
  getDbs1,
};
