import { useSelector } from "react-redux";

const useCheckPermission = () => {
  const userCredential = useSelector((state) => state.auth.userCredential);

  let permissionObject = {
    acc: null,
    fo: null,
    kit: null,
    ma: null, //management
    hr: null,
    mkt: null,
    sec: null,
    stw: null,
    hk: null,
    me: null,
    fb: null,
    sale: null,
    it: null,
    remove: null,
    gm: null, //GM
  };

  let divisionObject = {
    culinary: null,
    room: null,
    office: null,
    snm: null,
    technical: null,
  };

  switch (userCredential.department) {
    case "Kế Toán":
      permissionObject.acc = true;
      break;
    case "Tiền Sảnh":
      permissionObject.fo = true;
      break;
    case "Bếp":
      permissionObject.kit = true;
      break;
    case "Quản Lý":
      permissionObject.ma = true;
      break;
    case "Nhân Sự":
      permissionObject.hr = true;
      break;
    case "Marketing":
      permissionObject.mkt = true;
      break;
    case "An Ninh":
      permissionObject.sec = true;
      break;
    case "Steward":
      permissionObject.stw = true;
      break;
    case "Buồng Phòng":
      permissionObject.hk = true;
      break;
    case "Bảo Trì":
      permissionObject.me = true;
      break;
    case "Ẩm Thực":
      permissionObject.fb = true;
      break;
    case "Kinh Doanh":
      permissionObject.sale = true;
      break;
    case "remove":
      permissionObject.remove = true;
      break;
    default:
  }

  if (permissionObject.kit || permissionObject.fb || permissionObject.stw) {
    divisionObject.culinary = true;
  }

  if (permissionObject.hk || permissionObject.fo) {
    divisionObject.room = true;
  }

  if (permissionObject.hr || permissionObject.acc) {
    divisionObject.office = true;
  }

  if (permissionObject.mkt || permissionObject.sale) {
    divisionObject.snm = true;
  }

  const setTrueObject = (arr) => {
    Object.keys(arr).forEach((item) => {
      if (item !== "remove") {
        arr[item] = true;
      }
    });
  };

  if (userCredential.isSuperAdmin || userCredential.role === "admin") {
    setTrueObject(permissionObject);
    setTrueObject(divisionObject);
  }

  return {
    departmentAccess: permissionObject,
    divisionAccess: divisionObject,
  };
};

export { useCheckPermission };
