import React, { useState, useRef, useEffect, Fragment } from "react";
import SearchIcon from "@mui/icons-material/Search";
import classes from "./DuLieuHinhAnhSearchBar.module.css";
import SelectFieldMonAn from "../department/KIT/SelectFieldMonAn";
import McpButtonV1 from "../UI/button/McpButtonV1";
import { db } from "../../firebase/firebase";
import { collection, onSnapshot } from "firebase/firestore";

// optionsList : {
//     value,
//     textContent,
//     disabled,
//   },
//   id,
//   label,
//   onClick,
//   onClickSub,
//   defaultOption

/*
  Originated from KitSearBar.js, customized for HinhAnhMonAn page
  Main purpose is to get search keywords and select value.   
 */

const DuLieuHinhAnhSearchBar = (props) => {
  const [optionValue, setOptionValue] = useState("Món ăn");
  const [foodGroup, setFoodGroup] = useState([]);
  const [outlet, setOutlet] = useState([]);
  const [groupChoice, setGroupChoice] = useState();
  const [outletChoice, setOutletChoice] = useState();

  const inputRef = useRef();
  const formRef = useRef();

  const optionsList = [
    {
      value: "Món ăn",
      textContent: "Món ăn",
      disabled: false,
      key: 1,
    },
    {
      value: "Nhóm món ăn",
      textContent: "Nhóm món ăn",
      disabled: false,
      key: 2,
    },
    {
      value: "Outlet",
      textContent: "Outlet",
      disabled: false,
      key: 3,
    },
    {
      value: "no_review",
      textContent: "Chưa có đánh giá hình ảnh",
      disabled: false,
      key: 4,
    },
  ];

  //fetch data from database
  useEffect(() => {
    const groupRef = collection(db, "food_group");
    const outletRef = collection(db, "outlet");

    const fetchGroup = onSnapshot(groupRef, (snapshot) => {
      if (!snapshot.empty) {
        let arr = [];
        snapshot.forEach((item) =>
          arr.push({
            value: item.data().foodGroup,
            textContent: item.data().foodGroup,
            disabled: false,
          })
        );
        setFoodGroup(arr);
      } else {
        //set bao loi
      }
    });

    const fetchOulet = onSnapshot(outletRef, (snapshot) => {
      if (!snapshot.empty) {
        let arr = [];
        snapshot.forEach((item) => {
          arr.push({
            value: item.data().outlet,
            textContent: item.data().outlet,
            disabled: false,
          });
        });
        console.log(arr);
        setOutlet(arr);
      } else {
      }
    });
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    let keyword;
    let secondKw; 
    switch (optionValue) {
      case "Món ăn":
        keyword = inputRef.current.value;
        break;
      case "Nhóm món ăn":
        keyword = groupChoice;
        break;
      case "Outlet":
        keyword = outletChoice;
        break;
      case "no_review":
        keyword = groupChoice;
        secondKw = outletChoice
        break;
      // default:
      //   keyword = inputRef.current.value;
      //   break;
    }
    props.onSearch({
      keyword: keyword,
      filterGroup: optionValue,
      secondKw,
    });
  };

  const loadAll = (e) => {
    // formRef.current.reset();
    // submitHandler(e);
    props.onSearch({
      keyword: "",
      filterGroup: "Món ăn",
    });
  };

  //fetch select value
  const fetchSearchOption = (value) => {
    setOptionValue(value);
  };

  //get foodgroup option
  const getFoodGroup = (value) => {
    setGroupChoice(value);
  };

  //get outlet option
  const getOutlet = (value) => {
    setOutletChoice(value);
  };

  //option content
  let optionContent;
  switch (optionValue) {
    case "Món ăn":
      optionContent = (
        <div className={classes["text-field"]}>
          <button type="submit">
            <SearchIcon
              sx={{
                backgroundColor: "#30BE75",
                color: "white",
                borderRadius: "4px",
              }}
            />
          </button>
          <input
            type="text"
            placeholder="Nhập từ khoá tìm kiếm"
            ref={inputRef}
          />
        </div>
      );
      break;
    case "Nhóm món ăn":
      optionContent = (
        <SelectFieldMonAn
          id="foodGroup"
          label="Chọn nhóm món ăn"
          optionsList={foodGroup}
          onClick={getFoodGroup}
          defaultOption={foodGroup[0].value}
        />
      );
      break;
    case "Outlet":
      optionContent = (
        <SelectFieldMonAn
          id="outlet"
          label="Chọn outlet"
          optionsList={outlet}
          onClick={getOutlet}
          defaultOption={outlet[0].value}
        />
      );
      break;
    case "no_review":
      optionContent = (
        <Fragment>
          <SelectFieldMonAn
            id="filterGroup"
            label="Chọn nhóm món ăn"
            optionsList={foodGroup}
            onClick={getFoodGroup}
            defaultOption={foodGroup[0].value}
          />
          <SelectFieldMonAn
            id="filterOulet"
            label="Chọn outlet"
            optionsList={outlet}
            onClick={getOutlet}
            defaultOption={outlet[0].value}
          />
        </Fragment>
      );
      break;
    default:
      optionContent = (
        <div className={classes["text-field"]}>
          <button type="submit">
            <SearchIcon
              sx={{
                backgroundColor: "#30BE75",
                color: "white",
                borderRadius: "4px",
              }}
            />
          </button>
          <input
            type="text"
            placeholder="Nhập từ khoá tìm kiếm"
            ref={inputRef}
          />
        </div>
      );
      break;
  }

  return (
    <form
      className={classes["search-container"]}
      onSubmit={submitHandler}
      ref={formRef}
    >
      <div>
        <SelectFieldMonAn
          id="searchSelect"
          label="Lựa chọn phạm vi tìm kiếm: "
          optionsList={optionsList}
          onClick={fetchSearchOption}
          defaultOption="Món ăn"
        />
      </div>
      {optionContent}
      <div className={classes["btn-panel"]}>
        <McpButtonV1 type="green" content="Tìm" onClick={submitHandler} />
        <McpButtonV1 type="blue" content="Tải toàn bộ" onClick={loadAll} />
        {/* <McpButtonV1 type="red" btnType="reset" content="Huỷ" /> */}
      </div>
    </form>
  );
};

export default DuLieuHinhAnhSearchBar;
