import React, { useState } from "react";
import ReactDOM from "react-dom";
import classes from "./ApproveMultipleBtn.module.css";
import MainBackdropV3 from "../backdrops/MainBackdropV3";
import ApproveModal from "../../approve/ApproveModal";

const ApproveMultipleBtn = (props) => {
  const [isActive, setIsActive] = useState();

  const approveHandler = () => {
    setIsActive(true);
  };

  const closeModal = () => {
    setIsActive(false);
  };

  return (
    <React.Fragment>
      {isActive &&
        ReactDOM.createPortal(
          <MainBackdropV3 onClick={closeModal} />,
          document.getElementById("main-backdrop")
        )}
      {isActive &&
        ReactDOM.createPortal(
          <ApproveModal
            data={props.data}
            onClick={props.onClick}
            onClose={closeModal}
            outlet={props.outlet}
          />,
          document.getElementById("main-overlay")
        )}
      <button className={classes.button} type="button" onClick={approveHandler}>
        Duyệt
      </button>
    </React.Fragment>
  );
};

export default ApproveMultipleBtn;
