import React from "react";
import ModalWrapperV4 from "../../UI/modals/ModalWrapperV4";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./ViewEmployeeGroupModal.module.css";

const ViewEmployeeGroupModal = (props) => {
  let tableContent;
  if (props.data && props.data.members.length > 0) {
    tableContent = props.data.members.map((item, index) => (
      <div className={classes.row} key={item.docId}>
        <div>{index + 1}</div>
        <div>{`${item.data.familyName} ${item.data.name}`}</div>
        <div>{item.data.department}</div>
      </div>
    ));
  }

  return (
    <ModalWrapperV4>
      <div className={classes.panel}>
        <button onClick={props.onClick}>
          <CloseIcon />
        </button>
      </div>
      <div className={classes.info}>
        <p>
          <span className={classes.label}>Tên nhóm: </span>{props.dataId && props.dataId.col1}
        </p>
      </div>
      <div className={classes.table}>
        <div className={`${classes.row} ${classes.header}`}>
          <div>STT</div>
          <div>Thành viên</div>
          <div>Bộ phận</div>
        </div>
        {tableContent}
      </div>
    </ModalWrapperV4>
  );
};

export default ViewEmployeeGroupModal;
