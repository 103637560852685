import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext,
} from "react";
import ReactDOM from "react-dom";
import classes from "./FormDeXuatDanhGiaTrangTri.module.css";
import AccordionSM from "../UI/accordion/AccordionSM";
import AccordionBtnV1 from "../UI/accordion/AccordionBtnV1";
import SearchBar from "../panel/SearchBar";
import MenuBtn from "../department/KIT/MenuBtn";
import RaterBtn from "../department/KIT/RaterBtn";
import {
  collection,
  query,
  where,
  onSnapshot,
  serverTimestamp,
  doc,
} from "firebase/firestore";
import Loader from "../UI/Loader";
import { db } from "../../firebase/firebase";
import { SysNorRightFix } from "../UI/systemNor/SysNorRightFix";
import RaterModal from "../department/KIT/RaterModal";
import MainBackdropV3 from "../UI/backdrops/MainBackdropV3";
import SearchDishTable from "../department/KIT/SearchDishTable";
import { getDbs1, setCusDoc, updateDbDoc } from "../../hooks/fetchFirebase";
import NortPTag from "../UI/systemNor/NortPTag";
import { useSelector } from "react-redux";
import ChonNhomDoiTuong from "../chon_nhom_doi_tuong/ChonNhomDoiTuong";
import SelectImgModal from "./SelectImgModal";
import CompCtx from "./CompCtx";

const FormDeXuatDanhGiaTrangTri = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [dataReady, setDataReady] = useState(false);
  const [sysNort, setSysNort] = useState([]);
  const [formNort, setFormNort] = useState([]);
  const [user, setUser] = useState();
  const [dishList, setDishList] = useState();
  const [employeeGroup, setEmloyeeGroup] = useState();
  const [generatedId, setGeneratedId] = useState();
  const [userSearchResult, setUserSearchResult] = useState(null);
  const [dishSearchResult, setDishSearchResult] = useState(null);
  const [userCart, setUserCart] = useState([]);
  const [dishCart, setDishCart] = useState([]);
  const [isViewingRater, setIsViewingRater] = useState(false);
  const [isViewingMenu, setIsViewingMenu] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pickDishStage, setPickDishStage] = useState("overview");

  const reasonInputRef = useRef();

  const compCtx = useContext(CompCtx);

  const auth = useSelector((state) => state.auth.userCredential);

  const generateIdFn = () => {
    const randomNum = Math.floor(Math.random() * 1000);
    const generatedID = `DXDGTTMA-${randomNum}`;
    setGeneratedId(generatedID);
  };

  //fetch data
  useEffect(() => {
    //fetch and wait to load all data

    //fetch activated users and exclude deleted users
    const fetchUser = new Promise((resolve, reject) => {
      const userDbQuery = query(
        collection(db, "employee_users"),
        where("department", "!=", "remove")
      );
      onSnapshot(userDbQuery, (snapshot) => {
        if (!snapshot.isEmpty) {
          let userArr = [];
          snapshot.forEach((item) =>
            userArr.push({
              id: item.id,
              data: item.data(),
            })
          );
          setUser(userArr);
          resolve(true);
        } else {
          resolve(true); // still true but with no users
        }
      });
    });

    //fetch pre-defined employee group
    const fetchEmployeeGroup = new Promise((resolve) => {
      const groupRef = collection(db, "nhom_nhan_su");
      getDbs1(groupRef).then((res) => {
        setEmloyeeGroup(res);
        resolve(true);
      });
    });

    //fetch dish from db

    const fetchDish = new Promise((resolve) => {
      onSnapshot(collection(db, "dish_list"), (snapshot) => {
        let dishArr = [];
        if (!snapshot.empty) {
          snapshot.forEach((item) =>
            dishArr.push({
              id: item.id,
              data: item.data(),
            })
          );
          setDishList(dishArr);
          resolve(true);
        } else {
          resolve(true); //still true but with no dish
        }
      });
    });

    Promise.all([fetchUser, fetchDish, fetchEmployeeGroup]).then(() => {
      generateIdFn();
      setIsFetching(false);
      setDataReady(true);
    });
  }, []);

  //search user
  const searchUser = (keyword) => {
    const result = user.filter((item) => {
      const lowerCaseKeyword = keyword.toLowerCase();
      const fullName = item.data.familyName + " " + item.data.name;
      const lowerCaseFullName = fullName.toLowerCase();
      return lowerCaseFullName.includes(lowerCaseKeyword);
    });
    setUserSearchResult(result);
  };

  //clear search user
  const resetUserSearch = () => {
    setUserSearchResult(null);
  };

  //search dish
  const searchDish = (keyword) => {
    const result = dishList.filter((item) => {
      const lowerCaseKeyword = keyword.toLowerCase();
      const lowerCaseDishName = item.data.name.toLowerCase();
      return lowerCaseDishName.includes(lowerCaseKeyword);
    });
    console.log(result);
    setDishSearchResult(result);
  };

  //clear search dish
  const resetDishSearch = () => {
    // setDishSearchResult([]);
    setDishSearchResult(null);
  };

  //remove single sys nort
  const removeNort = (key) => {
    setSysNort((prev) => prev.filter((item) => item.key !== key));
  };

  //remove single form nort
  const removeFormNort = (key) => {
    setFormNort((prev) => prev.filter((item) => item.key !== key));
  };

  //add to cart function
  //add user to cart
  const addUserToCartFn = (user) => {
    //check duplicated user
    const nortKey = Math.floor(Math.random() * 10000); //generate local nort key
    const duplicateArr = userCart.filter((item) => item.id === user.id);
    let nameString = "";
    if (duplicateArr.length > 0) {
      duplicateArr.forEach((item) => (nameString += item.fullName + ", "));
      setSysNort((prev) => [
        ...prev,
        {
          type: "error",
          msg: `Đối tượng ${nameString} này đã có trong danh sách`,
          key: nortKey,
        },
      ]);
      setTimeout(() => removeNort(nortKey), 2500);
    } else {
      setUserCart((prev) => [...prev, user]);
      setSysNort((prev) => [
        ...prev,
        {
          type: "success",
          msg: "Đã thêm đối tượng vào danh sách",
          key: nortKey,
        },
      ]);
      setTimeout(() => removeNort(nortKey), 2500);
      // setTimeout(() => console.log(nortKey), 1000)
    }
  };

  //add dish to cart
  // -new approach
  const addDishToCartFn = (dish) => {
    //check duplicated user
    const nortKey = Math.floor(Math.random() * 10000); //generate local nort key
    const duplicateArr = compCtx.dishCartCtx.filter(
      (item) => item.dish.id === dish.id
    );
    if (duplicateArr.length > 0) {
      setSysNort((prev) => [
        ...prev,
        {
          type: "error",
          msg: "Món ăn này đã có trong danh sách",
          key: nortKey,
        },
      ]);
      setTimeout(() => removeNort(nortKey), 2500);
    } else {
      const dishData = {
        dish,
        img: [],
      };
      compCtx.setDishCartCtx((prev) => [...prev, dishData]);
      setSysNort((prev) => [
        ...prev,
        {
          type: "success",
          msg: "Đã thêm món ăn vào danh sách",
          key: nortKey,
        },
      ]);
      setTimeout(() => removeNort(nortKey), 2500);
    }
  };

  //add group to cart
  const addGroup = (value) => {
    console.log(value);
    let newArr = [];
    const filterArr = employeeGroup.filter((item) => item.docId === value);
    filterArr[0].data.group_members.forEach((member) => {
      newArr.push({
        id: member.docId,
        department: member.data.department,
        fullName: `${member.data.familyName} ${member.data.name}`,
      });

      const user = {
        id: member.docId,
        department: member.data.department,
        fullName: `${member.data.familyName} ${member.data.name}`,
      };
      addUserToCartFn(user);
    });
  };

  //remove from cart function
  const userCartRemove = (id) => {
    setUserCart((prev) => prev.filter((item) => item.id !== id));
  };
  const dishCartRemove = (id) => {
    compCtx.setDishCartCtx((prev) =>
      prev.filter((item) => item.dish.id !== id)
    );
  };

  //reset all form input
  const resetForm = () => {
    reasonInputRef.current.value = null;
    setUserCart([]);
    compCtx.setDishCartCtx([]);
  };

  //modal active and deactive
  const raterModalClose = () => {
    setIsViewingRater(false);
  };
  const menuModalClose = () => {
    setIsViewingMenu(false);
  };
  const raterModalActive = () => {
    menuModalClose();
    setIsViewingRater(true);
  };
  const menuModalActive = () => {
    raterModalClose();
    setIsViewingMenu(true);
  };

  //reused set nortification function
  const setFormNortMsg = (type, msg) => {
    const randomKey = Math.floor(Math.random() * 10000);
    setFormNort((prev) => [
      ...prev,
      {
        type,
        msg,
        key: randomKey,
      },
    ]);
    setTimeout(() => {
      setFormNort(randomKey);
    }, 3000);
    setIsSubmitting(false);
  };

  //form submit handler
  const formSubmitHandler = (e) => {
    e.preventDefault();
    setFormNort([]);
    setIsSubmitting(true);

    const reasonCheck =
      reasonInputRef.current.value.trim() === "" ? false : true;

    let imgSelectionCheck = true;

    compCtx.dishCartCtx.length > 0 &&
      compCtx.dishCartCtx.forEach((item) => {
        if (item.img.length > 0) {
          imgSelectionCheck = imgSelectionCheck && true;
        } else {
          imgSelectionCheck = imgSelectionCheck && false;
        }
      });

    if (
      userCart.length > 0 &&
      compCtx.dishCartCtx.length > 0 &&
      reasonCheck &&
      imgSelectionCheck
    ) {
      let submitObject = {
        id: generatedId,
        reason: reasonInputRef.current.value.trim(),
        timeCreated: serverTimestamp(),
        creator: auth.fullName,
        creatorEmail: auth.email,
        reviewers: userCart,
        dishes: compCtx.dishCartCtx,
      };
      console.log(submitObject);

      //add to db
      const dbRef = doc(db, "decor_review_request", generatedId);
      setCusDoc(dbRef, submitObject).then((res) => {
        if (res.code === 0) {
          //move img to 'de xuat thay doi' category
          const updateImgDocs = new Promise((resolve, reject) => {
            submitObject.dishes.forEach((dishItem) => {
              let check = 0;
              dishItem.img.forEach((img) => {
                const imgDocRef = doc(
                  db,
                  "dish_list",
                  dishItem.dish.id,
                  "images",
                  img.imgUID
                );
                updateDbDoc(imgDocRef, {
                  category: "Đề xuất thay đổi",
                  updater: auth.fullName,
                  updatedDate: serverTimestamp(),
                }).then((res) => {
                  check += res.code;
                  if (check === 0) {
                    resolve(res.message);
                  } else {
                    reject(res.message);
                  }
                });
              });
            });
          });

          //generate decor review request for each users in the array.
          const generateUserRequest = new Promise((resolve, reject) => {
            let check = 0;
            const userRequestObject = {
              id: generatedId,
              reason: reasonInputRef.current.value.trim(),
              timeCreated: serverTimestamp(),
              creator: auth.fullName,
              creatorEmail: auth.email,
              dishes: compCtx.dishCartCtx,
              status: "khởi tạo",
            };
            submitObject.reviewers.forEach((user) => {
              console.log(user.id);
              const docRef = doc(
                db,
                "employee_users",
                user.id,
                "decor_review_request",
                generatedId
              );
              setCusDoc(docRef, userRequestObject).then((res) => {
                check += res.code;
                if (check === 0) {
                  resolve(res.message);
                } else {
                  reject(res.message);
                }
              });
            });
          });

          // generateUserRequest.then(res => console.log(res));

          Promise.all([updateImgDocs, generateUserRequest])
            .then((res) => {
              const randomKey = Math.floor(Math.random() * 10000);
              setFormNort((prev) => [
                ...prev,
                {
                  type: "success",
                  msg: "Đề xuất đã được tạo",
                  key: randomKey,
                },
              ]);
              resetForm();
              setTimeout(() => removeFormNort(randomKey), 3000);
            })
            .catch((err) => {
              const randomKey = Math.floor(Math.random() * 10000);
              setFormNort((prev) => [
                ...prev,
                {
                  type: "error",
                  msg: err.message,
                  key: randomKey,
                },
              ]);
              resetForm();
              setTimeout(() => removeFormNort(randomKey), 3000);
            });
        } else {
          const randomKey = Math.floor(Math.random() * 10000);
          setFormNort((prev) => [
            ...prev,
            {
              type: "error",
              msg: res.message,
              key: randomKey,
            },
          ]);
          setTimeout(() => removeFormNort(randomKey), 3000);
        }
        setIsSubmitting(false);
      });
    } else {
      if (userCart.length === 0) {
        setFormNortMsg("error", "Chưa có đối tượng thử món");
      }
      if (compCtx.dishCartCtx.length === 0) {
        setFormNortMsg("error", "Chưa có món ăn trong danh sách");
      }
      if (!reasonCheck) {
        setFormNortMsg("error", "Bạn chưa có điền tên hoặc thông tin đề xuất");
      }
      if (!imgSelectionCheck) {
        setFormNortMsg("error", "Bạn chưa chọn hình ảnh cho tất cả món ăn");
      }
      if (
        userCart.length > 0 &&
        compCtx.dishCartCtx.length > 0 &&
        reasonCheck &&
        imgSelectionCheck
      ) {
        setFormNortMsg(
          "error",
          "Lỗi hệ thống, vui lòng liên hệ IT để kiểm tra"
        );
      }
    }
  };

  //loading nortification
  let loadingNort;
  if (isFetching) {
    loadingNort = (
      <div className={classes["loading-nort"]}>
        <Loader />
        <p>Đang thực hiện đồng bộ dữ liệu</p>
      </div>
    );
  }

  //define content for picking dish images stage
  let stageContent;
  switch (pickDishStage) {
    case "overview":
      stageContent = (
        <Fragment>
          <SearchBar onSearch={searchDish} onReset={resetDishSearch} />
          <SearchDishTable data={dishSearchResult} onAdd={addDishToCartFn} />
        </Fragment>
      );
      break;
    case "select":
      stageContent = (
        <Fragment>
          <button onClick={() => setPickDishStage("overview")}>quay ve</button>
          <p>Chon hinh</p>
        </Fragment>
      );
  }

  //search audience and dish content
  let searchForm;
  if (dataReady) {
    const userO = {
      search: searchUser,
      reset: resetUserSearch,
      data: userSearchResult,
      add: addUserToCartFn,
    };
    const dishO = {
      search: searchDish,
      reset: resetDishSearch,
      data: dishSearchResult,
      add: addDishToCartFn,
    };
    const groupO = {
      data: employeeGroup,
      add: addGroup,
    };

    console.log(employeeGroup);

    searchForm = (
      <React.Fragment>
        <ChonNhomDoiTuong user={userO} dish={dishO} group={groupO} />

        <AccordionBtnV1 title="Chọn món ăn">{stageContent}</AccordionBtnV1>
      </React.Fragment>
    );
  } else {
    searchForm = null;
  }

  //system nortification content
  let nortContent = [];
  if (sysNort.length > 0) {
    nortContent = <SysNorRightFix data={sysNort} onClick={removeNort} />;
  }

  //form nortification content
  let formNortContent;
  if (formNort.length > 0) {
    formNortContent = formNort.map((item) => (
      <NortPTag key={item.key} type={item.type}>
        {item.msg}
      </NortPTag>
    ));
  }

  console.log(compCtx.dishCartCtx, auth);

  return (
    <AccordionSM>
      <AccordionBtnV1 title="Thông tin">
        <div className={classes.description}>
          <div>
            <p>
              <span className={classes.id}>ID: </span>
              <span className={classes.idNum}>{generatedId}</span>
            </p>
          </div>
          <div>
            <label htmlFor="formNote">Tên hoặc thông tin đề xuất</label>
            <textarea rows="3" id="formNote" ref={reasonInputRef} required />
          </div>
          <div className={classes["icon-panel"]}>
            <div>
              <MenuBtn
                dishCount={compCtx.dishCartCtx.length}
                onClick={menuModalActive}
              />
            </div>
            <div>
              <RaterBtn
                userCount={userCart.length}
                onClick={raterModalActive}
              />
            </div>
          </div>
        </div>
      </AccordionBtnV1>
      {loadingNort}
      {searchForm}
      <div className={classes["submit-panel"]}>
        <button
          className={classes["submit-btn"]}
          type="button"
          onClick={formSubmitHandler}
          disabled={isSubmitting ? true : false}
        >
          Tạo đề xuất
        </button>
        {isSubmitting && <Loader />}
      </div>
      {formNortContent}

      {nortContent}
      {isViewingRater &&
        ReactDOM.createPortal(
          <MainBackdropV3 onClick={raterModalClose} />,
          document.getElementById("main-backdrop")
        )}
      {isViewingRater &&
        ReactDOM.createPortal(
          <RaterModal
            onClose={raterModalClose}
            data={userCart}
            onRemove={userCartRemove}
          />,
          document.getElementById("main-overlay")
        )}
      {isViewingMenu &&
        ReactDOM.createPortal(
          <MainBackdropV3 onClick={menuModalClose} />,
          document.getElementById("main-backdrop")
        )}

      {isViewingMenu &&
        ReactDOM.createPortal(
          <SelectImgModal
            onClose={menuModalClose}
            data={dishCart}
            onRemove={dishCartRemove}
          />,
          document.getElementById("main-overlay")
        )}
    </AccordionSM>
  );
};

export default FormDeXuatDanhGiaTrangTri;
