import React from 'react';
import PeopleIcon from '@mui/icons-material/People';
import classes from './RaterBtn.module.css'

/*
  props list : {
    userCount, 
    onClick, 
  }
*/

const RaterBtn = (props) => {

    let countContent = null; 
    if(props.userCount > 0) {
      countContent = <div className={classes.count}>{props.userCount}</div>
    }

  return (
    <React.Fragment>
        <button key={props.userCount} className={classes.btn} onClick={props.onClick}>
            <PeopleIcon sx={{color: '#30BE75', fontSize: '30px' }}/>
            {/* {props.userCount && <div className={classes.count}>{props.userCount}</div>} */}
            {countContent}
        </button>
    </React.Fragment>
  )
}

export default RaterBtn