import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import classes from "./CloseTopBar.module.css";

const CloseTopBar = (props) => {
  return (
    <div className={classes.wrapper}>
      <HighlightOffIcon onClick={props.onClick} sx={{ color: "606060" }} />
    </div>
  );
};

export default CloseTopBar;
