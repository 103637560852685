import React from "react";
import AddBtn from "../../UI/button/AddBtn";

const AddDishBtn = (props) => {

  return (
    <React.Fragment>
      <AddBtn onClick={() => props.onAdd(props.data)} />
    </React.Fragment>
  );
};

export default AddDishBtn;
