import { Fragment, useState, useEffect } from "react";
import classes from "./SliderDecor.module.css";
import demoImg from "../../assets/images/img-placeholder-2.png";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Rating } from "@mui/material";
import McpButtonV1 from "../UI/button/McpButtonV1";
import { useSelector } from "react-redux";
import { db } from "../../firebase/firebase";
import { createDocV2 } from "../../hooks/fetchFirebase";
import {
  collection,
  onSnapshot,
  serverTimestamp,
  doc,
} from "firebase/firestore";
import NortPTag from "../UI/systemNor/NortPTag";

/**
 * props list: {data}
 */

const SliderDecor = (props) => {
  const [imgIndex, setImgIndex] = useState(0);
  const [avgScore, setAvgScore] = useState(0);
  const [rating, setRating] = useState(0);
  const [didReview, setDidReview] = useState(false);
  const [lastReview, setLastReview] = useState();
  const [conceptRatingArr, setConceptRatingArr] = useState([]);
  const [formErr, setFormErr] = useState();

  const user = useSelector((state) => state.auth.userCredential);

  const isAdmin = user.role === "admin" || user.position === "Giám Đốc";

  const imgArr = props.data.image;
  console.log(props.data);

  //fetch score from db
  //check whether user review or not
  useEffect(() => {
    if (imgArr.length > 0) {
      // const colRef = collection(
      //   db,
      //   "dish_list",
      //   props.data.docId,
      //   "images",
      //   props.data.image[imgIndex].imgUID,
      //   "dish_concept_rating"
      // );

      const colRef = collection(
        db,
        "dish_list",
        props.data.docId,
        "images",
        props.data.image[imgIndex].imgUID,
        "decor-rating"
      );

      const fetchRating = onSnapshot(colRef, (snapshot) => {
        if (!snapshot.empty) {
          let ratingArr = [];
          snapshot.forEach((item) => {
            ratingArr.push({
              docId: item.id,
              data: item.data(),
            });
          });
          setConceptRatingArr(ratingArr);

          let totalScore = 0;
          ratingArr.forEach((item) => {
            totalScore += item.data.score;
          });

          const inScopeAvgScore = totalScore / ratingArr.length;
          setAvgScore(inScopeAvgScore);

          const userReviewArr = ratingArr.filter(
            (item) => item.data.raterUID === user.uid
          );
          if (userReviewArr.length > 0) {
            setDidReview(true);
            setLastReview({
              rating: userReviewArr[userReviewArr.length - 1].data.score,
              timeCreated:
                userReviewArr[userReviewArr.length - 1].data.timeCreated,
            });
          } else {
            setDidReview(false);
          }
        } else {
          setAvgScore(0);
          setDidReview(false);
          setLastReview();
        }
      });
    }
  }, [imgIndex]);

  //slide navigation button
  const nextClick = () => {
    if (imgIndex + 1 < imgArr.length) {
      setImgIndex((prev) => prev + 1);
    } else {
      setImgIndex((prev) => prev + 1 - imgArr.length);
    }
  };

  const prevClick = () => {
    if (imgIndex > 0) {
      setImgIndex((prev) => prev - 1);
    } else {
      setImgIndex(imgArr.length - 1);
    }
  };

  //submit handler
  const submitHandler = (uid) => {
    setFormErr();
    let submitData = {
      // rater: {
      // },
      name: user.fullName,
      raterUID: user.uid,
      department: user.department,
      position: user.position,
      email: user.email,
      requestId: `DGS-${Math.floor(Math.random() * 1000)}`,
      timeCreated: serverTimestamp(),
      score: rating,
    };
    const colRef = collection(
      db,
      "dish_list",
      props.data.docId,
      "images",
      uid,
      "decor-rating"
    );

    createDocV2(colRef, submitData).then((res) => {
      if (res.code !== 0) {
        setFormErr(res.message);
      }
    });
  };

  //fn to style score
  const getScore = (value) => {
    let scoreContent;
    let classesContent = `${classes.score} `;
    if (value <= 0) {
      classesContent += `${classes.grey}`;
    } else if (value < 3) {
      classesContent += `${classes.red}`;
    } else if (value < 3.5) {
      classesContent += `${classes.gold}`;
    } else {
      classesContent += `${classes.green}`;
    }

    scoreContent = <p className={classesContent}>{value}</p>;

    return scoreContent;
  };

  const indexContent = `${imgIndex + 1} / ${imgArr.length}`;

  let reviewAllow;
  if (!didReview) {
    reviewAllow = (
      <div className={classes.rating}>
        <p>Đánh giá trang trí theo bạn nghĩ</p>
        <div>
          <Rating
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
        </div>
        <McpButtonV1
          type="green"
          onClick={() => submitHandler(imgArr[imgIndex].imgUID)}
          content="Gửi"
          btnType="button"
        />
        <div className={classes["submit-nort"]}>
          {formErr && <NortPTag type="error" children={formErr} />}
        </div>
      </div>
    );
  } else {
    reviewAllow = (
      <div className={classes.rating}>
        <p>
          Bạn đã đánh giá trang trí món ăn này {lastReview && lastReview.rating}{" "}
          điểm vào{" "}
          {lastReview.timeCreated &&
            lastReview.timeCreated.toDate().toLocaleDateString("vi-VI")}
        </p>
      </div>
    );
  }

  let imgContent;
  let itemContent;
  if (props.data && props.data.image.length > 0) {
    imgContent = <img src={imgArr[imgIndex].imgData.URL} />;
    itemContent = (
      <Fragment>
        <div className={classes.nav}>
          <button>
            <NavigateBeforeIcon onClick={prevClick} />
          </button>
          <span>{indexContent}</span>
          <button onClick={nextClick}>
            <NavigateNextIcon />
          </button>
        </div>
        <div className={classes.rating}>
          <p>Điểm trang trí hiện tại</p>
          <Rating name="avgScore" value={avgScore} precision={0.1} />
          {getScore(Math.round(avgScore * 100) / 100)}
        </div>
        <div className={classes.info}>
          <ul>
            <li>
              <span className={classes.label}>Tên: </span>
              {imgArr[imgIndex].imgData.name}
            </li>
            <li>
              <span className={classes.label}>Phân loại: </span>
              {imgArr[imgIndex].imgData.category}
            </li>
          </ul>
        </div>
        {reviewAllow}
        {isAdmin && (
          <McpButtonV1
            onClick={() =>
              props.onViewRating(conceptRatingArr, imgArr[imgIndex].imgUID)
            }
            type="blue"
            btnType="button"
            content="Xem tất cả đánh gía"
          />
        )}
      </Fragment>
    );
  } else if (imgArr.length === 0) {
    imgContent = <img src={demoImg} />;
    itemContent = <p className={classes.nort}>Món ăn này chưa có hình ảnh.</p>;
  }

  return (
    <div className={classes.container}>
      {imgContent}
      {itemContent}
      {/* {isAdmin && <McpButtonV1 onClick={() => props.onViewRating(conceptRatingArr)} type='blue' btnType='button' content='Xem tất cả đánh gía' />} */}
    </div>
  );
};

export default SliderDecor;
