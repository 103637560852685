import ModalWrapperV4 from "../../UI/modals/ModalWrapperV4";
import CreateFoodForm from "./CreateFoodForm";
import CloseTopBar from "../../UI/modals/CloseTopBar";

const CreateFoodModal = (props) => {
  return (
    <ModalWrapperV4>
      <CloseTopBar onClick={props.onClose} />
      <CreateFoodForm data={props.data} submitType="update" />
    </ModalWrapperV4>
  );
};

export default CreateFoodModal;
