import React from 'react'
import ProfileDisplay from '../../components/profile/ProfileDisplay'

const ProfileMain = () => {
  return (
    <React.Fragment>
        <ProfileDisplay/>
    </React.Fragment>
  )
}

export default ProfileMain
