import { initializeApp, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
// import { initializeApp } from "firebase/combat/app";
// import { getFirestore } from "firebase/combat/firestore";

// Your web app's Firebase configuration
let firebaseConfig = {
  apiKey: "AIzaSyAVM6ltKADGuEl0dd-wv1oWiuDYZxygiCE",
  authDomain: "mcp-app-ba4fd.firebaseapp.com",
  databaseURL:
    "https://mcp-app-ba4fd-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "mcp-app-ba4fd",
  storageBucket: "mcp-app-ba4fd.appspot.com",
  messagingSenderId: "367904436136",
  appId: "1:367904436136:web:9c72a72bd626b2eb8ef79d",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Iniialize Firestore
const db = getFirestore(app);

// Initialize Authentication
const auth = getAuth(app);

// Initialize Function
const functions = getFunctions(getApp());

// Init emulators firestore
const dbE = getFirestore();

export { db, auth, functions, dbE };
export default app;
