import classes from "./DashBoardTemplate.module.css";
import HeadingDep from "../../UI/HeadingDep";
import PanelWrapper from "../../UI/PanelWrapper";
import FunctionPanel from "../../panel/FunctionPanel";

const DashBoardTemplate = (props) => {
  return (
    <div className={classes.container}>
      <HeadingDep content={props.title} />
      <PanelWrapper>
        <FunctionPanel data={props.siteMap} />
      </PanelWrapper>
    </div>
  );
};

export default DashBoardTemplate;
