import React, { useState, useEffect, useRef } from "react";
import FormTemplate1 from "../../form/template/FormTemplate1";
import SingleFieldV3 from "../../form/template/SingleFieldV3";
import SelectFieldV3 from "../../form/template/SelectFieldV3";
import {
  collection,
  onSnapshot,
  serverTimestamp,
  doc,
} from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import NortPTag from "../../UI/systemNor/NortPTag";
import { createDocV2, updateDbDoc } from "../../../hooks/fetchFirebase";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  capitalizeEveryFirstLetter,
  captializeWhole,
} from "../../../hooks/capitalize";

const CreateFoodForm = (props) => {
  const [foodGroupData, setFoodGroupData] = useState([
    {
      value: "init",
      textContent: "Chọn nhóm món ăn",
      disabled: true,
      defaultFunction: null,
    },
  ]);
  const [outletData, setOutletData] = useState([
    {
      value: "init",
      textContent: "Chọn Outlet",
      disabled: true,
      defaultFunction: null,
    },
  ]);
  const [outletSelect, setOutletSelect] = useState([]);
  const [groupSelect, setGroupSelect] = useState();
  const [nort, setNort] = useState();

  const user = useSelector((state) => state.auth.userCredential);

  const nameInputRef = useRef();
  let initGroup;
  let initOutlet;

  if (props.data) {
    initGroup = props.data.data.group;
    initOutlet = props.data.data.outlet;
  }

  useEffect(() => {
    const outletDbRef = collection(db, "outlet");
    onSnapshot(outletDbRef, (snapshot) => {
      if (!snapshot.empty) {
        let dataArr = [
          {
            value: "init",
            textContent: "Chọn Outlet",
            disabled: true,
            defaultFunction: null,
          },
        ];
        snapshot.forEach((item) => {
          const arr = item.data();
          let updateObj = {
            value: arr.outlet,
            textContent: arr.outlet,
            key: arr.id,
            defaultFunction: null,
          };
          dataArr.push(updateObj);
        });

        const filterArr = dataArr.filter(
          (item) => !captializeWhole(item.value).includes("DELETE")
        ); // filter out any relate delete outlet

        setOutletData(filterArr);
      }
    });

    const groupDbRef = collection(db, "food_group");
    onSnapshot(groupDbRef, (snapshot) => {
      if (!snapshot.empty) {
        let dataArr = [
          {
            value: "init",
            textContent: "Chọn nhóm món ăn",
            disabled: true,
            defaultFunction: null,
          },
        ];
        snapshot.forEach((item) => {
          const arr = item.data();
          let updateObj = {
            value: arr.foodGroup,
            textContent: arr.foodGroup,
            key: arr.id,
            defaultFunction: null,
          };
          dataArr.push(updateObj);
        });

        setFoodGroupData(dataArr);
      }
    });

    if (props.data) {
      nameInputRef.current.value = props.data.data.name;
    }
  }, [props.data]);

  //Fetch value from select field
  const fetchOutletValue = (value) => {
    setOutletSelect(value);
  };

  const fetchFoodGroupValue = (value) => {
    setGroupSelect(value);
  };

  //submit handler
  const submitHandler = (e) => {
    e.preventDefault();
    const nameInput = nameInputRef.current.value;
    if (
      nameInput !== null &&
      outletSelect !== "init" &&
      groupSelect !== "init"
    ) {
      const foodDbRef = collection(db, "dish_list");

      //check to see whether create or update

      if (props.submitType === "create") {
        createDocV2(foodDbRef, {
          // name: nameInput,
          name: capitalizeEveryFirstLetter(nameInput).trim(),
          outlet: outletSelect,
          group: groupSelect,
          // id: Math.floor(Math.random() * 1000),
          id: `MA-${uuidv4()}`,
          timeCreated: serverTimestamp(),
          timeUpdated: null,
          creator: user.email,
        }).then((res) => {
          if (res.code === 0) {
            setNort({
              type: "success",
              message: res.message,
            });
            setTimeout(() => {
              setNort(null);
            }, 5000);
          } else {
            setNort({
              type: "error",
              message: res.message,
            });
            setTimeout(() => {
              setNort(null);
            }, 5000);
          }
        });
      } else if (props.submitType === "update") {
        updateDbDoc(doc(foodDbRef, props.data.docId), {
          name: nameInput,
          outlet: outletSelect,
          group: groupSelect,
          timeUpdated: serverTimestamp(),
          creator: user.email,
        }).then((res) => {
          if (res.code === 0) {
            setNort({
              type: "success",
              message: res.message,
            });
            setTimeout(() => {
              setNort(null);
            }, 5000);
          } else {
            setNort({
              type: "error",
              message: res.message,
            });
            setTimeout(() => {
              setNort(null);
            }, 5000);
          }
        });
      }
    } else if (outletSelect === "init" || groupSelect === "init") {
      setNort({
        type: "error",
        message: "Bạn chưa chọn outlet hoặc nhóm món ăn",
      });
      setTimeout(() => {
        setNort(null);
      }, 5000);
    } else {
      setNort({
        type: "error",
        message: "Đã có lỗi hệ thống, vui lòng liên hệ kỹ thuật",
      });
      setTimeout(() => {
        setNort(null);
      }, 5000);
    }
  };

  //nortification
  let nortContent;
  if (nort) {
    nortContent = <NortPTag type={nort.type}>{nort.message}</NortPTag>;
  }

  return (
    <FormTemplate1 id="createFoodForm" onSubmit={submitHandler}>
      <SingleFieldV3
        label="Tên món ăn"
        id="foodName"
        required
        reference={nameInputRef}
      />
      <SelectFieldV3
        optionsList={outletData}
        label="Outlet"
        id="Oulet"
        onClick={fetchOutletValue}
        onClickSub={null}
        defaultOption={initOutlet ? initOutlet : "init"}
      />
      <SelectFieldV3
        optionsList={foodGroupData}
        label="Nhóm món ăn"
        id="foodGroup"
        onClick={fetchFoodGroupValue}
        onClickSub={null}
        defaultOption={initGroup ? initGroup : "init"}
      />
      {nortContent}
    </FormTemplate1>
  );
};

export default CreateFoodForm;
