import { useState, useEffect } from "react";
import classes from "./Sidebar.module.css";
import SidebarMenu2 from "./SidebarMenu2";
import HomeIcon from "@mui/icons-material/Home";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import HotelIcon from "@mui/icons-material/Hotel";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SettingsIcon from "@mui/icons-material/Settings";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ChevronRight from "../UI/ChevronRight";
import DataThresholdingOutlinedIcon from "@mui/icons-material/DataThresholdingOutlined";
import { useDispatch } from "react-redux";
import { specActions } from "../../store/index";
import ListIcon from "@mui/icons-material/List";

let sideBarContent = [
  {
    title: "Trang chủ",
    icon: <HomeIcon />,
    submenu: null,
    path: "/trang-chu",
  },
  {
    title: "Sales & Marketing",
    icon: <TrendingUpIcon />,
    submenu: [
      {
        subtitle: "Sales",
        subPath: "/sale-marketing/sales",
      },
      {
        subtitle: "Marketing",
        subPath: "/sale-marketing/marketing",
      },
      {
        subtitle: "Chức năng chung",
        subPath: "/sale-marketing/chuc-nang-chung",
      },
    ],
    path: "/sale-marketing",
  },
  {
    title: "Khối ẩm thực ",
    icon: <LocalDiningIcon />,
    // submenu: ["Bếp", "F&B", "Steward"],
    submenu: [
      {
        subtitle: "Bếp",
        subPath: "/khoi-am-thuc/bep",
      },
      {
        subtitle: "F&B",
        subPath: "/khoi-am-thuc/fb",
      },
      {
        subtitle: "Steward",
        subPath: "/khoi-am-thuc/steward",
      },
    ],
    path: "/khoi-am-thuc",
  },
  {
    title: "Khối phòng lưu trú",
    icon: <HotelIcon />,
    // submenu: ["House Keeping", "Font Office"],
    submenu: [
      {
        subtitle: "House Keeping",
        subPath: "/khoi-phong-luu-tru/house-keeping",
      },
      {
        subtitle: "Font Office",
        subPath: "/khoi-phong-luu-tru/font-office",
      },
    ],
    path: "/khoi-phong-luu-tru",
  },
  {
    title: "Khối văn phòng",
    icon: <CoPresentIcon />,
    // submenu: ["Kế toán", "Nhân sự"],
    submenu: [
      {
        subtitle: "Kế Toán",
        subPath: "/khoi-van-phong/ke-toan",
      },
      {
        subtitle: "Nhân sự",
        subPath: "/khoi-van-phong/nhan-su",
      },
      {
        subtitle: "Chức năng chung",
        subPath: "/khoi-van-phong/chuc-nang-chung",
      },
    ],
    path: "/khoi-van-phong",
  },
  {
    title: "Chức năng chung",
    icon: <ListIcon />,
    submenu: [
      {
        subtitle: "Đánh giá",
        subPath: "/chuc-nang-chung/danh-gia",
      },
    ],
    path: "/chuc-nang-chung",
  },
  {
    title: "Quản lý",
    icon: <DataThresholdingOutlinedIcon />,
    submenu: null,
    path: "/quan-ly",
  },
  {
    title: "IT - hệ thống",
    icon: <AdminPanelSettingsIcon />,
    submenu: null,
    path: "/IT-he-thong",
  },
  {
    title: "Cài đặt",
    icon: <SettingsIcon />,
    submenu: null,
    path: "cai-dat",
  },
];

const Sidebar = () => {
  const [isExpanding, setIsExpanding] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const sectionElement = document.getElementById("main-sidebar");
    // console.log(sectionElement.offsetWidth);
    dispatch(
      specActions.changeNavSize({
        nav: {
          width: sectionElement.offsetWidth,
        },
      })
    );
  }, [isExpanding]);

  const onClickHandler = (event) => {
    event.preventDefault();
    setIsExpanding((prev) => !prev);
  };

  return (
    <aside
      className={
        isExpanding
          ? `${classes.sidebar}`
          : `${classes.sidebar} ${classes.hide}`
      }
      id="main-sidebar"
    >
      <div className={classes.toggle}>
        <ChevronRight onClick={onClickHandler} state={isExpanding} />
      </div>
      <nav
        className={
          isExpanding ? `${classes.nav}` : `${classes.nav} ${classes.hide}`
        }
      >
        {sideBarContent.map((content) => (
          <SidebarMenu2 key={content.title} data={content} />
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar;
