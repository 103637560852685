import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

const uploadImageAsPromise = (ref, targetImg, imgName, uploadFN) => {
  return new Promise((resolve, reject) => {
    const uploadTask = uploadBytesResumable(ref, targetImg);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      (error) => {
        reject(error.code);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
};

export default uploadImageAsPromise;
