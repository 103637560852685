import {useRef} from "react";
import classes from "./CardV3.module.css";

const CardV3 = (props) => {
  const modalContainerRerf = useRef();


  return (
    <div className={classes.container} ref={modalContainerRerf}>
      <div className={classes.card}>{props.children}</div>
    </div>
  );
};

export default CardV3;
