import React from "react";
import classes from "./McpButtonV1.module.css";

/*
  versions:
  v0-McpButton.js
  v1-this version-allow to recive props.btnType
  props list: {
    type, 
    onCLick, 
    content, 
    btnType
    disabled
  } 
*/

const McpButtonV1 = (props) => {
  return (
    <React.Fragment>
      <button
        className={`${classes.button} ${classes[`${props.type}`]}`}
        // onClick={props.onClick}
        onClick={props.onClick}
        type={props.btnType}
        disabled={props.disabled}
      >
        {props.content}
      </button>
    </React.Fragment>
  );
};

export default McpButtonV1;
