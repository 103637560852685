import { Fragment } from "react"; 
import { Outlet } from "react-router-dom";

const UserXemDeXuatLanding = () => {
  return (
    <Fragment>
        <Outlet/>
    </Fragment>
  )
}

export default UserXemDeXuatLanding