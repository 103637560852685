import React from "react";
import AddDishBtn from "./AddDishBtn";
import classes from "./SearchDishTable.module.css";
import NortPTag from "../../UI/systemNor/NortPTag";

/*
  props list: {
    data, 
    onAdd
  }
 */

const SearchDishTable = (props) => {
  let headerContent;
  let tableContent;
  if (props.data) {
    if (props.data.length > 0) {
      headerContent = (
        <div className={`${classes.row} ${classes.header}`}>
          <div className={classes.col1}>Thông tin</div>
          <div className={classes["sm-col"]}>Công cụ</div>
        </div>
      );
      tableContent = props.data.map((item) => (
        <div className={classes.row} key={item.id}>
          <div className={classes.col1}>
            <p>{item.data.name}</p>
            <p>{item.data.group}</p>
          </div>
          <div className={classes["sm-col"]}>
            <AddDishBtn
              data={{
                group: item.data.group,
                id: item.id,
                name: item.data.name,
                dishID: item.data.id
              }}
              onAdd={props.onAdd}
            />
          </div>
        </div>
      ));
    } else if (props.data.length === 0) {
      tableContent = (
        <NortPTag type="error">
          Không tìm thấy được dữ liệu với từ khoá bạn tìm kiếm
        </NortPTag>
      );
    }
  }

  // if (props.data != null  && props.data.length > 0) {
  //   headerContent = (
  //     <div className={`${classes.row} ${classes.header}`}>
  //       <div className={classes.col1}>Thông tin</div>
  //       <div className={classes["sm-col"]}>Công cụ</div>
  //     </div>
  //   );
  // }
  // else if(props.data != null && props.data.length === 0) {
  //   headerContent = (<NortPTag type='error'>Không có dữ liệu</NortPTag>)
  // } else {
  //   headerContent = ''
  // }

  return (
    <div className={classes.wrapper}>
      {headerContent}

      {tableContent}
    </div>
  );
};

export default SearchDishTable;
