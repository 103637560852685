import { Fragment, useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import classes from "./TasterStatus.module.css";

const TasterStatus = (props) => {
  const [tasterStat, setTasterStat] = useState("chưa đánh giá");

  useEffect(() => {
    const docRef = doc(
      db,
      "employee_users",
      props.uid,
      "dish_review_request",
      props.requestID
    );
    onSnapshot(docRef, (snapshot) => {
      console.log(snapshot.data());
      if (!snapshot.isEmpty) {
        setTasterStat(snapshot.data().reviewStatus);
      } else {
        setTasterStat("có lỗi");
      }
    });
  }, []);

  let statusContent;
  if (tasterStat === "chưa đánh giá") {
    statusContent = <div className={classes.no}>{tasterStat}</div>;
  } else if (tasterStat === "đã đánh giá") {
    statusContent = <div className={classes.yes}>{tasterStat}</div>;
  } else {
    statusContent = <div className={classes.error}>{tasterStat}</div>;
  }

  return <Fragment>{statusContent}</Fragment>;
};

export default TasterStatus;
