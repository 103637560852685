import { useState } from "react";
import classes from './AccordionBtn.module.css'; 
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RemoveIcon from '@mui/icons-material/Remove';

const AccordionBtn = (props) => {

  const [isHidden, setIsHidden] = useState(false);

  const onClickHandler = () => {
    setIsHidden((prev) => !prev);
  };

  return (
    <div className={classes.wrapper}>
      <button className={classes.accordion} onClick={onClickHandler}>{props.title}<span className={classes.icon}>{isHidden ? <ArrowDropDownIcon/> : <RemoveIcon/>}</span></button>
      {!isHidden && <div className={classes.panel}>{props.children}</div>}
    </div>
  );
};

export default AccordionBtn;
