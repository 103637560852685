import { useState } from "react";

const bytesFormatJSX = (value) => {
  let formatSize;
  if (value > 1000000) {
    formatSize = <span>{`${Math.round(value / 1000000)}MB`}</span>;
  } else if (value > 1000) {
    formatSize = <span>{`${Math.round(value / 1000)}KB`}</span>;
  } else {
    formatSize = <span>{`${value}Bytes`}</span>;
  }
  return formatSize;
};

const useForceUpdate = () => {
  const [value, setValue] = useState(0);
  return {
    value,
    run: () => setValue((value) => value + 1),
  };
};

function extractEmails(text) {
  return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi);
}

function objectIsEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export { bytesFormatJSX, useForceUpdate, extractEmails, objectIsEmpty };
