import ReactDOM from "react-dom";
import { useEffect, useState, useRef, useContext } from "react";
import MainBackdropV3 from "../UI/backdrops/MainBackdropV3";
import ImgZoomModal from "../department/KIT/ImgZoomModal";
import classes from "./ImgGallery.module.css";
import CompCtx from "./CompCtx";
import { useForceUpdate } from "../../hooks/allOther";

const ImgGallery = (props) => {
  const [isZoom, setIsZoom] = useState(false);
  const [selectingItem, setSelectingItem] = useState();

  const compCtx = useContext(CompCtx);
  const galleryContainerRef = useRef();
  const forceUpdate = useForceUpdate();

  const selectItem = (dishData) => {
    // setIsZoom(true);
    // setSelectingItem(dishData);
    props.activateZoom(dishData);
  };

  const onCheckHandler = (e, item) => {
    if (e.target.checked) {
      const currentNode = e.target;
      const parentNode = currentNode.parentNode;
      parentNode.style.boxShadow =
        "#f8BD26 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px";
      props.checkItemFn({
        dishId: props.data.dishId,
        imgUID: e.target.name,
        imgData: item.data,
      });
    } else if (e.target.checked === false) {
      const currentNode = e.target;
      const parentNode = currentNode.parentNode;
      parentNode.style.boxShadow =
        "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px";
      props.uncheckItemFn({
        dishId: props.data.dishId,
        imgUID: e.target.name,
        imgData: item.data,
      });
    }
    forceUpdate.run();
  };

  const closeZoom = () => {
    setIsZoom(false);
  };

  //function to check whether an img is in selection or not
  const checkImgInSelection = (id) => {
    if (compCtx.dishCartCtx.length > 0) {
      const dishObject = compCtx.dishCartCtx.filter(
        (item) => item.dish.id === props.data.dishId
      )[0];
      if (dishObject.img.length > 0) {
        const filterArr = dishObject.img.filter((item) => item.imgUID === id);
        if (filterArr.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  //define allow checkbox or not
  const checkBoxContent = (item) => {
    let content;
    content = (
      <input
        type="checkbox"
        name={item.docId}
        onChange={(e) => onCheckHandler(e, item)}
        checked={checkImgInSelection(item.docId)}
      />
    );

    return content;
  };

  //define main content
  let mainContent;
  if (props.data.imgData.length === 0) {
    mainContent = (
      <div className={classes.note}>
        <p>Chưa có dữ liệu hình ảnh cho món ăn này </p>
      </div>
    );
  } else if (props.data.imgData.length > 0) {
    mainContent = props.data.imgData.map((item) => (
      <div key={item.docId}>
        <img src={item.data.URL} onClick={() => selectItem(item.data)} />
        {checkBoxContent(item)}
      </div>
    ));
  }

  //disable input until submit is completed
  useEffect(() => {
    const containerDOM = galleryContainerRef.current;
    const inputDOM = containerDOM.querySelectorAll("div input");
    if (props.isSubmitting) {
      inputDOM.forEach((item) => (item.disabled = true));
    } else {
      inputDOM.forEach((item) => (item.disabled = false));
    }
  }, [props.isSubmitting]);

  return (
    <div className={classes.container} ref={galleryContainerRef}>
      {/* {isZoom &&
        ReactDOM.createPortal(
          <MainBackdropV3 onClick={closeZoom} />,
          document.getElementById("main-backdrop")
        )}
      {isZoom &&
        ReactDOM.createPortal(
          <ImgZoomModal data={selectingItem} />,
          document.getElementById("main-overlay")
        )} */}
      {mainContent}
    </div>
  );
};

export default ImgGallery;
