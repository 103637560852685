import React from "react";
import classes from "./PrimaryForm.module.css";

/*
  props list : {
    onSubmit, 
    formData 
  }
*/

const PrimaryForm = (props) => {
  return (
    <form className={classes.form} onSubmit={props.onSubmit}>
      {props.formData.map((data) => (
        <div className={classes["field-wrapper"]} key={data.id}>
          <label>{data.label}</label>
          <input
            name={data.name}
            id={data.id}
            type={data.type}
            placeholder={data.placeholder}
            required={data.required}
          ></input>
        </div>
      ))}
      <div className={classes["action-nav"]}>
        <button className={classes["create-btn"]} type="submit">
          Tạo
        </button>
        <button className={classes["cancel-btn"]} type="reset">
          Huỷ
        </button>
      </div>
    </form>
  );
};

export default PrimaryForm;
