import {Fragment} from 'react';
import classes from './ImgZoomModal.module.css'

const ImgZoomModal = (props) => {
  return (
    <Fragment>
        <img className={classes.img} src={props.data.URL} />
    </Fragment>
  )
}

export default ImgZoomModal