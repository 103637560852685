import React, { useState, useEffect } from "react";
import classes from "./KitMonAn2.module.css";
import AccordionSM from "../../components/UI/accordion/AccordionSM";
import AccordionBtn from "../../components/UI/accordion/AccordionBtn";
import CreateFoodForm from "../../components/department/KIT/CreateFoodForm";
import FlexTableDish from "../../components/department/KIT/FlexTableDish";
import KitSearchBar2 from "../../components/department/KIT/KitSearchBar2";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import Loader from "../../components/UI/Loader";
import NortPTag from "../../components/UI/systemNor/NortPTag";
import { SysNorRightFix } from "../../components/UI/systemNor/SysNorRightFix";
import AccordionBtnV1 from "../../components/UI/accordion/AccordionBtnV1";
import DishOrderFilter from "../../components/department/KIT/DishOrderFilter";
import { formatOutlet } from "../../hooks/capitalize";

const KitMonAn2 = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dishData, setDishData] = useState([]);
  const [dataNort, setDataNort] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState(null);
  const [displayData, setDisplayData] = useState([]);
  const [sysNort, setSysNort] = useState([]);
  const [filterSetting, setFilterSetting] = useState(null);

  useEffect(() => {
    const dishDbRef = collection(db, "dish_list");
    onSnapshot(dishDbRef, (snapshot) => {
      setIsLoading(true);
      if (!snapshot.empty) {
        let dataArr = [];
        snapshot.forEach((item) =>
          dataArr.push({
            docId: item.id,
            data: item.data(),
          })
        );

        setDishData(dataArr);

        if (searchCriteria == null) {
          const dupArr = dataArr.slice();

          console.log(dataArr);

          const sortArr = dupArr.sort((a, b) =>
            a.data.timeCreated.toDate() > b.data.timeCreated.toDate() ? -1 : 1
          );
          setDisplayData(sortArr);
        }
        setIsLoading(false);
        setDataNort(null); //to clear nortification after adding new dish
      } else {
        setIsLoading(false);
        setDisplayData([]);
        setDataNort({
          type: "error",
          message:
            "Không có dữ liệu, vui lòng kiểm tra lại kết nối hoặc liên hệ kỹ thuật để được hỗ trợ",
        });
      }
    });
  }, [searchCriteria]);

  const searchFn = (value) => {
    setDataNort(null);
    setIsLoading(true);
    setDisplayData([]);
    setSearchCriteria(value);
    let convertGroup;
    switch (value.filterGroup) {
      case "Món ăn":
        convertGroup = "name";
        break;
      case "Nhóm món ăn":
        convertGroup = "group";
        break;
      case "Outlet":
        convertGroup = "outlet";
        break;
      default:
        convertGroup = "name";
        break;
    }
    let resultArr = [];
    if (convertGroup === "outlet" || convertGroup === "group") {
      resultArr = dishData.filter(
        (item) => item.data[`${convertGroup}`] === value.keyword
      );
    } else {
      resultArr = dishData.filter((item) => {
        const formatItemName = formatOutlet(item.data[`${convertGroup}`]);
        const formatKw = formatOutlet(value.keyword);
        return formatItemName.includes(formatKw) === true;
      });
    }
    if (resultArr.length > 0) {
      setTimeout(() => {
        setDisplayData(resultArr);
        setIsLoading(false);
      }, 500);
      // setDisplayData(resultArr);
    } else {
      setTimeout(() => {
        setIsLoading(false);
        setDataNort({
          type: "error",
          message: "Không tìm được dữ liệu",
        });
        setDisplayData([]);
      }, 500);
    }
  };

  let nortContent;
  if (dataNort) {
    nortContent = (
      <div className={classes["nort-wrapper"]}>
        <NortPTag type={dataNort.type}>{dataNort.message}</NortPTag>
      </div>
    );
  }

  //set system nortification system
  const fetchSysNort = (value) => {
    setSysNort(value);
  };

  //close system nortification
  const nortRemove = (key) => {
    const afterFilterArr = sysNort.filter((item) => item.key !== key);
    setSysNort(afterFilterArr);
  };

  const filterSelectHandler = (value) => {
    setFilterSetting(value);
  };

  //display mon an table based on filter
  useEffect(() => {
    //all filter function
    const sortDateAsc = () => {
      const dupArr = dishData.slice();
      const sortArr = dupArr.sort((a, b) =>
        a.data.timeCreated.toDate() < b.data.timeCreated.toDate() ? -1 : 1
      );
      setDisplayData(sortArr);
    };

    const sortDateDes = () => {
      // const dupArr = JSON.parse(JSON.stringify(dishData));
      const dupArr = dishData.slice();
      const sortArr = dupArr.sort((a, b) =>
        a.data.timeCreated.toDate() > b.data.timeCreated.toDate() ? -1 : 1
      );
      setDisplayData(sortArr);
    };

    const sortGroupAcs = () => {
      const dupArr = dishData.slice();
      const sortArr = dupArr.sort((a, b) =>
        a.data.group < b.data.group ? -1 : 1
      );
      setDisplayData(sortArr);
    };

    const sortGroupDes = () => {
      const dupArr = dishData.slice();
      const sortArr = dupArr.sort((a, b) =>
        a.data.group > b.data.group ? -1 : 1
      );
      setDisplayData(sortArr);
    };

    if (filterSetting) {
      setSearchCriteria(null);
      switch (filterSetting) {
        case "date_asc":
          sortDateAsc();
          break;
        case "date_des":
          sortDateDes();
          break;
        case "group_asc":
          sortGroupAcs();
          break;
        case "group_des":
          sortGroupDes();
          break;
        default:
          sortDateAsc();
      }
    }
  }, [filterSetting, dishData]);

  //display mon an table based of search
  useEffect(() => {}, []);

  let tableContent;
  if (displayData.length > 0) {
    tableContent = (
      <FlexTableDish
        data={displayData}
        onNort={fetchSysNort}
        onClearNort={nortRemove}
      />
    );
  }

  return (
    <React.Fragment>
      <AccordionSM>
        <AccordionBtn title="Tạo món ăn">
          {/* <PrimaryForm formData={formField} /> */}
          {/* <SelectionForm
          formData={formField}
          selectionData={selectionData}
          formId="foodCreatedForm"
        /> */}

          {/* <SelectForm/> */}
          <CreateFoodForm submitType="create" />
        </AccordionBtn>
        <AccordionBtnV1 title="Tìm kiếm" onClose={true}>
          <KitSearchBar2 onSearch={searchFn} />
        </AccordionBtnV1>
        <AccordionBtnV1 title="Lọc theo thứ tự" onClose={true}>
          <DishOrderFilter onSelect={filterSelectHandler} />
        </AccordionBtnV1>
      </AccordionSM>
      {isLoading && <Loader />}
      {nortContent}
      {tableContent}
      <SysNorRightFix data={sysNort} onClick={nortRemove} />
    </React.Fragment>
  );
};

export default KitMonAn2;
