import { useEffect, useState, useRef } from "react";
import classes from "./DanhGiaMonChiTiet3.module.css";
import FlexTable3Col2 from "../../table/FlexTable3Col2";
import RateBtn from "./RateBtn";
import { useSelector } from "react-redux";
import {
  collection,
  serverTimestamp,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { createDocV2, updateDbDoc } from "../../../hooks/fetchFirebase";
import McpButtonV1 from "../../UI/button/McpButtonV1";
import NortPTag from "../../UI/systemNor/NortPTag";
import ScoreComp from "./ScoreComp";
import { useNavigate } from "react-router-dom";
import Loader from "../../UI/Loader";
import AccordionBtnV2Sm from "../../UI/accordion/AccordionBtnV2Sm";

/*
  props list: {
    data
  }
*/

const DanhGiaMonChiTiet3 = (props) => {
  const user = useSelector((state) => state.auth.userCredential);
  const navigate = useNavigate();

  const [dishScoreArr, setDishScoreArr] = useState([]);
  const [formNort, setFormNort] = useState({});
  const [requestData, setRequestData] = useState({});
  const [dishData, setDishData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const commentRef = useRef();

  const tableTitle = {
    col1: "STT",
    col2: "Thông tin",
    col3: "Đánh giá",
  };

  //fetch data from db
  useEffect(() => {
    const requestDbRef = doc(
      db,
      "employee_users",
      user.uid,
      "dish_review_request",
      props.requestID
    );

    onSnapshot(requestDbRef, (snapshot) => {
      console.log(snapshot.data());

      // setRequestData(snapshot.data());

      // const {} = snapshot.data();

      const dishData = snapshot.data().dishes.map((item) => {
        return {
          key: item.id,
          info: [item.name, item.group],
          score: 0,
        };
      });

      // setTimeout(() => {
      //   setIsLoading(false);
      //   setRequestData(snapshot.data());
      //   setDishData(dishData);
      // }, 5000);

      setIsLoading(false);
      setRequestData(snapshot.data());
      setDishData(dishData);

      // setDishData(dishData);
    });
  }, [props.requestID, user.uid]);

  //retrieve dishscore from rate comp - score select
  const fetchScore = (id, score) => {
    const workingArr = dishData;

    const editArr = workingArr.map((item) => {
      if (item.key === id) {
        item.score = score;
      }
      return item;
    });
    setDishScoreArr(editArr);
  };

  //add on component
  const rateComp = (id) => {
    return <RateBtn id={id} onFetch={fetchScore} />;
  };

  const scoreComp = (dishID) => {
    return <ScoreComp dishID={dishID} userID={user.uid} />;
  };

  //submit
  const submitHandler = () => {
    setFormNort();
    console.log(commentRef.current.value);
    if (dishScoreArr.length > 0) {
      setIsSubmitting(true);
      let scoreIsValid;
      //check submit validation
      // dishScoreArr.forEach((item) => {
      //   const formatScore = parseInt(item.score);
      //   scoreIsValid = (scoreIsValid && formatScore > 0) || formatScore < 6;
      //   console.log(formatScore > 0);
      // });

      const emptyScoreArr = dishScoreArr.filter((item) => {
        const formatScore = parseInt(item.score);
        return formatScore === 0;
      });

      if (emptyScoreArr.length === 0) {
        scoreIsValid = true;
      } else {
        scoreIsValid = false;
      }

      if (scoreIsValid) {
        setIsSubmitting(false);
        dishScoreArr.forEach((item) => {
          const formatScore = parseInt(item.score);
          const dishRef = collection(db, "dish_list", item.key, "dish_review");
          const submitData = {
            rater: user.fullName,
            raterID: user.uid,
            raterEmail: user.email,
            raterDepartment: user.department,
            score: formatScore,
            timeCreated: serverTimestamp(),
            requestId: requestData.requestId,
            requestCreatedDate: requestData.requestDate,
            foodTestDate: requestData.requestDate, // same value with requestCreatedDate, but this field is used for running sort function
          };
          const commentContent = commentRef.current.value;
          if (commentContent.length > 0) {
            submitData.comment = commentContent.trim();
          }
          createDocV2(dishRef, submitData).then(() => {
            //udpate review status in request
            const requestDbRef = doc(
              db,
              "employee_users",
              user.uid,
              "dish_review_request",
              props.requestID
            );
            updateDbDoc(requestDbRef, {
              reviewStatus: "đã đánh giá",
            }).then((res) => {
              if (res.code === 0) {
                setFormNort({
                  code: 0,
                  message: "Đã gửi đánh giá thành công",
                });
                setTimeout(() => {
                  setFormNort();
                }, 4000);
              } else {
                setFormNort({
                  code: 1,
                  message: res.message,
                });
                setTimeout(() => {
                  setFormNort();
                }, 4000);
              }
              setIsSubmitting(false);
            });
          });
        });
      } else {
        setIsSubmitting(false);
        setFormNort({
          code: 1,
          message: "Các món ăn chưa được đánh giá đầy đủ",
        });
        setTimeout(() => {
          setFormNort();
        }, 4000);
        // return;
      }
    } else {
      setFormNort({
        code: 1,
        message: "Bạn chưa đánh giá món ăn",
      });
      setTimeout(() => {
        setFormNort();
      }, 4000);
    }
  };

  // return
  const returnHandler = () => {
    setDishScoreArr([]);
    navigate("/chuc-nang-chung/danh-gia/danh-gia-mon-an");
  };

  // nort content
  let nortContent;
  if (formNort && formNort.code === 0) {
    nortContent = (
      <NortPTag type="success">
        Đã gửi đánh giá thành công, trang sẽ được cập nhật trong vòng 5 giây
      </NortPTag>
    );
  } else if (formNort && formNort.code === 1) {
    nortContent = <NortPTag type="error">{formNort.message}</NortPTag>;
  }

  //page content
  let statusContent;
  let tableContent;
  let commentContent;
  let buttonBarContent;
  if (requestData.approveStatus === "no") {
    statusContent = <span className={classes.no}>chưa được duyệt</span>;
    tableContent = null;
  } else if (requestData.approveStatus === "đã duyệt") {
    statusContent = (
      <span className={classes.yes}>{requestData.approveStatus}</span>
    );
    if (requestData.reviewStatus === "chưa đánh giá") {
      tableContent = (
        <div className={classes["table-wrapper"]}>
          <p className={classes.title}>Đánh Giá </p>
          <FlexTable3Col2
            title={tableTitle}
            data={dishData}
            addOnComp={rateComp}
          />
        </div>
      );
      if (isSubmitting) {
        buttonBarContent = (
          <div className={classes["btn-bar"]}>
            <Loader />
            <p>Đang gửi đánh giá của bạn, vui lòng chờ trong giây lát!</p>
          </div>
        );
      } else {
        commentContent = (
          <AccordionBtnV2Sm title="Ý kiến khác nếu muốn">
            <p className={classes["comment-note"]}>
              Vì để đảm bảo cho kết quả đánh giá được khách quan nên tên của bạn
              sẽ không được thể hiện. Tuy nhiên nếu bạn muốn cho Chef biết thì
              bạn có thể chủ động ghi tên mình vào phần ý kiến!
            </p>
            <p className={classes["comment-note"]}>
              <strong>Lưu ý</strong>: nếu bạn muốn ý kiến món ăn nào thì bạn nên
              đề cập tên món ăn đó
            </p>
            <div className={classes["comment-wrapper"]}>
              <label htmlFor="danhgia-comment">Ý kiến</label>
              <textarea id="danhgia-comment" rows={5} ref={commentRef} />
            </div>
          </AccordionBtnV2Sm>
        );
        buttonBarContent = (
          <div className={classes["btn-bar"]}>
            <NortPTag type="warning">
              Bạn nên suy nghĩ kỹ trước khi đánh giá vì bạn sẽ không được chỉnh
              sửa sau khi xác nhận
            </NortPTag>
            {nortContent}
            <McpButtonV1
              type="green"
              onClick={submitHandler}
              content="Xác nhận"
              btnType="button"
            />
            <McpButtonV1
              type="grey"
              onClick={returnHandler}
              content="Quay về danh sách"
              btnType="button"
            />
          </div>
        );
      }
    } else if (requestData.reviewStatus === "đã đánh giá") {
      tableContent = (
        <div>
          <p className={classes.title}>Thông tin bạn đã đánh giá: </p>
          <FlexTable3Col2
            title={tableTitle}
            data={dishData}
            addOnComp={scoreComp}
          />
        </div>
      );
      buttonBarContent = (
        <div className={classes["btn-bar"]}>
          {nortContent}

          <McpButtonV1
            type="grey"
            onClick={returnHandler}
            content="Quay về danh sách"
            btnType="button"
          />
        </div>
      );
    }
  }

  return (
    <div className={classes.container}>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className={classes.info}>
          <p className={classes.title}>Thông Tin</p>
          <p>
            <span>Tên đề xuất: </span>
            {requestData.requestName}
          </p>
          <p>
            <span>Ngày diễn ra: </span>
            {requestData.requestDate}
          </p>
          <p>
            <span>Giờ ước tính: </span>
            {requestData.requestTime}
          </p>
          <p>
            <span>Đề xuất ID: </span>
            {requestData.requestId}
          </p>
          <p className={classes.status}>
            <span>Tình trạng đề xuất: </span>
            {statusContent}
          </p>
        </div>
      )}

      {tableContent}
      {commentContent}
      {buttonBarContent}
    </div>
  );
};

export default DanhGiaMonChiTiet3;
