import classes from "./GalleryGrid.module.css";
import ReactDOM from "react-dom";
import { useEffect, useState, useRef } from "react";
import { db } from "../../../firebase/firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { SysNorRightFix } from "../../UI/systemNor/SysNorRightFix";
import ImgZoomModal from "../../department/KIT/ImgZoomModal";
import MainBackdropV3 from "../backdrops/MainBackdropV3";

const GalleryGrid = (props) => {
  const [imgArr, setImgArr] = useState([]);
  const [systemNor, setSystemNor] = useState([]);
  const [isZoom, setIsZoom] = useState(false);
  const [selectingItem, setSelectingItem] = useState();

  const galleryContainerRef = useRef();

  const selectItem = (dishData) => {
    setIsZoom(true);
    setSelectingItem(dishData);
  };

  const onCheckHandler = (e, item) => {
    if (e.target.checked) {
      const currentNode = e.target;
      const parentNode = currentNode.parentNode;
      parentNode.style.boxShadow =
        "#f8BD26 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px";
      props.checkItemFn({
        UID: e.target.name,
        imgData: item.data,
      });
    } else if (e.target.checked === false) {
      const currentNode = e.target;
      const parentNode = currentNode.parentNode;
      parentNode.style.boxShadow =
        "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px";
      props.uncheckItemFn({
        UID: e.target.name,
        imgData: item.data,
      });
    }
  };

  const closeZoom = () => {
    setIsZoom(false);
  };

  //fetch image files - category : tham khao

  useEffect(() => {
    const dishImgRef = collection(db, "dish_list", props.dishUID, "images");
    const thamKhaoQuery = query(
      dishImgRef,
      where("category", "==", "tham khảo")
    );
    const fetchRTImg = onSnapshot(thamKhaoQuery, (snapshot) => {
      if (!snapshot.empty) {
        let imgArr = [];
        snapshot.forEach((item) =>
          imgArr.push({
            uid: item.id,
            data: item.data(),
          })
        );
        setImgArr(imgArr);
      } else {
        // setSystemNor((prev) => [
        //   ...prev,
        //   {
        //     key: Math.floor(Math.random() / 1000),
        //     type: "error",
        //     msg: "Lỗi dữ liệu cloud, vui lòng liên hệ kỹ thuật",
        //   },
        // ]);
        setTimeout([]);
      }
    });
  }, []);

  //remove single nort function
  const clearANort = (key) => {
    setSystemNor((state) => state.filter((item) => item.key !== key));
  };

  //define main content
  let mainContent;
  if (imgArr.length === 0) {
    mainContent = (
      <div className={classes.note}>
        <p>Chưa có dữ liệu hình ảnh cho món ăn này!</p>
      </div>
    );
  } else if (imgArr.length > 0) {
    mainContent = imgArr.map((item) => (
      <div key={item.uid}>
        <img src={item.data.URL} onClick={() => selectItem(item.data)} />
        <input
          type="checkbox"
          name={item.uid}
          onChange={(e) => onCheckHandler(e, item)}
        />
      </div>
    ));
  }

  //disable input until submit is completed
  useEffect(() => {
    const containerDOM = galleryContainerRef.current;
    const inputDOM = containerDOM.querySelectorAll("div input");
    if (props.isSubmitting) {
      inputDOM.forEach((item) => (item.disabled = true));
    } else {
      inputDOM.forEach((item) => (item.disabled = false));
    }
  }, [props.isSubmitting]);

  return (
    <div className={classes.container} ref={galleryContainerRef}>
      {isZoom &&
        ReactDOM.createPortal(
          <MainBackdropV3 onClick={closeZoom} />,
          document.getElementById("main-backdrop")
        )}
      {isZoom &&
        ReactDOM.createPortal(
          <ImgZoomModal data={selectingItem} />,
          document.getElementById("main-overlay")
        )}
      {mainContent}
      <SysNorRightFix data={systemNor} onClick={clearANort} />
    </div>
  );
};

export default GalleryGrid;
