import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import classes from "./CreateReviewRequestForm.module.css";
import AccordionSM from "../../UI/accordion/AccordionSM";
import AccordionBtnV1 from "../../UI/accordion/AccordionBtnV1";
import SearchBar from "../../panel/SearchBar";
import MenuBtn from "./MenuBtn";
import RaterBtn from "./RaterBtn";
import {
  collection,
  query,
  where,
  onSnapshot,
  serverTimestamp,
  getDocs,
} from "firebase/firestore";
import Loader from "../../UI/Loader";
import { db, functions } from "../../../firebase/firebase";
import { SysNorRightFix } from "../../UI/systemNor/SysNorRightFix";
import RaterModal from "./RaterModal";
import MainBackdropV3 from "../../UI/backdrops/MainBackdropV3";
import MenuModal from "./MenuModal";
import SearchUserTable from "./SearchUserTable";
import SearchDishTable from "./SearchDishTable";
import { createDocV2 } from "../../../hooks/fetchFirebase";
import NortPTag from "../../UI/systemNor/NortPTag";
import { useSelector } from "react-redux";
import ChonNhomDoiTuong from "../../chon_nhom_doi_tuong/ChonNhomDoiTuong";
import { httpsCallable } from "firebase/functions";

const CreateReviewRequestForm = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [dataReady, setDataReady] = useState(false);
  const [sysNort, setSysNort] = useState([]);
  const [formNort, setFormNort] = useState([]);
  const [user, setUser] = useState();
  const [userGroup, setUserGroup] = useState([]);
  const [dishList, setDishList] = useState();
  const [requestList, setRequestList] = useState([]);
  const [generatedId, setGeneratedId] = useState();
  const [userSearchResult, setUserSearchResult] = useState(null);
  const [dishSearchResult, setDishSearchResult] = useState(null);
  const [userCart, setUserCart] = useState([]);
  const [dishCart, setDishCart] = useState([]);
  const [isViewingRater, setIsViewingRater] = useState(false);
  const [isViewingMenu, setIsViewingMenu] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const nameInputRef = useRef();
  const dateInputRef = useRef();
  const timeInputRef = useRef();
  const reasonInputRef = useRef();

  const auth = useSelector((state) => state.auth.userCredential);

  const generateIdFn = (listLength) => {
    const randomNum = Math.floor(Math.random() * 1000);
    let generatedID;
    if (listLength.length > 0) {
      generatedID = listLength.length + 1 + "-" + randomNum;
    } else {
      generatedID = 1 + "-" + randomNum;
    }
    setGeneratedId(generatedID);
  };

  //fetch data
  useEffect(() => {
    //fetch and wait to load all data

    //fetch activated users and exclude deleted users
    const fetchUser = new Promise((resolve, reject) => {
      const userDbQuery = query(
        collection(db, "employee_users"),
        where("department", "!=", "remove")
      );
      onSnapshot(userDbQuery, (snapshot) => {
        if (!snapshot.isEmpty) {
          let userArr = [];
          snapshot.forEach((item) =>
            userArr.push({
              id: item.id,
              data: item.data(),
            })
          );
          setUser(userArr);
          resolve(true);
        } else {
          resolve(true); // still true but with no users
        }
      });
    });

    //fetch dish from db

    const fetchDish = new Promise((resolve) => {
      onSnapshot(collection(db, "dish_list"), (snapshot) => {
        let dishArr = [];
        if (!snapshot.empty) {
          snapshot.forEach((item) =>
            dishArr.push({
              id: item.id,
              data: item.data(),
            })
          );
          setDishList(dishArr);
          resolve(true);
        } else {
          resolve(true); //still true but with no dish
        }
      });
    });

    const fetchRequest = new Promise((resolve) => {
      onSnapshot(collection(db, "food_review_request"), (snapshot) => {
        if (!snapshot.empty) {
          let requestArr = [];
          snapshot.forEach((item) =>
            requestArr.push({
              id: item.id,
              data: item.data(),
            })
          );
          setRequestList(requestArr);
          generateIdFn(requestArr);
          resolve(true);
        } else {
          generateIdFn(requestList);
          resolve(true); //still true but with no request list
        }
      });
    });

    //fetch user group
    const fetchUserGroup = new Promise((resolve) => {
      const userGroupCol = collection(db, "nhom_nhan_su");
      getDocs(userGroupCol).then((res) => {
        let newArr = [];
        res.forEach((item) =>
          newArr.push({
            docId: item.id,
            data: item.data(),
          })
        );
        setUserGroup(newArr);
        resolve(true);
      });
    });

    Promise.all([fetchUser, fetchDish, fetchRequest, fetchUserGroup]).then(
      () => {
        setIsFetching(false);
        setDataReady(true);
      }
    );
  }, []);

  //generate id everytime request list update
  // useEffect(() => {
  //   const randomNum = Math.floor(Math.random() * 1000);
  //   const generatedID = requestList.length + 1 + "-" + randomNum;
  //   setGeneratedId(generatedID);
  // }, [requestList]);

  //search function
  const searchFn = (keyword, data) => {
    const newArr = data.filter((item) => {
      const lowerCaseKeyword = keyword.toLowerCase();
      const fullName = item.data.familyName + " " + item.data.name;
      const lowerCaseFullName = fullName.toLowerCase();
      return lowerCaseFullName.includes(lowerCaseKeyword);
    });
    return newArr;
  };

  //search user
  const searchUser = (keyword) => {
    const result = user.filter((item) => {
      const lowerCaseKeyword = keyword.toLowerCase();
      const fullName = item.data.familyName + " " + item.data.name;
      const lowerCaseFullName = fullName.toLowerCase();
      return lowerCaseFullName.includes(lowerCaseKeyword);
    });
    setUserSearchResult(result);
  };

  //clear search user
  const resetUserSearch = () => {
    setUserSearchResult(null);
  };

  //search dish
  const searchDish = (keyword) => {
    const result = dishList.filter((item) => {
      const lowerCaseKeyword = keyword.toLowerCase();
      const lowerCaseDishName = item.data.name.toLowerCase();
      return lowerCaseDishName.includes(lowerCaseKeyword);
    });
    console.log(result);
    setDishSearchResult(result);
  };

  //clear search dish
  const resetDishSearch = () => {
    // setDishSearchResult([]);
    setDishSearchResult(null);
  };

  //remove single sys nort
  // const removeNort = (key) => {
  //   const newArr = sysNort.filter((nort) => nort.key !== key);
  //   setSysNort(newArr);
  // };
  const removeNort = (key) => {
    // const newArr = sysNort.filter((nort) => nort.key !== key);
    setSysNort((prev) => prev.filter((item) => item.key !== key));
  };

  //remove single form nort
  const removeFormNort = (key) => {
    setFormNort((prev) => prev.filter((item) => item.key !== key));
  };

  //add to cart function
  //add user to cart
  const addUserToCartFn = (user) => {
    //check duplicated user
    const nortKey = Math.floor(Math.random() * 10000); //generate local nort key
    const duplicateArr = userCart.filter((item) => item.id === user.id);
    if (duplicateArr.length > 0) {
      setSysNort((prev) => [
        ...prev,
        {
          type: "error",
          msg: "Đối tượng này đã có trong danh sách",
          key: nortKey,
        },
      ]);
      setTimeout(() => removeNort(nortKey), 2500);
    } else {
      setUserCart((prev) => [...prev, user]);
      setSysNort((prev) => [
        ...prev,
        {
          type: "success",
          msg: "Đã thêm đối tượng vào danh sách",
          key: nortKey,
        },
      ]);
      setTimeout(() => removeNort(nortKey), 2500);
      // setTimeout(() => console.log(nortKey), 1000)
    }
  };

  //add dish to cart
  const addDishToCartFn = (dish) => {
    //check duplicated user
    const nortKey = Math.floor(Math.random() * 10000); //generate local nort key
    const duplicateArr = dishCart.filter((item) => item.id === dish.id);
    if (duplicateArr.length > 0) {
      setSysNort((prev) => [
        ...prev,
        {
          type: "error",
          msg: "Món ăn này đã có trong danh sách",
          key: nortKey,
        },
      ]);
      setTimeout(() => removeNort(nortKey), 2500);
    } else {
      setDishCart((prev) => [...prev, dish]);
      setSysNort((prev) => [
        ...prev,
        {
          type: "success",
          msg: "Đã thêm món ăn vào danh sách",
          key: nortKey,
        },
      ]);
      setTimeout(() => removeNort(nortKey), 2500);
      // setTimeout(() => console.log(nortKey), 1000)
    }
  };

  //add group to cart
  const addGroup = (value) => {
    let newArr = [];
    const filterArr = userGroup.filter((item) => item.docId === value);
    filterArr[0].data.group_members.forEach((member) => {
      newArr.push({
        id: member.docId,
        department: member.data.department,
        fullName: `${member.data.familyName} ${member.data.name}`,
      });

      const user = {
        id: member.docId,
        department: member.data.department,
        fullName: `${member.data.familyName} ${member.data.name}`,
      };
      addUserToCartFn(user);
    });
  };

  //remove from cart function
  const userCartRemove = (id) => {
    setUserCart((prev) => prev.filter((item) => item.id !== id));
  };
  const dishCartRemove = (id) => {
    setDishCart((prev) => prev.filter((item) => item.id !== id));
  };

  //reset all form input
  const resetForm = () => {
    nameInputRef.current.value = null;
    dateInputRef.current.value = null;
    timeInputRef.current.value = null;
    reasonInputRef.current.value = null;
    setUserCart([]);
    setDishCart([]);
  };

  //modal active and deactive
  const raterModalClose = () => {
    setIsViewingRater(false);
  };
  const menuModalClose = () => {
    setIsViewingMenu(false);
  };
  const raterModalActive = () => {
    menuModalClose();
    setIsViewingRater(true);
  };
  const menuModalActive = () => {
    raterModalClose();
    setIsViewingMenu(true);
  };

  //reused set nortification function
  const setFormNortMsg = (type, msg) => {
    const randomKey = Math.floor(Math.random() * 10000);
    setFormNort((prev) => [
      ...prev,
      {
        type,
        msg,
        key: randomKey,
      },
    ]);
    setTimeout(() => {
      setFormNort(randomKey);
    }, 3000);
    setIsSubmitting(false);
  };

  //form submit handler
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setFormNort([]);
    setIsSubmitting(true);

    const nameCheck = nameInputRef.current.value !== false;
    const dateCheck = dateInputRef.current.value !== false;
    const timeCheck = timeInputRef.current.value !== false;

    if (
      userCart.length > 0 &&
      dishCart.length > 0 &&
      nameCheck &&
      dateCheck &&
      timeCheck
    ) {
      let submitObject = {
        id: generatedId,
        name: nameInputRef.current.value,
        date: dateInputRef.current.value,
        time: timeInputRef.current.value,
        reason: reasonInputRef.current.value,
        timeCreated: serverTimestamp(),
        creator: auth.uid,
        creatorEmail: auth.email,
        isAllowed: false,
        tasters: userCart,
        dishes: dishCart,
        approveStatus: "waiting",
      };

      //<-- add to db
      console.log(submitObject.dishes);
      setIsSubmitting(false);
      const validCheckFn = httpsCallable(functions, "callFoodRequestSubmit");

      const validCheck = await validCheckFn({ data: submitObject.dishes });

      console.log(validCheck);

      if (validCheck.data.allowedToRequest) {
        const dbRef = collection(db, "food_review_request");
        createDocV2(dbRef, submitObject).then((res) => {
          if (res.code === 0) {
            const randomKey = Math.floor(Math.random() * 10000);
            setFormNort((prev) => [
              ...prev,
              {
                type: "success",
                msg: "Đề xuất đã được tạo",
                key: randomKey,
              },
            ]);
            resetForm();
            setIsSubmitting(false);
            setTimeout(() => removeFormNort(randomKey), 3000);
          } else {
            const randomKey = Math.floor(Math.random() * 10000);
            setFormNort((prev) => [
              ...prev,
              {
                type: "error",
                msg: res.message,
                key: randomKey,
              },
            ]);
            setIsSubmitting(false);
            setTimeout(() => removeFormNort(randomKey), 3000);
          }
        });
      } else {
        const randomKey = Math.floor(Math.random() * 10000);
        let dishList = "";
        for (const item of validCheck.data.fArr) {
          const dishName = " " + item.dishName + ",";
          dishList = dishList.concat(dishName);
          console.log(dishName);
        }
        console.log(dishList);
        setFormNort((prev) => [
          ...prev,
          {
            type: "error",
            msg: `Có món ăn quá 3 lần thử món hoặc quá 90 ngày sau lần thử món đầu tiên: ${dishList}`,
            key: randomKey,
          },
        ]);
        setIsSubmitting(false);
        setTimeout(() => removeFormNort(randomKey), 6000);
      }
      //-->
    } else {
      if (userCart.length === 0) {
        setFormNortMsg("error", "Chưa có đối tượng thử món");
      }
      if (dishCart.length === 0) {
        setFormNortMsg("error", "Chưa có món ăn trong danh sách");
      }
      if (dateCheck == false) {
        setFormNortMsg("error", "Bạn chưa nhập ngày");
      }
      if (timeCheck == false) {
        setFormNortMsg("error", "Bạn chưa nhập thời gian ước tính");
      }
      if (
        userCart.length > 0 &&
        dishCart.length > 0 &&
        nameCheck &&
        dateCheck &&
        timeCheck
      ) {
        setFormNortMsg(
          "error",
          "Lỗi hệ thống, vui lòng liên hệ IT để kiểm tra"
        );
      }
    }
  };

  //loading nortification
  let loadingNort;
  if (isFetching) {
    loadingNort = (
      <div className={classes["loading-nort"]}>
        <Loader />
        <p>Đang thực hiện đồng bộ dữ liệu</p>
      </div>
    );
  }

  //search audience and dish content
  let searchForm;
  // if (dataReady) {
  //   searchForm = (
  //     <React.Fragment>
  //       <AccordionBtnV1 title="Đối tượng thử món">
  //         <SearchBar onSearch={searchUser} onReset={resetUserSearch} />
  //         {/* <FlexTableKIT3cols
  //         col1={reviewerTableHeader.col1}
  //         col2={reviewerTableHeader.col2}
  //         col3={reviewerTableHeader.col3}
  //         data={reviewerData}
  //         toolFn={<KitTest/>}
  //       /> */}
  //         <SearchUserTable data={userSearchResult} onAdd={addUserToCartFn} />
  //         {/* <SearchUserTable data={[]} onAdd={addUserToCartFn} /> */}
  //       </AccordionBtnV1>
  //       <AccordionBtnV1 title="Món ăn đề xuất">
  //         <SearchBar onSearch={searchDish} onReset={resetDishSearch} />
  //         <SearchDishTable data={dishSearchResult} onAdd={addDishToCartFn} />
  //       </AccordionBtnV1>
  //     </React.Fragment>
  //   );
  // } else {
  //   searchForm = null;
  // }

  if (dataReady) {
    const userO = {
      search: searchUser,
      reset: resetUserSearch,
      data: userSearchResult,
      add: addUserToCartFn,
    };
    const groupO = {
      data: userGroup,
      add: addGroup,
    };

    searchForm = (
      <React.Fragment>
        <ChonNhomDoiTuong user={userO} group={groupO} />

        <AccordionBtnV1 title="Món ăn đề xuất">
          <SearchBar onSearch={searchDish} onReset={resetDishSearch} />
          <SearchDishTable data={dishSearchResult} onAdd={addDishToCartFn} />
        </AccordionBtnV1>
      </React.Fragment>
    );
  } else {
    searchForm = null;
  }

  //system nortification content
  let nortContent = [];
  if (sysNort.length > 0) {
    nortContent = <SysNorRightFix data={sysNort} onClick={removeNort} />;
  }

  //form nortification content
  let formNortContent;
  if (formNort.length > 0) {
    formNortContent = formNort.map((item) => (
      <NortPTag key={item.key} type={item.type}>
        {item.msg}
      </NortPTag>
    ));
  }

  //submit css state
  let submitBtnClass;
  if (isSubmitting) {
    submitBtnClass = `${classes["submit-btn"]} ${classes.submitting}`;
  } else {
    submitBtnClass = `${classes["submit-btn"]}`;
  }

  //submit content
  let submitBtnContent;
  if (isSubmitting) {
    submitBtnContent = (
      <React.Fragment>
        <Loader />
        <p>Đang tạo đề xuất, vui lòng chờ trong giây lát!</p>
      </React.Fragment>
    );
  } else {
    submitBtnContent = (
      <button
        // className={classes["submit-btn"]}
        className={submitBtnClass}
        type="button"
        onClick={formSubmitHandler}
        disabled={isSubmitting ? true : false}
      >
        Tạo đề xuất
      </button>
    );
  }

  return (
    <AccordionSM>
      <AccordionBtnV1 title="Thông tin">
        <div className={classes.description}>
          <div>
            <p>
              <span className={classes.id}>ID:</span>
              <span className={classes.idNum}>{generatedId}</span>
            </p>
          </div>
          <div>
            <label htmlFor="">Tên đề xuất</label>
            <input type="text" id="formName" ref={nameInputRef} />
          </div>
          <div>
            <label htmlFor="formDate">Ngày diễn ra</label>
            <input type="date" id="formDate" ref={dateInputRef} required />
          </div>
          <div>
            <label htmlFor="formTime">Thời gian ước tính</label>
            <input type="time" id="formTime" ref={timeInputRef} required />
          </div>
          <div>
            <label htmlFor="formNote">Lý do đề xuất</label>
            <textarea rows="3" id="formNote" ref={reasonInputRef} required />
          </div>
          <div className={classes["icon-panel"]}>
            <div>
              <MenuBtn dishCount={dishCart.length} onClick={menuModalActive} />
            </div>
            <div>
              <RaterBtn
                userCount={userCart.length}
                onClick={raterModalActive}
              />
            </div>
          </div>
        </div>
      </AccordionBtnV1>
      {loadingNort}
      {/* <AccordionBtnV1 title="Đối tượng thử món">
        <SearchBar onSearch={searchUser} onReset={resetUserSearch} />
        <SearchUserTable data={userSearchResult} onAdd={addUserToCartFn} />
      </AccordionBtnV1> */}
      {/* <AccordionBtnV1 title="Món ăn đề xuất">
        <SearchBar onSearch={searchDish} onReset={resetDishSearch} />
        <SearchDishTable data={dishSearchResult} onAdd={addDishToCartFn} />
      </AccordionBtnV1> */}
      {searchForm}
      <div className={classes["submit-panel"]}>
        {/* <button
          // className={classes["submit-btn"]}
          className={submitBtnClass}
          type="button"
          onClick={formSubmitHandler}
          disabled={isSubmitting ? true : false}
        >
          Tạo đề xuất
        </button> */}
        {submitBtnContent}
      </div>
      {formNortContent}

      {nortContent}
      {isViewingRater &&
        ReactDOM.createPortal(
          <MainBackdropV3 onClick={raterModalClose} />,
          document.getElementById("main-backdrop")
        )}
      {isViewingRater &&
        ReactDOM.createPortal(
          <RaterModal
            onClose={raterModalClose}
            data={userCart}
            onRemove={userCartRemove}
          />,
          document.getElementById("main-overlay")
        )}
      {isViewingMenu &&
        ReactDOM.createPortal(
          <MainBackdropV3 onClick={menuModalClose} />,
          document.getElementById("main-backdrop")
        )}
      {isViewingMenu &&
        ReactDOM.createPortal(
          <MenuModal
            onClose={menuModalClose}
            data={dishCart}
            onRemove={dishCartRemove}
          />,
          document.getElementById("main-overlay")
        )}
    </AccordionSM>
  );
};

export default CreateReviewRequestForm;
