import classes from "./ViewEmployeeGroupForm.module.css";
import FlexTable3Col1Start from "../../table/FlexTable3Col1Start";
import McpButtonV1 from "../../UI/button/McpButtonV1";
import { useState } from "react";
import ReactDOM from "react-dom";
import MainBackdropV3 from "../../UI/backdrops/MainBackdropV3";
import ViewEmployeeGroupModal from "./ViewEmployeeGroupModal";
import { db } from "../../../firebase/firebase";
import { collection, onSnapshot, doc } from "firebase/firestore";
import { useEffect } from "react";
import { deleteDbDoc } from "../../../hooks/fetchFirebase";
import { SysNorRightFix } from "../../UI/systemNor/SysNorRightFix";

const ViewEmployeeGroupForm = () => {
  const [modalIsOn, setModalIsOn] = useState(false);
  const [groupDb, setGroupDb] = useState([]);
  const [groupMember, setGroupMember] = useState([]);
  const [viewItem, setViewItem] = useState([]);
  const [viewId, setViewId] = useState();
  const [sysNort, setSysNort] = useState([]);

  const title = {
    col1: "STT",
    col2: "Tên nhóm",
    col3: "Công cụ",
  };

  useEffect(() => {
    const colRef = collection(db, "nhom_nhan_su");
    const fetchDbGroup = onSnapshot(colRef, (snapshot) => {
      if (!snapshot.empty) {
        const groupArr = [];
        const memberArr = [];
        snapshot.forEach((item) => {
          const data = item.data();
          groupArr.push({
            col1: data.group_name,
            col2: data.group_id,
            col3: item.id,
          });
          memberArr.push({
            docId: item.id,
            members: data.group_members,
          });
        });
        setGroupDb(groupArr);
        setGroupMember(memberArr);
      }
    });
  }, []);

  const closeModal = () => {
    setModalIsOn(false);
  };

  const addOnFn = (docId) => {
    const openModal = () => {
      setModalIsOn(true);
      const viewItem = groupMember.filter((item) => item.docId === docId);
      const viewId = groupDb.filter((item) => item.col3 === docId);
      setViewItem(viewItem[0]);
      setViewId(viewId[0]);
    };

    const deleteDoc = () => {
      const docRef = doc(db, "nhom_nhan_su", docId);
      deleteDbDoc(docRef).then((res) => {
        if (res.code === 0) {
          setSysNort((prev) => [
            ...prev,
            {
              key: Math.floor(Math.random() * 1000),
              type: "success",
              msg: "Đã xoá thành công",
            },
          ]);
        } else {
          setSysNort((prev) => [
            ...prev,
            {
              key: Math.floor(Math.random() * 1000),
              type: "error",
              msg: `Đã có lỗi ${res.message}`,
            },
          ]);
        }
      });
    };

    return (
      <div className={classes.panel}>
        <McpButtonV1
          content="Xem"
          type="blue"
          btnType="button"
          onClick={openModal}
        />
        <McpButtonV1
          content="Xoá"
          type="yellow"
          btnType="button"
          onClick={deleteDoc}
        />
      </div>
    );
  };

  const removeNort = (key) => {
    setSysNort((prev) => prev.filter((item) => item.key !== key));
  };

  return (
    <div className={classes.container}>
      {/* <AccordionBtnV3 title='test' /> */}
      <FlexTable3Col1Start title={title} data={groupDb} addOnComp={addOnFn} />
      {modalIsOn &&
        ReactDOM.createPortal(
          <MainBackdropV3 onClick={closeModal} />,
          document.getElementById("main-backdrop")
        )}
      {modalIsOn &&
        ReactDOM.createPortal(
          <ViewEmployeeGroupModal
            onClick={closeModal}
            data={viewItem}
            dataId={viewId}
          />,
          document.getElementById("main-backdrop")
        )}
      <SysNorRightFix data={sysNort} onClick={removeNort} />
    </div>
  );
};

export default ViewEmployeeGroupForm;
