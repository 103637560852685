import { Fragment, useState } from "react";
import ImgGallery from "./ImgGallery";
import classes from "./ImgSelectModal.module.css";
import ImgZoomModal from "./ImgZoomModal";
import McpButtonV1 from "../UI/button/McpButtonV1";

const ImgSelectModal = (props) => {
  const [modaIndex, setModalIndex] = useState("list");
  const [zoomItem, setZoomItem] = useState();

  const activateZoom = (item) => {
    setModalIndex("zoom");
    setZoomItem(item);
  };

  const backToList = () => {
    setModalIndex("list");
  };

  let modalContent;
  switch (modaIndex) {
    case "list":
      modalContent = (
        <Fragment>
          <div className={classes.btn}>
            <McpButtonV1
              type="blue"
              btnType="button"
              onClick={props.onBack}
              content="Quay về"
            />
          </div>
          <ImgGallery
            data={props.data}
            checkItemFn={props.checkItemFn}
            uncheckItemFn={props.uncheckItemFn}
            activateZoom={activateZoom}
          />
        </Fragment>
      );
      break;
    case "zoom":
      modalContent = (
        <Fragment>
          <div className={classes.btn}>
            <McpButtonV1
              type="blue"
              btnType="button"
              onClick={backToList}
              content="Quay về"
            />
          </div>
          <ImgZoomModal data={zoomItem} />
        </Fragment>
      );
      break;
  }

  return <Fragment>{modalContent}</Fragment>;
};

export default ImgSelectModal;
