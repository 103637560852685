import React from "react";
import classes from "./SingleFieldV3.module.css";

const SingleFieldV3 = (props) => {
  /*
    This is single field V3, change css style - label margin bottom : {
        label, 
        id, 
        type, 
        placeholder, 
        required,
        reference
    }
 */
    

  return (
    <div className={classes["field-wrapper"]}>
      <label>{props.label}</label>
      <input
        id={props.id}
        name={props.id}
        type={props.type}
        required={props.required}
        placeholder={props.placeholder}
        ref={props.reference}
      ></input>
    </div>
  );
};

export default SingleFieldV3 
