import React from "react";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import classes from "./MenuBtn.module.css";

const MenuBtn = (props) => {

  let countContent = null;
  if (props.dishCount > 0) {
    countContent = <div className={classes.count}>{props.dishCount}</div>;
  }


  return (
    <React.Fragment>
      <button key={props.dishCount} className={classes.btn} onClick={props.onClick}>
        <MenuBookIcon sx={{ color: "#30BE75", fontSize: "30px" }} />
        {countContent}
      </button>
    </React.Fragment>
  );
};

export default MenuBtn;
