import React from 'react'; 
import classes from './BurgerIcon.module.css';

const BurgerIcon = (props) => {
  return (
    <div className={props.state ? `${classes.burgerIcon} ${classes.active}` : `${classes.burgerIcon}`} onClick={props.onClick}>
      <span />
      <span />
      <span />
    </div>
  )
}

export default BurgerIcon
