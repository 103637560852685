import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useWindowSize, makeSizeRepsonsive } from "../../../hooks/layoutDOM";
import CardV3 from "../card/CardV3";
import classes from "./ModalWrapperV4.module.css";

/*
  This version allow backdrop to automatically listen to browser sizes change an adjust

  props list : {
    children
  }
*/

const ModalWrapperV4 = (props) => {
  const wrapperRef = useRef();

  const windowSize = useWindowSize();
  const navWidth = useSelector((state) => state.spec.nav.width);

  useEffect(() => {
    makeSizeRepsonsive(wrapperRef.current.style);
    wrapperRef.current.style.position = "fixed";
    wrapperRef.current.style.top = "7%";
    wrapperRef.current.style.zIndex = "10";
    wrapperRef.current.style.height = "";
  }, [windowSize, navWidth]);

  return (
    <div className={classes["modal-wrapper"]} ref={wrapperRef}>
      <CardV3>{props.children}</CardV3>
    </div>
  );
};

export default ModalWrapperV4;
