import React from 'react'; 
import classes from './NortPTag.module.css';

const NortPTag = (props) => {
  return (
    <React.Fragment>
        <p className={`${classes.nort} ${classes[`${props.type}`]}`}>{props.children}</p>
    </React.Fragment>
  )
}

export default NortPTag; 
