import React, { useState } from "react";
import classes from "./DeXuatDanhGiaTrangTri.module.css";
import FormDeXuatDanhGiaTrangTri from "./FormDeXuatDanhGiaTrangTri";
import CompCtx from "./CompCtx";
import XemDeXuatDanhGiaTrangTri from "./XemDeXuatDanhGiaTrangTri";

const DeXuatDanhGiaTrangTri = () => {
  const [pageSelect, setPageSelect] = useState("create");
  
  const [dishCartCtx, setDishCartCtx ] = useState([])

  const pageOnChangeHandler = (e) => {
    setPageSelect(e.target.value);
  };


  return (
    <div>
      <CompCtx.Provider value={{ dishId: null, selection: [], dishCartCtx, setDishCartCtx}}>

        <div className={classes["page-choice-panel"]}>
          <form>
            <div>
              <input
                type="radio"
                value="create"
                id="createOption"
                name="option"
                checked={pageSelect === "create" ? true : false}
                onChange={pageOnChangeHandler}
              />
              <label htmlFor="createOption">Tạo đề xuất mới</label>
            </div>
            <div>
              <input
                type="radio"
                value="view"
                id="viewOption"
                name="option"
                checked={pageSelect === "view" ? true : false}
                onChange={pageOnChangeHandler}
              />
              <label htmlFor="viewOption">Xem đề xuất</label>
            </div>
          </form>
        </div>
        {pageSelect === "create" && <FormDeXuatDanhGiaTrangTri />}
        {pageSelect === "view" && <XemDeXuatDanhGiaTrangTri />}
      </CompCtx.Provider>
    </div>
  );
};

export default DeXuatDanhGiaTrangTri;
