import { useState } from "react";
import classes from "./AccordionBtnV2Sm.module.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RemoveIcon from "@mui/icons-material/Remove";

/*
  Version:
  0-accordionBtn.js
  V1-this version - receive setting form parent
  props: {
    title, 
    onClose, 
    children
  }
*/

const AccordionBtnV2Sm = (props) => {
  let onState = props.onClose ? true : false;

  const [isHidden, setIsHidden] = useState(onState);

  const onClickHandler = () => {
    setIsHidden((prev) => !prev);
  };

  return (
    <div className={classes.wrapper}>
      <button className={classes.accordion} onClick={onClickHandler}>
        {props.title}
        <span className={classes.icon}>
          {isHidden ? <ArrowDropDownIcon /> : <RemoveIcon />}
        </span>
      </button>
      {!isHidden && <div className={classes.panel}>{props.children}</div>}
    </div>
  );
};

export default AccordionBtnV2Sm;
