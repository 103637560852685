import { useEffect, useRef } from "react";
import classes from "./MainBackdropV3.module.css";
import { makeSizeRepsonsive, useWindowSize } from "../../../hooks/layoutDOM";
// import { useSelector } from "react-redux/es/exports";
import { useSelector } from "react-redux";

/*
  This version allow backdrop to automatically listen to browser sizes change an adjust

  props list : {
    onClick, 
    children
  }
*/

const MainBackdropV3 = (props) => {
  const backdropRef = useRef();

  const windowSize = useWindowSize();
  const navWidth = useSelector((state) => state.spec.nav.width);

  useEffect(() => {
    makeSizeRepsonsive(backdropRef.current.style);
  }, [windowSize, navWidth]);

  return (
    <div className={classes.backdrop} onClick={props.onClick} ref={backdropRef}>
      {props.children}
    </div>
  );
};

export default MainBackdropV3;
