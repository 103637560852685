import { Fragment } from "react";
import SelectFieldV3 from "../../form/template/SelectFieldV3";

const DishOrderFilter = (props) => {
  const optionsList = [
    {
      value: "date_des",
      textContent: "Theo ngày (mới -> cũ)",
      key: 1,
    },
    {
      value: "date_asc",
      textContent: "Theo ngày (cũ -> mới)",
      key: 2,
    },
    {
      value: "group_asc",
      textContent: "Theo tên nhóm (A -> Z)",
      key: 3,
    },
    {
      value: "group_des",
      textContent: "Theo tên nhóm (Z -> A)",
      key: 4,
    },
  ];

  const onSelectHandler = (value) => {
    props.onSelect(value);
  };

  return (
    <Fragment>
      <SelectFieldV3
        id="select_filter"
        label="Chọn lưa phương thức lọc"
        optionsList={optionsList}
        onClick={onSelectHandler}
        defaultOption="date_des"
        defaultText="Theo ngày (mới -> cũ)"
      />
    </Fragment>
  );
};

export default DishOrderFilter;
