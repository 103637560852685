import { Fragment, useState } from "react";
import SliderDecor from "./SliderDecor";
import ViewConceptRating from "./ViewConceptRating";

const ToolPage = (props) => {
  const [pageIndex, setPageIndex] = useState("standard");
  const [ratingArr, setRatingArr] = useState([]);
  const [imgUID, setImgUID] = useState(); 


  //page navigation fn 
  const viewRating = (arr, imgUID) => {
    setPageIndex('admin-view'); 
    setRatingArr(arr);
    setImgUID(imgUID);
  }

  const backToList = () => {
    setPageIndex('standard')
  }

  let compContent;
  switch (pageIndex) {
    case "standard":
      compContent = <SliderDecor data={props.data} onViewRating = {viewRating} />;
      break;
    case "admin-view":
      compContent = <ViewConceptRating onBack ={backToList} data={ratingArr} imgUID={imgUID}/>;
      break;
    default:
      compContent = <SliderDecor data={props.data} onViewRating = {viewRating} />;
      break;
  }

  return (
    // <SliderDecor data={props.data} />
    <Fragment>
      {compContent}
    </Fragment>
  );
};

export default ToolPage;
