import React, { useState, useRef, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import classes from "./KitSearchBar2.module.css";
import SelectFieldMonAn from "./SelectFieldMonAn";
import McpButtonV1 from "../../UI/button/McpButtonV1";
import { db } from "../../../firebase/firebase";
import { collection } from "firebase/firestore";
import { getDbs1 } from "../../../hooks/fetchFirebase";
import SelectFieldV3 from "../../form/template/SelectFieldV3";

// optionsList : {
//     value,
//     textContent,
//     disabled,
//     key,
//   },
//   id,
//   label,
//   onClick,
//   onClickSub,
//   defaultOption

/*
  Originated from SearchBar.js, customized for MonAn page
  Main purpose is to get search keywords and select value.   
 */

const KitSearchBar2 = (props) => {
  const [optionValue, setOptionValue] = useState("Món ăn");
  const [dbGroup, setDbGroup] = useState([]);
  const [dbOutlet, setDbOutlet] = useState([]);
  const [dbIsReady, setDbIsReady] = useState(false);
  const [groupChoice, setGroupChoice] = useState();
  const [outletChoice, setOutletChoice] = useState();

  const inputRef = useRef();
  const formRef = useRef();

  const optionsList = [
    {
      value: "Món ăn",
      textContent: "Món ăn",
      disabled: false,
      key: 1,
    },
    {
      value: "Nhóm món ăn",
      textContent: "Nhóm món ăn",
      disabled: false,
      key: 2,
    },
    {
      value: "Outlet",
      textContent: "Outlet",
      disabled: false,
      key: 3,
    },
  ];

  useEffect(() => {
    const groupRef = collection(db, "food_group");
    const outletRef = collection(db, "outlet");

    const fetchGroup = new Promise((resolve) => {
      getDbs1(groupRef).then((res) => resolve(res));
    });

    const fetchOutlet = new Promise((resolve) => {
      getDbs1(outletRef).then((res) => resolve(res));
    });

    Promise.all([fetchGroup, fetchOutlet]).then((res) => {
      setDbIsReady(true);
      setDbGroup(res[0]);
      setDbOutlet(res[1]);
    });
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    let inputValue;
    switch (optionValue) {
      case "Món ăn":
        inputValue = inputRef.current.value;
        break;
      case "Nhóm món ăn":
        inputValue = groupChoice;
        break;
      case "Outlet":
        inputValue = outletChoice;
        break;
      default:
        inputValue = outletChoice;
    }

    props.onSearch({
      keyword: inputValue,
      filterGroup: optionValue,
    });
  };

  const loadAll = (e) => {
    formRef.current.reset();
    submitHandler(e);
  };

  //fetch select value
  const fetchSearchOption = (value) => {
    setOptionValue(value);
  };

  //select field function
  const selectGroupHandler = (value) => {
    setGroupChoice(value);
  };

  const selectOutletHandler = (value) => {
    setOutletChoice(value);
  };

  //seach input content
  let searchInputContent;
  switch (optionValue) {
    case "Món ăn":
      searchInputContent = (
        <div className={classes["text-field"]}>
          <button type="button">
            <SearchIcon
              sx={{
                backgroundColor: "#30BE75",
                color: "white",
                borderRadius: "4px",
              }}
            />
          </button>
          <input
            type="text"
            placeholder="Nhập từ khoá tìm kiếm"
            ref={inputRef}
          />
        </div>
      );
      break;
    case "Nhóm món ăn":
      if (dbIsReady) {
        const formatGroup = dbGroup.map((item) => {
          return {
            value: item.data.foodGroup,
            textContent: item.data.foodGroup,
            disabled: false,
            key: item.docId,
          };
        });
        searchInputContent = (
          <React.Fragment>
            <SelectFieldV3
              optionsList={formatGroup}
              id="food_group"
              label="Chọn nhóm món ăn"
              onClick={selectGroupHandler}
            />
          </React.Fragment>
        );
      }
      break;
    case "Outlet":
      if (dbIsReady) {
        const formatOutlet = dbOutlet.map((item) => {
          return {
            value: item.data.outlet,
            textContent: item.data.outlet,
            disabled: false,
            key: item.docId,
          };
        });
        searchInputContent = (
          <React.Fragment>
            <SelectFieldV3
              optionsList={formatOutlet}
              id="food_outlet"
              label="Chọn outlet"
              onClick={selectOutletHandler}
            />
          </React.Fragment>
        );
      }
      break;
    default:
  }

  return (
    <form
      className={classes["search-container"]}
      onSubmit={submitHandler}
      ref={formRef}
    >
      <div>
        {/* <label htmlFor="searchSelect">Lựa chọn phạm vi tìm kiếm</label> */}
        <SelectFieldMonAn
          id="searchSelect"
          label="Lựa chọn phạm vi tìm kiếm (nếu cần) :"
          optionsList={optionsList}
          onClick={fetchSearchOption}
          defaultOption="Món ăn"
        />
      </div>
      <div>{searchInputContent}</div>
      <div className={classes["btn-panel"]}>
        <McpButtonV1 type="green" content="Tìm" onClick={submitHandler} />
        <McpButtonV1 type="blue" content="Tải toàn bộ" onClick={loadAll} />
        <McpButtonV1 type="red" btnType="reset" content="Huỷ" />
      </div>
    </form>
  );
};

export default KitSearchBar2;
