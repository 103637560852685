import { Fragment, useEffect, useState } from "react";
import ApproveCheck from "../UI/button/ApproveCheck";
import McpButton from "../UI/button/McpButton";
import classes from "./FlexTableOutlet.module.css";
import { useSelector } from "react-redux";
import { doc } from "firebase/firestore";
import { updateDbDoc, deleteDbDoc } from "../../hooks/fetchFirebase";
import { db } from "../../firebase/firebase";
import ApproveMultipleBtn from "../UI/button/ApproveMultipleBtn";
import { SysNorRightFix } from "../UI/systemNor/SysNorRightFix";
import ReactDOM from "react-dom";
import MainBackdropV3 from "../UI/backdrops/MainBackdropV3";
import EditOutletModal from "../../pages/Kitchen/EditOutletModal";

/*
  versions:
  0 - originated from FlexTable
  outlet - designed for outlet 
*/

const FlexTableOutlet = (props) => {
  const [sysNort, setSysNort] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState();
  const user = useSelector((state) => state.auth.userCredential);

  const approveCheckBox = (docID) => {
    const docDBRef = doc(db, "outlet", docID);
    const targetDoc = props.data.filter((item) => item.docID === docID);
    const targetApproval = targetDoc[0].data.isApproved;
    console.log("current approver" + targetApproval);
    let updateApproval;
    if (targetApproval == null) {
      updateApproval = user.role;
    } else if (targetApproval === "HOD") {
      if (user.role === "HOD") {
        updateApproval = null;
      } else if (user.role === "GM") {
        updateApproval = user.role;
      }
    } else {
      if (user.role === "HOD") {
        return;
      } else if (user.role === "GM") {
        updateApproval = null;
      }
    }
    const updateAction = updateDbDoc(docDBRef, {
      isApproved: updateApproval,
    });
    console.log(targetDoc, updateApproval);
  };

  const deleteDb = (docID) => {
    console.log("delete", docID);
    const docRef = doc(db, "outlet", docID);
    deleteDbDoc(docRef).then((res) => {
      if (res.code === 0) {
        setSysNort((prev) => [
          ...prev,
          {
            type: "success",
            msg: res.message,
            key: Math.floor(Math.random() * 100),
          },
        ]);
      } else {
        setSysNort((prev) => [
          ...prev,
          {
            type: "error",
            msg: "res.message",
            key: Math.floor(Math.random() * 100),
          },
        ]);
      }
      setTimeout(() => {
        setSysNort([]);
      }, 5000);
    });
  };

  const editFn = (docID) => {
    setIsEditing(true);
    const editDoc = props.data.filter((item) => item.docID === docID);
    console.log(editDoc);
    setSelectedDoc(editDoc);
  };

  const closeModal = () => {
    setIsEditing(false);
  };

  const AllowedFunction = (props) => {
    let allowedContent;

    if (user.role != "HOD" && user.role != "GM") {
      if (props.isApproved === "GM" || props.isApproved === "HOD") {
        allowedContent = (
          <Fragment>
            <ApproveCheck
              role={props.approver}
              checked={props.isApproved !== null ? true : false}
              disabled={true}
            />
          </Fragment>
        );
      } else {
        allowedContent = (
          <Fragment>
            <McpButton
              type="yellow"
              content="Sửa"
              onClick={() => props.onEdit(props.docID)}
            />
            <McpButton
              type="red"
              content="Xoá"
              onClick={() => deleteDb(props.docID)}
            />
          </Fragment>
        );
      }
    }

    if (user.role === "GM") {
      if (props.isApproved !== null && props.isApproved !== "GM") {
        let approverData = [
          {
            status: "yes",
            name: props.isApproved,
            department: "Bếp",
          },
        ];
        allowedContent = (
          <Fragment>
            <ApproveMultipleBtn
              data={approverData}
              onClick={() => approveCheckBox(props.docID)}
              outlet={props.outlet}
            />
            <McpButton
              type="yellow"
              content="Sửa"
              onClick={() => props.onEdit(props.docID)}
            />
            <McpButton
              type="red"
              content="Xoá"
              onClick={() => deleteDb(props.docID)}
            />
          </Fragment>
        );
      } else {
        allowedContent = (
          <Fragment>
            <ApproveCheck
              role={props.approver}
              onChange={() => approveCheckBox(props.docID)}
              checked={props.isApproved !== null ? true : false}
            />
            <McpButton
              type="yellow"
              content="Sửa"
              onClick={() => props.onEdit(props.docID)}
            />
            <McpButton
              type="red"
              content="Xoá"
              onClick={() => deleteDb(props.docID)}
            />
          </Fragment>
        );
      }
    }

    if (user.role === "HOD") {
      if (props.isApproved === "GM") {
        allowedContent = (
          <Fragment>
            <ApproveCheck
              role={props.approver}
              checked={true}
              disabled={true}
            />
          </Fragment>
        );
      } else {
        allowedContent = (
          <Fragment>
            <ApproveCheck
              role={props.approver}
              onChange={() => approveCheckBox(props.docID)}
              checked={props.isApproved !== null ? true : false}
            />
            <McpButton
              type="yellow"
              content="Sửa"
              onClick={() => props.onEdit(props.docID)}
            />
            <McpButton
              type="red"
              content="Xoá"
              onClick={() => deleteDb(props.docID)}
            />
          </Fragment>
        );
      }
    }

    return <Fragment>{allowedContent}</Fragment>;
  };

  //system nortification functions
  const nortRemove = (key) => {
    const newArr = sysNort.filter((item) => item.key !== key);
    console.log(newArr);
    setSysNort(newArr);
  };

  let sysNortContent;
  if (sysNort.length > 0) {
    sysNortContent = <SysNorRightFix data={sysNort} onClick={nortRemove} />;
  }

  return (
    <div className={classes.flexTableOutlet}>
      {sysNortContent}

      {isEditing &&
        ReactDOM.createPortal(
          <MainBackdropV3 onClick={closeModal} />,
          document.getElementById("main-backdrop")
        )}

      {isEditing &&
        ReactDOM.createPortal(
          <EditOutletModal data={selectedDoc} onCancel={closeModal} />,
          document.getElementById("main-overlay")
        )}

      <div className={classes.theader}>
        <div>{props.tHeadLeft && props.tHeadLeft}</div>
        <div>{props.tHeadRight && props.tHeadRight}</div>
        <div className={classes[`button-bar`]}>Công cụ</div>
      </div>
      {props.data.map((child) => (
        <div className={classes.tableRow} key={child.data.id}>
          <div>{child.data.id}</div>
          <div>{child.data.outlet}</div>
          <div className={classes[`button-row`]}>
            <AllowedFunction
              approver={
                child.data.isApproved ? child.data.isApproved : user.role
              }
              docID={child.docID}
              isApproved={child.data.isApproved}
              outlet={child.data.outlet}
              onEdit={editFn}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default FlexTableOutlet;
