import React, { useEffect, useState, useRef } from "react";
import SearchBar from "../../panel/SearchBar";
import classes from "./CreateEmployeeGroupForm.module.css";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import McpButtonV1 from "../../UI/button/McpButtonV1";
import { db } from "../../../firebase/firebase";
import { collection } from "firebase/firestore";
import { getDbs1, createDocV2 } from "../../../hooks/fetchFirebase";
import SearchResultTable from "./SearchResultTable";
import { SysNorRightFix } from "../../UI/systemNor/SysNorRightFix";
import NortPTag from "../../UI/systemNor/NortPTag";

const CreateEmployeeGroupForm = () => {
  const [member, setMember] = useState([]);
  const [dbEmployees, setDbEmployees] = useState();
  const [searchResult, setSearchResult] = useState([]);
  const [nortSys, setNortSys] = useState([]);
  const inputRef = useRef();
  const [formSys, setFormSys] = useState();
  const [searchNort, setSearchNort] = useState();

  useEffect(() => {
    const colRef = collection(db, "employee_users");
    getDbs1(colRef).then((res) => {
      setDbEmployees(res);
    });
  }, []);

  const idNum = `NNS-${Math.floor(Math.random() * 1000)}`;

  const submitHandler = (e) => {
    e.preventDefault();
    setFormSys();
    if (inputRef.current.value.trim() !== "" && member.length > 0) {
      const colRef = collection(db, "nhom_nhan_su");
      let submitObject;
      submitObject = {
        group_id: idNum,
        group_name: inputRef.current.value,
        group_members: member,
      };
      createDocV2(colRef, submitObject).then((res) => {
        if (res.code === 0) {
          setFormSys({
            type: "success",
            message: "Đã tạo nhóm thành công!",
          });
          setTimeout(() => {
            setFormSys();
          }, [5000]);
          inputRef.current.value = "";
          setMember([]);
        } else {
          setFormSys({
            type: "error",
            message: res.message,
          });
          setTimeout(() => {
            setFormSys();
          }, [5000]);
          inputRef.current.value = "";
          setMember([]);
        }
      });
    } else {
      if (inputRef.current.value.trim() === "" && member.length > 0) {
        setNortSys((prev) => [
          ...prev,
          {
            key: Math.floor(Math.random() * 1000),
            msg: "Bạn chưa nhập tên nhóm !!",
            type: "error",
          },
        ]);
      } else if (member.length === 0 && inputRef.current.value.trim() !== "") {
        setNortSys((prev) => [
          ...prev,
          {
            key: Math.floor(Math.random() * 1000),
            msg: "Bạn chưa thêm thành viên !!",
            type: "error",
          },
        ]);
      } else if (inputRef.current.value.trim() === "" && member.length === 0) {
        setNortSys((prev) => [
          ...prev,
          {
            key: Math.floor(Math.random() * 1000),
            msg: "Bạn chưa nhập đủ thông tin !!",
            type: "error",
          },
        ]);
      }
    }
  };

  const searchHandler = (keyword) => {
    setSearchNort();
    if (keyword) {
      const filterArr = dbEmployees.filter((item) => {
        const fullName = item.data.familyName + " " + item.data.name;
        const lowerFName = fullName.toLowerCase();
        // if (lowerFName.includes(keyword.toLowerCase())) {
        //   return item;
        // }
        return lowerFName.includes(keyword.toLowerCase());
      });

      setSearchResult(filterArr);
    } else if (keyword.trim() === "") {
      setSearchNort("Bạn chưa điền vào thông tin");
    }
  };

  const resetHandler = () => {
    setSearchResult([]);
  };

  const addHandler = (item) => {
    const checkArr = member.filter((member) => member.docId === item.docId);

    if (checkArr.length > 0) {
      let key = Math.floor(Math.random() * 1000);
      setNortSys((prev) => [
        ...prev,
        {
          key,
          msg: "Bạn đã thêm thành viên này vào nhóm",
          type: "error",
        },
      ]);
    } else {
      setMember((prev) => [...prev, item]);
    }
  };

  const removeHandler = (id) => {
    setMember((prev) => prev.filter((item) => item.docId !== id));
  };

  const removeNort = (key) => {
    setNortSys((prev) => prev.filter((item) => item.key !== key));
  };

  let searchContent;
  if (searchResult.length > 0) {
    searchContent = (
      <SearchResultTable data={searchResult} onAdd={addHandler} />
    );
  }

  let memberContent;
  if (member.length > 0) {
    memberContent = member.map((item) => (
      <li key={item.docId}>
        <div>
          <p>{`${item.data.familyName} ${item.data.name}`}</p>
          <p>{item.data.position}</p>
          <p>{item.data.department}</p>
        </div>
        <div>
          <button onClick={() => removeHandler(item.docId)}>
            <RemoveCircleIcon sx={{ color: "#EA1010", fontSize: "18px" }} />
          </button>
        </div>
      </li>
    ));
  } else {
    memberContent = (
      <p className={classes.note}>
        Hiện bạn chưa thêm thành viên nào vào nhóm. Bạn có thể sử dụng thanh tìm
        kiếm để thêm thành viên vào.
      </p>
    );
  }

  let nortContent;
  if (nortSys.length > 0) {
    nortContent = <SysNorRightFix data={nortSys} onClick={removeNort} />;
  }

  const resultNort = <p className={classes["result-nort"]}>{searchNort}</p>;

  return (
    <div className={classes.container}>
      {nortContent}
      <div>
        <h6>Tạo nhóm nhân sự</h6>
      </div>
      <div>
        <p className={classes.heading}>Thông tin nhóm: </p>
        <p>
          ID: <span>{idNum}</span>{" "}
        </p>
        <div className={classes["name-input"]}>
          <form action="">
            <div>
              <label htmlFor="groupname">Tên nhóm: </label>
              <input type="text" id="groupName" required ref={inputRef}></input>
            </div>
            <div>
              <McpButtonV1
                type="blue"
                btnType="submit"
                content="Tạo"
                onClick={submitHandler}
              />
            </div>
          </form>
          {formSys && (
            <NortPTag type={formSys.type}>{formSys.message}</NortPTag>
          )}
        </div>
      </div>
      <div className={classes.members}>
        <p className={classes.heading}>Thành viên trong nhóm: </p>
        <ul>{memberContent}</ul>
      </div>

      <div>
        <SearchBar onSearch={searchHandler} onReset={resetHandler} />
      </div>
      <div className={classes["search-result"]}>
        {!searchNort && searchContent}
        {searchNort && resultNort}
      </div>
    </div>
  );
};

export default CreateEmployeeGroupForm;
