import React from "react";
import classes from "./Section.module.css";

const Section = (props) => {
  return (
    <div className={classes.section} id='main-section'>
      <div id="main-backdrop"></div>
      <div id="main-overlay"></div>
      {props.children}
    </div>
  );
};

export default Section;
