import { Fragment } from "react";
import { Outlet } from "react-router-dom";

const GeneralFnLanding = () => {
    return (
        <Fragment>
            <Outlet/>
        </Fragment>
    )
}

export default GeneralFnLanding
