import React from "react";
import { Link } from "react-router-dom";
import classes from './BackHomeBtn.module.css'

const BackHomeBtn = () => {
  return (
    <button className={classes.button}>
      <Link className={classes.link} to="/trang-chu">
        Về trang chủ
      </Link>
    </button>
  );
};

export default BackHomeBtn;
