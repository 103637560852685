import { useState, useReducer, useEffect, useRef, useContext } from "react";
import ModalWrapperV3 from "../UI/modals/ModalWrapperV3";
import classes from "./UploadStatusModal.module.css";
import { ref as refFBS, getStorage } from "firebase/storage";
import uploadImageAsPromise from "../../hooks/upload";
import Loader from "../UI/Loader";
import { db } from "../../firebase/firebase";
import { collection, serverTimestamp } from "firebase/firestore";
import { createDocV2 } from "../../hooks/fetchFirebase";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { SysNorV4Center } from "../UI/systemNor/SysNorV4Center";
import FnContext from "../hinh_anh_mon_an/FunctionContext";

/*
  props list : {
    onclick, 
    path, 
    data, 
    dishName, 
    onRemoveAfterUpload, 
    dishData
  }
 */

const uploadInitState = {
  uploadArr: [],
  previewArr: [],
};

const uploadReducer = (state, action) => {
  switch (action.type) {
    case "INIT_FETCH":
      const newUploadArr = action.payload.arr;
      let previewArr = [];

      for (let i = 0; i < newUploadArr.length; i++) {
        const url = URL.createObjectURL(newUploadArr[i]);
        previewArr.push({
          url,
          // name: action.payload.id + "_" + Math.floor(Math.random() * 1000),
          name: action.payload.id + "_" + uuidv4(),
          size: newUploadArr[i].size,
          isUploaded: false,
          error: null,
          process: null,
          isRunning: true,
        });
        URL.revokeObjectURL(newUploadArr[i]);
      }

      return {
        uploadArr: newUploadArr,
        previewArr,
      };
    case "SUCCESS":
      const sArr = state.previewArr.map((item, index) => {
        if (index === action.payload.index) {
          item.isRunning = false;
          item.isUploaded = true;
        }
        return item;
      });
      return {
        previewArr: sArr,
        uploadArr: state.uploadArr,
      };
    case "FAIL":
      const failExcludeArr = state.previewArr.map((item, index) => {
        if (index === action.payload.index) {
          item.isRunning = false;
          item.error = action.payload.error;
        }
        return item;
      });
      return {
        previewArr: failExcludeArr,
        uploadArr: state.uploadArr,
      };
    default:
      return {
        uploadArr: [],
        previewArr: [],
      };
  }
};

const UploadStatusModal = (props) => {
  const [upload, dispatchUpload] = useReducer(uploadReducer, uploadInitState);
  const [isLoading, setIsLoading] = useState(true);
  const [allowClose, setAllowClose] = useState(false);
  const [sysNort, setSysNort] = useState([]);
  const imgRef = useRef();

  const fnCtx = useContext(FnContext);
  const user = useSelector((state) => state.auth.userCredential);

  const loadingContent = (
    <div className={classes["loading-wrapper"]}>
      <Loader />
      <p className={classes["loading-note"]}>
        Hệ thống đang xử lý, vui lòng không đóng cửa sổ này
      </p>
    </div>
  );

  let closeButton;
  if (allowClose === false) {
    closeButton = (
      <div className={classes["close-wrapper"]}>
        <p>Bạn có thể xem hình ảnh tại mục hình tham khảo</p>
        <button onClick={props.onClick} disabled>
          Đóng
        </button>
      </div>
    );
  } else {
    closeButton = (
      <div className={classes["close-wrapper"]}>
        <p>Bạn có thể xem hình ảnh tại mục hình tham khảo</p>
        <button onClick={props.onClick}>Đóng</button>
      </div>
    );
  }

  const runningNote = (
    <div className={classes["running-note"]}>
      <span>Đang xử lý</span>
    </div>
  );

  const completeNote = (
    <div className={classes["complete-note"]}>
      <span>Đã đăng thành công</span>
    </div>
  );

  const errorNote = (error) => {
    return (
      <div className={classes["error-note"]}>
        <span>{error}</span>
      </div>
    );
  };

  //nort add & remove function
  const addNort = (content) => {
    setSysNort({
      key: Math.floor(Math.random() * 100000),
      msg: content,
      type: "error",
    });
  };
  const removeNort = (key) => {
    setSysNort((prev) => prev.filter((item) => item.key !== key));
  };

  //define add image doc function
  // const createImgDoc = (imgName, imgURL) => {
  //   const imgDocRef = collection(
  //     db,
  //     "dish_list",
  //     props.dishData.docId,
  //     "images"
  //   );

  //   createDocV2(imgDocRef, {
  //     name: imgName,
  //     URL: `${imgURL}`,
  //     createdDate: serverTimestamp(),
  //     creator: `${user.fullName}`,
  //     category: "tham khảo",
  //   })
  //     .then((res) => {
  //       console.log(res.message)
  //       // return res.message;
  //     })
  //     .catch((error) => {
  //       console.log(error.message)
  //       // return error.message;
  //     });
  // };

  //fetch initial data from prop
  useEffect(() => {
    dispatchUpload({
      type: "INIT_FETCH",
      payload: {
        arr: props.data,
        id: props.dishName,
      },
    });
  }, []);

  //upload function
  useEffect(() => {
    // dispatchUpload({
    //   type: "INIT_FETCH",
    //   payload: {
    //     arr: props.data,
    //     id: props.dishName,
    //   },
    // });

    // iterate array and run function upload
    for (let i = 0; i < upload.uploadArr.length; i++) {
      const dbStorage = getStorage();
      const item = upload.uploadArr[i];
      const storageRef = refFBS(
        dbStorage,
        `${props.path}${upload.previewArr[i].name}`
      );
      const imgName = upload.previewArr[i].name;
      uploadImageAsPromise(storageRef, item, imgName)
        .then((res) => {
          const imgDocRef = collection(
            db,
            "dish_list",
            props.dishData.docId,
            "images"
          );

          createDocV2(imgDocRef, {
            name: imgName,
            URL: `${res}`,
            createdDate: serverTimestamp(),
            creator: `${user.fullName}`,
            category: "tham khảo",
          }).then((res) => {
            if (res.code === 0) {
              dispatchUpload({
                type: "SUCCESS",
                payload: {
                  index: i,
                },
              });
            } else if (res.code === 1) {
              dispatchUpload({
                type: "FAIL",
                payload: {
                  index: i,
                  error: res.message,
                },
              });
            }

            //run update conunt from fnContxt
            fnCtx.countFn();
          });
        })
        .catch((error) => {
          // upload.previewArr[i].isUploaded = false;
          // upload.previewArr[i].error = error;
          dispatchUpload({
            type: "FAIL",
            payload: {
              index: i,
              error,
            },
          });
        });
    }
  }, [upload.uploadArr]);

  //1_update isLoading status after complete uploading
  //2_clear uploaded items
  useEffect(() => {
    let isRunning = false;
    let completedUploadArr = [];

    upload.previewArr.length > 0 &&
      upload.previewArr.forEach((item, index) => {
        isRunning = isRunning && item.isRunning;
        item.isUploaded && completedUploadArr.push(index);
      });

    //turn off loading when files are completely uploaded
    if (isRunning === false) {
      setTimeout(() => {
        setIsLoading(false);
        props.onRemoveAfterUpload({
          type: "UPLOAD_CLEAR",
          payload: {
            indexArr: completedUploadArr,
          },
        });
        setAllowClose(true);
      }, [3000]);
    }
  }, [upload.previewArr]);

  //nort content
  let nortContent;
  if (sysNort.length > 0) {
    nortContent = <SysNorV4Center data={sysNort} onClick={removeNort} />;
  }

  return (
    <ModalWrapperV3 onClick={props.onClick}>
      <div className={classes.container}>
        {isLoading && loadingContent}
        {nortContent}
        {upload.previewArr.length > 0 &&
          upload.previewArr.map((item) => (
            <div className={classes["row"]} key={item.name}>
              <div className={classes["img-container"]}>
                <img ref={imgRef} src={item.url} />
              </div>
              <div>
                <div className={classes["item-name"]}>{item.name}</div>
                <div>{item.size}</div>
                {item.isRunning && runningNote}
                {item.isUploaded && completeNote}
                {item.error != null && errorNote(item.error)}
              </div>
            </div>
          ))}
        {!isLoading && closeButton}
      </div>
    </ModalWrapperV3>
  );
};

export default UploadStatusModal;
