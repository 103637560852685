import React from "react";
import classes from './ApproveStatusIcon.module.css'; 

const ApproveStatusIcon = (props) => {
    let classContent;
    let statusContent;  
    if(props.data === 'yes') {
        classContent = `${classes.icon} ${classes.yes}`; 
        statusContent = 'Đã duyệt'
    } else {
        classContent = `${classes.icon} ${classes.no}`
        statusContent = 'Chưa duyệt'
    }

  return (
    <React.Fragment>
      <span className={classContent}>{statusContent}</span>
    </React.Fragment>
  );
};

export default ApproveStatusIcon;
