import React from "react";
import classes from "./SingleField.module.css";

const SingleField = (props) => {
  /*
    props list : {
        label, 
        id, 
        type, 
        placeholder, 
        required,
    }
 */

    

  return (
    <div className={classes["field-wrapper"]}>
      <label>{props.label}</label>
      <input
        id={props.id}
        name={props.id}
        type={props.type}
        required = {props.required}
        placeholder={props.placeholder}
        ref = {props.reference}
        defaultValue = {props.defaultValue ? props.defaultValue : ''}
      ></input>
    </div>
  );
};

export default SingleField;
