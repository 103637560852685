import { Fragment } from "react";
import { useParams} from "react-router-dom"; 
import UserXemDeXuatDetail2 from "./UserXemDeXuatDetail2";

const UserXemDeXuatDetailWrapper = () => {
    const params = useParams();

  return (
    <Fragment>
        <UserXemDeXuatDetail2 requestID={params.requestID} />
    </Fragment>
  )
}

export default UserXemDeXuatDetailWrapper