import classes from "./DanhGiaMon3.module.css";
import { Routes, Route } from "react-router-dom";
import TrangDanhGiaChiTiet from "./TrangDanhGiaChiTiet";
import DanhGiaMonTong from "../../components/department/General/DanhGiaMonTong";
import Test from "../../components/department/General/Test";
import Page404 from "../404&others/404";
import DanhGiaMonLanding from "../../components/department/General/DanhGiaMonLanding";

const DanhGiaMon3 = () => {
  return (
    //option 1
    <div className={classes.container}>
      <Routes>
        <Route path="/" element={<DanhGiaMonLanding />}>
          <Route index element={<DanhGiaMonTong />} />
          <Route path='abc' element={<Test />} />
          <Route path="/:requestID" element={<TrangDanhGiaChiTiet />} />
        </Route>

        {/* <Route path="/*" element={<Page404 />}/> */}
      </Routes>
    </div>
  );
};

export default DanhGiaMon3;
