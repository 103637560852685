function capitalizeFirstLetter(string) {
  const lowerString = string.toLowerCase();
  return lowerString.charAt(0).toUpperCase() + lowerString.slice(1);
} //lower every words

function capitalizeFirstLetterV2(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function captializeWhole(string) {
  return string.toUpperCase();
}

function capitalizeEveryFirstLetter(string) {
  const filteredString = string.trim(" ");
  const words = filteredString.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
  }
  return words.join(" ");
}

function formatOutlet(string) {
  return string.toLowerCase().replace(/[\s-]+/g, "_");
}

export {
  capitalizeFirstLetter,
  captializeWhole,
  capitalizeEveryFirstLetter,
  capitalizeFirstLetterV2,
  formatOutlet,
};
