import React, { Fragment } from "react";
import classes from "./ImgTableDish.module.css";
import McpButtonV1 from "../UI/button/McpButtonV1";
/*
  alternative option to FlextImgTableDish.js
  Style using row 
  
  props list: {

    data: [
      {
        id, 
        name, 
        group
        outlet
      },
    ]

    onDelete
    onEdit
  }
*/

const ImgTableDish = (props) => {
  const header = {
    col1: "STT",
    col2: "Món ăn",
    col3: "Nhóm",
    col4: "Outlet",
    col5: "Hình ảnh",
  };


  let tableBodyContent;
  if (props.data.length > 0) {
    // tableBodyContent = props.data.map((item, index) => (
    //   <tr key={item.data.id}>
    //   <td>{index + 1}</td>
    //   <td>{item.data.name}</td>
    //   <td>{item.data.group}</td>
    //   <td>{item.data.outlet}</td>
    //   <td>{item.data.id}</td>
    //   <td>
    //     <McpButtonV1 btnType='button' content='Chọn' type='blue' onClick={() => props.onView(item.data.id)} />
    //   </td>
    // </tr>
    // ))

    tableBodyContent = (
      <Fragment>
        <thead>
          <tr>
            <th className={classes["top-left"]}>{header.col1}</th>
            <th>{header.col2}</th>
            <th>{header.col3}</th>
            <th>{header.col4}</th>
            <th>{header.col5}</th>
            <th colSpan="1" className={classes["top-right"]}>
              Bảng điều khiển
            </th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((item, index) => (
            <tr key={item.data.id}>
              <td>{index + 1}</td>
              <td>{item.data.name}</td>
              <td>{item.data.group}</td>
              <td>{item.data.outlet}</td>
              {/* <td>{item.data.id}</td> */}
              <td>{item.count}</td>
              <td>
                <McpButtonV1
                  btnType="button"
                  content="Chọn"
                  type="blue"
                  onClick={() => props.onView(item.data.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Fragment>
    );
  } else {
    tableBodyContent = null;
  }

  // const errorNote = <p>Không tìm thấy dữ liệu. Vui lòng chọn lại thông tin</p>;

  return (
    <table className={classes.table}>
      {tableBodyContent}
      {/* {!props.data.length > 0 && errorNote} */}
    </table>
  );
};

export default ImgTableDish;
