import { useState, useEffect, Fragment, useContext } from "react";
import classes from "./KetQuaSearchHinhAnh.module.css";
import { db } from "../../firebase/firebase";
import { collection, onSnapshot } from "firebase/firestore";
import ImgTableDish from "./ImgTableDish";
import ThemHinhAnhDetail from "./ThemHinhAnhDetail";
import HinhAnhThamKhao from "./HinhAnhThamKhao";
import McpButtonV1 from "../UI/button/McpButtonV1";
import KetQuaHinhAnhOverview from "./KetQuaHinhAnhOverview";
import NortContext from "./NortContext";
import { countDb } from "../../hooks/fetchFirebase";
import SelectPageContent from "../form/template/SelectPageContent ";
import FnContext from "./FunctionContext";

/*
    props list: {
        searchValue
    }
 */

const KetQuaSearchHinhAnh = (props) => {
  const [pageIndex, setPageIndex] = useState("overview");
  const [dishData, setDishData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [selectData, setSelectData] = useState();
  const [pageSelect, setPageSelect] = useState();

  const nortCtx = useContext(NortContext);

  const optionList = [
    {
      value: "Đang áp dụng",
      textContent: "Đang áp dụng",
      disabled: false,
      key: "dang-ap-dung",
    },
    {
      value: "Thêm hình ảnh",
      textContent: "Thêm hình ảnh",
      disabled: false,
      key: "them-hinh-anh",
    },
    {
      value: "Tham khảo",
      textContent: "Tham khảo",
      disabled: false,
      key: "tham-khao",
    },
    {
      value: "Đề xuất thay đổi",
      textContent: "Đề xuất thay đổi",
      disabled: false,
      key: "de-xuat-thay-doi",
    },
    {
      value: "Được duyệt",
      textContent: "Được duyệt",
      disabled: false,
      key: "duoc-duyet",
    },
  ];

  useEffect(() => {
    const dishRef = collection(db, "dish_list");

    const fetchDishDb = onSnapshot(dishRef, (snapshot) => {
      if (!snapshot.empty) {
        let data = [];
        snapshot.forEach((item) =>
          data.push({
            docId: item.id,
            data: item.data(),
          })
        );

        console.log(data);

        //fetch count data
        data.length > 0 &&
          data.forEach((item) => {
            // console.log(`dish_list, ${item.docId}, images`);
            const ref = collection(db, "dish_list", item.docId, "images");
            countDb(ref).then((res) => {
              item.count = res.count;
            });
          });
        setDishData(data);
      } else {
        nortCtx.onAdd({
          msg: "Lỗi: không tìm thấy dữ liệu từ cloud",
          type: "error",
          key: Math.floor(Math.random() * 10000),
        });
      }
    });
  }, []);

  useEffect(() => {
    // if (dishData.length > 0) {
    if (props.searchValue.keyword === "") {
      setDisplayData(dishData);
    } else {
      let filterArr = [];
      switch (props.searchValue.filterGroup) {
        case "Món ăn":
          const lowerCaseValue = props.searchValue.keyword.toLowerCase();
          filterArr = dishData.filter((item) => {
            const lowerCaseName = item.data.name.toLowerCase();
            if (lowerCaseName.includes(lowerCaseValue)) {
              return item;
            }
          });
          break;
        case "Nhóm món ăn":
          filterArr = dishData.filter(
            (item) => item.data.group === props.searchValue.keyword
          );
          break;
        case "Outlet":
          filterArr = dishData.filter(
            (item) => item.data.outlet === props.searchValue.keyword
          );
          break;
        case "no_image":
          //filter by count
          const noImgArr = dishData.filter((item) => item.count === 0);
          //filter by group
          const groupFilterArr = noImgArr.filter(
            (item) => item.data.group === props.searchValue.keyword
          );
          filterArr = groupFilterArr;
          break;
      }

      if (filterArr.length > 0) {
        setDisplayData(filterArr);
      } else {
        setDisplayData([]);
      }
    }
    // }
  }, [dishData, props.searchValue]);

  const backToList = () => {
    setPageIndex("overview");
  };

  const goToDish = (id) => {
    setPageIndex("detail");
    const selectData = displayData.filter((item) => item.data.id === id);
    setSelectData(selectData[0]);
  };

  const onClickHanlder = (value) => {
    setPageSelect(value);
  };

  const clickSubHandler = () => {
    console.log("This function is not available for this page");
  };

  const countDbHandler = () => {
    let newArr = displayData;
    newArr.forEach((item) => {
      const ref = collection(db, "dish_list", item.docId, "images");
      countDb(ref).then((res) => {
        item.count = res.count;
      });
    });
    setDishData(newArr);
  };

  let sectionContent;
  if (pageIndex === "overview") {
    if (displayData.length > 0) {
      sectionContent = <ImgTableDish data={displayData} onView={goToDish} />;
    } else {
      sectionContent = (
        <div className={classes["error-note"]}>
          <p>Hiện tại không có dữ liệu theo như lựa chọn tìm kiếm của bạn</p>
        </div>
      );
    }
  } else {
    // switch (props.onSelect) {
    //   case "Đang áp dụng":
    //     sectionContent = (
    //       <Fragment>
    //         <McpButtonV1
    //           content="Quay về danh sách"
    //           type="blue"
    //           btnType="button"
    //           onClick={backToList}
    //         />
    //         <KetQuaHinhAnhOverview select={props.onSelect} data={selectData} />
    //       </Fragment>
    //     );
    //     break;
    //   case "Đề xuất thay đổi":
    //     sectionContent = (
    //       <Fragment>
    //         <McpButtonV1
    //           content="Quay về danh sách"
    //           type="blue"
    //           btnType="button"
    //           onClick={backToList}
    //         />
    //         <KetQuaHinhAnhOverview select={props.onSelect} data={selectData} />
    //       </Fragment>
    //     );
    //     break;
    //   case "Được duyệt":
    //     sectionContent = (
    //       <Fragment>
    //         <McpButtonV1
    //           content="Quay về danh sách"
    //           type="blue"
    //           btnType="button"
    //           onClick={backToList}
    //         />
    //         <KetQuaHinhAnhOverview select={props.onSelect} data={selectData} />
    //       </Fragment>
    //     );
    //     break;
    //   case "Tham khảo":
    //     sectionContent = (
    //       <Fragment>
    //         <McpButtonV1
    //           content="Quay về danh sách"
    //           type="blue"
    //           btnType="button"
    //           onClick={backToList}
    //         />
    //         <HinhAnhThamKhao data={selectData} />
    //       </Fragment>
    //     );
    //     break;
    //   case "Thêm hình ảnh":
    //     sectionContent = (
    //       <Fragment>
    //         <McpButtonV1
    //           content="Quay về danh sách"
    //           type="blue"
    //           btnType="button"
    //           onClick={backToList}
    //         />
    //         <ThemHinhAnhDetail data={selectData} />
    //       </Fragment>
    //     );
    //     break;
    //   default:
    //     sectionContent = <p>Vui lòng chọn trang</p>;
    // }

    switch (pageSelect) {
      case "Đang áp dụng":
        sectionContent = (
          <Fragment>
            <div className={classes["dish-title"]}>
              <p>
                Món ăn: <span>{selectData.data.name}</span>
              </p>
            </div>
            <McpButtonV1
              content="Quay về danh sách"
              type="blue"
              btnType="button"
              onClick={backToList}
            />
            <KetQuaHinhAnhOverview select={pageSelect} data={selectData} />
          </Fragment>
        );
        break;
      case "Đề xuất thay đổi":
        sectionContent = (
          <Fragment>
            <div className={classes["dish-title"]}>
              <p>
                Món ăn: <span>{selectData.data.name}</span>
              </p>
            </div>
            <McpButtonV1
              content="Quay về danh sách"
              type="blue"
              btnType="button"
              onClick={backToList}
            />
            <KetQuaHinhAnhOverview select={pageSelect} data={selectData} />
          </Fragment>
        );
        break;
      case "Được duyệt":
        sectionContent = (
          <Fragment>
            <div className={classes["dish-title"]}>
              <p>
                Món ăn: <span>{selectData.data.name}</span>
              </p>
            </div>
            <McpButtonV1
              content="Quay về danh sách"
              type="blue"
              btnType="button"
              onClick={backToList}
            />
            <KetQuaHinhAnhOverview select={pageSelect} data={selectData} />
          </Fragment>
        );
        break;
      case "Tham khảo":
        sectionContent = (
          <Fragment>
            <div className={classes["dish-title"]}>
              <p>
                Món ăn: <span>{selectData.data.name}</span>
              </p>
            </div>
            <McpButtonV1
              content="Quay về danh sách"
              type="blue"
              btnType="button"
              onClick={backToList}
            />
            <HinhAnhThamKhao data={selectData} />
          </Fragment>
        );
        break;
      case "Thêm hình ảnh":
        sectionContent = (
          <Fragment>
            <McpButtonV1
              content="Quay về danh sách"
              type="blue"
              btnType="button"
              onClick={backToList}
            />
            <ThemHinhAnhDetail data={selectData} />
          </Fragment>
        );
        break;
      default:
        sectionContent = <p>Vui lòng chọn trang</p>;
    }
  }

  // return <div className={classes.container}>{sectionContent}</div>;
  return (
    <div className={classes.container}>
      <FnContext.Provider value={{ countFn: countDbHandler, test: "test" }}>
        {displayData.length > 0 && pageIndex !== "overview" && (
          <div className={classes.switcher}>
            <SelectPageContent
              id="view-switch"
              label="Chọn trang trạng thái"
              optionsList={optionList}
              defaultOption="Đang áp dụng"
              onClick={onClickHanlder}
              onClickSub={clickSubHandler}
            />
          </div>
        )}
        {sectionContent}
      </FnContext.Provider>
    </div>
  );
};

export default KetQuaSearchHinhAnh;
