import React from "react";
import { getAuth } from "firebase/auth";

const TestCreateUser = () => {
  const clickHandler = (e) => {
    e.preventDefault();
    getAuth.createUser({
        email: "tester@example.com",
        emailVerified: false,
        phoneNumber: "+11234567890",
        password: "tester@123",
        displayName: "tester 1",
        photoURL: "http://www.example.com/12345678/photo.png",
        disabled: false,
      })
      .then((userRecord) => {
        // See the UserRecord reference doc for the contents of userRecord.
        console.log("Successfully created new user:", userRecord.uid);
      })
      .catch((error) => {
        console.log("Error creating new user:", error);
      });
  };

  return (
    <div>
      <button onClick={clickHandler}>tao user moi</button>
    </div>
  );
};

export default TestCreateUser;
