import classes from "./DeXuatDetailModal.module.css";
import ModalWrapperV3 from "../UI/modals/ModalWrapperV3";
import CloseIcon from "@mui/icons-material/Close";
import AccordionBtnV2Sm from "../UI/accordion/AccordionBtnV2Sm";
import GalleryGridNoTick from "./GalleryGridNoTick";
import FoodDecorationStatCheck from "./FoodDecorationStatCheck";

const DeXuatDetailModal = (props) => {
  const data = props.data.data;

  //reviewers content
  let reviewersContent;
  if (data.reviewers.length > 0) {
    reviewersContent = (
      <div>
        <h5>Đối tượng: </h5>

        <div className={classes.table}>
          <div className={`${classes.row} ${classes.header}`}>
            <div>STT</div>
            <div>Thông tin</div>
            <div>Đánh giá</div>
          </div>
          {data.reviewers.map((user, index) => (
            <div className={classes.row} key={user.id}>
              <div className={classes.grey}>{index + 1}</div>
              <div className={classes.info}>
                <div>{user.fullName}</div>
                <div className={classes.grey}>{user.department}</div>
              </div>
              <FoodDecorationStatCheck dishes={data.dishes} user={user} />
            </div>
          ))}
        </div>
      </div>
    );
  }

  //food images content
  let foodImgContent;
  if (data.dishes.length > 0) {
    foodImgContent = (
      <div>
        <h5>Món ăn: </h5>
        {data.dishes.map((dish) => (
          <AccordionBtnV2Sm
            title={dish.dish.name}
            onClose={true}
            key={dish.dish.id}
          >
            <GalleryGridNoTick data={dish.img} dishId={dish.dish.id} />
          </AccordionBtnV2Sm>
        ))}
      </div>
    );
  }

  return (
    <ModalWrapperV3>
      <div className={classes.container}>
        <div className={classes.nav}>
          <button onClick={props.onClose}>
            <CloseIcon sx={{ color: "#606060" }} />
          </button>
        </div>
        <div>
          <h5>Thông tin</h5>
          <p>
            <span className={classes.grey}>Tên/thông tin:</span>
            <span className={classes.bold}> {data.reason}</span>
          </p>
          <p>
            <span className={classes.grey}>ID: </span>
            <span className={classes.bold}> {data.id}</span>
          </p>
        </div>

        {reviewersContent}
        {foodImgContent}
      </div>
    </ModalWrapperV3>
  );
};

export default DeXuatDetailModal;
