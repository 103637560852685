import classes from "./GalleryGridBasic.module.css";
import ReactDOM from "react-dom";
import { useEffect, useState, useRef } from "react";

import ImgZoomModal from "../../department/KIT/ImgZoomModal";
import MainBackdropV3 from "../backdrops/MainBackdropV3";
import TrangTriRateBar from "../../hinh_anh_mon_an/TrangTriRateBar";

/*
    versions control:
    _this version: to be used as template only, limit logic and retrieve data from props. Originated form GalleryGrid.js
*/

/**
 * props list
 */
const GalleryGridBasic = (props) => {
  const [isZoom, setIsZoom] = useState(false);
  const [selectingItem, setSelectingItem] = useState();

  const galleryContainerRef = useRef();

  const selectItem = (dishData) => {
    setIsZoom(true);
    setSelectingItem(dishData);
  };

  const onCheckHandler = (e, item) => {
    if (e.target.checked) {
      const currentNode = e.target;
      const parentNode = currentNode.parentNode;
      parentNode.style.boxShadow =
        "#f8BD26 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px";
      props.checkItemFn({
        UID: e.target.name,
        imgData: item.data,
      });
    } else if (e.target.checked === false) {
      const currentNode = e.target;
      const parentNode = currentNode.parentNode;
      parentNode.style.boxShadow =
        "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px";
      props.uncheckItemFn({
        UID: e.target.name,
        imgData: item.data,
      });
    }
  };

  const closeZoom = () => {
    setIsZoom(false);
  };

  //define allow checkbox or not
  const isAdmin = props.isAdmin;
  const pageSelect = props.pageSelect;
  const checkBoxContent = (item) => {
    let content;
    if (pageSelect === "Được duyệt") {
      if (isAdmin) {
        content = (
          <input
            type="checkbox"
            name={item.uid}
            onChange={(e) => onCheckHandler(e, item)}
          />
        );
      } else {
        content = null;
      }
    } else {
      content = (
        <input
          type="checkbox"
          name={item.uid}
          onChange={(e) => onCheckHandler(e, item)}
        />
      );
    }

    return content;
  };

  //rating bar content
  const fetchRating = (id) => {
    let testVar = "init";

    const fetchPromise = new Promise((resolve) => {
      setTimeout(() => {
        testVar = "done fetching";
      }, 2000);
      resolve(testVar);
    });

    fetchPromise.then((res) => {
      return <p>{res}</p>;
    });
  };

  //define main content
  let mainContent;
  if (props.data.length === 0) {
    mainContent = (
      <div className={classes.note}>
        <p>Chưa có dữ liệu hình ảnh cho món ăn này </p>
      </div>
    );
  } else if (props.data.length > 0) {
    mainContent = props.data.map((item) => (
      <div key={item.uid}>
        <img src={item.data.URL} onClick={() => selectItem(item.data)} />
        {checkBoxContent(item)}
        {/* {fetchRating(item)} */}
        <TrangTriRateBar dishId={props.dishUID} imgUID={item.uid}/>
      </div>
    ));
  }

  //programmatically uncheck input after submiting
  useEffect(() => {
    const arr = props.submitArr;
    const containerDOM = galleryContainerRef.current;
    arr.length > 0 &&
      arr.forEach((item) => {
        const inputDOM = containerDOM.querySelector(
          `div input[name='${item.UID}']`
        );
        inputDOM.checked = false;
        const parentNode = inputDOM.parentNode;
        parentNode.style.boxShadow =
          "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px";
      });
  }, [props.submitArr]);

  //disable input until submit is completed
  useEffect(() => {
    const containerDOM = galleryContainerRef.current;
    const inputDOM = containerDOM.querySelectorAll("div input");
    if (props.isSubmitting) {
      inputDOM.forEach((item) => (item.disabled = true));
    } else {
      inputDOM.forEach((item) => (item.disabled = false));
    }
  }, [props.isSubmitting]);

  return (
    <div className={classes.container} ref={galleryContainerRef}>
      {isZoom &&
        ReactDOM.createPortal(
          <MainBackdropV3 onClick={closeZoom} />,
          document.getElementById("main-backdrop")
        )}
      {isZoom &&
        ReactDOM.createPortal(
          <ImgZoomModal data={selectingItem} />,
          document.getElementById("main-overlay")
        )}
      {mainContent}
    </div>
  );
};

export default GalleryGridBasic;
