import React from "react";
import classes from "./HrListTableV2.module.css";
import HrDelete from "./HrDelete";

/*
  props list: {
    employeesData: {
      id, 
      name, 
    }
    onDelete
    onView
    onEdit
  }
*/

const HrListTableV2 = (props) => {
  let count = 1;

  const StatTag = (value) => {
    let createdDate;
    let lessThan72;
    if (!value.time) {
      lessThan72 = true;
    } else {
      createdDate = value.time.toDate().getTime();
      const timeDifference = Date.now() - createdDate;
      const convertTime = timeDifference / 3600000;
      lessThan72 = convertTime < 72;
    }

    let statContent;
    if (lessThan72) {
      statContent = <span className={classes.tagNew}>Mới</span>;
    } else {
      // if(value.isActivated) {
      //   statContent = <span className={classes['green-tag']}>TC</span>
      // } else {
      //   statContent = <span className={classes['grey-tag']}>CKH</span>
      // }
      statContent = <span className={classes["green-tag"]}>Cơ bản</span>;
    }

    return <React.Fragment>{statContent}</React.Fragment>;
  };

  // const deleteFn = (
  //   <button
  //     className={classes.redBtn}
  //     onClick={() => props.onEdit(data.docID)}
  //   >
  //     Xoá 
  //   </button>
  // );

  const ListContent = () => {
    if (props.employeesData.length > 0) {
      return (
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classes["top-left"]}>STT</th>
              <th>Trạng thái</th>
              <th>ID</th>
              <th>Tên</th>
              <th colSpan="3" className={classes["top-right"]}>
                Bảng điều khiển
              </th>
            </tr>
          </thead>
          <tbody>
            {props.employeesData &&
              props.employeesData.map((data) => (
                <tr key={data.data.id}>
                  <td>{count++}</td>
                  <td>
                    <StatTag
                      time={data.data.timeCreated}
                      isActivated={data.data.accActivated}
                    />
                    {/* <span className={classes.tagNew}>New</span> */}
                  </td>
                  <td>{data.data.id}</td>
                  <td>{data.data.familyName + " " + data.data.name}</td>
                  <td>
                    <button
                      className={classes.greenBtn}
                      onClick={() => props.onView(data.docID)}
                    >
                      Xem
                    </button>
                  </td>
                  <td>
                    <button
                      className={classes.yellowBtn}
                      onClick={() => props.onEdit(data.docID)}
                    >
                      Sửa
                    </button>
                  </td>
                  <td className={classes.delete}>
                    <HrDelete
                      id={data.id}
                      onDelete={() => props.onDelete(data.docID, data.data.id)}
                      userDocRole={data.data.role}
                      userDocPosition={data.data.position}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      );
    } else {
      return <div className={classes.errMsg}>Không có dữ liệu</div>;
    }
  };

  return <ListContent />;
};

export default HrListTableV2;
