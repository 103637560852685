import DuLieuHinhAnhSearchBar from "./DuLieuHinhAnhSearchBar";
import classes from "./DuLieuMonAn.module.css";
import DuLieuFlexTable from "./DuLieuFlexTable";
import { useEffect, useState, Fragment } from "react";
import { db } from "../../firebase/firebase";
import { collection, onSnapshot, where, query } from "firebase/firestore";
import Loader from "../UI/Loader";
import { getDbs } from "../../hooks/fetchFirebase";

const DuLieuMonAn = () => {
  const [dishData, setDishData] = useState([]);
  const [displayArr, setDisplayArr] = useState([]);
  const [isSearching, setIsSearching] = useState();
  const [searchCount, setSearchCount] = useState(0);

  useEffect(() => {
    const dishCollRef = collection(db, "dish_list");
    const fetchDishData = onSnapshot(dishCollRef, (snapshot) => {
      let arr = [];
      if (!snapshot.empty) {
        snapshot.forEach((item) =>
          arr.push({
            docId: item.id,
            data: item.data(),
          })
        );
      }
      setDishData(arr);
    });
  }, []);

  const createDisplayArr = (arr, noImgFilter) => {
    console.log(arr);
    arr.length > 0 &&
      arr.forEach((item) => {
        let newItem = {};
        const reviewRef = collection(
          db,
          "dish_list",
          item.docId,
          "dish_review"
        );
        const imgRef = collection(db, "dish_list", item.docId, "images");
        const queryCategory = query(
          imgRef,
          where("category", "in", ["Đang áp dụng", "Được duyệt"])
        );

        //define function to fetch and count review score
        const fetchReview = new Promise((resolve) => {
          getDbs(reviewRef).then((res) => resolve(res));
        });

        //define function to fetch images database
        const fetchImage = new Promise((resolve) => {
          getDbs(queryCategory).then((returnArr) => resolve(returnArr));
        });

        //execute all promises
        Promise.all([fetchReview, fetchImage]).then((response) => {
          console.log(response);
          let totalScore = 0;
          let averageScore = 0;
          if (response[0].length > 0) {
            response[0].forEach((review) => {
              totalScore += review.imgData.score;
            });
            averageScore = totalScore / response[0].length;
          } else {
            averageScore = 0;
          }

          newItem.image = response[1];
          newItem.data = item.data;
          newItem.docId = item.docId;
          newItem.teamScore = Math.round(averageScore * 10) / 10;

          if (!noImgFilter) {
            setDisplayArr((prev) => [...prev, newItem]);
          } else {
            response[1].length === 0 &&
              setDisplayArr((prev) => [...prev, newItem]);
          }
        });
      });
  };

  const searchHandler = (value) => {
    setDisplayArr([]);
    console.log(value);
    console.log(dishData);
    setIsSearching(true);
    setSearchCount((prev) => prev + 1);
    let filterArr = [];
    let noImgFilter = false;
    const formatKw = value.keyword.toUpperCase().trim();
    switch (value.filterGroup) {
      case "Món ăn":
        filterArr = dishData.filter((item) => {
          if (item.data.name.toUpperCase().trim().includes(formatKw)) {
            return item;
          }
        });
        break;
      case "Nhóm món ăn":
        filterArr = dishData.filter(
          (item) => item.data.group === value.keyword
        );
        break;
      case "Outlet":
        filterArr = dishData.filter(
          (item) => item.data.outlet === value.keyword
        );
        break;
      case "no_review":
        if (value.secondKw != null) {
          const outletArr = dishData.filter(
            (item) => item.data.outlet === value.secondKw
          );
          const groupArr = outletArr.filter(
            (item) => item.data.group.trim().toUpperCase() === formatKw
          );
          console.log(outletArr, groupArr);

          filterArr = groupArr;
          noImgFilter = true;
        }
        break;
    }
    createDisplayArr(filterArr, noImgFilter);
    setTimeout(() => {
      setIsSearching(false);
    }, 1000);
  };

  let searchSection;
  if (dishData.length > 0) {
    searchSection = (
      <div className={classes["search-wrapper"]}>
        <DuLieuHinhAnhSearchBar onSearch={searchHandler} />
      </div>
    );
  }

  // let note;
  // if (displayArr.length === 0) {
  //   note = (
  //     <div className={classes.note}>
  //       <p>Không có dữ liệu. Vui lòng chọn lại phạm vi tìm kiếm!!</p>
  //     </div>
  //   );
  // }

  let searchResult;
  if (displayArr.length > 0) {
    searchResult = (
      <div className={classes["search-result"]}>
        <p>
          Đã tìm thấy <span>{displayArr.length}</span> món ăn!!
        </p>
      </div>
    );
  }
  // else {
  //   searchResult = (
  //     <div className={classes["search-result"]}>
  //       <p>Không có dữ liệu. Vui lòng chọn lại phạm vi tìm kiếm!!</p>
  //     </div>
  //   );
  // }

  let resultContent;
  if (!isSearching) {
    if (displayArr.length > 0) {
      resultContent = (
        <Fragment>
          <div>
            <DuLieuFlexTable data={displayArr} />
          </div>
          {/* {note} */}
        </Fragment>
      );
    } else {
      if (searchCount > 0) {
        resultContent = (
          <div className={classes.note}>
            <p>Không có dữ liệu. Vui lòng chọn lại phạm vi tìm kiếm!!</p>
          </div>
        );
      }
    }
  } else {
    resultContent = <Loader />;
  }

  // let loader;
  // useEffect(() => {
  //   console.log("searching");
  //   if (isSearching) {
  //     loader = <Loader />;
  //   }
  // }, [isSearching]);

  return (
    <div className={classes.container}>
      <div>
        <h6>Dữ liệu món ăn</h6>
      </div>
      {searchSection}
      {searchResult}
      {/* <div>
        <DuLieuFlexTable data={displayArr} />
      </div>
      {note} */}
      {/* {loader} */}
      {resultContent}
    </div>
  );
};

export default DuLieuMonAn;
