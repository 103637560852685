import classes from "./ImgZoomModal.module.css";
import ImgModalWrapper from "../../UI/modals/ImgModalWrapper";
import AccordionBtnV2Sm from "../../UI/accordion/AccordionBtnV2Sm";

const ImgZoomModal = (props) => {
  const date = new Date(props.data.createdDate.toDate());
  const convertDate = `${date.getDate()} ${date.getMonth()} ${date.getFullYear()}`
  console.log(props.data, convertDate);
  return (
    <ImgModalWrapper>
      {props.data && <img className={classes.img} src={props.data.URL} />}
      <AccordionBtnV2Sm title='Chi tiết' onClose={true}>
        <div className={classes.info}>
          <p><span className={classes.title}>Tên món: </span>{props.data.name}</p>
          <p><span className={classes.title}>Nhóm: </span>{props.data.category}</p>
          <p><span className={classes.title}>Người đăng: </span>{props.data.creator}</p>
          <p><span className={classes.title}>Ngày đăng: </span>{convertDate}</p>
        </div>
      </AccordionBtnV2Sm>
    </ImgModalWrapper>
  );
};

export default ImgZoomModal;
