import { useEffect, useContext, useState } from "react";
import classes from "./ViewConceptRating.module.css";
import McpButtonV1 from "../UI/button/McpButtonV1";
import { deleteDbDoc, getDbs } from "../../hooks/fetchFirebase";
import { db } from "../../firebase/firebase";
import { doc, collection } from "firebase/firestore";
import DuLieuMonAnContext from "./DuLieuMonAnContext";
import { useForceUpdate } from "../../hooks/allOther";

const ViewConceptRating = (props) => {
  const [ratingList, setRatingList] = useState([]);

  const forceUpdate = useForceUpdate();
  const duLieuCtx = useContext(DuLieuMonAnContext);

  const deleteRating = (id) => {
    // console.log(id, props.imgUID, duLieuCtx.dishId);
    // const docRef = doc(
    //   db,
    //   "dish_list",
    //   duLieuCtx.dishId,
    //   "images",
    //   props.imgUID,
    //   "dish_concept_rating",
    //   id
    // );
    const docRef = doc(
      db,
      "dish_list",
      duLieuCtx.dishId,
      "images",
      props.imgUID,
      "decor-rating",
      id
    );
    deleteDbDoc(docRef).then((res) => {
      forceUpdate.run();
    });
  };

  useEffect(() => {
    // const colRef = collection(
    //   db,
    //   "dish_list",
    //   duLieuCtx.dishId,
    //   "images",
    //   props.imgUID,
    //   "dish_concept_rating"
    // );
    const colRef = collection(
      db,
      "dish_list",
      duLieuCtx.dishId,
      "images",
      props.imgUID,
      "decor-rating"
    );
    getDbs(colRef).then((res) => {
      setRatingList(res);
    });
  }, [forceUpdate.value]);

  let tableContent;
  if (ratingList.length > 0) {
    tableContent = ratingList.map((item) => (
      <div className={classes.row} key={item.imgUID}>
        <div className={classes.info}>
          <p>
            Tên: <span className={classes.content}>{item.imgData.name}</span>
          </p>
          <p>
            Ngày đánh gía:{" "}
            <span className={classes.content}>
              {item.imgData.timeCreated.toDate().toLocaleDateString("vi-VI")}
            </span>
          </p>
          <p>
            Điểm: <span className={classes.content}>{item.imgData.score}</span>
          </p>
        </div>
        <div>
          <McpButtonV1
            type="red"
            btnType="button"
            content="Xoá"
            onClick={() => deleteRating(item.imgUID)}
          />
        </div>
      </div>
    ));
  }

  console.log(ratingList, forceUpdate);

  return (
    <div className={classes.container}>
      <div className={classes.nav}>
        <McpButtonV1
          onClick={props.onBack}
          type="blue"
          content="Quay lại"
          btnType="button"
        />
      </div>
      <div className={classes.table}>{tableContent}</div>
    </div>
  );
};

export default ViewConceptRating;
