import React, { useState, useEffect } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useSelector } from "react-redux";
import { SysNorRightFix } from "../../UI/systemNor/SysNorRightFix";

const HrDelete = (props) => {
  const [writePermit, setWritePermit] = useState(true);
  const [nort, setNort] = useState([]);

  const user = useSelector((state) => state.auth.userCredential);

  useEffect(() => {
    if (props.userDocRole === "admin" && user.role !== "admin") {
      setWritePermit(false);
    }
  }, [props.userDocRole, user.role])

  const removeHandler = (event) => {
    event.preventDefault();

    if (props.userDocRole === "admin") {
      if (user.role === "admin") {
        props.onDelete(props.id);
      } else {
        setNort((prev) => [
          ...prev,
          {
            key: Math.floor(Math.random() * 1000),
            msg: "Bạn không đủ quyền để thực hiện thao tác này",
            type: "error",
          },
        ]);
        return;
      }
    }
    props.onDelete(props.id);
  };

  const clearNort = (key) => {
    const newArr = nort.filter((item) => item.key !== key);
    setNort(newArr);
  };

  let nortContent;
  if (nort.length > 0) {
    nortContent = <SysNorRightFix data={nort} onClick={clearNort} />;
  }

  return (
    <React.Fragment>
      {nortContent}
      {writePermit && <DeleteOutlineOutlinedIcon onClick={removeHandler} />}
    </React.Fragment>
  );
};

export default HrDelete;
