import React from "react";
import classes from './FormTemplate1.module.css'

const FormTemplate1 = (props) => {
  /*
    props list : {
      id, 
      onSubmit, 
      onCancel, 
    }
  */
  let cancelButton; 
  if(props.onCancel != null) {
    cancelButton = <button className={classes["cancel-btn"]} type="button" onClick={props.onCancel}>
    Huỷ
  </button>
  } else {
    cancelButton = <button className={classes["cancel-btn"]} type="reset">
    Huỷ
  </button>
  }

  return (
    <form className={classes.form} id={props.id} onSubmit={props.onSubmit}>
      {props.children}
      <div className={classes["action-nav"]}>
        <button className={classes["create-btn"]} type="submit">
          Tạo
        </button>
        {/* <button className={classes["cancel-btn"]} type="reset">
          Huỷ
        </button> */}
        {cancelButton}
      </div>
    </form>
  );
};

export default FormTemplate1;
