import { useState, useRef } from "react";
import HrCreateNewUser from "../../components/department/HR/HrCreateNewUser";
import HrIdResult from "../../components/department/HR/HrIdResult";
import classes from "./SignUpPage.module.css";
import { collection, where, query, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import Loader from "../../components/UI/Loader";
import Section from "../../components/layout/Section";

const SignUpPage = () => {
  const [idIsExist, setIdIsExist] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [noFound, setNoFound] = useState(true);
  const inputIdRef = useRef();

  let responseContent;

  const submitHandler = (e) => {
    e.preventDefault();
    setNoFound(null);
    setIdIsExist(null);
    const submitInput = inputIdRef.current.value;
    setIsloading(true);
    const dbref = collection(db, "signup_list");
    const dbQuery = query(dbref, where("id", "==", submitInput));
    getDocs(dbQuery)
      .then((querySnapshot) => {
        let recordFound = [];
        console.log(querySnapshot.length);
        querySnapshot.forEach((doc) => {
          console.log('check');
          console.log(doc.data());
          recordFound.push(doc.data())
        });
        console.log(recordFound);
        console.log(recordFound.length, recordFound.length === 0);
        if (recordFound.length === 0) {
          console.log("không có kết quả");
          setNoFound(
            "Không tìm thấy được kết quả nào với ID bạn vừa nhập, liên hệ với kỹ thuật đễ được hỗ trợ"
          );
        } else {
          setIdIsExist(recordFound)
          setNoFound(false);
        }
        // setIdIsExist(recordFound);
        setIsloading(false);
      })
      .catch((err) => setNoFound(err.message));
  };

  const confirmHandler = (e) => {
    e.preventDefault();
    setIsCreating(true);
  };

  console.log(idIsExist !== null && isCreating === false);
  console.log(idIsExist == null && noFound);

  if (idIsExist !== null && isCreating === false) {
    responseContent = (
      <HrIdResult data={idIsExist[0]} onConfirm={confirmHandler} />
    );
  }
  if (idIsExist && isCreating) {
    responseContent = <HrCreateNewUser data={idIsExist[0]} />;
  }
  if (idIsExist == null && noFound) {
    responseContent = <div className={classes['nort-section']}><p>{noFound}</p></div>;
  }

  console.log(idIsExist !== null && isCreating === false);
  console.log(idIsExist, isCreating);
  return (
    <Section>
      <div className={classes.wrapper}>
        <div>
          <h3>Đăng ký tài khoản mới</h3>
          <form onSubmit={submitHandler}>
            <div>
              <p>Vui lòng nhập số ID đã được cung cấp</p>
            </div>
            <div className={classes["form-input"]}>
              <label htmlFor="inputID">Mã ID</label>
              <input id="inputID" ref={inputIdRef}></input>
            </div>
            <button type="submit">Tiếp tục</button>
          </form>
        </div>

        {isLoading && <Loader />}

        {responseContent}
      </div>
    </Section>
  );
};

export default SignUpPage;
