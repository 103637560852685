import HomeIcon from "@mui/icons-material/Home";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import HotelIcon from "@mui/icons-material/Hotel";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SettingsIcon from "@mui/icons-material/Settings";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ListIcon from "@mui/icons-material/List";
import DataThresholdingOutlinedIcon from "@mui/icons-material/DataThresholdingOutlined";

//This file contains only nav contents object variable. Nothing related to the redux

const navContent = [
  {
    title: "Trang chủ",
    icon: <HomeIcon />,
    submenu: null,
    path: "/trang-chu",
  },
  {
    title: "Sales & Marketing",
    icon: <TrendingUpIcon />,
    submenu: [
      {
        subtitle: "Sales",
        subPath: "/sale-marketing/sales",
      },
      {
        subtitle: "Marketing",
        subPath: "/sale-marketing/marketing",
      },
      {
        subtitle: "Chức năng chung",
        subPath: "/sale-marketing/chuc-nang-chung",
      },
    ],
    path: "/sale-marketing",
  },
  {
    title: "Khối ẩm thực ",
    icon: <LocalDiningIcon />,
    // submenu: ["Bếp", "F&B", "Steward"],
    submenu: [
      {
        subtitle: "Bếp",
        subPath: "/khoi-am-thuc/bep",
      },
      {
        subtitle: "F&B",
        subPath: "/khoi-am-thuc/fb",
      },
      {
        subtitle: "Steward",
        subPath: "/khoi-am-thuc/steward",
      },
    ],
    path: "/khoi-am-thuc",
  },
  {
    title: "Khối phòng lưu trú",
    icon: <HotelIcon />,
    // submenu: ["House Keeping", "Font Office"],
    submenu: [
      {
        subtitle: "House Keeping",
        subPath: "/khoi-phong-luu-tru/house-keeping",
      },
      {
        subtitle: "Font Office",
        subPath: "/khoi-phong-luu-tru/font-office",
      },
    ],
    path: "/khoi-phong-luu-tru",
  },
  {
    title: "Khối văn phòng",
    icon: <CoPresentIcon />,
    // submenu: ["Kế toán", "Nhân sự"],
    submenu: [
      {
        subtitle: "Kế Toán",
        subPath: "/khoi-van-phong/ke-toan",
      },
      {
        subtitle: "Nhân sự",
        subPath: "/khoi-van-phong/nhan-su",
      },
      {
        subtitle: "Chức năng chung",
        subPath: "/khoi-van-phong/chuc-nang-chung",
      },
    ],
    path: "/khoi-van-phong",
  },
  {
    title: "Chức năng chung",
    icon: <ListIcon />,
    submenu: [
      {
        subtitle: "Đánh giá",
        subPath: "/chuc-nang-chung/danh-gia",
      },
    ],
    path: "/chuc-nang-chung",
  },
  {
    title: "Quản lý",
    icon: <DataThresholdingOutlinedIcon />,
    submenu: null,
    path: "/quan-ly",
  },
  {
    title: "IT - hệ thống",
    icon: <AdminPanelSettingsIcon />,
    submenu: null,
    path: "/IT-he-thong",
  },
  {
    title: "Cài đặt",
    icon: <SettingsIcon />,
    submenu: null,
    path: "/cai-dat",
  },
];

export default navContent;
