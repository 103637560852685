import React from "react";
import AddUserBtn from "./AddUserBtn";
import classes from "./SearchUserTable.module.css";
import NortPTag from "../../UI/systemNor/NortPTag";

/*
  props list: {
    data, 
    onAdd
  }
 */

const SearchUserTable = (props) => {
  let headerContent;
  let tableContent;
  if (props.data) {
    if (props.data.length > 0) {
      headerContent = (
        <div className={`${classes.row} ${classes.header}`}>
          <div className={classes.col1}>Thông tin</div>
          <div className={classes["sm-col"]}>Công cụ</div>
        </div>
      );

      tableContent = props.data.map((item) => (
        <div className={classes.row} key={item.id}>
          <div className={classes.col1}>
            <p>
              {typeof item.data.familyName !== "undefined"
                ? item.data.familyName + " " + item.data.name
                : item.data.name}
            </p>
            <p>{item.data.department}</p>
          </div>
          <div className={classes["sm-col"]}>
            <AddUserBtn
              data={{
                department: item.data.department,
                id: item.id,
                fullName:
                  typeof item.data.familyName !== "undefined"
                    ? item.data.familyName + " " + item.data.name
                    : item.data.name,
              }}
              onAdd={props.onAdd}
            />
          </div>
        </div>
      ));
    } else if (props.data.length === 0) {
      tableContent = (
        <NortPTag type="error">
          Không tìm thấy được dữ liệu với từ khóa bạn tìm kiếm
        </NortPTag>
      );
    }
  }

  return (
    <div className={classes.wrapper}>
      {/* <div className={`${classes.row} ${classes.header}`}>
        <div className={classes.col1}>Tên</div>
        <div className={classes["sm-col"]}>Công cụ</div>
      </div> */}
      {headerContent}
      {/* {props.data.length > 0 &&
        props.data.map((item) => (
          <div className={classes.row} key={item.id}>
            <div className={classes.col1}>
              <p>
                {typeof item.data.familyName !== "undefined"
                  ? item.data.familyName + " " + item.data.name
                  : item.data.name}
              </p>
              <p>{item.data.department}</p>
            </div>
            <div className={classes["sm-col"]}>
              <AddUserBtn
                data={{
                  department: item.data.department,
                  id: item.id,
                  fullName:
                    typeof item.data.familyName !== "undefined"
                      ? item.data.familyName + " " + item.data.name
                      : item.data.name,
                }}
                onAdd={props.onAdd}
              />
            </div>
          </div>
        ))} */}
      {tableContent}
    </div>
  );
};

export default SearchUserTable;
