import {Fragment} from 'react'; 
import classes from "./UserXemDeXuatList.module.css"; 
import { useEffect, useReducer } from 'react';
import Loader from '../UI/Loader';
import DeXuatListTable from './DeXuatListTable';
import { useSelector } from 'react-redux';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import DanhGiaMonChiTiet from '../department/General/DanhGiaMonChiTiet';

const pageContentReducer = (state, action) => {
  switch (action.type) {
    case "NAV":
      return {
        ...state,
        displayContent: action.payload,
      };
    case "NAV_HOME":
      return {
        ...state,
        displayContent: (
          <DeXuatListTable
            data={state.requestData}
            onView={action.payload.onView}
          />
        ),
      };
    case "FETCH_REQUEST_DATA":
      return {
        ...state,
        displayContent: action.payload.tableContent,
        requestData: action.payload.requestData,
      };
    case "VIEW":
      return {
        ...state,
        displayContent: (
          <DanhGiaMonChiTiet
            data={state.requestData.filter(
              (item) => item.docID === action.payload.dataId
            )}
            onReturn={action.payload.onReturn}
          />
        ),
      };
    default:
      return {
        displayContent: null,
        requestData: [],
        requestTableContent: <Loader />,
      };
  }
};

const pageInitialContent = {
  displayContent: null,
  requestData: [],
  requestTableContent: <Loader />,
};

const UserXemDeXuatList = () => {
  const [pageContent, dispatchPageContent] = useReducer(
    pageContentReducer,
    pageInitialContent
  );

  const user = useSelector((state) => state.auth.userCredential);

  const navigate = useNavigate();

  //function to view detail
  const viewDetailHandler = (value) => {
    navigate(value); 
  };

  //fetch review request data and generate table content based on review request data
  useEffect(() => {
    //init loading state
    dispatchPageContent({
      type: "NAV",
      payload: <Loader />,
    });

    //fetching db data
    const reviewRequestRef = collection(
      db,
      "employee_users", 
      user.uid,
      "decor_review_request"
    );

    const sortQuery = query(reviewRequestRef, orderBy('timeCreated', 'desc'))

    

    onSnapshot(sortQuery, (snapshot) => {
      if (!snapshot.empty) {
        let requestArr = [];

        snapshot.forEach((item) =>
          requestArr.push({
            docID: item.id,
            data: item.data(),
          })
        );

        dispatchPageContent({
          type: "FETCH_REQUEST_DATA",
          payload: {
            requestData: requestArr,
            tableContent: (
              <DeXuatListTable data={requestArr} onView={viewDetailHandler} />
            ),
          },
        });
      } else {
        dispatchPageContent({
          type: "FETCH_REQUEST_DATA",
          payload: {
            requestData: [],
            tableContent: (
              <p className={classes.note}>Hiện tại không có yêu cầu đánh giá món ăn nào!!</p>
            ),
          },
        });
      }
    });
  }, []);


  return (
    <Fragment>
      <div className={classes.table}>{pageContent.displayContent}</div>
    </Fragment>
  );
};

export default UserXemDeXuatList