import {useRef} from "react";
import classes from "./ImgZoomCard.module.css";

const ImgZoomCard = (props) => {
  const modalContainerRerf = useRef();

  return (
    <div className={classes.container} ref={modalContainerRerf}>
      <div className={classes.card}>{props.children}</div>
    </div>
  );
};

export default ImgZoomCard;
