import { useRef, useState } from "react";
import classes from "./HrCreateNewUser.module.css";
import { auth } from "../../../firebase/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import NortPTag from "../../UI/systemNor/NortPTag";
import { useNavigate } from "react-router-dom";

const HrCreateNewUser = (props) => {
  const [err, setErr] = useState([]);
  const [isCreated, setIsCreated] = useState(false); 

  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const passCheckInputRef = useRef();

  const navigate = useNavigate(); 

  const submitHandler = (e) => {
    e.preventDefault();
    setErr([]);
    //validate input
    const emailInput = emailInputRef.current.value.toLowerCase();
    console.log(emailInput);
    let emailIsValid;
    let passwordIsValid;
    let passCheckIsValid;
    emailIsValid =
      emailInput.includes("@merperle.vn") ||
      emailInput.includes("@crystalpalacevn.com") ||
      emailInput.includes("doannkhai@gmail.com")
        ? true
        : false;
    if (!emailIsValid) {
      setErr((prev) => [...prev, "Lỗi email, vui lòng liên hệ kỹ thuật"]);
    }
    if (passwordInputRef.current.value.length < 6) {
      passwordIsValid = true;
      setErr((prev) => [...prev, "Mật khẩu không đủ 6 ký tự"]);
    }
    if (passCheckInputRef.current.value === passwordInputRef.current.value) {
      passCheckIsValid = true;
    } else {
      passCheckIsValid = false;
      setErr((prev) => [...prev, "Mật khẩu xác nhận không giống nhau"]);
    }

    //create new user
    if (emailIsValid && passCheckIsValid && passCheckIsValid) {
      createUserWithEmailAndPassword(auth, emailInput, passCheckInputRef.current.value)
        .then(() => {
          setIsCreated(true); 
          setTimeout(() => {navigate('/')}, 5000)
        })
        .catch((error) => {
          setErr((prev) => [...prev, error.message]);
        });
    }
  };

  //error content
  let errContent;
  if (err.length > 0) {
    errContent = (
      <div>
        {err.map((error) => (
          <p key={error} className={classes[`error-message`]}>
            {error}
          </p>
        ))}
      </div>
    );
  }

  //success content 
  let successContent; 
  if(isCreated) {
    successContent = (
      <NortPTag type='ok'>Đã tạo tài khoản thành công</NortPTag>
    ) 
  }

  return (
    <form className={classes.form} onSubmit={submitHandler}>
      <div>
        <label htmlFor="resultEmail">Email</label>
        <input
          id="resultEmail"
          type="email"
          required
          disabled={true}
          value={props.data.email}
          ref={emailInputRef}
        />
      </div>
      <div>
        <label htmlFor="resultPass">Mât khẩu </label>
        <input
          id="resultPass"
          type="password"
          autoComplete="new-password"
          required
          placeholder="Mật khẩu bạn tạo cần đủ 6 ký tự"
          ref={passwordInputRef}
        />
      </div>
      <div>
        <label htmlFor="confirmPass">Xác nhận lại mật khẩu</label>
        <input
          id="confirmPass"
          type="password"
          autoComplete="new-password"
          required
          placeholder="Điền mật khẩu như trên 1 lần nữa"
          ref={passCheckInputRef}
        />
      </div>
      {errContent}
      {successContent}
      <button type="submit">Tạo tài khoản</button>
    </form>
  );
};

export default HrCreateNewUser;
