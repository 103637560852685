import React, { useEffect, useRef, useState } from "react";
import FormWrapper from "../../components/UI/FormWrapper";
import FormTemplate1 from "../../components/form/template/FormTemplate1";
import SingleFieldV3 from "../../components/form/template/SingleFieldV3";
import ModalWrapperV3 from "../../components/UI/modals/ModalWrapperV3";
import { updateDbDoc } from "../../hooks/fetchFirebase";
import { db } from "../../firebase/firebase";
import { doc, serverTimestamp } from "firebase/firestore";
import NortPTag from "../../components/UI/systemNor/NortPTag";

/*
  Originated from the FlexTable 4 col
  props list: {
    data, 
    user,
    onCancel
  }
*/

const EditFoodGroupModal = (props) => {
  const [sysNort, setSysNort] = useState();
  const foodGroupInput = useRef();
  const inputEN = useRef();
  useEffect(() => {
    foodGroupInput.current.value = props.data[0].data.col2;
    inputEN.current.value = props.data[0].data.col3;
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    const docDbRef = doc(db, "food_group", props.data[0].docID);
    console.log(
      foodGroupInput.current.value,
      inputEN.current.value,
      serverTimestamp(),
      props.user
    );
    updateDbDoc(docDbRef, {
      foodGroup: foodGroupInput.current.value,
      foodGroupEN: inputEN.current.value,
      lastUpdated: serverTimestamp(),
      updater: props.user,
    }).then((res) => {
      if (res.code == 0) {
        setSysNort({
          type: "success",
          message: res.message,
        });
      } else {
        setSysNort({
          type: "error",
          message: res.message,
        });
      }
    });
  };

  let nortContent;
  if (sysNort) {
    nortContent = <NortPTag type={sysNort.type}>{sysNort.message}</NortPTag>;
  }

  return (
    <ModalWrapperV3>
      <FormWrapper>
        <FormTemplate1
          id="FoodGroupForm"
          onSubmit={submitHandler}
          onCancel={props.onCancel}
        >
          <SingleFieldV3
            label="Nhóm món ăn"
            id="foodGroup"
            type="text"
            required
            reference={foodGroupInput}
          />
          <SingleFieldV3
            label="Tên Tiếng Anh"
            id="foodGroupEN"
            type="text"
            required
            reference={inputEN}
          />
        </FormTemplate1>
        {nortContent}
      </FormWrapper>
    </ModalWrapperV3>
  );
};

export default EditFoodGroupModal;
