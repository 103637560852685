import React from "react";
import classes from "./ImgInfo.module.css";

const ImgInfo = (props) => {
  const data = props.data;

  const formatDate = data.timeCreated.toDate().toLocaleDateString("vi-VI");

  //score content
  const getScore = (value, label) => {
    let scoreContent;
    let classesContent = `${classes.score} `;
    if (value <= 0) {
      classesContent += `${classes.grey}`;
    } else if (value < 3) {
      classesContent += `${classes.red}`;
    } else if (value < 3.5) {
      classesContent += `${classes.gold}`;
    } else {
      classesContent += `${classes.green}`;
    }

    scoreContent = (
      <li className={classes.label}>
        {label}:{" "}
        <span className={classesContent}>
          {value > 0 ? value : "Chưa có đánh giá"}
        </span>
      </li>
    );

    return scoreContent;
  };

  return (
    <ul>
      <li>
        <span className={classes.label}>ID: </span>
        {data.id}
      </li>
      <li>
        <span className={classes.label}>Tên món: </span>
        {data.name}
      </li>
      <li>
        <span className={classes.label}>Outlet: </span>
        {data.outlet}
      </li>
      <li>
        <span className={classes.label}>Nhóm: </span>
        {data.group}
      </li>
      {getScore(props.score, "Điểm nội bộ")}
      <li>
        <span className={classes.label}>Khách hàng đáng giá: </span>
        chưa có
      </li>
      <li>
        <span className={classes.label}>Ngày tạo: </span>
        {formatDate}
      </li>
      {/* <li>
        <span className={classes.label}>Ngày câp nhật: </span>
        {data.timeUpdated
          ? data.timeUpdated
          : "Chưa có cập nhật"}
      </li> */}
    </ul>
  );
};

export default ImgInfo;
