import React, { useState, useRef } from "react";
import FormTemplate1 from "../../components/form/template/FormTemplate1";
import SingleFieldV3 from "../../components/form/template/SingleFieldV3";
import Section from "../../components/layout/Section";
import classes from "./ResetPass.module.css";
import { resetPassDb } from "../../hooks/fetchFirebase";
import NortPTag from "../../components/UI/systemNor/NortPTag";

const ResetPass = () => {
  const [nort, setNort] = useState(null);
  const inputRef = useRef();

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(inputRef.current.value);
    let inputEmail = inputRef.current.value;
    resetPassDb(inputEmail).then((res) => {
      if (res.code === 0) {
        setNort({
          type: "success",
          message: res.message,
        });
      } else {
        setNort({
          type: "error",
          message: res.message,
        });
      }
    });
  };

  let nortContent;
  if (nort) {
    nortContent = <NortPTag type={nort.type}>{nort.message}</NortPTag>;
  }

  return (
    <Section>
      <div className={classes.container}>
        <h3>Yêu cầu đặt lại mật khẩu</h3>
        <FormTemplate1 onSubmit={submitHandler}>
          <SingleFieldV3
            label="Điền vào email của bạn"
            id="resetPassForm"
            type="email"
            placeholder="Email của bạn"
            required
            reference={inputRef}
          />
        </FormTemplate1>
        <div className={classes.nort}>{nortContent}</div>
      </div>
    </Section>
  );
};

export default ResetPass;
