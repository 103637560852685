import { Fragment } from "react";
import classes from "./FunctionPanel.module.css";
import FunctionBtn from "./FunctionBtn";

const FunctionPanel = (props) => {
  return (
    <Fragment>
      <h4 className={classes.panelHeading}>Bảng điều khiển</h4>
      <ul className={classes.list}>
        {props.data &&
          props.data.map((child) =>
            child.name ? (
              <FunctionBtn
                content={child.name}
                path={child.path.replace(/\s+/g, "-")}
                key={child.path.replace(/\s+/g, "-")}
              />
            ) : null
          )}
      </ul>
    </Fragment>
  );
};

export default FunctionPanel;
