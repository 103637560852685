import React from "react";
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <div>
        <div>Copyright &#169; 2022 </div>
        <div>mcp.app@merperle.vn</div>
      </div>
    </footer>
  );
};

export default Footer;
