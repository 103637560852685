// This version 2 only create a wrapper for form section, you can import form component anywhere and put it in

import ModalWrapperV3 from './ModalWrapperV3'
import classes from "./EditModal2.module.css";
import CloseIcon from "@mui/icons-material/Close";
import SingleField from "../../form/template/SingleField";
import SelectField from "../../form/template/SelectField";

const EditModal2 = (props) => {
  return (
    <ModalWrapperV3>
      <div className={classes["top-nav"]}>
        <button onClick={props.onClose}>
          <CloseIcon sx={{ size: 16, color: "#30BE75" }} />
        </button>
      </div>
      <div className={classes["form-section"]}>
        <form onSubmit={props.onSubmit}>
          <SingleField
            label="chức vụ"
            id="position"
            type="text"
            required={true}
            placeholder= {props.placeholder}
            reference={props.reference}
            defaultValue={props.defaultValue}
          />
          <SelectField
            label="quyền hạn"
            id="roleDef"
            optionsList={props.data}
            onClick={props.fieldClick}
          />
          <div className={classes["button-nav"]}>
            <button className={classes["save-button"]} type="submit">
              Lưu
            </button>
            <button
              className={classes["cancel-button"]}
              type="button"
              onClick={props.onClose}
            >
              Huỷ
            </button>
          </div>
        </form>
      </div>
    </ModalWrapperV3>
  );
};

export default EditModal2;
