import React, { useState } from "react";
import classes from "./ViewReviewRequest.module.css";
import ToolBtn from "./ToolBtn";
import ReactDOM from "react-dom";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import NortPTag from "../../UI/systemNor/NortPTag";
import { useEffect } from "react";
// import { useWindowSize } from "../../../hooks/layoutDOM";
import MainBackdropV3 from "../../UI/backdrops/MainBackdropV3";
import FoodRequestModal from "./FoodRequestModal";
import { useSelector } from "react-redux";

const ViewReviewRequest = (props) => {
  const [reviewList, setReviewList] = useState([]);
  const [isViewing, setIsViewing] = useState();
  const [viewingData, setViewingData] = useState({});

  const user = useSelector((state) => state.auth.userCredential);
  console.log(user);
  const dep = user.department;
  let scoreViewPermit;
  if (
    dep !== "Bếp" &&
    dep !== "Quản lý" &&
    dep !== "Kinh Doanh" &&
    dep !== "Marketing"
  ) {
    scoreViewPermit = false;
  } else {
    scoreViewPermit = true;
  }

  console.log(scoreViewPermit);
  // const browserSize = useWindowSize();

  useEffect(() => {
    onSnapshot(collection(db, "food_review_request"), (snapshot) => {
      if (!snapshot.empty) {
        let requestArr = [];
        snapshot.forEach((item) =>
          requestArr.push({
            id: item.id,
            data: item.data(),
          })
        );
        const dupArr = requestArr.slice();
        const sortArr = dupArr.sort((a, b) => {
          const aDate = Date.parse(a.data.date);
          const bDate = Date.parse(b.data.date);
          if (bDate > aDate) {
            return 1;
          }
          if (aDate > bDate) {
            return -1;
          }
        });
        setReviewList(sortArr);
      } else {
        setReviewList([]);
      }
    });
  }, []);

  let tableContent;
  let tableHeader;

  //active and deactive modal
  const viewHandler = (data, docId) => {
    setIsViewing(true);
    setViewingData({
      data,
      docId,
    });
  };

  const closeView = () => {
    setIsViewing(false);
  };

  //select page display content
  const viewScoredetail = (data, docId) => {
    console.log(data, docId);
    props.onSelectRequest({
      docId,
      data,
    });
  };

  //tool contents based on screen size
  const ToolContent = (props) => {
    let toolContent;
    toolContent = (
      <div>
        <ToolBtn
          content="Xem"
          type="yellow"
          btnType="button"
          onClick={() => viewHandler(props.data, props.dataDocId)}
        />
        {scoreViewPermit && (
          <ToolBtn
            content="Điểm"
            type="blue"
            btnType="button"
            onClick={() => viewScoredetail(props.data, props.dataDocId)}
          />
        )}
        {/* <ToolBtn
          content="Điểm"
          type="blue"
          btnType="button"
          onClick={() => viewScoredetail(props.data, props.dataDocId)}
        /> */}
      </div>
    );

    return <React.Fragment>{toolContent}</React.Fragment>;
  };

  //table content
  if (reviewList.length > 0) {
    tableHeader = (
      <div className={`${classes.row} ${classes.header}`}>
        <div>ID</div>
        <div>Thông tin</div>
        <div>Công cụ</div>
      </div>
    );

    tableContent = reviewList.map((item) => (
      <div className={classes.row} key={item.data.id}>
        <div>{item.data.id}</div>
        <div>{item.data.name}</div>
        {/* <ToolContent data={item.data} /> */}
        <ToolContent data={item.data} dataDocId={item.id} />
      </div>
    ));
  } else if (reviewList.length === 0) {
    tableContent = <NortPTag type="error">Không có dữ liệu</NortPTag>;
  }

  return (
    <div className={classes["review-table"]}>
      {tableHeader}
      {tableContent}
      {isViewing &&
        ReactDOM.createPortal(
          <MainBackdropV3 onClick={closeView} />,
          document.getElementById("main-backdrop")
        )}
      {isViewing &&
        ReactDOM.createPortal(
          <FoodRequestModal
            data={viewingData.data}
            docId={viewingData.docId}
            onClose={closeView}
          />,
          document.getElementById("main-overlay")
        )}
    </div>
  );
};

export default ViewReviewRequest;
