import { Fragment } from "react";
import McpButton from "../UI/button/McpButton";

/*
    props list {
      docID, 
      onEdit, 
      onDelete, 
    }
 */

const AllowedFunctionBasic = (props) => {

  return (
    <Fragment>
      <McpButton
        type="blue"
        content="Sửa"
        onClick={() => props.onEdit(props.docID)}
      />
      <McpButton
        type="grey"
        content="Xoá"
        onClick={() => props.onDelete(props.docID)}
      />
    </Fragment>
  );
};

export default AllowedFunctionBasic;
