import IsBuiding from "../404&others/IsBuiding";
import Page404 from "../404&others/404";
import DanhGiaMon3 from "./DanhGiaMon3";
import UserXemSiteMap from "../../components/danh_gia_trang_tri_v2/UserXemSiteMap";
import DanhGiaMonLanding from "../../components/department/General/DanhGiaMonLanding";

const DanhGiaSiteMap = [
  {
    name: "Đánh giá món ăn",
    path: "danh gia mon an",
    element: <DanhGiaMonLanding />,
  },
  {
    name: "Đánh giá trang trí",
    path: "danh gia trang tri",
    element: <UserXemSiteMap />,
  },
];

export default DanhGiaSiteMap;
