import { Fragment, useState } from "react";
import classes from "./Slider.module.css";
import demoImg from "../../../assets/images/img-placeholder-2.png";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

/**
 * props list: {data}
 */

const Slider = (props) => {
  const [imgIndex, setImgIndex] = useState(0);

  const imgArr = props.data.image;

  const nextClick = () => {
    if (imgIndex + 1 < imgArr.length) {
      setImgIndex((prev) => prev + 1);
    } else {
      setImgIndex((prev) => prev + 1 - imgArr.length);
    }
  };

  const prevClick = () => {
    if (imgIndex > 0) {
      setImgIndex((prev) => prev - 1);
    } else {
      setImgIndex(imgArr.length - 1);
    }
  };

  const indexContent = `${imgIndex + 1} / ${imgArr.length}`;

  let imgContent;
  let itemContent;
  // if (props.data && props.data.image.length > 0) {
  //   imgContent = <img src={imgArr[imgIndex].URL} />;
  //   itemContent = (
  //     <Fragment>
  //       <div className={classes.nav}>
  //         <button>
  //           <NavigateBeforeIcon onClick={prevClick} />
  //         </button>
  //         <span>{indexContent}</span>
  //         <button onClick={nextClick}>
  //           <NavigateNextIcon />
  //         </button>
  //       </div>
  //       <div className={classes.info}>
  //         <ul>
  //           <li>
  //             <span className={classes.label}>Tên: </span>
  //             {imgArr[imgIndex].name}
  //           </li>
  //           <li>
  //             <span className={classes.label}>Phân loại: </span>
  //             {imgArr[imgIndex].category}
  //           </li>
  //         </ul>
  //       </div>
  //     </Fragment>
  //   );
  // } else if (imgArr.length === 0) {
  //   imgContent = <img src={demoImg} />;
  //   itemContent = <p className={classes.nort}>Món ăn này chưa có hình ảnh.</p>;
  // }

  if (props.data && props.data.image.length > 0) {
    imgContent = <img src={imgArr[imgIndex].imgData.URL} />;
    itemContent = (
      <Fragment>
        <div className={classes.nav}>
          <button>
            <NavigateBeforeIcon onClick={prevClick} />
          </button>
          <span>{indexContent}</span>
          <button onClick={nextClick}>
            <NavigateNextIcon />
          </button>
        </div>
        <div className={classes.info}>
          <ul>
            <li>
              <span className={classes.label}>Tên: </span>
              {imgArr[imgIndex].imgData.name}
            </li>
            <li>
              <span className={classes.label}>Phân loại: </span>
              {imgArr[imgIndex].imgData.category}
            </li>
          </ul>
        </div>
      </Fragment>
    );
  } else if (imgArr.length === 0) {
    imgContent = <img src={demoImg} />;
    itemContent = <p className={classes.nort}>Món ăn này chưa có hình ảnh.</p>;
  }

  return (
    <div className={classes.container}>
      {imgContent}
      {itemContent}
    </div>
  );
};

export default Slider;
