import classes from "./GalleryGridReview.module.css";
import ReactDOM from "react-dom";
import { useEffect, useState, useRef } from "react";
import { useWindowSize } from "../../hooks/layoutDOM";

import ImgZoomModal from "../department/KIT/ImgZoomModal";
import MainBackdropV3 from "../UI/backdrops/MainBackdropV3";
import { Rating } from "@mui/material";

/*
    versions control:
    _this version: to be used as template only, limit logic and retrieve data from props. Originated form GalleryGrid.js
*/

/**
 * props list: {
 *  data
 * }
 */

const GalleryGridReview = (props) => {
  const [isZoom, setIsZoom] = useState(false);
  const [selectingItem, setSelectingItem] = useState();

  const galleryContainerRef = useRef();
  const windownSize = useWindowSize();

  const selectItem = (dishData) => {
    setIsZoom(true);
    setSelectingItem(dishData);
  };

  const closeZoom = () => {
    setIsZoom(false);
  };

  //review bar
  let reviewBarSize;
  if (windownSize[0] > 768) {
    reviewBarSize = "medium";
  } else {
    reviewBarSize = "small";
  }

  const reviewBar = (item) => {
    let content;
    content = (
      <div className={classes["rating-wrapper"]}>
        <Rating
          key={item.imgUID}
          name="decor-rating"
          precision={0.5}
          onChange={(event, newValue) => {
            props.onChange(props.dishId, item, newValue);
          }}
          size={reviewBarSize}
          value={item.score}
        />
      </div>
    );
    return content;
  };

  //define main content
  let mainContent;
  if (props.data.length === 0) {
    mainContent = (
      <div className={classes.note}>
        <p>Chưa có dữ liệu hình ảnh cho món ăn này </p>
      </div>
    );
  } else if (props.data.length > 0) {
    mainContent = props.data.map((item) => (
      <div key={item.imgUID}>
        <img src={item.imgData.URL} onClick={() => selectItem(item.imgData)} />
        {reviewBar(item)}
      </div>
    ));
  }

  //disable input until submit is completed
  useEffect(() => {
    const containerDOM = galleryContainerRef.current;
    const inputDOM = containerDOM.querySelectorAll("div input");
    if (props.isSubmitting) {
      inputDOM.forEach((item) => (item.disabled = true));
    } else {
      inputDOM.forEach((item) => (item.disabled = false));
    }
  }, [props.isSubmitting]);

  return (
    <div className={classes.container} ref={galleryContainerRef}>
      {isZoom &&
        ReactDOM.createPortal(
          <MainBackdropV3 onClick={closeZoom} />,
          document.getElementById("main-backdrop")
        )}
      {isZoom &&
        ReactDOM.createPortal(
          <ImgZoomModal data={selectingItem} />,
          document.getElementById("main-overlay")
        )}
      {mainContent}
    </div>
  );
};

export default GalleryGridReview;
