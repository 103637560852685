import React, { useState, useEffect } from "react";
import {
  getCountFromServer,
  collection,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import StatTag from "../UI/stat-tag/StatTag";
import Loader from "../UI/Loader";

const FoodDecorationStatCheck = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [finalStat, setfinalStat] = useState();

  useEffect(() => {
    let dishStatArr = [];
    let fetchImgArr = [];
    props.dishes.forEach((dish) => {
      dish.img.forEach((img) => {
        const colRef = collection(
          db,
          "dish_list",
          dish.dish.id,
          "images",
          img.imgUID,
          "decor-rating"
        );
        const userQuery = query(colRef, where("raterUID", "==", props.user.id));
        getCountFromServer(userQuery).then((snapshot) => {
          fetchImgArr.push({
            name: img.imgData.name,
            imgUID: img.imgUID,
            rater: props.user.fullName,
            numOfUserReview: snapshot.data(),
          });
        });
      });

      setTimeout(() => {
        if (fetchImgArr.length > 0) {
          const checkArr = fetchImgArr.filter(
            (item) => item.numOfUserReview.count > 0
          );
          if (checkArr.length > 0) {
            dishStatArr.push({
              dishName: dish.dish.name,
              isRated: true,
            });
          }
        } else {
          dishStatArr.push({
            dishName: dish.dish.name,
            isRated: false,
          });
        }
      }, 4000);
    });

    setTimeout(() => {
      if (dishStatArr.length > 0) {
        const statusCheck = dishStatArr.filter(
          (item) => item.isRated === false
        );
        if (statusCheck.length > 0) {
          //   finalStat = false;
          setIsLoading(false);
          setfinalStat(false);
        } else {
          //   finalStat = true;
          setIsLoading(false);
          setfinalStat(true);
        }
      } else {
        setIsLoading(false);
        setfinalStat(false);
      }
    }, 5000);
  }, [props]);

  let returnContent = (
    <div>
      <Loader />
    </div>
  );

  if (!isLoading) {
    if (finalStat) {
      returnContent = (
        <div>
          <StatTag style={{ bg: "green" }} />
        </div>
      );
    } else {
      returnContent = (
        <div>
          <StatTag style={{ bg: "grey" }} />
        </div>
      );
    }
  }

  return returnContent;
};

export default FoodDecorationStatCheck;
