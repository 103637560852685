import { useState, useRef, useEffect } from "react";
import FlexTableOutlet from "../../components/table/FlexTableOutlet";
import FormWrapper from "../../components/UI/FormWrapper";
import FormTemplate1 from "../../components/form/template/FormTemplate1";
import SingleFieldV3 from "../../components/form/template/SingleFieldV3";
import { collection, serverTimestamp, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { createDocV2 } from "../../hooks/fetchFirebase";
import { useSelector } from "react-redux";
import NortPTag from "../../components/UI/systemNor/NortPTag";
import classes from "./KitDanhSachOutlet.module.css";
import Loader from "../../components/UI/Loader";

const KitDanhSachOutlet = () => {
  const [nort, setNort] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [outletData, setOutletData] = useState([]);
  const outletInputRef = useRef();

  const user = useSelector((state) => state.auth.userCredential);

  useEffect(() => {
    const dataRef = collection(db, "outlet");
    onSnapshot(dataRef, (snapshots) => {
      let fetchedData = [];
      snapshots.forEach((item) => {
        fetchedData.push({
          docID: item.id,
          data: item.data(),
        });
      });
      setIsLoading(false);
      setOutletData(fetchedData);

      // fetchOutletData();
    });
    // fetchDbData();
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    setNort(null);
    console.log(outletInputRef.current.value);
    const dbRef = collection(db, "outlet");
    createDocV2(dbRef, {
      id: Math.floor(Math.random() * 1000),
      outlet: outletInputRef.current.value,
      author: user.email,
      timeCreated: serverTimestamp(),
      isApproved: null,
    }).then((res) => {
      if (res.code === 0) {
        setNort({
          type: "success",
          message: res.message,
        });
        setTimeout(() => setNort(null), 15000);
      } else {
        setNort({
          type: "error",
          message: `Đã có lỗi hệ thống, vui lòng chụp màn hình và báo kỹ thuật. Chi tiết: ${res.message}`,
        });
      }
    });
  };

  //form nortification content
  let nortContent;
  if (nort) {
    nortContent = (
      <div className={classes.nort}>
        <NortPTag type={nort.type}>{nort.message}</NortPTag>
      </div>
    );
  }

  let tableContent;
  if (outletData.length > 0) {
    tableContent = (
      <div className={classes["table-wrapper"]}>
        <FlexTableOutlet
          tHeadLeft="ID"
          tHeadRight="Outlet"
          data={outletData}
          tdataLeft="outlet"
          key="outlet"
          role={user.role}
        />
      </div>
    );
  } else if (isLoading) {
    tableContent = <Loader />;
  } else {
    tableContent = (
      <NortPTag type="error">
        Không có dữ liệu, vui lòng kiểm tra lại kết nối hoặc liên hệ kỹ thuật để
        được hỗ trợ
      </NortPTag>
    );
  }

  return (
    <div className={classes.container}>
      <FormWrapper>
        {/*
        <PrimaryForm formData={outletField} onSubmit={submitHandler} /> */}

        <FormTemplate1 id="outletForm" onSubmit={submitHandler}>
          <SingleFieldV3
            label="Tên Outlet"
            id="outlet"
            type="text"
            placeholder="Ví dụ: Coffee Terrace"
            required
            reference={outletInputRef}
          />
        </FormTemplate1>
        {nortContent}
      </FormWrapper>
      {tableContent}
    </div>
  );
};

export default KitDanhSachOutlet;
