import KitDeXuatDanhGia from "../Kitchen/KitDeXuatDanhGia";
import CreateConclude from "../../components/quan_ly/CreateConclude";
import GmTest from "../../components/quan_ly/GmTest";

const GMSiteMap = [
  {
    name: "Test chức năng",
    path: "test chuc nang",
    element: <GmTest />,
    depCode: "bếp",
  },
  {
    name: "Tạo thủ công dish review conclude",
    path: "tao thu cong dish review conclude",
    element: <CreateConclude />,
    depCode: "bếp",
  },
  {
    name: "test a",
    path: "test a",
    element: <KitDeXuatDanhGia />,
    depCode: "fo",
  },
  {
    name: "form mẫu 1",
    path: "form mau 1",
    element: <KitDeXuatDanhGia />,
    depCode: "UI/UX components",
  },
];

export default GMSiteMap;
