import { Routes, Route } from "react-router-dom";
import classes from "./UserXemSiteMap.module.css";
import UserXemDeXuatList from "./UserXemDeXuatList";
import UserXemDeXuatLanding from "./UserXemDeXuatLanding";
import UserXemDeXuatDetailWrapper from "./UserXemDeXuatDetailWrapper";

const UserXemSiteMap = () => {
  return (
    <div className={classes.container}>
      {/* <Routes>
        <Route index element={<UserXemDeXuatList />} />
        <Route path=":requestID" element={<UserXemDeXuatDetail />} />
      </Routes> */}

      <Routes>
        <Route path="/" element={<UserXemDeXuatLanding />}>
          <Route index element={<UserXemDeXuatList />} />
          <Route path="/:requestID" element={<UserXemDeXuatDetailWrapper />} />
        </Route>
      </Routes>
    </div>
  );
};

export default UserXemSiteMap;
