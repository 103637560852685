import React from "react";
import classes from "./FlexTable3Col1.module.css";

/* versions:

  originated from FlexTable3Col.js
  this version allows to have flexible tool section

    props list : {
        title: {
            col1, 
            col2,
            col3 - id
        },
        data: {
          col1,
          col2, 
          col3 - key, 
        }, 
        addOnComp
    }
*/

const FlexTable3Col1 = (props) => {
  let title;
  if (props.title) {
    title = props.title;
  }

  return (
    <div className={classes.container}>
      <div className={`${classes.row} ${classes.header}`}>
        <div>{title.col1}</div>
        <div>{title.col2}</div>
        <div>{title.col3}</div>
      </div>
      {props.data.length > 0 &&
        props.data.map((item, index) => (
          <div className={classes.row} key={item.col3}>
            <div className={classes.index}>{index + 1}</div>
            <div className={classes.info}>
              <p>{item.col1}</p>
              <p>{item.col2}</p>
            </div>
            {/* <div>{props.addOnComp(item.col3)}</div> */}
            {/* <p>{item.col3}</p> */}
            {props.addOnComp(item.col3)}
          </div>
        ))}
    </div>
  );
};

export default FlexTable3Col1;
