import React from "react";
import classes from "./FlexTable3Col.module.css";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

/*
    props list : {
        title: {
            col1, 
            col2,
            col3
        },
        data, 
        onRemove
    }
*/

const FlexTable3Col = (props) => {
    let title;
    if(props.title) {
        title = props.title
    }

  return (
    <div className={classes.container}>
      <div className={`${classes.row} ${classes.header}`}>
        <div>{title.col1}</div>
        <div>{title.col2}</div>
        <div>{title.col3}</div>
      </div>
      {props.data.length > 0 &&
        props.data.map((item, index) => (
          <div className={classes.row} key={item.col3}>
            <div className={classes.index}>{index + 1}</div>
            <div className={classes.info}>
              <p>{item.col1}</p>
              <p>{item.col2}</p>
            </div>
            <div>
              <button onClick={() => props.onRemove(item.col3)} >
                <RemoveCircleIcon sx={{color: '#EA1010'}} />
              </button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default FlexTable3Col;
