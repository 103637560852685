import React from "react";
import classes from "./MainDashboard.module.css";

const MainDashboard = () => {
  return (
    <div className={classes.mainDashBoard}>
      <h3>Chào mừng bạn đã gia nhập vào hệ thống<br/>Merperle Crystal Palace</h3>
      <p>Chúc một ngày tốt lành !</p>
    </div>
  );
};

export default MainDashboard;
