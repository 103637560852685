import React, { useState, useEffect, useRef } from "react";
import classes from "./HrChucVuQuyenHan.module.css";
import { db } from "../../firebase/firebase";
import {
  doc,
  serverTimestamp,
  onSnapshot,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterV2,
} from "../../hooks/capitalize";
import loader from "../../assets/gif/loader.gif";
import ReactDOM from "react-dom";
import PermissionErrModal from "../../components/UI/modals/PermissionErrModal";
import FormTemplate1 from "../../components/form/template/FormTemplate1";
import SingleField from "../../components/form/template/SingleField";
import SelectField from "../../components/form/template/SelectField";
import EditModal2 from "../../components/UI/modals/EditModal2";
import { SysNorRightFix } from "../../components/UI/systemNor/SysNorRightFix";
import Loader from "../../components/UI/Loader";
import NortPTag from "../../components/UI/systemNor/NortPTag";
import MainBackdropV3 from "../../components/UI/backdrops/MainBackdropV3";

const HrChucVuQuyenHan = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [editingContent, setEditingContent] = useState(false);
  const [systemErr, setSystemErr] = useState([{ key: null, msg: null }]);
  const [authorityErr, setAuthorityErr] = useState(false);
  const [deletePermit, setDeletePermit] = useState(false);
  const [editPermit, setEditPermit] = useState(false);
  const [createPermit, setCreatePermit] = useState(false);
  const [readPermit, setReadPermit] = useState(false);
  const [positionValue, setPositionValue] = useState();
  const [definitionData, setDefinitionData] = useState();
  const [definitionFilteredData, setDefinitionFilterdData] = useState();
  const [updateData, setUpdateData] = useState({
    position: null,
    role: null,
    author: null,
    createdDate: null,
  });

  const userIsLoggin = useSelector((state) => state.auth.userIsLoggin);
  const userEmail = useSelector((state) => state.auth.userCredential.email);
  // const userDisplayName = useSelector(
  //   (state) => state.userCredential.displayName
  // );
  const userRole = useSelector((state) => state.auth.userCredential.role);
  const userDepartment = useSelector(
    (state) => state.auth.userCredential.department
  );
  const userIsSuperAdmin = useSelector(
    (state) => state.auth.userCredential.isSuperAdmin
  );
  const [formNort, setFormNort] = useState(false);

  const roleFieldRef = useRef();
  const editFieldRef = useRef();

  useEffect(() => {
    //check authorization
    if (userDepartment === "Nhân Sự") {
      setCreatePermit(true);
      setEditPermit(true);
      setReadPermit(true);
    }

    if (userDepartment === "Nhân Sự" && userRole === "HOD") {
      setCreatePermit(true);
      setEditPermit(true);
      setReadPermit(true);
      setDeletePermit(true);
    }

    if (userRole === "admin" || userIsSuperAdmin === true) {
      setReadPermit(true);
      setDeletePermit(true);
      setCreatePermit(true);
      setEditPermit(true);
    }
  }, [userRole, userIsSuperAdmin, userDepartment]);

  //loading firebase data
  useEffect(() => {
    if (readPermit) {
      const fetchDefinitionData = onSnapshot(
        doc(db, "role", "position_role_definition"),
        (snapshot) => {
          setIsLoading(true);
          const data = snapshot.data();
          const dataArr = Object.entries(data);
          const reArrangedArr = dataArr.map((item) => ({
            position: item[1].position,
            role: item[1].role,
            id: item[0],
          }));
          const compareFn = (a, b) => {
            const positionA = a.position.toUpperCase();
            const positionB = b.position.toUpperCase();
            if (positionA > positionB) {
              return 1;
            }
            if (positionA < positionB) {
              return -1;
            }
            return 0;
          };

          reArrangedArr.sort(compareFn);
          setDefinitionData(dataArr);
          setDefinitionFilterdData(reArrangedArr);
          setIsLoading(false);
        }
      );

      if (userIsLoggin !== true) {
        fetchDefinitionData();
      }
    } else {
      setIsLoading(false);
      setDefinitionData(null);
    }
  }, [readPermit, userIsLoggin]);

  const submitHandler = (e) => {
    e.preventDefault();
    setIsCreating(true);
    setFormNort(false);
    if (createPermit) {
      const positionInput = roleFieldRef.current.value.toUpperCase().trim();
      console.log(positionInput, typeof positionInput);
      const filterArr = definitionData.filter(
        (item) => item[1].position.toUpperCase().trim() === positionInput
      );
      console.log(filterArr);

      if (filterArr.length === 0) {
        let fieldInputs;
        let uid = positionValue + Math.floor(Math.random() * 1000).toString();
        fieldInputs = {
          [uid]: {
            position: capitalizeFirstLetterV2(roleFieldRef.current.value),
            role: positionValue,
            author: userEmail,
            createdDate: serverTimestamp(),
          },
        };
        const dbDocRef = doc(db, "role", "position_role_definition");
        updateDoc(dbDocRef, fieldInputs)
          .then(() => {
            setFormNort({
              type: "success",
              key: Math.floor(Math.random() * 1000),
              message: "Đã tạo",
            });
            setIsCreating(false);
            setTimeout(() => setFormNort(false), 5000);
          })
          .catch((error) => {
            setIsCreating(false);
            console.log(error.toString());
            const errorStr = error.toString();
            setFormNort({
              type: "error",
              key: Math.floor(Math.random() * 1000),
              message: errorStr,
            });
            setTimeout(() => {
              setFormNort();
            }, [5000]);
          });
        roleFieldRef.current.value = "";
      } else {
        setIsCreating(false);
        setFormNort({
          type: "error",
          key: Math.floor(Math.random() * 1000),
          message:
            "Vị trí này đã được tạo trước đó! Bạn nên chỉnh sửa thay vì tạo mới!",
        });
        setTimeout(() => {
          setFormNort();
        }, [5000]);
      }
    } else {
      setAuthorityErr(true);
    }
  };

  const closeModal = () => {
    setIsEditing(false);
  };

  const editHandler = (fieldID) => {
    if (editPermit) {
      setIsEditing(true);
      const filteredData = definitionData.filter((def) => def[0] === fieldID);
      const data = {
        id: fieldID,
        position: filteredData[0][1].position,
        role: filteredData[0][1].role,
      };
      setEditingContent(data);
    } else {
      setAuthorityErr(true);
    }
  };

  const deleteHandler = (id) => {
    if (deletePermit) {
      const docRef = doc(db, "role", "position_role_definition");
      const deleteDocRef = doc(db, "role", "position_role_definition_deleted");
      let targetData = definitionData.filter((data) => data[0] === id);
      let transferData = {
        [targetData[0][0]]: {
          position: targetData[0][1].position,
          role: targetData[0][1].role,
          author: userEmail,
          createdDate: serverTimestamp(),
        },
      };
      try {
        updateDoc(deleteDocRef, transferData);
        updateDoc(docRef, {
          [id]: deleteField(),
        });
      } catch (error) {
        setSystemErr((prev) => [...prev, "Đã có lỗi " + error]);
      }
    } else {
      setAuthorityErr(true);
    }
  };

  const closePermissionModal = () => {
    setAuthorityErr(false);
  };

  const loaderSection = (
    <div className={classes.loader}>
      <img src={loader} alt="loading" />
      <p>Đang tải ...</p>
    </div>
  );

  const translateRole = (role) => {
    switch (role) {
      case "standard":
        return "Cơ bản";
        break;
      case "editor":
        return "Biên soạn viên";
        break;
      case "IT":
        return "Chuyên viên IT";
        break;
      case "HOD":
        return "Trưởng bộ phận";
        break;
      case "admin":
        return "Admin";
        break;
      default:
        return "Đã có lỗi";
    }
  };

  //conditon prevent to deleting admin role
  const ToolPanel = (props) => {
    let deleteContent;
    if (props.docRole === "admin") {
      if (props.userRole === "admin") {
        deleteContent = (
          <div className={classes.panel}>
            <button
              className={classes.editBtn}
              onClick={() => editHandler(props.def)}
            >
              Sửa
            </button>
            <button
              className={classes.deleteBtn}
              onClick={() => deleteHandler(props.def)}
            >
              Xóa
            </button>
          </div>
        );
      } else {
        deleteContent = (
          <div className={classes.panel}>
            <p>Không được cấp quyền</p>
          </div>
        );
      }
    } else {
      deleteContent = (
        <div className={classes.panel}>
          <button
            className={classes.editBtn}
            onClick={() => editHandler(props.def)}
          >
            Sửa
          </button>
          <button
            className={classes.deleteBtn}
            onClick={() => deleteHandler(props.def)}
          >
            Xóa
          </button>
        </div>
      );
    }
    return <React.Fragment>{deleteContent}</React.Fragment>;
  };

  const DataCheckedSection = () => {
    if (definitionData) {
      return (
        <div className={classes.flexTable}>
          <div className={classes.theader}>
            <div className={classes["sm-col"]}>STT</div>
            <div>Chức vụ</div>
            <div>Quyền hạn</div>
            <div>Công cụ</div>
          </div>
          {/* {definitionData &&
            definitionData.map((def, index) => (
              <div className={classes.tableRow} key={def[0]}>
                <div className={classes["sm-col"]}>{index + 1}</div>
                <div>{def[1].position}</div>
                <div>{translateRole(def[1].role)}</div>
                <ToolPanel
                  docRole={def[1].role}
                  userRole={userRole}
                  def={def[0]}
                />
              </div>
            ))} */}
          {definitionFilteredData &&
            definitionFilteredData.map((def, index) => (
              <div className={classes.tableRow} key={def.id}>
                <div className={classes["sm-col"]}>{index + 1}</div>
                <div>{def.position}</div>
                <div>{translateRole(def.role)}</div>
                <ToolPanel
                  docRole={def.role}
                  userRole={userRole}
                  def={def.id}
                />
              </div>
            ))}
        </div>
      );
    } else {
      return (
        <p className={classes["error-msg"]}>
          Không có dữ liệu, vui lòng kiểm tra lại mạng hoặc nhờ hỗ trợ
        </p>
      );
    }
  };

  const sendUpdate = (e) => {
    e.preventDefault();
    let updatedDataObject = updateData;
    if (updatedDataObject.role === "init") {
      setSystemErr((prev) => [
        ...prev,
        {
          key: Math.floor(Math.random() * 1000),
          msg: "Lựa chọn 'Chọn quyền hạn' không được áp dụng",
        },
      ]);
      closeModal();
      return;
    } else {
      updatedDataObject.position = capitalizeFirstLetter(
        editFieldRef.current.value
      );
      updatedDataObject.author = userEmail;
      updatedDataObject.createdDate = serverTimestamp();
      const dbDocRef = doc(db, "role", "position_role_definition");
      updateDoc(dbDocRef, {
        [editingContent.id]: updatedDataObject,
      });
      closeModal();
    }
  };

  const fetchValue = (value) => {
    setPositionValue(value);
  };

  const fetchEditValue = (value) => {
    let editDataObject = updateData;
    editDataObject.role = value;
    setUpdateData(editDataObject);
  };

  const role = [
    {
      value: "init",
      textContent: "Chọn quyền hạn",
      disable: true,
      defaultFunction: null,
    },
    {
      value: "standard",
      textContent: "Cơ bản",
      defaultFunction: "null",
    },
    {
      value: "editor",
      textContent: "Biên soạn viên",
      defaultFunction: null,
    },
    {
      value: "IT",
      textContent: "Chuyên viên IT",
      defaultFunction: null,
    },
    {
      value: "HOD",
      textContent: "Trưởng bô phận",
      defaultFunction: null,
    },
  ];

  const modalFnActivate = () => {
    console.log("this function is not available on this page");
  };

  const closeSysErr = (value) => {
    const errData = systemErr;
    const newErrData = errData.filter((err) => err.key !== value);
    setSystemErr(newErrData);
  };

  return (
    <React.Fragment>
      {systemErr && <SysNorRightFix onClick={closeSysErr} data={systemErr} />}
      <div className={classes.deptForm}>
        <FormTemplate1 onSubmit={submitHandler}>
          <SingleField
            label="chức vụ"
            id="position"
            type="text"
            required={true}
            placeholder="vị trí / chức vụ"
            reference={roleFieldRef}
          />
          <SelectField
            label="quyền hạn"
            id="roleDef"
            optionsList={role}
            onClick={fetchValue}
          />
          {isCreating && <Loader />}
          {formNort && (
            <NortPTag type={formNort.type}>{formNort.message}</NortPTag>
          )}
        </FormTemplate1>
      </div>
      {isLoading ? loaderSection : <DataCheckedSection />}
      {(isEditing || authorityErr) &&
        ReactDOM.createPortal(
          <MainBackdropV3 />,
          document.getElementById("backdrop-root")
        )}
      {isEditing &&
        editingContent &&
        ReactDOM.createPortal(
          <EditModal2
            fieldClick={fetchEditValue}
            onSubmit={sendUpdate}
            onClose={closeModal}
            reference={editFieldRef}
            data={role}
            onClickSub={modalFnActivate}
            defaultValue={editingContent.position}
            placeholder={editingContent.position}
          />,
          document.getElementById("overlay-root")
        )}
      {authorityErr &&
        ReactDOM.createPortal(
          <PermissionErrModal onClick={closePermissionModal} />,
          document.getElementById("overlay-root")
        )}
    </React.Fragment>
  );
};

export default HrChucVuQuyenHan;
