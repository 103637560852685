import { useState, Fragment, useContext } from "react";
import ModalWrapperV3 from "../UI/modals/ModalWrapperV3";
import CloseModal from "../UI/modals/CloseModal";
import DishImgTable from "./DishImgTable";
import ImgSelectModal from "./ImgSelectModal";
import CompCtx from "./CompCtx";

const SelectImgModal = (props) => {
  const [modalIndex, setModalIndex] = useState("init");
  const [dbImg, setDbImg] = useState({ dishId: null, imgData: [] });

  const compCtx = useContext(CompCtx);

  const title = {
    col1: "STT",
    col2: "Thông tin",
    col3: "Xóa",
  };

  let convertData = [];
  if (compCtx.dishCartCtx.length > 0) {
    compCtx.dishCartCtx.map((item) => {
      convertData.push({
        col1: item.dish.name,
        col2: item.dish.group,
        col3: item.dish.id,
      });
    });
  }

  const openImgSelect = () => {
    setModalIndex("select");
  };

  const backToInit = () => {
    setModalIndex("init");
  };

  const getImgDb = (dbArr) => {
    setDbImg(dbArr);
  };

  const tickSelect = (item) => {
    compCtx.dishCartCtx.forEach((dishItem) => {
      if (dishItem.dish.id === item.dishId) {
        dishItem.img.push({
          imgUID: item.imgUID,
          imgData: item.imgData,
        });
      }
      return dishItem;
    });
  };

  const tickDeselect = (item) => {
    const modifiedArr = compCtx.dishCartCtx.map((dishItem) => {
      if (dishItem.dish.id === item.dishId) {
        const filterImgArr = dishItem.img.filter(
          (img) => img.imgUID !== item.imgUID
        );
        dishItem.img = filterImgArr;
      }
      return dishItem;
    });
    compCtx.dishCartCtx.i = modifiedArr;
  };

  let modalContent;
  switch (modalIndex) {
    case "init":
      modalContent = (
        <Fragment>
          <DishImgTable
            title={title}
            data={convertData}
            onRemove={props.onRemove}
            onViewImg={openImgSelect}
            fetchImg={getImgDb}
          />
        </Fragment>
      );
      break;
    case "select":
      modalContent = (
        <Fragment>
          <ImgSelectModal
            onBack={backToInit}
            data={dbImg}
            checkItemFn={tickSelect}
            uncheckItemFn={tickDeselect}
          />
        </Fragment>
      );
      break;
  }

  return (
    <ModalWrapperV3>
      <CloseModal onClose={props.onClose} />
      {modalContent}
    </ModalWrapperV3>
  );
};

export default SelectImgModal;
