import React, { useState } from "react";
import classes from "./FlexTableDish.module.css";
// import AllowedFunctionBasic from "../../panel/AllowedFunctionBasic";
import { deleteDbDoc } from "../../../hooks/fetchFirebase";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import ReactDOM from "react-dom";
import MainBackdropV3 from "../../UI/backdrops/MainBackdropV3";
import CreateFoodModal from "./CreateFoodModal";
import TableDish from "./TableDish";
import ModalWrapperV4 from "../../UI/modals/ModalWrapperV4";
import { captializeWhole } from "../../../hooks/capitalize";
import { useSelector } from "react-redux";

const FlexTableDish = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [isDlting, setIsDlting] = useState(false);
  const [dltItem, setDltItem] = useState(false);
  const [modalNort, setModalNort] = useState();

  const user = useSelector((state) => state.auth.userCredential);

  const editHandler = (id) => {
    const matchedArr = props.data.filter((item) => item.docId === id);
    setEditItem(matchedArr[0]);
    setIsEditing(true);
  };

  const clearNort = () => {
    setTimeout(() => {
      setModalNort();
      setIsDlting(false);
    }, 5000);
  };

  const dbDelete = async () => {
    const docDbRef = doc(db, "dish_list", dltItem.docId);
    const reviewColRef = collection(
      db,
      "dish_list",
      dltItem.docId,
      "dish_review"
    );

    // check wether the dish has review

    const docsSnapshot = await getDocs(reviewColRef);

    const dishReviewSize = docsSnapshot.size;

    // change dish outlet to Banquet-delete

    if (dishReviewSize === 0) {
      const formatOutlet = captializeWhole(dltItem.data.outlet);

      const deleteOutletCheck = formatOutlet.includes("DELETE");

      if (deleteOutletCheck) {
        const permissionCheck = user.role === "admin";
        if (permissionCheck) {
          await deleteDbDoc(docDbRef);
          setModalNort("Món ăn đã được xóa khỏi cơ sở dữ liệu");
          clearNort();
        } else {
          setModalNort("Bạn không được phần quyền để thực hiện tao tác này");
          clearNort();
        }
      } else {
        const updateOutlet = `${dltItem.data.outlet}-delete`;
        await updateDoc(docDbRef, { outlet: updateOutlet });
        setModalNort(
          `Món ăn đã được chuyển sang outlet ${dltItem.data.outlet}-delete`
        );
        clearNort();
      }
    } else {
      setModalNort("Không xóa được món ăn đã có đánh giá thử món");

      clearNort();
      return;
    }
  };

  const deleteHandler = async (id) => {
    const filterArr = props.data.filter((item) => item.docId === id);
    const matchedItem = filterArr[0];
    clearTimeout(clearNort); //clear timeout clearNort just incase
    setIsDlting(true);
    setDltItem(matchedItem);
  };

  const closeEditModal = () => {
    setIsEditing(false);
  };

  const closeDltModal = () => {
    clearTimeout(clearNort);
    setIsDlting(false);
  };

  // let tableContent;
  // if (props.data.length > 0) {
  //   tableContent = props.data.map((item, index) => (
  //     <div
  //       className={`${classes.row} ${classes["dish-info"]}`}
  //       key={item.data.id}
  //     >
  //       <div className={classes.stt}>{index + 1}</div>
  //       <div className={`${classes.col} ${classes.description}`}>
  //         <div className={classes.dish}>
  //           <span>Món: </span> {item.data.name}
  //         </div>
  //         <div className={classes.group}>
  //           <span>Nhóm: </span>
  //           {item.data.group}
  //         </div>
  //         <div className={classes.outlet}>
  //           <span>Oulet: </span>
  //           {item.data.outlet}
  //         </div>
  //       </div>
  //       <div>
  //         <AllowedFunctionBasic
  //           docID={item.docId}
  //           onDelete={deleteHandler}
  //           onEdit={editHandler}
  //         />
  //       </div>
  //     </div>
  //   ));
  // }

  let dltModal;
  let dltOutlet;
  if (dltItem) {
    const deleteOutletCheck = captializeWhole(dltItem.data.outlet).includes(
      "DELETE"
    );
    if (deleteOutletCheck) {
      dltOutlet = (
        <p className={classes.note}>
          Món ăn sẽ bị xóa khỏi cơ sở dữ liệu, bạn có chắc chưa ?
        </p>
      );
    } else {
      dltOutlet = (
        <p
          className={classes.note}
        >{`Món ăn đang thuộc outlet ${dltItem.data.outlet} và sẽ chuyển qua outlet ${dltItem.data.outlet}_delete nếu xác nhận xóa`}</p>
      );
    }
  }
  if (dltItem) {
    dltModal = (
      <ModalWrapperV4>
        <div className={classes.modal}>
          <p
            className={classes.warning}
          >{`Bạn có chắc là muốn xóa món ăn ${dltItem.data.name} hay không ?`}</p>
          {dltOutlet}
          <div className={classes["btn-nav"]}>
            {!modalNort && (
              <button
                className={`${classes.button} ${classes.delete}`}
                onClick={() => dbDelete()}
              >
                Xóa
              </button>
            )}
            <button
              className={`${classes.button} ${classes.cancel}`}
              onClick={closeDltModal}
            >
              Đóng
            </button>
          </div>

          <div className={classes["modal-nort"]}>{modalNort}</div>
        </div>
      </ModalWrapperV4>
    );
  }

  return (
    <div className={classes.container}>
      {isEditing &&
        ReactDOM.createPortal(
          <MainBackdropV3 onClick={closeEditModal} />,
          document.getElementById("main-backdrop")
        )}
      {isEditing &&
        ReactDOM.createPortal(
          <CreateFoodModal data={editItem} onClose={closeEditModal} />,
          document.getElementById("main-overlay")
        )}
      {isDlting &&
        ReactDOM.createPortal(
          <ModalWrapperV4
            onClick={() => {
              setIsDlting(false);
            }}
          />,
          document.getElementById("main-backdrop")
        )}
      {isDlting &&
        ReactDOM.createPortal(
          dltModal,
          document.getElementById("main-overlay")
        )}
      {/* <div className={`${classes.row} ${classes.header}`}>
        <div>STT</div>
        <div>Món ăn</div>
        <div>Công cụ</div>
      </div> */}
      {/* {tableContent} */}
      <TableDish
        onDelete={deleteHandler}
        onEdit={editHandler}
        data={props.data}
      />
    </div>
  );
};

export default FlexTableDish;
