import React from "react";
import classes from "./ApproveCheck.module.css";

/*
  props lists : {
    author
    onClick
    check
    disabled
  }
*/

const ApproveCheck = (props) => {
  return (
    <React.Fragment>
      <input
        className={`${classes.checkbox}`}
        type="checkbox"
        onChange={props.onChange}
        checked={props.checked}
        disabled={props.disabled}
      ></input>
      <span className={classes.author}>{props.role}</span>
    </React.Fragment>
  );
};

export default ApproveCheck;
