import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classes from "./Profile.module.css";
import logoAvatar from "../../assets/images/profile-nav.png";
import ProfilePanel from "./ProfilePanel";
import { useSelector } from "react-redux";

const Profile = () => {
  const [isExpanding, setIsExpanding] = useState(false);

  const profile = useSelector((state) => state.auth.userCredential);

  const onClickHandler = () => {
    setIsExpanding((prev) => !prev);
  };

  return (
    <div className={classes.profile}>
      <div className={classes.avatar}>
        <img src={logoAvatar} alt="logo avatar" />
      </div>
      <div className={`${classes.profile} ${classes.profileName}`}>
        {profile.displayName}
      </div>
      <div>
        <ExpandMoreIcon className={classes.chevron} onClick={onClickHandler} />
      </div>
      {/* <ProfilePanel state={isExpanding} /> */}
      {isExpanding && <ProfilePanel onClick={onClickHandler} />}
    </div>
  );
};

export default Profile;
