import React, { useState, useRef, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import classes from "./HinhAnhSearchBar.module.css";
import SelectFieldMonAn from "../department/KIT/SelectFieldMonAn";
import McpButtonV1 from "../UI/button/McpButtonV1";
import { db } from "../../firebase/firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { getDbs1 } from "../../hooks/fetchFirebase";
import Loader from "../UI/Loader";
import NortPTag from "../UI/systemNor/NortPTag";

// optionsList : {
//     value,
//     textContent,
//     disabled,
//   },
//   id,
//   label,
//   onClick,
//   onClickSub,
//   defaultOption

/*
  Originated from KitSearBar.js, customized for HinhAnhMonAn page
  Main purpose is to get search keywords and select value.   
 */

const HinhAnhSearchBar = (props) => {
  const [optionValue, setOptionValue] = useState("Món ăn");
  const [foodGroup, setFoodGroup] = useState([]);
  const [outlet, setOutlet] = useState([]);
  const [groupChoice, setGroupChoice] = useState();
  const [outletChoice, setOuletChoice] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const inputRef = useRef();
  const formRef = useRef();

  const optionsList = [
    {
      value: "Món ăn",
      textContent: "Món ăn",
      disabled: false,
      key: 1,
    },
    {
      value: "Nhóm món ăn",
      textContent: "Nhóm món ăn",
      disabled: false,
      key: 2,
    },
    {
      value: "Outlet",
      textContent: "Outlet",
      disabled: false,
      key: 3,
    },
    {
      value: "no_image",
      textContent: "Chưa có hình ảnh",
      disabled: false,
      key: 4,
    },
  ];

  //fetch data from database
  useEffect(() => {
    const groupRef = collection(db, "food_group");
    const outletRef = collection(db, "outlet");

    // onSnapshot(groupRef, (snapshot) => {
    //   if (!snapshot.empty) {
    //     let arr = [];
    //     snapshot.forEach((item) =>
    //       arr.push({
    //         value: item.data().foodGroup,
    //         textContent: item.data().foodGroup,
    //         disabled: false,
    //       })
    //     );
    //     setFoodGroup(arr);
    //   } else {
    //     //set bao loi
    //   }
    // });

    const fetchGroup = new Promise((resolve) => {
      getDbs1(groupRef).then((res) => {
        const formatArr = res.map((item) => {
          return {
            value: item.data.foodGroup,
            textContent: item.data.foodGroup,
            disabled: false,
          };
        });
        setFoodGroup(formatArr);
        resolve(formatArr);
      });
    });

    const fetchOutlet = new Promise((resolve) => {
      getDbs1(outletRef).then((res) => {
        const formatArr = res.map((item) => {
          return {
            value: item.data.outlet,
            textContent: item.data.outlet,
            disabled: false,
          };
        });
        setOutlet(formatArr);
        resolve(true);
      });
    });

    Promise.all([fetchGroup, fetchOutlet]).then((res) => {
      setIsLoading(false);
    });

    // const fetchOulet = onSnapshot(outletRef, (snapshot) => {
    //   if (!snapshot.empty) {
    //     let arr = [];
    //     snapshot.forEach((item) => {
    //       arr.push({
    //         value: item.data().outlet,
    //         textContent: item.data().outlet,
    //         disabled: false,
    //       });
    //     });
    //     console.log(arr);
    //     setOutlet(arr);
    //   } else {
    //   }
    // });
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    let keyword;
    switch (optionValue) {
      case "Món ăn":
        keyword = inputRef.current.value;
        break;
      case "Nhóm món ăn":
        keyword = groupChoice;
        break;
      case "Outlet":
        keyword = outletChoice;
        break;
      case "no_image":
        keyword = groupChoice;
        break;
      // default:
      //   keyword = inputRef.current.value;
      //   break;
    }
    props.onSearch({
      keyword: keyword,
      filterGroup: optionValue,
    });
  };

  const loadAll = (e) => {
    // formRef.current.reset();
    // submitHandler(e);
    props.onSearch({
      keyword: "",
      filterGroup: "Món ăn",
    });
  };

  //fetch select value
  const fetchSearchOption = (value) => {
    setOptionValue(value);
  };

  //get foodgroup option
  const getFoodGroup = (value) => {
    setGroupChoice(value);
  };

  //get outlet option
  const getOutlet = (value) => {
    setOuletChoice(value);
  };

  //option content
  let optionContent;
  switch (optionValue) {
    case "Món ăn":
      optionContent = (
        <div className={classes["text-field"]}>
          <button type="submit">
            <SearchIcon
              sx={{
                backgroundColor: "#30BE75",
                color: "white",
                borderRadius: "4px",
              }}
            />
          </button>
          <input
            type="text"
            placeholder="Nhập từ khoá tìm kiếm"
            ref={inputRef}
          />
        </div>
      );
      break;
    case "Nhóm món ăn":
      optionContent = (
        <SelectFieldMonAn
          id="foodGroup"
          label="Chọn nhóm món ăn"
          optionsList={foodGroup}
          onClick={getFoodGroup}
          defaultOption={foodGroup[0].value}
        />
      );
      break;
    case "Outlet":
      optionContent = (
        <SelectFieldMonAn
          id="outlet"
          label="Chọn outlet"
          optionsList={outlet}
          onClick={getOutlet}
          defaultOption={outlet[0].value}
        />
      );
      break;
    case "no_image":
      optionContent = (
        <SelectFieldMonAn
          id="noImage"
          label="Chọn nhóm món ăn"
          optionsList={foodGroup}
          onClick={getFoodGroup}
          defaultOption={foodGroup[0].value}
        />
      );
      break;
    default:
      optionContent = (
        <div className={classes["text-field"]}>
          <button type="submit">
            <SearchIcon
              sx={{
                backgroundColor: "#30BE75",
                color: "white",
                borderRadius: "4px",
              }}
            />
          </button>
          <input
            type="text"
            placeholder="Nhập từ khoá tìm kiếm"
            ref={inputRef}
          />
        </div>
      );
      break;
  }

  let mainContent;
  if (isLoading) {
    mainContent = (
      <div className={classes.loader}>
        <Loader />
        <p>Đang tải dữ liệu!!</p>
      </div>
    );
  } else {
    mainContent = (
      <form
        className={classes["search-container"]}
        onSubmit={submitHandler}
        ref={formRef}
      >
        <div>
          <SelectFieldMonAn
            id="searchSelect"
            label="Lựa chọn phạm vi tìm kiếm: "
            optionsList={optionsList}
            onClick={fetchSearchOption}
            defaultOption="Món ăn"
          />
        </div>
        {optionContent}
        <div className={classes["btn-panel"]}>
          <McpButtonV1 type="green" content="Tìm" onClick={submitHandler} />
          <McpButtonV1 type="blue" content="Tải toàn bộ" onClick={loadAll} />
          {/* <McpButtonV1 type="red" btnType="reset" content="Huỷ" /> */}
        </div>
      </form>
    );
  }

  //setTimout in case something is wrong with isLoading
  setTimeout(() => {
    isLoading && setIsLoading(false);
  }, 8000);

  return (
    <React.Fragment>{mainContent}</React.Fragment>
    // <form
    //   className={classes["search-container"]}
    //   onSubmit={submitHandler}
    //   ref={formRef}
    // >
    //   <div>
    //     <SelectFieldMonAn
    //       id="searchSelect"
    //       label="Lựa chọn phạm vi tìm kiếm: "
    //       optionsList={optionsList}
    //       onClick={fetchSearchOption}
    //       defaultOption="Món ăn"
    //     />
    //   </div>
    //   {optionContent}
    //   <div className={classes["btn-panel"]}>
    //     <McpButtonV1 type="green" content="Tìm" onClick={submitHandler} />
    //     <McpButtonV1 type="blue" content="Tải toàn bộ" onClick={loadAll} />
    //     {/* <McpButtonV1 type="red" btnType="reset" content="Huỷ" /> */}
    //   </div>
    // </form>
  );
};

export default HinhAnhSearchBar;
