import React, { useState, useRef, useEffect } from "react";
import classes from "./MainNavigation.module.css";
import MobileNav from "../mobile/MobileNav";
import MobileNav2 from "../mobile/MobileNav2";
import BurgerIcon from "../UI/BurgerIcon";
import { useSelector } from "react-redux";
import Profile from "../profile/Profile";
import logo from "../../assets/images/whiteLogo50x50.png";
import { Link } from "react-router-dom";
import { useWindowSize } from "../../hooks/layoutDOM";

const MainNavigation = () => {
  const [isActive, setIsActive] = useState(false);
  const userIsLoggin = useSelector((state) => state.auth.userIsLoggin);

  const navContainerRef = useRef();

  const browserWindowSize = useWindowSize();

  useEffect(() => {
    if (isActive) {
      const scrollHeight = document.getElementById("main-section").scrollHeight;
      navContainerRef.current.style.height = `${scrollHeight}px`;
    }
  }, [browserWindowSize, isActive]);

  const onClickHandler = () => {
    setIsActive((prev) => !prev);
  };

  const profileContent = userIsLoggin ? <Profile /> : null;
  // <div className={classes.profile}>
  //   <a href="/#">Sign in</a>
  //   <PermIdentityOutlinedIcon />
  // </div>

  let navClasses;
  if (userIsLoggin) {
    navClasses = "logged-in";
  } else {
    navClasses = "logged-out";
  }

  return (
    <header className={classes.header} id="main-header">
      <nav className={`${classes.headerNav} ${classes[`${navClasses}`]}`}>
        {userIsLoggin ? (
          <BurgerIcon state={isActive} onClick={onClickHandler} />
        ) : null}
        <div>
          {/* <a href="/#">
            <img src={logo} alt='Logo' />
          </a> */}
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        {profileContent}
      </nav>
      <MobileNav2
        state={isActive}
        onClick={onClickHandler}
        reference={navContainerRef}
      />
    </header>
  );
};

export default MainNavigation;
