import React, { useEffect, useRef, useState } from "react";
import classes from "./HrEmployeeEditForm.module.css";
import { db } from "../../../firebase/firebase";
import {
  collection,
  serverTimestamp,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import FormTemplate1 from "../../form/template/FormTemplate1";
import SingleFieldV3 from "../../form/template/SingleFieldV3";
import SelectFieldV2 from "../../form/template/SelectFieldV2";
import NortPTag from "../../UI/systemNor/NortPTag";
import Loader from "../../UI/Loader";

const HrEmployeeEditForm = (props) => {
  const [hasError, setHasError] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dbPosition, setDbPosition] = useState([
    {
      value: "init",
      textContent: "Chọn chức vụ",
      disable: true,
      defaultFunction: null,
    },
  ]);
  const [dbDepartment, setDbDepartment] = useState([
    {
      value: "init",
      textContent: "Chọn bộ phận",
      disable: true,
      defaultFunction: null,
    },
  ]);
  const [inputPosition, setInputPosition] = useState();
  const [inputDepartment, setInputDepartment] = useState();
  const [inputRole, setInputRole] = useState();
  const genderOptions = [
    {
      value: "init",
      textContent: "Chọn giới tính",
      disable: true,
      defaultFunction: null,
    },
    {
      value: "Nam",
      textContent: "Nam",
      disable: false,
      defaultFunction: null,
    },
    {
      value: "Nữ",
      textContent: "Nữ",
      disable: false,
      defaultFunction: null,
    },
  ];
  const [inputGender, setInputGender] = useState();

  //Permmission states
  const [writePermit, setWritePermit] = useState(false);

  //retrieve auth info
  const userIsLogin = useSelector((state) => state.auth.userIsLoggin);
  const userEmail = useSelector((state) => state.auth.userCredential.email);
  const userRole = useSelector((state) => state.auth.userCredential.role);
  const userDepartment = useSelector(
    (state) => state.auth.userCredential.department
  );
  const userIsSuperAdmin = useSelector(
    (state) => state.auth.userCredential.isSuperAdmin
  );

  //refs for every fields
  const nameRef = useRef();
  const familyNameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const dobRef = useRef();
  const startRef = useRef();

  //authority definition
  useEffect(() => {
    if (userDepartment === "Nhân Sự") {
      setWritePermit(true);
    }

    if (userRole === "admin" || userIsSuperAdmin) {
      setWritePermit(true);
    }
  }, [userDepartment, userRole, userIsSuperAdmin]);

  useEffect(() => {
    const fetchDbPosition = onSnapshot(
      doc(db, "role", "position_role_definition"),
      (snapshot) => {
        const dbPositionData = snapshot.data();
        let dataArr = Object.keys(dbPositionData).map((key) => {
          return dbPositionData[key].position;
        });
        let modifiedArray = [
          {
            value: "init",
            textContent: "Chọn chức vụ",
            disable: true,
            defaultFunction: null,
          },
        ];
        dataArr.map((position) => {
          modifiedArray.push({
            value: position,
            textContent: position,
            disable: false,
            defaultFunction: null,
            // selected: false
          });
        });

        //update value if there is an init input
        if (props.defaultOption) {
          const initIndex = modifiedArray.findIndex(
            (obj) => obj.value === props.defaultOption
          );
          // modifiedArray[initIndex].selected = true;
        }

        setDbPosition(modifiedArray);

        //get role relative to position
        let dataArr2 = Object.entries(dbPositionData);
        const roleResult = dataArr2.filter(
          (data) => data[1].position === inputPosition
        );
        roleResult.length > 0 && setInputRole(roleResult[0][1].role);
      }
    );

    const fetchDbDepartment = onSnapshot(
      collection(db, "department"),
      (snapshot) => {
        if (snapshot) {
          let dataArr = [];
          snapshot.forEach((doc) => {
            dataArr.push(doc.data());
          });

          let finalData = [
            {
              value: "init",
              textContent: "Chọn bộ phận",
              disable: true,
              defaultFunction: null,
            },
          ];

          dataArr.map((data) =>
            finalData.push({
              value: data.departmentName,
              textContent: data.departmentName,
              disable: false,
              defaultFunction: null,
            })
          );

          // const selectedIndex = finalData.findIndex(unit => unit.value === props.initInput.department )

          // finalData[selectedIndex].selected = true;
          setDbDepartment(finalData);
        }
      }
    );

    if (!userIsLogin) {
      fetchDbPosition(); //detach firestore event listener
      fetchDbDepartment();
    }

    if (props.initInput) {
      nameRef.current.value = props.initInput.name;
      familyNameRef.current.value = props.initInput.familyName;
      // emailRef.current.value = props.initInput.email;
      phoneRef.current.value = props.initInput.phone;
      dobRef.current.value = props.initInput.dob;
      startRef.current.value = props.initInput.startDate;
    }
  }, [userIsLogin, inputPosition]);

  //ui/ux interaction

  useEffect(() => {
    if (hasError.length > 0) {
      const nortificationReset = () => {
        setHasError([]);
      };
      setTimeout(nortificationReset, 10000);
    }
  }, [hasError]);

  //submit handler
  const submitHandler = (e) => {
    setHasError([]);
    
    setIsLoading(true); 

    e.preventDefault();
    if (writePermit) {
      // const emailInput = emailRef.current.value;

      //email validataion
      // let emailIsValid;
      // emailIsValid =
      //   emailInput.includes("@merperle.vn") ||
      //   emailInput.includes("@crystalpalacevn.com")
      //     ? true
      //     : false;

      //department validation
      let departmentIsValid;
      if (inputDepartment === "init") {
        departmentIsValid = false;
      } else {
        departmentIsValid = true;
      }

      //position validation
      let positionIsValid;
      if (inputPosition === "init") {
        positionIsValid = false;
      } else {
        positionIsValid = true;
      }

      if (departmentIsValid && positionIsValid) {
        let submitData = {};
        const enteredFamilyName = familyNameRef.current.value;
        const lastWord = enteredFamilyName.substring(
          enteredFamilyName.trim().lastIndexOf(" ") + 1
        );
        submitData = {
          name: nameRef.current.value,
          familyName: familyNameRef.current.value,
          phone: phoneRef.current.value,
          dob: dobRef.current.value,
          startDate: startRef.current.value,
          position: inputPosition,
          role: inputRole,
          creator: userEmail,
          timeCreated: serverTimestamp(),
          accActivated: false,
          // id:
          //   nameRef.current.value.charAt(0) +
          //   lastWord.charAt(0) +
          //   emailRef.current.value.charAt(0) +
          //   Math.floor(Math.random() * 1000).toString(),
          id: props.initInput.id,
          department: inputDepartment,
          gender: inputGender
        };

        //update db
        props.onUpdate(submitData).then(() => {
          setTimeout(() => setIsLoading(false), 1000)
        });
        // setIsLoading(false)
      } else {
        if (!departmentIsValid) {
          setHasError((prev) => [...prev, "Bạn chưa chọn bộ phận"]);
        }
        if (!positionIsValid) {
          setHasError((prev) => [...prev, "Bạn chưa chọn vị trí"]);
        }
      }
    } else {
      setHasError((prev) => [
        ...prev,
        "Bạn không đủ quyền hạn để thực hiện thao tác này",
      ]);
    }
  };

  //placeholder functions of select fields
  const positionFieldClickHanlder = (choice) => {
    setInputPosition(choice);
  };

  const departmentFieldClickHanlder = (choice) => {
    setInputDepartment(choice);
  };

  const genderFieldClickHanlder = (choice) => {
    setInputGender(choice);
  };

  const selectFieldSubClickHanlder = () => {
    console.log("chức năng này không hoạt động ở trang này");
  };

  //nort content
  let nortContent;
  if (props.nortContent.length > 0) {
    nortContent = props.nortContent.map((nort) => (
      <NortPTag type={nort.type} key={nort.key}>{nort.msg}</NortPTag>
    ));
  }



  return (
    <React.Fragment>
      <FormTemplate1 onSubmit={submitHandler} onCancel={props.onCancel}>
        <SingleFieldV3
          label="Tên"
          id="name"
          type="text"
          placeholder=""
          required={true}
          reference={nameRef}
        />
        <SingleFieldV3
          label="Họ và tên lót"
          id="familyName"
          type="text"
          placeholder="VD: Nguyễn Thị"
          required={true}
          reference={familyNameRef}
        />
        {/* <SingleFieldV3
          label="Email - @merperle.vn / @crystalpalacevn.com"
          id="email"
          type="email"
          placeholder="phải là email công ty"
          required={true}
          reference={emailRef}
        /> */}
        <SingleFieldV3
          label="Phone"
          id="phone"
          type="phone"
          placeholder=""
          required={true}
          reference={phoneRef}
        />
        <SingleFieldV3
          label="Ngày sinh"
          id="DOB"
          type="date"
          placeholder=""
          required={true}
          reference={dobRef}
        />
        <SingleFieldV3
          label="Ngày bắt đầu"
          id="startDate"
          type="date"
          placeholder=""
          required={true}
          reference={startRef}
        />
        <SelectFieldV2
          id="department"
          label="Bộ phận"
          onClick={departmentFieldClickHanlder}
          onClickSub={selectFieldSubClickHanlder}
          optionsList={dbDepartment}
          defaultOption={props.defaultDepartment}
        />
        <SelectFieldV2
          id="position"
          label="Chức vụ"
          onClick={positionFieldClickHanlder}
          onClickSub={selectFieldSubClickHanlder}
          optionsList={dbPosition}
          defaultOption={props.defaultPosition}
        />
        <SelectFieldV2
          id="gender"
          label="Giới tính"
          onClick={genderFieldClickHanlder}
          onClickSub={selectFieldSubClickHanlder}
          optionsList={genderOptions}
          defaultOption={props.defaultGender}
        />

        {/* {hasError && <div className={classes.errMsg}>{hasError}</div>} */}

        {hasError &&
          hasError.map((error) => (
            <div className={classes.errMsg} key={error}>
              {error}
            </div>
          ))}

        {nortContent}

        {isLoading && <Loader/>}

        {/* {isSuccess && (
          <div className={classes["success-created"]}>{isSuccess}</div>
        )} */}
      </FormTemplate1>

      {/* <button onClick={testHanlder}>activated</button> */}
    </React.Fragment>
  );
};

export default HrEmployeeEditForm;
