import classes from "./DishScoreTable.module.css";
import McpButtonV1 from "../UI/button/McpButtonV1";

/* versions:

  1 - originated from FlexTable3Col.js
  this version is customized for dish reviews overall 

    props list : {
        title: {
            col1, 
            col2,
            col3 
        },
        data: [{
          key,
          info : []
        }, 
        addOnComp
    }
*/

const DishScoreTable = (props) => {
  let title;
  if (props.title) {
    title = props.title;
  }

  const returnScoreTag = (score) => {
    let scoreType;
    const numberScore = parseInt(score);

    if (numberScore <= 2.5) {
      scoreType = "red";
    }
    if (numberScore <= 3.3) {
      scoreType = "yellow";
    }
    if (numberScore <= 4) {
      scoreType = "blue";
    } else {
      if (numberScore > 4) {
        scoreType = "green";
      } else {
        scoreType = "none";
      }
    }

    return (
      <span className={`${classes.score} ${classes[`${scoreType}`]}`}>
        {score ? score : "Chưa có"}
      </span>
    );
  };

  let returnDOM;
  if (props.data.length > 0) {
    returnDOM = (
      <div className={classes.container}>
        <div className={`${classes.row} ${classes.header}`}>
          <div>{title.col1}</div>
          <div>{title.col2}</div>
          <div>{title.col3}</div>
        </div>
        {props.data.length > 0 &&
          props.data.map((item, index) => (
            <div className={classes.row} key={item.dishId}>
              <div className={classes.index}>{index + 1}</div>
              <div className={classes.info}>
                <p>
                  <span>Tên món: </span>
                  <span className={classes["dish-name"]}>{item.dishName}</span>
                </p>
                <p>
                  <span>Điểm cao nhất: </span>
                  {returnScoreTag(item["diem_cao_nhat"])}
                </p>
                <p>
                  <span>Số lần thử món: </span>
                  {item["so_lan_thu_mon"] ? item["so_lan_thu_mon"] : "Chưa có"}
                </p>
              </div>
              <div>
                <McpButtonV1
                  btnType="button"
                  type="blue"
                  onClick={() => props.onClick(item)}
                  content="Chi tiết"
                />
              </div>
            </div>
          ))}
      </div>
    );
  } else
    returnDOM = <p className={classes.center}>Hiện tại chưa có thông tin!</p>;

  return returnDOM;
};

export default DishScoreTable;
