import React from "react";
import classes from "./McpButton.module.css";

const McpButton = (props) => {
  return (
    <React.Fragment>
      <button
        className={`${classes.button} ${classes[`${props.type}`]}`}
        onClick={props.onClick}
        type="button"
      >
        {props.content}
      </button>
    </React.Fragment>
  );
};

export default McpButton;
