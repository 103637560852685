import { useState } from "react";
import classes from "./SysNor.module.css";

export const SysNor = (props) => {
  //   const [showError, setShowError] = useState(true);

  //   const norUnitContent = (
  //     <div className={showError ? classes["nor-unit"] : classes["nor-hidden"]}>
  //       <span className={classes.closeBtn} onClick={closeNortification}>
  //         X
  //       </span>
  //       <span className={classes["err-msg"]}>Error message</span>
  //     </div>
  //   );

  const NorUnit = (props) => {
    const [showError, setShowError] = useState(true);
    const closeNortification = (e) => {
      setShowError(false);
    };
    return (
      <div className={showError ? classes["nor-unit"] : classes["nor-hidden"]}>
        <span className={classes.closeBtn} onClick={closeNortification}>
          X
        </span>
        <span className={classes["err-msg"]}>{props.errorMsg}</span>
      </div>
    );
  };

  return (
    <div className={classes["nortification-container"]}>
      {props.data &&
        props.data.map((error) => (
            <NorUnit key={error} errorMsg = {error} />
        ))}
    </div>
  );
};
