// This version 2 only create a wrapper for form section, you can import form component anywhere and put it in

import ModalWrapperV3 from '../../UI/modals/ModalWrapperV3'
import HrEmployeeEditForm from "./HrEmployeeEditForm"

const EmployeeEditModal = (props) => {
  return (
    // <ModalWrapperV2 reference={props.reference}>
    <ModalWrapperV3>
      <HrEmployeeEditForm
        initInput={props.initInput}
        onCancel={props.onCancel}
        onUpdate={props.onUpdate}
        // nortContent={props.nortContent}
        nortContent = {props.nort}
        defaultPosition={props.defaultPosition}
        defaultDepartment={props.defaultDepartment}
        defaultGender={props.defaultGender}
      />
    </ModalWrapperV3>
    // </ModalWrapperV2>
  );
};

export default EmployeeEditModal;
