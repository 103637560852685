import React from "react";
import ModalWrapperV3 from "../UI/modals/ModalWrapperV3";
import classes from "./ApproveModal.module.css";
import ApproveStatusIcon from "../UI/icon/ApproveStatusIcon";
import CloseIcon from '@mui/icons-material/Close';

/*
    Need:
    data array: {
        tinh trang
        ten 
        bo phan
    }
    outlet
    
*/

const ApproveModal = (props) => {
  let approverList;
  console.log(props.data);

  if (props.data.length > 0) {
    approverList = props.data.map((item) => (
      <div className={classes["flex-row"]} key={item.department}>
        <div>
          <ApproveStatusIcon data={item.status} />
        </div>
        <div>{item.name}</div>
        <div>{item.department}</div>
      </div>
    ));
  }

  let GMStatus;
  let functionStatus;
  if (props.isApproved === "GM") {
    GMStatus = (
      <p>
        <ApproveStatusIcon data="yes" />
      </p>
    );
    functionStatus = (
      <button type="button" onClick={props.onClick} className={classes.unapprove}>
        Bỏ tình trạng duyệt
      </button>
    );
  } else {
    GMStatus = (
      <p>
        <ApproveStatusIcon data="no" />
      </p>
    );
    functionStatus = (
      <button type="button" onClick={props.onClick} className={classes.approve}>
        Duyệt
      </button>
    );
  }

  return (
    <ModalWrapperV3>
      <div className={classes.container}>
        <div className={classes.close}>
            <button type='button' onClick={props.onClose}>
                <CloseIcon fontSize="small" sx={{color: 'white'}}/>
            </button>
        </div>
        <div>
            <p className={classes.title}>Thông tin outlet:</p>
            <p>{props.outlet}</p>
        </div>
        <div className={classes["approved-list"]}>
          <p className={classes.title}>Kiểm duyệt</p>
          <div>
            {approverList}
          </div>
        </div>
        <div className={classes["final-approve"]}>
          <p>GM kiểm duyệt:</p>
          {GMStatus}
          {functionStatus}
        </div>
      </div>
    </ModalWrapperV3>
  );
};

export default ApproveModal;
