import Card from '../card/Card'
import classes from './ModalWrapper.module.css'

const ModalWrapper = (props) => {
  return (
    <div className={classes['modal-wrapper']}>
      <Card>
        {props.children}
      </Card>
    </div>
  )
}

export default ModalWrapper
