import React from "react";
import BackHomeBtn from "../../components/UI/BackHomeBtn";
import classes from './IsBuilding.module.css'
import bgImg from '../../assets/images/isBuilding_iconBg.png'

const IsBuiding = () => {
  return (
    <div className={classes.isBuilding}>
      <div className={classes.wrapper}>
        <div className={classes.iconBg}>
            <img src={bgImg}></img>
        </div>
        <p className={classes.des}>
          Chức năng này đang được xây dựng.
          <br />
          Vui lòng quay lại sau
        </p>
        <BackHomeBtn />
      </div>
    </div>
  );
};

export default IsBuiding;
