import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import classes from './AddBtn.module.css'

/*
    props list: {
        onClick, 
    }
 */

const AddBtn = (props) => {

  return (
    <React.Fragment>
        <button className={classes.btn} onClick={props.onClick}>
            <AddIcon sx={{color: 'white'}} />
        </button>
    </React.Fragment>
  )
}

export default AddBtn