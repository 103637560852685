import React, {useRef} from "react";
import SearchIcon from "@mui/icons-material/Search";
import classes from "./SearchBar.module.css";
import McpButtonV1 from "../UI/button/McpButtonV1";


const SearchBar = (props) => {

  const InputRef = useRef();
  const formRef = useRef(); 

  const submitHandler = (e) => {
    e.preventDefault();
    props.onSearch(InputRef.current.value);
  };
  
  const resetHandler = () => {
    InputRef.current.value = '';
    props.onReset();
  }
  return (
    <form className={classes["search-container"]} onSubmit={submitHandler} ref={formRef}>
      <div className={classes["text-field"]}>
        <button type="submit">
          <SearchIcon
            sx={{
              backgroundColor: "#30BE75",
              color: "white",
              borderRadius: "4px",
            }}
          />
        </button>
        <input type="text" placeholder="Nhập từ khoá tìm kiếm" ref={InputRef}></input>
      </div>
      <div>
        <McpButtonV1 type='yellow' btnType='button' content='Huỷ' onClick={resetHandler} />
      </div>
    </form>
  );
};

export default SearchBar;
