import React, { useEffect, useState } from "react";
import AccordionBtnV2Sm from "../UI/accordion/AccordionBtnV2Sm";
import classes from "./XemDiemDanhGiaMonAnV1.module.css";
import SelectFieldV3 from "../form/template/SelectFieldV3";
import McpButtonV1 from "../UI/button/McpButtonV1";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import DishScoreTable from "./DishScoreTable";
import DOM from "react-dom";
import MainBackdropV3 from "../UI/backdrops/MainBackdropV3";
import ModalWrapperV4 from "../UI/modals/ModalWrapperV4";
import { formatOutlet } from "../../hooks/capitalize";
import Loader from "../UI/Loader";

const XemDiemDanhGiaMonAnV1 = () => {
  const [loadedOutlet, setLoadedOutlet] = useState([]);
  const [loadedGroup, setLoadedGroup] = useState([
    {
      value: "all",
      textContent: "Tất cả",
      key: 111,
    },
  ]);
  const [selectOutlet, setSelectOutlet] = useState();
  const [selectGroup, setSelectGroup] = useState("all");
  const [fetchData, setFetchData] = useState([]);
  const [isViewing, setIsViewing] = useState(false);
  const [fetchConclude, setFetchConclude] = useState(null);
  const [reviewDOM, setReviewDOM] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [nort, setNort] = useState(null);

  useEffect(() => {
    const fetchOutlet = async () => {
      const outletColRef = collection(db, "outlet");
      const snapshot = await getDocs(outletColRef);
      const data = [];
      snapshot.forEach((doc) => {
        const fetchData = doc.data();
        data.push({
          value: fetchData.outlet,
          textContent: fetchData.outlet,
          key: fetchData.id,
        });
      });
      const filterData = data.filter((item) => !item.value.includes("delete"));
      setLoadedOutlet(filterData);
    };

    const fetchGroup = async () => {
      const groupColRef = collection(db, "food_group");
      const snapshot = await getDocs(groupColRef);
      const data = [];
      snapshot.forEach((doc) => {
        const fetchData = doc.data();
        data.push({
          value: fetchData.foodGroup,
          textContent: fetchData.foodGroup,
          key: fetchData.id,
        });
      });

      setLoadedGroup((prev) => [...prev, ...data]);
    };

    fetchGroup();
    fetchOutlet();
  }, []);

  useEffect(() => {
    let dom;
    if (isViewing && isViewing.avgOverall) {
      dom = Object.entries(isViewing.avgOverall).map(([key, value]) => {
        return (
          <li>
            <span>{key + ": "} </span>
            <span className={classes.value}>{value}</span>
          </li>
        );
      });
    } else {
      dom = "chưa có";
    }
    setReviewDOM(dom);
  }, [isViewing]);

  const tableTitle = {
    col1: "STT",
    col2: "Thông tin",
    col3: "Công cụ",
  };

  const outletClickHandler = (value) => {
    setSelectOutlet(value);
  };

  const groupClickHandler = (value) => {
    setSelectGroup(value);
  };

  const nortTO = () => {
    setTimeout(() => setNort(null), 3500);
  };

  const getDbConclude = async () => {
    setIsLoading(true);
    const concludeRef = collection(db, "dish_review_conclude");
    // const formatOutlet = capitalizeFirstLetterV2(selectOutlet)
    //   .trim()
    //   .replace(" ", "_")
    //   .concat("_conclude");
    const fetchOutlet = formatOutlet(selectOutlet).concat("_conclude");
    const docRef = doc(concludeRef, fetchOutlet);
    const res = await getDoc(docRef);
    const data = res.data();
    setFetchConclude(data);
    setIsLoading(false);
    setNort("Đã truy vấn dử liệu thành công");
    nortTO();
  };

  const onFindHandler = async (e) => {
    e.preventDefault();
    clearTimeout(nortTO);
    setNort(null);
    setFetchData([]);
    const fetchCol = collection(db, "dish_review_overall");
    let q;
    if (selectGroup === "all") {
      q = query(fetchCol, where("outlet", "==", selectOutlet));
    } else {
      q = query(
        fetchCol,
        where("outlet", "==", selectOutlet),
        where("group", "==", selectGroup)
      );
    }

    const snapshot = await getDocs(q);
    const arr = [];
    snapshot.forEach((doc) => {
      arr.push(doc.data());
    });
    setFetchData(arr);

    getDbConclude();
  };

  const closeDetail = () => {
    setIsViewing(false);
  };

  let scoreStatTable;
  let rewardDetail;
  let totalRewardComment;

  const scoreDesConvert = (descripton) => {
    let formatDes;
    switch (descripton) {
      case "VERY GOOD DISHES":
        formatDes = "Từ 4 trở lên";
        break;
      case "PASS DISHES":
        formatDes = "Từ 3.3 đến 4";
        break;
      case "BAD DISHES":
        formatDes = "Từ 2.5 đến 3.3";
        break;
      case "VERY BAD DISHES":
        formatDes = "Từ 0 đến 2.5";
        break;
      case "NULL DISHES":
        formatDes = "Chưa có đánh giá";
        break;
      default:
        formatDes = "";
    }
    return formatDes;
  };

  if (fetchConclude) {
    const scoreStat = fetchConclude.scoreOverview;
    scoreStatTable = Object.keys(scoreStat).map((key) => {
      const data = scoreStat[key];
      const scoreBar = data.name.trim().toUpperCase();
      const displayBar = scoreDesConvert(scoreBar);
      return (
        <div className={classes.row}>
          <div>{displayBar}</div>
          <div>{data.count}</div>
          <div>{data.numPercent}</div>
        </div>
      );
    });

    rewardDetail = Object.keys(scoreStat).map((key) => {
      const data = scoreStat[key];
      const scoreBar = data.name.trim().toUpperCase();
      const displayBar = scoreDesConvert(scoreBar);
      const totalAmount = (data.reward * data.count).toLocaleString("vi-vn");
      return (
        <div className={classes.padL}>
          <p>{`${displayBar}: ${data.reward.toLocaleString("vi-VN")}/món x ${
            data.count
          } =  ${totalAmount}`}</p>
        </div>
      );
    });

    totalRewardComment = `${fetchConclude.tong_thuong.toLocaleString(
      "vi-VN"
    )} x (100% - ${
      fetchConclude.ty_le_giam_tru_thuong
    }%) = ${fetchConclude.thuong_thuc_dat.toLocaleString("vi-VN")}`;
  }

  let formDetail;
  if (fetchConclude) {
    formDetail = (
      <div className={classes.verdict}>
        <div className={classes.warning}>
          {`(Thông tin tổng quát không thể hiện cho từng nhóm mà chỉ thể hiện cho tất
          cả)`}
        </div>
        <div>
          <p>
            <span className={classes.label}>Ngày: </span>
            {new Date().toLocaleDateString("vi-VN")}
          </p>
          <p>
            <span className={classes.label}>Outlet: </span>
            {fetchConclude.outlet}
          </p>
          <p>
            <span className={classes.label}>Nhóm: </span>Tất cả
          </p>
          <p>
            <span className={classes.label}>Số lượng món: </span>
            {fetchConclude.totalNum}
          </p>
        </div>
        <div>
          <p>
            <span className={classes.label}>Thống kê điểm: </span>
          </p>
          <div className={classes["score-stat-table"]}>
            <div>
              <div className={`${classes.row} ${classes["row-label"]}`}>
                <div>Thang điểm</div>
                <div>Số lượng món</div>
                <div>%</div>
              </div>
              {scoreStatTable}
            </div>
          </div>
        </div>
        <div>
          <p>
            <span className={classes.label}>Kết luận: </span>
          </p>
          <div className={classes.padL}>
            <p>{fetchConclude.ket_luan.nhan_xet.chung}</p>
            <p className={classes.important}>
              {fetchConclude.ket_luan.nhan_xet.cu_the}
            </p>
          </div>
        </div>
        <div>
          <p>
            <span className={classes.label}>Tổng thưởng: </span>
            {fetchConclude.tong_thuong.toLocaleString("vi-VN")}
          </p>
          {rewardDetail}
        </div>
        <div>
          <p>
            <span className={classes.label}>Thưởng thực đạt: </span>{" "}
            {totalRewardComment}
          </p>
        </div>
        <div>
          <p>
            <span className={classes.label}>Mức giảm trưởng bộ phận: </span>{" "}
            {typeof fetchConclude.muc_giam_tru_truong_bo_phan === "string"
              ? fetchConclude.muc_giam_tru_truong_bo_phan
              : fetchConclude.muc_giam_tru_truong_bo_phan * 100 + "%"}
          </p>
        </div>
      </div>
    );
  } else {
    formDetail = <p className={classes.center}>Hiện tại chưa có thông tin!</p>;
  }

  const onViewHandler = (item) => {
    setIsViewing(item);
  };

  const modalContent = (
    <ModalWrapperV4>
      <div className={classes["list-row"]}>
        <p>
          <span className={classes.label}>Tên món: </span>
          {isViewing.dishName}
        </p>
      </div>
      <div className={classes["list-row"]}>
        <p>
          <span className={classes.label}>Nhóm: </span>
          {isViewing.group}
        </p>
      </div>
      <div className={classes["list-row"]}>
        <p>
          <span className={`${classes.label} ${classes.blue}`}>
            Điểm cao nhất:{" "}
          </span>
          {isViewing.diem_cao_nhat ? isViewing.diem_cao_nhat : "chưa có"}
        </p>
      </div>
      <div className={classes["list-row"]}>
        <p>
          <span className={classes.label}>Số lần thử món: </span>
          {isViewing.so_lan_thu_mon ? isViewing.so_lan_thu_mon : "chưa có"}
        </p>
      </div>
      <div className={classes["list-row"]}>
        <div>
          <span className={classes.label}>Thông tin thử món:</span>
        </div>
        <ul>{reviewDOM}</ul>
      </div>
      <div className={classes["list-row"]}>
        <p>
          <span className={classes.label}>Outlet: </span>
          {isViewing.outlet}
        </p>
      </div>
      <div className={classes["list-row"]}>
        <p>
          <span className={classes.label}>ID: </span>
          {isViewing.dishDocUID}
        </p>
      </div>
      <div className={classes["list-row"]}>
        <p>
          <span className={classes.label}>Ngày thử món đầu tiên: </span>
          {!isViewing.ngay_thu_mon_dau_tien
            ? "Chưa thử món"
            : isViewing.ngay_thu_mon_dau_tien}
        </p>
      </div>

      <div className={classes["modal-nav"]}>
        <button onClick={closeDetail} className={classes["close-modal"]}>
          Đóng
        </button>
      </div>
    </ModalWrapperV4>
  );

  return (
    <div className={classes.wrapper}>
      <AccordionBtnV2Sm onClose={false} title="Lựa chọn tìm kiếm">
        <div className={classes["search-wrapper"]}>
          <form onSubmit={onFindHandler}>
            <SelectFieldV3
              label="Chọn outlet"
              id="outlet-select"
              defaultOption="Banquet"
              defaultText="Banquet"
              optionsList={loadedOutlet}
              onClick={outletClickHandler}
            />
            <SelectFieldV3
              label="Chọn nhóm món ăn"
              id="group-select"
              optionsList={loadedGroup}
              defaultOption="all"
              defaultText="Tất cả"
              onClick={groupClickHandler}
            />
            <div className={classes["btn-nav"]}>
              <McpButtonV1 content="Tìm" type="green" btnType="submit" />
            </div>
          </form>
          <div className={classes["nort-bar"]}>
            {isLoading && <Loader />}
            {nort && <p>{nort}</p>}
          </div>
        </div>
      </AccordionBtnV2Sm>
      <AccordionBtnV2Sm onClose="false" title="Thông tin tổng quát">
        {formDetail}
      </AccordionBtnV2Sm>
      <AccordionBtnV2Sm onClose="false" title="Thông tin chi tiết">
        <div>
          <DishScoreTable
            title={tableTitle}
            data={fetchData}
            onClick={onViewHandler}
          />
        </div>
      </AccordionBtnV2Sm>
      {isViewing &&
        DOM.createPortal(
          <MainBackdropV3 onClick={closeDetail} />,
          document.getElementById("main-backdrop")
        )}
      {isViewing &&
        DOM.createPortal(modalContent, document.getElementById("main-overlay"))}
    </div>
  );
};

export default XemDiemDanhGiaMonAnV1;
