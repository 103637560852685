import IsBuiding from "../404&others/IsBuiding";
import Page404 from "../404&others/404";
import KitDanhSachOutlet from "./KitDanhSachOutlet";
import KitNhomMonAn from "./KitNhomMonAn";
import KitMonAn2 from "./KitMonAn2";
import KitDeXuatDanhGia from "./KitDeXuatDanhGia";
import HinhAnhMonAn from "../../components/hinh_anh_mon_an/HinhAnhMonAn";
import DuLieuMonAn from "../../components/du_lieu_mon_an/DuLieuMonAn";
import DeXuatDanhGiaTrangTri from "../../components/danh_gia_trang_tri_v2/DeXuatDanhGiaTrangTri";

import XemDiemDanhGiaMonAnV1 from "../../components/xem_diem_danh_gia_mon_an/XemDiemDanhGiaMonAnV1";

const KitSiteMap = [
  {
    name: "Danh sách outlet",
    path: "danh sach outlet",
    element: <KitDanhSachOutlet />,
  },
  {
    name: "Nhóm món ăn",
    path: "nhom mon an",
    element: <KitNhomMonAn />,
  },
  {
    name: "Món ăn",
    path: "mon an",
    element: <KitMonAn2 />,
  },
  {
    name: "Đề xuất thử món",
    path: "de xuat thu mon",
    element: <KitDeXuatDanhGia />,
  },
  {
    name: "Dữ liệu món ăn",
    path: "du lieu mon an",
    element: <DuLieuMonAn />,
  },
  {
    name: "Hình ảnh món ăn",
    path: "hinh anh mon an",
    element: <HinhAnhMonAn />,
  },
  {
    name: "Đề xuất đánh giá trang trí",
    path: "de xuat danh gia trang tri",
    element: <DeXuatDanhGiaTrangTri />,
  },
  {
    name: "Điểm đánh giá món ăn",
    path: "diem danh gia mon an",
    element: <XemDiemDanhGiaMonAnV1 />,
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export default KitSiteMap;
