import React, { useEffect, useState, useRef } from "react";
import classes from "./CreateConclude.module.css";
import { getDocs, collection } from "firebase/firestore";
import SelectFieldV3 from "../form/template/SelectFieldV3";
import { db, functions } from "../../firebase/firebase";
import { httpsCallable } from "firebase/functions";
import Loader from "../UI/Loader";
import { capitalizeEveryFirstLetter } from "../../hooks/capitalize";

const CreateConclude = () => {
  const [dbOutlet, setDbOutlet] = useState([]);
  const [selectOutlet, setSelectOutlet] = useState();
  const [isSubmiting, setIsSubmiting] = useState();
  const [nort, setNort] = useState();

  const inputRef = useRef();

  useEffect(() => {
    const fetchOutlet = async () => {
      const colRef = collection(db, "outlet");
      let outletArr = [];
      const snapshot = await getDocs(colRef);
      snapshot.forEach((item) => {
        const data = item.data();
        outletArr.push({
          value: data.outlet,
          textContent: data.outlet,
          disabled: false,
          key: data.id,
        });
      });
      return outletArr;
    };
    fetchOutlet().then((res) => {
      setDbOutlet(res);
    });
  }, []);

  const submitHandler = (event) => {
    setNort();
    setIsSubmiting(true);
    event.preventDefault();
    console.log(inputRef.current.value, selectOutlet);

    const inputArr = inputRef.current.value;
    let excludeArr = [];
    if (inputArr) {
      excludeArr = inputRef.current.value
        .split(",")
        .map((sstr) => capitalizeEveryFirstLetter(sstr.trim().toLowerCase()));
      console.log(excludeArr);
    }

    if (excludeArr && selectOutlet) {
      const createCall = httpsCallable(functions, "callCreateDishOverallObj");
      createCall({ outlet: selectOutlet, excludeArr: [] })
        .then((result) => {
          console.log(result.data);
          setNort(result.data.message);
          setIsSubmiting(false);
        })
        .catch((error) => {
          console.log(error.code, error.message, error.details);
          setNort(error.message);
          setIsSubmiting(false);
        });
    }
    inputRef.current.value = "";
  };

  console.log(selectOutlet);

  let mainContent;
  if (dbOutlet.length === 0) {
    mainContent = (
      <div className={`${classes["flex-center"]} ${classes["flex-col"]}`}>
        <Loader />
        <p>Đang tải dữ liệu, vui lòng chờ!!</p>
      </div>
    );
  } else {
    mainContent = (
      <form onSubmit={submitHandler}>
        <SelectFieldV3
          id="select-outlet"
          label="Chọn outlet"
          optionsList={dbOutlet}
          onClick={setSelectOutlet}
          defaultOption={dbOutlet[0].value}
          defaultText={dbOutlet[0].textContent}
        />
        <div className={`${classes["flex-col"]} ${classes.input}`}>
          <label>Thêm trường loại trừ (nếu cần)</label>
          {/* <input
            placeholder="Ví dụ: nhóm thử nghiệm, nhóm test"
            ref={inputRef}
          /> */}
          <textarea
            placeholder="Ví dụ: nhóm thử nghiệm, nhóm test"
            ref={inputRef}
          />
        </div>
        <div className={classes.btn}>
          {!isSubmiting && <button type="submit">Tạo</button>}
          {isSubmiting && <Loader />}
        </div>
        <div
          className={`${classes["flex-center"]} ${classes["flex-col"]} ${classes.nort}`}
        >
          <p>{nort}</p>
        </div>
      </form>
    );
  }

  return <div className={classes.container}>{mainContent}</div>;
};

export default CreateConclude;
