import { useEffect } from "react";
import { useState } from "react";
import { db } from "../../firebase/firebase";
import { collection } from "firebase/firestore";
import { getDbs1 } from "../../hooks/fetchFirebase";
import classes from "./TrangTriRateBar.module.css";

const TrangTriRateBar = (props) => {
  const imgUID = props.imgUID;
  const dishId = props.dishId;

  const [rating, setRating] = useState("Đang tải điểm");

  useEffect(() => {
    let totalScore = 0;
    let avgScore;

    const ratingCol = collection(
      db,
      "dish_list",
      dishId,
      "images",
      imgUID,
      "decor-rating"
    );
    getDbs1(ratingCol).then((res) => {
      if (res.length === 0) {
        setRating("Chưa có đánh giá");
      } else if (res.length > 0) {
        res.forEach((item) => {
          totalScore += item.data.score;
        });
        avgScore = Math.round((totalScore / res.length) * 100) / 100;
        setRating(avgScore);
      }
    });
  }, []);

  let returnContent;
  if (typeof rating === "number") {
    let classContent;
    if (rating < 2) {
      classContent = "red";
    } else if (rating < 3) {
      classContent = "yellow";
    } else if (rating < 3.6) {
      classContent = "blue";
    } else {
      classContent = "green";
    }

    returnContent = (
      <p>
        Điểm đánh giá:{" "}
        <span className={classes[`${classContent}`]}>{rating}</span>
      </p>
    );
  } else {
    returnContent = <p>{rating}</p>;
  }

  return <div className={classes["rating-bar"]}>{returnContent}</div>;
};

export default TrangTriRateBar;
