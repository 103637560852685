import React, { useState } from "react";
import { NavLink, Link, Navigate } from "react-router-dom";
import Chevron from "../UI/Chevron";
import classes from "./MobileSubmenu2.module.css";

const MobileSubmenu2 = (props) => {
  const [isExpanding, setIsExpanding] = useState(false);

  const onClickHandler = () => {
    setIsExpanding((prev) => !prev);
  };

  return (
    <li className={classes.item}>
      <div className={classes["item-name"]}>
        <div>
          <Link className={classes.listmenu} to={props.data.path}>
            <div className={classes.wrapper}>
              <span className={classes.title} onClick={props.onClick}>
                {props.data.icon}
                <span>{props.data.title}</span>
              </span>
              {/* <span>
              {props.data.submenu ? (
                <Chevron state={isExpanding} onClick={onClickHandler} />
              ) : null}
            </span> */}
            </div>
          </Link>
        </div>
        <div className={classes.chevron}>
          {props.data.submenu ? (
            <Chevron state={isExpanding} onClick={onClickHandler} />
          ) : null}
        </div>
      </div>

      <div>
        <ul
          className={
            isExpanding
              ? `${classes.submenu}`
              : `${classes.submenu} ${classes.hide}`
          }
        >
          {props.data.submenu
            ? props.data.submenu.map((child) => (
                <li key={child.subtitle}>
                  <Link to={child.subPath} onClick={() => props.onClick()}>
                    {child.subtitle}
                  </Link>
                </li>
              ))
            : ""}
        </ul>
      </div>
    </li>
  );
};

export default MobileSubmenu2;
