import { Fragment } from "react";
import classes from "./DanhGiaMonTong.module.css";
import { useEffect, useReducer } from "react";
import Loader from "../../UI/Loader";
import DanhGiaMonTable from "./DanhGiaMonTable";
import { useSelector } from "react-redux";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { useNavigate } from "react-router-dom";
import DanhGiaMonChiTiet from "./DanhGiaMonChiTiet";

const pageContentReducer = (state, action) => {
  switch (action.type) {
    case "NAV":
      return {
        ...state,
        displayContent: action.payload,
      };
    case "NAV_HOME":
      return {
        ...state,
        displayContent: (
          <DanhGiaMonTable
            data={state.requestData}
            onView={action.payload.onView}
          />
        ),
      };
    case "FETCH_REQUEST_DATA":
      return {
        ...state,
        displayContent: action.payload.tableContent,
        requestData: action.payload.requestData,
      };
    case "VIEW":
      return {
        ...state,
        displayContent: (
          <DanhGiaMonChiTiet
            data={state.requestData.filter(
              (item) => item.docID === action.payload.dataId
            )}
            onReturn={action.payload.onReturn}
          />
        ),
      };
    default:
      return {
        displayContent: null,
        requestData: [],
        requestTableContent: <Loader />,
      };
  }
};

const pageInitialContent = {
  displayContent: null,
  requestData: [],
  requestTableContent: <Loader />,
};

const DanhGiaMonTong = () => {
  const [pageContent, dispatchPageContent] = useReducer(
    pageContentReducer,
    pageInitialContent
  );

  const user = useSelector((state) => state.auth.userCredential);

  const navigate = useNavigate();

  //function to view detail
  const viewDetailHandler = (value) => {
    // dispatchPageContent({
    //   type: "VIEW",
    //   payload: {
    //     dataId: value,
    //     onReturn: backToHome,
    //   },
    // });
    navigate(value);
  };

  //fetch review request data and generate table content based on review request data
  useEffect(() => {
    //init loading state
    dispatchPageContent({
      type: "NAV",
      payload: <Loader />,
    });

    //fetching db data
    const reviewRequestRef = collection(
      db,
      "employee_users",
      user.uid,
      "dish_review_request"
    );

    onSnapshot(reviewRequestRef, (snapshot) => {
      if (!snapshot.empty) {
        let requestArr = [];

        snapshot.forEach((item) =>
          requestArr.push({
            docID: item.id,
            data: item.data(),
          })
        );

        const dupArr = requestArr.slice();
        const formatArr = dupArr.sort((a, b) => {
          const aDate = Date.parse(a.data.requestDate);
          const bDate = Date.parse(b.data.requestDate);
          console.log(aDate, typeof(aDate));
          if(bDate > aDate) {
            return 1;
          } 
          if(aDate > bDate) {
            return -1;
          }
        });

        dispatchPageContent({
          type: "FETCH_REQUEST_DATA",
          payload: {
            // requestData: requestArr,
            requestData: formatArr,
            tableContent: (
              // <DanhGiaMonTable data={requestArr} onView={viewDetailHandler} />
              <DanhGiaMonTable data={formatArr} onView={viewDetailHandler} />
            ),
          },
        });
      } else {
        dispatchPageContent({
          type: "FETCH_REQUEST_DATA",
          payload: {
            requestData: [],
            tableContent: (
              <p>Hiện tại không có yêu cầu đánh giá món ăn nào!!</p>
            ),
          },
        });
      }
    });
  }, []);

  console.log(pageContent.requestData);
  return (
    <Fragment>
      <div className={classes.table}>{pageContent.displayContent}</div>
    </Fragment>
  );
};

export default DanhGiaMonTong;
