import { Fragment, useEffect, useState } from "react";
import classes from "./UserXemDeXuatDetail2.module.css";
import { useSelector } from "react-redux";
import {
  collection,
  serverTimestamp,
  doc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { createDocV2, getDbs1, updateDbDoc } from "../../hooks/fetchFirebase";
import McpButtonV1 from "../UI/button/McpButtonV1";
import NortPTag from "../UI/systemNor/NortPTag";
import { useNavigate } from "react-router-dom";
import Loader from "../UI/Loader";
import AccordionBtnV2Sm from "../UI/accordion/AccordionBtnV2Sm";
import GalleryGridRview from "./GalleryGridReview";
import GalleryGridReviewDisable from "./GalleryGridReviewDisable";

/**
 * props list: {
 *  requestId
 * }
 */

const UserXemDeXuatDetail2 = (props) => {
  const user = useSelector((state) => state.auth.userCredential);
  const navigate = useNavigate();

  const [formNort, setFormNort] = useState({});
  const [requestData, setRequestData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [submitArr, setSubmitArr] = useState([]);
  const [isViewingReview, setIsViewingReview] = useState(false);
  const [dbImgArr, setDbImgArr] = useState([]);
  const [isLoadingPreviewImg, setIsLoadingPreviewImg] = useState(false);

  //fetch data from db
  useEffect(() => {
    const requestDbRef = doc(
      db,
      "employee_users",
      user.uid,
      "decor_review_request",
      props.requestID
    );

    onSnapshot(requestDbRef, (snapshot) => {
      const data = snapshot.data();

      setIsLoading(true);

      setRequestData(data);

      //create init submit arr
      const initArr = data.dishes.map((item) => {
        return {
          dish: item.dish,
          img: item.img.map((img) => {
            return {
              imgData: img.imgData,
              imgUID: img.imgUID,
              score: null,
            };
          }),
        };
      });
      setSubmitArr(initArr);

      setTimeout(() => {
        setIsLoading(false);
        setRequestData(data);
      }, 1000);
    });
  }, []);

  //submit
  const submitHandler = () => {
    setFormNort();

    //validate submit arr - avoid no rating
    let check = true;
    submitArr.forEach((dish) => {
      dish.img.forEach((img) => {
        let scoreCheck;
        img.score === null ? (scoreCheck = false) : (scoreCheck = true);
        check = check && scoreCheck;
      });
    });
    if (check) {
      //submit rating
      submitArr.forEach((dish) => {
        dish.img.forEach((img) => {
          let submitObject;
          submitObject = {
            name: user.fullName,
            email: user.email,
            requestId: requestData.id,
            score: img.score,
            timeCreated: serverTimestamp(),
            raterUID: user.uid,
            department: user.department,
            position: user.position,
          };
          const imgDbRef = collection(
            db,
            "dish_list",
            dish.dish.id,
            "images",
            img.imgUID,
            "decor-rating"
          );
          createDocV2(imgDbRef, submitObject).then((res) => {
            if (res.code === 0) {
              setFormNort(res);
              //update request doc status for individual user
              const requestRef = doc(
                db,
                "employee_users",
                user.uid,
                "decor_review_request",
                requestData.id
              );
              updateDbDoc(requestRef, {
                status: "đã đánh giá",
                timeSubmit: serverTimestamp(),
              }).then((res) => {
                if (res.code !== 0) {
                  setFormNort({
                    code: 1,
                    message: res.message,
                  });
                }
              });
            } else {
              setFormNort({
                code: 1,
                message: res.message,
              });
            }
          });
        });
      });
    } else {
      setFormNort({
        code: 1,
        message: "Bạn chưa đánh giá đầy đủ.",
      });
    }
  };

  // return
  const returnHandler = () => {
    navigate("/chuc-nang-chung/danh-gia/danh-gia-trang-tri");
  };

  // nort content
  let nortContent;
  if (formNort && formNort.code === 1) {
    nortContent = (
      <div className={classes["nort-wrapper"]}>
        <NortPTag type="error">{formNort.message}</NortPTag>
      </div>
    );
  }

  //page content
  const btnBarContent = (
    <div className={classes["btn-bar"]}>
      {requestData.status === "khởi tạo" && (
        <div>
          <McpButtonV1
            type="green"
            btnType="button"
            content="Gửi đánh giá"
            onClick={submitHandler}
          />
        </div>
      )}

      <div>
        <McpButtonV1
          type="blue"
          btnType="button"
          content="Quay về danh sách"
          onClick={returnHandler}
        />
      </div>
    </div>
  );

  //ratingHandler
  const rateHandler = (dishId, img, score) => {
    setSubmitArr((prev) =>
      prev.filter((item) => {
        if (item.dish.id === dishId) {
          item.img.map((loopImg) => {
            if (loopImg.imgUID === img.imgUID) {
              loopImg.score = score;
            }
            return loopImg;
          });
        }
        return item;
      })
    );
  };

  //view didReview function
  const viewDidReview = () => {
    setIsLoadingPreviewImg(true);
    setIsViewingReview(true);

    submitArr.forEach((item) => {
      item.img.forEach((img, index) => {
        const colRef = collection(
          db,
          "dish_list",
          item.dish.id,
          "images",
          img.imgUID,
          "decor-rating"
        );

        const queryDb = query(
          colRef,
          where("email", "==", user.email),
          where("requestId", "==", requestData.id)
        );

        getDbs1(queryDb).then((res) => {
          let newImg = img;
          const fetchScore = res[res.length - 1].data.score;
          newImg.score = fetchScore;
          setDbImgArr((prev) => [...prev, newImg]);
          if (index === res.length - 1) {
            setIsLoadingPreviewImg(false);
          }
        });
      });
    });

    setTimeout(() => {
      setIsLoadingPreviewImg(false);
    }, [7000]);
  };

  let mainContent;
  if (!isLoading) {
    if (requestData.status === "khởi tạo") {
      mainContent = (
        <div>
          <p className={classes.title}>Đánh giá món ăn</p>
          {submitArr.length > 0 &&
            submitArr.map((dish) => (
              <AccordionBtnV2Sm
                key={dish.dish.id}
                title={dish.dish.name}
                onClose={true}
              >
                <GalleryGridRview
                  data={dish.img}
                  onChange={rateHandler}
                  dishId={dish.dish.id}
                />
              </AccordionBtnV2Sm>
            ))}
        </div>
      );
    } else if (requestData.status === "đã đánh giá") {
      mainContent = (
        <div className={classes["nort-wrapper"]}>
          <div>
            <NortPTag type="success">Bạn đã gửi đánh giá món ăn này!!</NortPTag>
          </div>
          {!isViewingReview && (
            <div>
              <McpButtonV1
                type="blue"
                btnType="button"
                content="Xem lại đánh giá"
                onClick={viewDidReview}
              />
            </div>
          )}
        </div>
      );
    } else {
      mainContent = (
        <div className={classes["nort-wrapper"]}>
          <NortPTag type="error">Đã phát sinh lỗi dữ liệu!!</NortPTag>
        </div>
      );
    }
  }

  let didReviewContent;
  if (isViewingReview) {
    if (isLoadingPreviewImg) {
      didReviewContent = (
        <div>
          <Loader />
        </div>
      );
    } else {
      didReviewContent = (
        <div>
          {requestData.timeSubmit && (
            <p
              className={classes["review-status"]}
            >{`Bạn đã gửi đánh giá món ăn vào ${requestData.timeSubmit
              .toDate()
              .toLocaleDateString("vi-VI")}`}</p>
          )}
          {submitArr.length > 0 &&
            submitArr.map((dish) => {
              let arrForGallery;
              dish.img.forEach((dish) => {
                const filterArr = dbImgArr.filter(
                  (item) => item.imgUID === dish.imgUID
                );
                arrForGallery = filterArr;
              });
              return (
                <AccordionBtnV2Sm
                  key={dish.dish.id}
                  title={dish.dish.name}
                  onClose={true}
                >
                  <GalleryGridReviewDisable
                    // data={dbImgArr}
                    data={arrForGallery}
                    dishId={dish.dish.id}
                  />
                </AccordionBtnV2Sm>
              );
            })}
        </div>
      );
    }
  }

  //timeout to clear nort
  setTimeout(() => {
    setFormNort();
  }, [5000]);

  return (
    <div className={classes.container}>
      {/* {isLoading && <Loader />} */}
      {!isLoading && (
        <Fragment>
          <div className={classes.info}>
            <p className={classes.title}>Thông Tin</p>
            <p>
              Tên / thông tin:
              <span>{" " + requestData.reason}</span>
            </p>
            <p>
              Đề xuất ID:
              <span>{" " + requestData.id}</span>
            </p>
            <p>
              Tình trạng:
              <span>{" " + requestData.status}</span>
            </p>
            <p>
              Ngày tạo đề xuất:
              <span>
                {" " +
                  requestData.timeCreated.toDate().toLocaleDateString("vi-VI")}
              </span>
            </p>
          </div>
          {btnBarContent}
          {nortContent}
          {mainContent}
          {didReviewContent}
        </Fragment>
      )}
    </div>
  );
};

export default UserXemDeXuatDetail2;
