import { useEffect, useState } from "react";
import classes from "./DanhGiaMonChiTiet.module.css";
import FlexTable3Col2 from "../../table/FlexTable3Col2";
import RateBtn from "./RateBtn";
import { useSelector } from "react-redux";
import { collection, serverTimestamp, doc } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import { createDocV2, updateDbDoc } from "../../../hooks/fetchFirebase";
import McpButtonV1 from "../../UI/button/McpButtonV1";
import NortPTag from "../../UI/systemNor/NortPTag";
import ScoreComp from "./ScoreComp";

const DanhGiaMonChiTiet = (props) => {
  const user = useSelector((state) => state.auth.userCredential);

  const [dishScoreArr, setDishScoreArr] = useState([]);
  const [formNort, setFormNort] = useState({});

  const tableTitle = {
    col1: "STT",
    col2: "Thông tin",
    col3: "Đánh giá",
  };

  let request;
  let dishData;

  if (props.data.length > 0) {
    request = props.data[0];
    console.log(request);
    dishData = request.data.dishes.map((item) => {
      return {
        key: item.id,
        info: [item.name, item.group],
        score: 0,
      };
    });
  }

  useEffect(() => {
    setDishScoreArr(dishData);
  }, []);

  //retrieve dishscore from rate comp
  const fetchScore = (id, score) => {
    const workingArr = dishScoreArr;

    const editArr = workingArr.map((item) => {
      if (item.key === id) {
        item.score = score;
      }
      return item;
    });
    console.log(editArr);
    setDishScoreArr(editArr);
  };

  //add on component
  const rateComp = (id) => {
    return <RateBtn id={id} onFetch={fetchScore} />;
  };

  const scoreComp = (dishID) => {
    // const docID = user.uid;
    // const docDbRef = collection(db, "dish_list", dishID, "dish_review");
    // const dbQuery = query(
    //   docDbRef,
    //   where("raterID", "==", docID),
    //   orderBy("timeCreated", "desc")
    // );
    // getDocs(dbQuery, (snapshot) => {
    //     console.log('getting data')
    //   if (!snapshot.empty) {
    //     let arr = [];
    //     snapshot.forEach((item) => arr.push(item.data()));
    //     console.log(arr);
    //   }
    // });

    return <ScoreComp dishID={dishID} userID={user.uid} />;
  };

  //submit
  const submitHandler = () => {
    setFormNort();
    console.log(dishScoreArr);
    let scoreIsValid = true;
    //check submit validation
    dishScoreArr.forEach((item) => {
      const formatScore = parseInt(item.score);
      scoreIsValid = (scoreIsValid && formatScore >= 0) || formatScore < 6;
    });

    if (scoreIsValid) {
      dishScoreArr.forEach((item) => {
        const formatScore = parseInt(item.score);
        const dishRef = collection(db, "dish_list", item.key, "dish_review");
        const submitData = {
          rater: user.fullName,
          raterID: user.uid,
          raterEmail: user.email,
          raterDepartment: user.department,
          score: formatScore,
          timeCreated: serverTimestamp(),
        };
        createDocV2(dishRef, submitData).then((res) => {
          //udpate review status in request
          const requestDbRef = doc(
            db,
            "employee_users",
            user.uid,
            "dish_review_request",
            request.docID
          );
          updateDbDoc(requestDbRef, {
            reviewStatus: "đã đánh giá",
          }).then((res) => {
            if (res.code === 0) {
              setFormNort("Đã gửi đánh giá thành công");
              setTimeout(() => {
                setFormNort();
              }, 4000);
            } else {
              setFormNort(res.message);
              setTimeout(() => {
                setFormNort();
              }, 4000);
            }
          });
        });
      });
    } else {
      setFormNort({
        code: 1,
        message: "Các món ăn chưa được đánh giá đầy đủ",
      });
      setTimeout(() => {
        setFormNort();
      }, 4000);
      return;
    }
  };

  //return
  const returnHandler = () => {
    setDishScoreArr([]);
    props.onReturn();
  };

  //nort content
  let nortContent;
  if (formNort && formNort.code === 0) {
    nortContent = (
      <NortPTag type="success">Đã gửi đánh giá thành công</NortPTag>
    );
  } else if (formNort && formNort.code === 1) {
    nortContent = <NortPTag type="error">{formNort.message}</NortPTag>;
  }

  //page content
  let statusContent;
  let tableContent;
  let buttonBarContent;
  if (request.data.approveStatus === "chưa duyệt") {
    statusContent = (
      <span className={classes.no}>{request.data.approveStatus}</span>
    );
    tableContent = null;
  } else if (request.data.approveStatus === "đã duyệt") {
    statusContent = (
      <span className={classes.yes}>{request.data.approveStatus}</span>
    );
    if (request.data.reviewStatus === "chưa đánh giá") {
      tableContent = (
        <div>
          <p className={classes.title}>Đánh Giá </p>
          <FlexTable3Col2
            title={tableTitle}
            data={dishData}
            addOnComp={rateComp}
          />
        </div>
      );

      buttonBarContent = (
        <div className={classes["btn-bar"]}>
          <NortPTag type="warning">
            Bạn nên suy nghĩ kỹ trước khi đánh giá vì sau bạn sẽ không được
            chỉnh sửa sau khi xác nhận
          </NortPTag>
          {nortContent}
          <McpButtonV1
            type="green"
            onClick={submitHandler}
            content="Xác nhận"
            btnType="button"
          />
          <McpButtonV1
            type="grey"
            onClick={returnHandler}
            content="Quay về danh sách"
            btnType="button"
          />
        </div>
      );
    } else if (request.data.reviewStatus === "đã đánh giá") {
      tableContent = (
        <div>
          <p className={classes.title}>Thông tin bạn đã đánh giá: </p>
          <FlexTable3Col2
            title={tableTitle}
            data={dishData}
            addOnComp={scoreComp}
          />
        </div>
      );
      buttonBarContent = (
        <div className={classes["btn-bar"]}>
          {nortContent}

          <McpButtonV1
            type="grey"
            onClick={returnHandler}
            content="Quay về danh sách"
            btnType="button"
          />
        </div>
      );
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.info}>
        <p className={classes.title}>Thông Tin</p>
        <p>
          <span>Tên đề xuất: </span>
          {request.data.requestName}
        </p>
        <p>
          <span>Ngày diễn ra: </span>
          {request.data.requestDate}
        </p>
        <p>
          <span>Giờ ước tính: </span>
          {request.data.requestTime}
        </p>
        <p>
          <span>Đề xuất ID: </span>
          {request.data.requestId}
        </p>
        <p className={classes.status}>
          <span>Tình trạng đề xuất: </span>
          {statusContent}
        </p>
      </div>
      {/* <div>
        <p className={classes.title}>Đánh Giá </p>
        <FlexTable3Col2
          title={tableTitle}
          data={dishData}
          addOnComp={rateComp}
        />
      </div>
      <div className={classes["btn-bar"]}>
        {nortContent}
        <McpButtonV1
          type="green"
          onClick={submitHandler}
          content="Xác nhận"
          btnType="button"
        />
        <McpButtonV1
          type="grey"
          onClick={returnHandler}
          content="Quay về danh sách"
          btnType="button"
        />
      </div> */}
      {tableContent}
      {buttonBarContent}
    </div>
  );
};

export default DanhGiaMonChiTiet;
