import React, { useEffect, useRef, useState } from "react";
import classes from "./HrFormV2.module.css";
import { db } from "../../../firebase/firebase";
import {
  collection,
  serverTimestamp,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import FormTemplate1 from "../../form/template/FormTemplate1";
import SingleFieldV3 from "../../form/template/SingleFieldV3";
import SelectField from "../../form/template/SelectField";
import SelectFieldV3 from "../../form/template/SelectFieldV3";
import { createDocV2 } from "../../../hooks/fetchFirebase";
import Loader from "../../UI/Loader";

const HrFormV2 = (props) => {
  const [hasError, setHasError] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dbPosition, setDbPosition] = useState([
    {
      value: "init",
      textContent: "Chọn chức vụ",
      disable: true,
      defaultFunction: null,
      key: "init",
    },
  ]);
  const [dbDepartment, setDbDepartment] = useState([
    {
      value: "init",
      textContent: "Chọn bộ phận",
      disable: true,
      defaultFunction: null,
      key: "init",
    },
  ]);
  const [inputPosition, setInputPosition] = useState();
  const [inputDepartment, setInputDepartment] = useState();
  const [inputGender, setInputGender] = useState();
  const [isSuccess, setIsSuccess] = useState(false);
  const [inputRole, setInputRole] = useState();
  const genderOptions = [
    {
      value: "init",
      textContent: "Chọn giới tính",
      disable: true,
      defaultFunction: null,
    },
    {
      value: "Nam",
      textContent: "Nam",
      disable: false,
      defaultFunction: null,
    },
    {
      value: "Nữ",
      textContent: "Nữ",
      disable: false,
      defaultFunction: null,
    },
  ];

  //Permmission states
  const [writePermit, setWritePermit] = useState(false);

  //retrieve auth info
  const userIsLogin = useSelector((state) => state.auth.userIsLoggin);
  const userEmail = useSelector((state) => state.auth.userCredential.email);
  const userRole = useSelector((state) => state.auth.userCredential.role);
  const userDepartment = useSelector(
    (state) => state.auth.userCredential.department
  );
  const userIsSuperAdmin = useSelector(
    (state) => state.auth.userCredential.isSuperAdmin
  );

  //refs for every fields
  const nameRef = useRef();
  const familyNameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const dobRef = useRef();
  const startRef = useRef();

  //authority definition
  useEffect(() => {
    if (userDepartment === "Nhân Sự") {
      setWritePermit(true);
    }

    if (userRole === "admin" || userIsSuperAdmin) {
      setWritePermit(true);
    }
  }, [userDepartment, userRole, userIsSuperAdmin]);

  useEffect(() => {
    const fetchDbPosition = onSnapshot(
      doc(db, "role", "position_role_definition"),
      (snapshot) => {
        const dbPositionData = snapshot.data();
        // console.log(dbPositionData);
        let dataArr = Object.keys(dbPositionData).map((key) => {
          // return dbPositionData[key].position;
          return {
            id: key,
            position: dbPositionData[key].position,
          };
        });

        let modifiedArray = [
          {
            value: "init",
            textContent: "Chọn chức vụ",
            disable: true,
            defaultFunction: null,
            key: "init",
          },
        ];
        dataArr.map((item) => {
          modifiedArray.push({
            value: item.position,
            textContent: item.position,
            disable: false,
            defaultFunction: null,
            key: item.id,
          });
          return item;
        });
        setDbPosition(modifiedArray);

        //get role relative to position
        let dataArr2 = Object.entries(dbPositionData);
        const roleResult = dataArr2.filter(
          (data) => data[1].position === inputPosition
        );
        roleResult.length > 0 && setInputRole(roleResult[0][1].role);
      }
    );

    const fetchDbDepartment = onSnapshot(
      collection(db, "department"),
      (snapshot) => {
        if (snapshot) {
          let dataArr = [];
          snapshot.forEach((doc) => {
            dataArr.push(doc.data());
          });

          let finalData = [
            {
              value: "init",
              textContent: "Chọn bộ phận",
              disable: true,
              defaultFunction: null,
            },
          ];

          dataArr.map((data) =>
            finalData.push({
              value: data.departmentName,
              textContent: data.departmentName,
              disable: false,
              defaultFunction: null,
            })
          );

          setDbDepartment(finalData);
        }
      }
    );

    if (!userIsLogin) {
      fetchDbPosition(); //detach firestore event listener
      fetchDbDepartment();
    }
  }, [userIsLogin, inputPosition]);

  // useEffect(() => {
  //   setTimeout(nortificationReset, 10000);
  // }, [nortificationReset]);

  //submit handler
  const submitHandler = (e) => {
    setHasError([]);
    setIsSuccess(false);
    e.preventDefault();
    setIsLoading(true);
    if (writePermit) {
      const emailInput = emailRef.current.value.toLowerCase();
      // let emailIsValid;
      // emailIsValid =
      //   emailInput.includes("@merperle.vn") ||
      //   emailInput.includes("@crystalpalacevn.com")
      //     ? true
      //     : false;

      let emailInputIsValid;
      emailInputIsValid =
        emailInput.includes("@merperle.vn") ||
        emailInput.includes("@crystalpalacevn.com")
          ? true
          : false;

      let emailNotInUsed;
      emailNotInUsed = !props.emailCheck(emailInput);

      const emailIsValid = emailInputIsValid && emailNotInUsed;

      //department validation
      let departmentIsValid;
      if (inputDepartment === "init") {
        departmentIsValid = false;
      } else {
        departmentIsValid = true;
      }

      //position validation
      let positionIsValid;
      if (inputPosition === "init") {
        positionIsValid = false;
      } else {
        positionIsValid = true;
      }

      //gender validation
      let genderIsValid;
      if (inputGender === "init") {
        genderIsValid = false;
      } else {
        genderIsValid = true;
      }

      if (
        emailIsValid &&
        departmentIsValid &&
        positionIsValid &&
        genderIsValid
      ) {
        let submitData = {};
        const enteredFamilyName = familyNameRef.current.value;
        const lastWord = enteredFamilyName.substring(
          enteredFamilyName.trim().lastIndexOf(" ") + 1
        );
        //generate id
        const initId =
          nameRef.current.value.charAt(0) +
          lastWord.charAt(0) +
          emailRef.current.value.charAt(0) +
          Math.floor(Math.random() * 100000).toString();
        const trimId = initId.trim();
        submitData = {
          name: nameRef.current.value,
          familyName: familyNameRef.current.value,
          // email: emailRef.current.value,
          email: emailInput,
          phone: phoneRef.current.value,
          dob: dobRef.current.value,
          startDate: startRef.current.value,
          position: inputPosition,
          role: inputRole,
          creator: userEmail,
          gender: inputGender,
          timeCreated: serverTimestamp(),
          // accActivated: false,
          id: trimId,
          department: inputDepartment,
          version: 1,
        };
        console.log(submitData);

        //check duplicate ID then submit
        const idCheck = props.idCheck(submitData.id);
        if (idCheck) {
          setHasError((prev) => [...prev, idCheck]);
        } else {
          const dbRef = collection(db, "employees");
          createDocV2(dbRef, submitData).then((response) => {
            if (response.code === 1) {
              setHasError((prev) => [...prev, response.message]);
            }
            if (response.code === 0) {
              //proceed to add to signup list
              const signupData = {
                id: submitData.id,
                email: submitData.email,
                timeCreated: serverTimestamp(),
              };
              //create user in signup_list
              const dbSignupRef = collection(db, "signup_list");
              createDocV2(dbSignupRef, signupData)
                .then(() => {
                  setIsSuccess(response.message);
                  setIsLoading(false);
                })
                .catch((err) => {
                  setHasError((prev) => [...prev, err.message]);
                  setIsLoading(false);
                });

              setTimeout(() => setIsSuccess(), 5000);
            }
          });
        }
      } else {
        if (!emailNotInUsed) {
          setHasError((prev) => [...prev, "Email này đã có người sử dụng"]);
        }
        if (!emailInputIsValid) {
          setHasError((prev) => [...prev, "Email không hợp lệ"]);
        }
        if (!departmentIsValid) {
          setHasError((prev) => [...prev, "Bạn chưa chọn bộ phận"]);
        }
        if (!positionIsValid) {
          setHasError((prev) => [...prev, "Bạn chưa chọn vị trí"]);
        }
        if (!genderIsValid) {
          setHasError((prev) => [...prev, "Bạn chưa chọn giới tính"]);
        }
        setIsLoading(false);

        // if (!emailInputIsValid) {
        //   setHasError("Email không hợp lệ");
        // } else if (!emailNotInUsed) {
        //   setHasError("Email này đã có người sử dụng");
        // } else {
        //   setHasError("Email không hợp lệ");
        // }
      }
    } else {
      setIsLoading(false);
      setHasError((prev) => [
        ...prev,
        "Bạn không đủ quyền hạn để thực hiện thao tác này",
      ]);
    }
  };

  //placeholder functions of select fields
  const positionFieldClickHanlder = (choice) => {
    setInputPosition(choice);
  };

  const departmentFieldClickHanlder = (choice) => {
    setInputDepartment(choice);
  };

  const genderFieldClickHanlder = (choice) => {
    setInputGender(choice);
  };

  const selectFieldSubClickHanlder = () => {
    console.log("chức năng này không hoạt động ở trang này");
  };

  return (
    <React.Fragment>
      <FormTemplate1 onSubmit={submitHandler}>
        <SingleFieldV3
          label="Tên"
          id="name"
          type="text"
          placeholder=""
          required={true}
          reference={nameRef}
        />
        <SingleFieldV3
          label="Họ và tên lót"
          id="familyName"
          type="text"
          placeholder="VD: Nguyễn Thị"
          required={true}
          reference={familyNameRef}
        />
        <SingleFieldV3
          label="Email - @merperle.vn / @crystalpalacevn.com"
          id="email"
          type="email"
          placeholder="phải là email công ty"
          required={true}
          reference={emailRef}
        />
        <SingleFieldV3
          label="Phone"
          id="phone"
          type="phone"
          placeholder=""
          required={true}
          reference={phoneRef}
        />
        <SingleFieldV3
          label="Ngày sinh"
          id="DOB"
          type="date"
          placeholder=""
          required={true}
          reference={dobRef}
        />
        <SingleFieldV3
          label="Ngày bắt đầu"
          id="startDate"
          type="date"
          placeholder=""
          required={true}
          reference={startRef}
        />
        <SelectField
          id="department"
          label="Bộ phận"
          onClick={departmentFieldClickHanlder}
          onClickSub={selectFieldSubClickHanlder}
          optionsList={dbDepartment}
        />
        <SelectFieldV3
          id="position"
          label="Chức vụ"
          onClick={positionFieldClickHanlder}
          onClickSub={selectFieldSubClickHanlder}
          optionsList={dbPosition}
          defaultOption="init"
        />
        <SelectField
          id="gender"
          label="Giới tính"
          onClick={genderFieldClickHanlder}
          onClickSub={selectFieldSubClickHanlder}
          optionsList={genderOptions}
        />

        {isLoading && <Loader />}

        {hasError &&
          hasError.map((error) => (
            <div className={classes.errMsg} key={error}>
              {error}
            </div>
          ))}

        {isSuccess && (
          <div className={classes["success-created"]}>{isSuccess}</div>
        )}
      </FormTemplate1>

      {/* <button onClick={testHanlder}>activated</button> */}
    </React.Fragment>
  );
};

export default HrFormV2;
