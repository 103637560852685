import { useState } from "react";
import classes from "./FlexTableFoodGroup.module.css";
import { useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { db } from "../../firebase/firebase";
import { deleteDbDoc } from "../../hooks/fetchFirebase";
import { doc } from "firebase/firestore";
import { SysNorRightFix } from "../../components/UI/systemNor/SysNorRightFix";
import MainBackdropV3 from "../../components/UI/backdrops/MainBackdropV3";
import AllowedFunctionBasic from "../../components/panel/AllowedFunctionBasic";
import EditFoodGroupModal from "./EditFoodGroupModal";

/*
  versions:
  0 - originated from FlexTable
  outlet - designed to have 4 cols - to retrive data from parent components. need to add on edit modal 

  props list:
    col1, 
    col2, 
    col3,
    data: {
      docID,
      data: {
        col1, 
        col2,
        col3
      }
    },
    allowType, 
    flex
*/

const FlexTableFoodGroup = (props) => {
  const [sysNort, setSysNort] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState();
  const user = useSelector((state) => state.auth.userCredential);

  const deleteDb = (docID) => {
    console.log("delete", docID);
    const docRef = doc(db, props.dbCollection, docID);
    deleteDbDoc(docRef).then((res) => {
      if (res.code === 0) {
        setSysNort((prev) => [
          ...prev,
          {
            type: "success",
            msg: res.message,
            key: Math.floor(Math.random() * 100),
          },
        ]);
      } else {
        setSysNort((prev) => [
          ...prev,
          {
            type: "error",
            msg: "res.message",
            key: Math.floor(Math.random() * 100),
          },
        ]);
      }
      setTimeout(() => {
        setSysNort([]);
      }, 5000);
    });
  };

  const editFn = (docID) => {
    setIsEditing(true);
    const editDoc = props.data.filter((item) => item.docID === docID);
    console.log(editDoc);
    setSelectedDoc(editDoc);
  };

  const closeModal = () => {
    setIsEditing(false);
  };

  //system nortification functions
  const nortRemove = (key) => {
    const newArr = sysNort.filter((item) => item.key !== key);
    console.log(newArr);
    setSysNort(newArr);
  };

  let sysNortContent;
  if (sysNort.length > 0) {
    sysNortContent = <SysNorRightFix data={sysNort} onClick={nortRemove} />;
  }

  return (
    <div className={classes.flexTable}>
      {sysNortContent}

      {isEditing &&
        ReactDOM.createPortal(
          <MainBackdropV3 onClick={closeModal} />,
          document.getElementById("main-backdrop")
        )}

      {isEditing &&
        ReactDOM.createPortal(
          <EditFoodGroupModal
            onCancel={closeModal}
            data={selectedDoc}
            user={user.email}
          />,
          document.getElementById("main-backdrop")
        )}

      <div className={classes.theader}>
        <div>{props.col1 && props.col1}</div>
        <div>{props.col2 && props.col2}</div>
        <div>{props.col3 && props.col3}</div>
        <div className={classes[`button-bar`]}>Công cụ</div>
      </div>
      {props.data.map((child) => (
        <div className={classes.tableRow} key={child.data.col1}>
          <div>{child.data.col1}</div>
          <div>{child.data.col2}</div>
          <div>{child.data.col3}</div>

          <div
            className={`${classes["button-row"]} ${classes[`${props.flex}`]}`}
          >
            <AllowedFunctionBasic
              type="basic"
              docID={child.docID}
              onDelete={deleteDb}
              onEdit={editFn}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default FlexTableFoodGroup;
