import XemDiemDanhGiaMonAnOffice from "../../components/xem_diem_danh_gia_mon_an/XemDiemDanhGiaMonAnOffice";

const BackOfficeSiteMap = [
  {
    name: "Thông tin đánh giá món ăn bếp",
    path: "thong-tin-danh-gia-mon-an-bep",
    element: <XemDiemDanhGiaMonAnOffice />,
  },
];

export default BackOfficeSiteMap;
