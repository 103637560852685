import React from "react";
import classes from "./HrIdResult.module.css";

const HrIdResult = (props) => {


    console.log(props.data); 
    const id = `ID: ${props.data.id}`;
    const email = `Email: ${props.data.email}`; 
    

  return (
    <div className={classes.wrapper}>
      <p>Thông tin này có phải của bạn không ?</p>
      <p>{id}</p>
      <p>{email}</p>
      <button onClick={props.onConfirm}>Xác nhận đúng thông tin</button>
    </div>
  );
};

export default HrIdResult;
