import React from "react";


const NortContext = React.createContext({
    nortContent: [], 
    onAdd: () => {}, 
    page: 'Trang hình ảnh món ăn'
}) 


export default NortContext