import classes from "./KetQuaHinhAnhOverview.module.css";
import { useState, useEffect, useContext } from "react";
import GalleryGridBasic from "../UI/gallery_grid/GalleryGridBasic";
import McpButtonV1 from "../UI/button/McpButtonV1";
import { db } from "../../firebase/firebase";
import { doc, serverTimestamp } from "firebase/firestore";
import { deleteDbDoc, updateDbDoc } from "../../hooks/fetchFirebase";
import { ref as FBSref, deleteObject, getStorage } from "firebase/storage";
import { useSelector } from "react-redux";
import Loader from "../UI/Loader";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import FnContext from "./FunctionContext";

/*
    versions control:
    _this version: purpose-resued mulitple times, combination of HinhAnhThamKhao and GalleryGrid
 */

/*
  props list: {
    data, 
    select, 
  }
 */

const KetQuaHinhAnhOverview = (props) => {
  const dishDocUID = props.data.docId;
  const [imgArr, setImgArr] = useState([]);
  const [selectingItems, setSelectingItems] = useState([]);
  const [actionType, setActionType] = useState("Xoá");
  const [submitStatus, setSubmitStatus] = useState({
    type: null,
    message: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [submitArr, setSubmitArr] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const user = useSelector((state) => state.auth.userCredential);
  const fnCtx = useContext(FnContext);

  let isAdmin;
  let toolOptions = [];

  if (user.role === "admin" || user.position === "Giám Đốc") {
    isAdmin = true;
    switch (props.select) {
      case "Đang áp dụng":
        toolOptions = ["Xoá", "Duyệt"];
        break;
      case "Đề xuất thay đổi":
        toolOptions = ["Xoá", "Duyệt"];
        break;
      case "Được duyệt":
        toolOptions = ["Xoá", "Bỏ duyệt"];
        break;
    }
  } else {
    isAdmin = false;
    switch (props.select) {
      case "Đang áp dụng":
        toolOptions = ["Xoá"];
        break;
      case "Đề xuất thay đổi":
        toolOptions = ["Xoá"];
        break;
      case "Được duyệt":
        toolOptions = [];
        break;
    }
  }

  //fetch images data for gallery
  useEffect(() => {
    setSelectingItems([]);
    const dishImgRef = collection(db, "dish_list", dishDocUID, "images");
    const categoryQuery = query(
      dishImgRef,
      where("category", "==", props.select)
    );
    const fetchRTImg = onSnapshot(categoryQuery, (snapshot) => {
      let imgArr = [];
      if (!snapshot.empty) {
        snapshot.forEach((item) =>
          imgArr.push({
            uid: item.id,
            data: item.data(),
          })
        );
      } else {
        // nortCtx.onAdd({
        //   key: Math.floor(Math.random() / 100000),
        //   type: "error",
        //   msg: "Lỗi dữ liệu cloud, vui lòng liên hệ kỹ thuật",
        // });
      }
      setImgArr(imgArr);
    });
  }, [props.select]);

  const onChangeHandler = (e) => {
    setActionType(e.target.value);
  };

  const checkItemFn = (itemValue) => {
    setSelectingItems((prev) => [...prev, itemValue]);
  };

  const uncheckItemFn = (itemValue) => {
    setSelectingItems((prev) =>
      prev.filter((item) => item.UID !== itemValue.UID)
    );
  };

  const submitCleanUp = (type, msg) => {
    setIsLoading(false);
    setSubmitStatus({
      type: `${type}`,
      message: `${msg}`,
    });
    setTimeout(() => {
      setSubmitStatus({
        type: null,
        message: null,
      });
      setSelectingItems([]);
      setIsSubmitting(false);
    }, [3500]);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const FBStorage = getStorage();
    setIsLoading(true);
    setIsSubmitting(true);
    const submitArr = selectingItems;
    setSubmitArr(selectingItems);
    // const imgDocRef = doc(db, "dish_list", dishDocUID, "images", item.UID);
    submitArr.forEach((item) => {
      const imgDocRef = doc(db, "dish_list", dishDocUID, "images", item.UID);
      switch (actionType) {
        case "Xoá":
          deleteDbDoc(imgDocRef).then((response) => {
            if (response.code === 0) {
              const imgRef = FBSref(FBStorage, `dishImg/${item.imgData.name}`);
              deleteObject(imgRef)
                .then(() => {
                  setIsLoading(false);
                  setSubmitStatus({
                    type: "ok",
                    message: "Đã xoá thành công",
                  });
                  fnCtx.countFn();
                  setTimeout(() => {
                    setSubmitStatus({
                      type: null,
                      message: null,
                    });
                    setSelectingItems([]);
                  }, [3500]);
                })
                .catch((error) => {
                  setIsLoading(false);
                  setSubmitStatus({
                    type: "error",
                    message: `${error}`,
                  });
                  setTimeout(() => {
                    setSubmitStatus({
                      type: null,
                      message: null,
                    });
                    setSelectingItems([]);
                  }, [3500]);
                });
            }
          });
          break;

        case "Đề xuất":
          updateDbDoc(imgDocRef, {
            category: "Đề xuất",
            updatedDate: serverTimestamp(),
            updater: user.fullName,
          })
            .then(() => {
              submitCleanUp("ok", "Đã chuyển sang Đề xuất");
            })
            .catch((error) => {
              submitCleanUp("error - ", error);
            });

          break;

        case "Đang áp dụng":
          updateDbDoc(imgDocRef, {
            category: "Đang áp dụng",
            updatedDate: serverTimestamp(),
            updater: user.fullName,
          })
            .then(() => {
              submitCleanUp("ok", "Đã chuyển sang Đang áp dụng");
            })
            .catch((error) => {
              submitCleanUp("error", error);
            });

          break;

        case "Duyệt":
          if (isAdmin) {
            updateDbDoc(imgDocRef, {
              category: "Được duyệt",
              updatedDate: serverTimestamp(),
              updater: user.fullName,
            })
              .then(() => {
                submitCleanUp("ok", "Đã chuyển sang Được duyệt");
              })
              .catch((error) => {
                submitCleanUp("error", error);
              });
          } else {
            setIsLoading(false);
            setSubmitStatus({
              type: "error",
              message: "Bạn không có phân quyền",
            });
            setTimeout(() => {
              setSubmitStatus({
                type: null,
                message: null,
              });
            }, [3500]);
          }
          break;

        case "Bỏ duyệt":
          if (isAdmin) {
            updateDbDoc(imgDocRef, {
              category: "tham khảo",
              updatedDate: serverTimestamp(),
              updater: user.fullName,
            })
              .then(() => {
                submitCleanUp("ok", "Đã chuyển sang Tham khảo");
              })
              .catch((error) => {
                submitCleanUp("error", error);
              });
          } else {
            setIsLoading(false);
            setSubmitStatus({
              type: "error",
              message: "Bạn không có phân quyền",
            });
            setTimeout(() => {
              setSubmitStatus({
                type: null,
                message: null,
              });
            }, [3500]);
          }
          break;
      }
    });
  };

  let submitResponse;

  if (isLoading) {
    submitResponse = <Loader />;
  } else if (!isLoading) {
    if (submitStatus.type !== null) {
      if (submitStatus.type === "ok") {
        submitResponse = <p className={classes.ok}>{submitStatus.message}</p>;
      } else if (submitStatus.type === "error") {
        submitResponse = (
          <p className={classes.error}>{submitStatus.message}</p>
        );
      }
    }
  }

  let toolBarContent;
  if (selectingItems.length > 0) {
    toolBarContent = (
      <div className={classes["tool-container"]}>
        <div className={classes["tool-bar"]}>
          <div>
            <p>
              Chọn:
              <span className={classes.count}>
                {" " + selectingItems.length}
              </span>
            </p>
          </div>
          <div>
            <select onChange={onChangeHandler} defaultValue={actionType}>
              {toolOptions.length > 0 &&
                toolOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <McpButtonV1
              btnType="button"
              content="Thực hiện"
              type="blue"
              onClick={submitHandler}
            />
          </div>
        </div>
        <div className={classes["submit-response"]}>{submitResponse}</div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {toolBarContent}
      <GalleryGridBasic
        dishUID={dishDocUID}
        data={imgArr}
        checkItemFn={checkItemFn}
        uncheckItemFn={uncheckItemFn}
        isAdmin={isAdmin}
        pageSelect={props.select}
        submitArr={submitArr}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default KetQuaHinhAnhOverview;
