import React, { useState } from "react";
import classes from "./SidebarMenu2.module.css";
import Chevron from "../UI/Chevron";
import { NavLink } from "react-router-dom";

const SidebarMenu2 = (props) => {
  const [isExpanding, setIsExpanding] = useState(false);

  const expandToggleHanlder = (event) => {
    event.preventDefault();
    setIsExpanding((prev) => !prev);
  };

  const submenuContent = props.data.submenu
    ? props.data.submenu.map((child) => (
        <li key={child.subtitle}>
          <NavLink
            className={(navData) =>
              navData.isActive ? `${classes["nav-active"]}` : ""
            }
            to={child.subPath}
          >
            {child.subtitle}
          </NavLink>
        </li>
      ))
    : "";

  const icon = props.data.icon ? props.data.icon : "";

  return (
    <li className={classes.menu}>
      <div className={classes.item}>
        <div>
          <NavLink
            className={(navData) =>
              navData.isActive ? `${classes["nav-active"]}` : `${classes.nav}`
            }
            to={props.data.path}
          >
            <div className={classes.wrapper}>
              <span>
                {icon} <span className={classes.title}>{props.data.title}</span>
              </span>
            </div>
          </NavLink>
        </div>
        <div className={classes.chevron}>
          {props.data.submenu ? (
            <Chevron onClick={expandToggleHanlder} state={isExpanding} />
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        <ul
          className={`${classes.submenu} ${
            isExpanding ? classes["show"] : classes["hide"]
          }`}
        >
          {submenuContent}
        </ul>
      </div>
    </li>
  );
};

export default SidebarMenu2;
