import React from 'react'; 
import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

const DanhGiaMonLanding = () => {
  return (
    <Fragment>
        <Outlet />
    </Fragment>
  )
}

export default DanhGiaMonLanding