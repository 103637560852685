import React, { useState } from "react";
import classes from "./KitDeXuatDanhGia.module.css";
import CreateReviewRequestForm from "../../components/department/KIT/CreateReviewRequestForm";
import ViewReviewRequest from "../../components/department/KIT/ViewReviewRequest";
import XemDiemDanhGiaMonAn from "../../components/xem_diem_danh_gia_mon_an/XemDiemDanhGiaMonAn";
import { objectIsEmpty } from "../../hooks/allOther";

const KitDeXuatDanhGia = () => {
  const [pageSelect, setPageSelect] = useState("create");
  const [selectRequest, setSelectRequest] = useState({});

  const pageOnChangeHandler = (e) => {
    setPageSelect(e.target.value);
  };

  console.log(objectIsEmpty(selectRequest));

  let viewContent;
  if (pageSelect === "view" && !objectIsEmpty(selectRequest)) {
    viewContent = (
      <XemDiemDanhGiaMonAn
        onSelectRequest={setSelectRequest}
        selectData={selectRequest}
      />
    );
  } else {
    viewContent = <ViewReviewRequest onSelectRequest={setSelectRequest} />;
  }

  return (
    <div>
      <div className={classes["page-choice-panel"]}>
        <form>
          <div>
            <input
              type="radio"
              value="create"
              id="createOption"
              name="option"
              checked={pageSelect === "create" ? true : false}
              onChange={pageOnChangeHandler}
            />
            <label htmlFor="createOption">Tạo đề xuất mới</label>
          </div>
          <div>
            <input
              type="radio"
              value="view"
              id="viewOption"
              name="option"
              checked={pageSelect === "view" ? true : false}
              onChange={pageOnChangeHandler}
            />
            <label htmlFor="viewOption">Xem đề xuất</label>
          </div>
        </form>
      </div>
      {pageSelect === "create" && <CreateReviewRequestForm />}
      {/* {pageSelect === "view" && <ViewReviewRequest/>} */}
      {viewContent}
    </div>
  );
};

export default KitDeXuatDanhGia;
