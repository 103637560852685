import React from "react";
import classes from "./NoPermission.module.css";
import BackHomeBtn from "../../components/UI/BackHomeBtn";

const NoPermission = () => {
  return (
    <div className={classes.wrapper}>
      <p className={classes.bold}>Ooops... </p>
      <p className={classes.des}>
        Bạn không được phân quyền vào trang này!!
        <br/>Bạn có thể nhờ kỹ thuật để được hỗ trợ
      </p>
      <BackHomeBtn />
    </div>
  );
};

export default NoPermission;
