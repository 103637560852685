import { Fragment } from "react";
import { useParams} from "react-router-dom"; 
import DanhGiaMonChiTiet3 from "../../components/department/General/DanhGiaMonChiTiet3";

const TrangDanhGiaChiTiet = () => {
    const params = useParams();

  return (
    <Fragment>
        <DanhGiaMonChiTiet3 requestID={params.requestID} />
    </Fragment>
  )
}

export default TrangDanhGiaChiTiet