import classes from "./AccTerminated.module.css";

const AccTerminated = () => {
  return (
    <div className={classes["not-found"]}>
      <h3>Tài khoản bị khoá</h3>
      <p className={classes.des}>
         Rất tiếc, tài khoản này đã bị khoá,
        <br /> Bạn có thể nhờ kỹ thuật để kiểm tra lại
      </p>
    </div>
  );
};

export default AccTerminated;
