import classes from './Chevron.module.css';

const Chevron = (props) => {

  let classContent = props.state ? `${classes.chevron} ${classes.active}` : `${classes.chevron}`; 

  return (
    <div className={classContent} onClick={props.onClick}/>
  )
}

export default Chevron
