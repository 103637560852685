import React from "react";
import classes from "./Home.module.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import Section from "../../components/layout/Section";
import Sidebar from "../../components/sidebar/Sidebar";
import Page404 from "../404&others/404";
import IsBuiding from "../404&others/IsBuiding";
import MainDashboard from "../../components/dashboard/MainDashboard";
import HRindex from "../HR/HRindex";
import HrDashBoard from "../../components/department/HR/HrDashBoard";
import HrSiteMap from "../HR/HrSiteMap";
import KitSiteMap from "../Kitchen/KitSiteMap";
import ProfileMain from "../profile/ProfileMain";
import { useCheckPermission } from "../../hooks/authority";
import NoPermission from "../404&others/NoPermission";
import AccTerminated from "../404&others/AccTerminated";
import DanhGiaSiteMap from "../generalFunc/DanhGiaSiteMap";
import DanhGiaDashBoard from "../../components/department/General/DanhGiaDashBoard";
import DanhGiaMon3 from "../generalFunc/DanhGiaMon3";
import GeneralFnLanding from "../generalFunc/GeneralFnLanding";
import UserXemSiteMap from "../../components/danh_gia_trang_tri_v2/UserXemSiteMap";
import SNMgeneralSiteMap from "../SNM-chuc-nang-chung/SNMgeneralSiteMap";
import DashBoardTemplate from "../../components/pageTemplate/dashBoadTemplate/DashBoardTemplate";
import SNMIndexPage from "../SNM-chuc-nang-chung/SNMIndexPage";
import MktIndexPage from "../Marketing/MktIndexPage";
import MktSiteMap from "../Marketing/MktSiteMap";
import SalesIndexPage from "../Sales/SalesIndexPage";
import SalesSiteMap from "../Sales/SalesSiteMap";
import GMSiteMap from "../GM/GMSiteMap";
import GMDashBoard from "../GM/GmDashBoard";
import BackOfficeIndexPage from "../Back-office-chuc-nang-chung/BackOfficeIndexPage";
import BackOfficeSiteMap from "../Back-office-chuc-nang-chung/BackOfficeSiteMap";
import OutletIndex from "../../components/outletIndex/OutletIndex";
import FBSiteMap from "../FB/FBSiteMap";

const Home = () => {
  const permissionToken = useCheckPermission();

  let siteMap = {
    home: null,
    sale: null,
    kit: null,
    fb: null,
    stw: null,
    hk: null,
    fo: null,
    acc: null,
    hr: null,
    it: null,
    setting: null,
    profile: null,
    culinary: null,
    room: null,
    office: null,
    snm: null,
    general: null,
    ma: null, // management
    gm: null, // GM
  };

  if (!permissionToken.departmentAccess.remove) {
    siteMap.home = <MainDashboard />;
    siteMap.snm = permissionToken.divisionAccess.snm ? (
      <IsBuiding />
    ) : (
      <NoPermission />
    );
    siteMap.sale = permissionToken.departmentAccess.sale ? (
      <IsBuiding />
    ) : (
      <NoPermission />
    );
    siteMap.culinary = permissionToken.divisionAccess.culinary ? (
      <IsBuiding />
    ) : (
      <NoPermission />
    );
    siteMap.kit = permissionToken.departmentAccess.kit ? (
      <OutletIndex />
    ) : (
      <NoPermission />
    );
    siteMap.fb = permissionToken.departmentAccess.fb ? (
      <OutletIndex />
    ) : (
      <NoPermission />
    );
    siteMap.stw = permissionToken.departmentAccess.stw ? (
      <IsBuiding />
    ) : (
      <NoPermission />
    );
    siteMap.room = permissionToken.divisionAccess.room ? (
      <IsBuiding />
    ) : (
      <NoPermission />
    );
    siteMap.hk = permissionToken.departmentAccess.hk ? (
      <IsBuiding />
    ) : (
      <NoPermission />
    );
    siteMap.fo = permissionToken.departmentAccess.fo ? (
      <IsBuiding />
    ) : (
      <NoPermission />
    );
    siteMap.office = permissionToken.divisionAccess.office ? (
      <IsBuiding />
    ) : (
      <NoPermission />
    );
    siteMap.acc = permissionToken.departmentAccess.acc ? (
      <IsBuiding />
    ) : (
      <NoPermission />
    );
    siteMap.hr =
      permissionToken.departmentAccess.hr ||
      permissionToken.departmentAccess.ma ? (
        <HrDashBoard />
      ) : (
        //<OutletIndex />
        <NoPermission />
      );
    siteMap.it = permissionToken.departmentAccess.it ? (
      <IsBuiding />
    ) : (
      <NoPermission />
    );
    siteMap.general = <IsBuiding />;
    siteMap.setting = <IsBuiding />;
    siteMap.ma = permissionToken.departmentAccess.ma ? (
      <IsBuiding />
    ) : (
      <NoPermission />
    );
    siteMap.gm = permissionToken.departmentAccess.gm ? (
      <OutletIndex />
    ) : (
      <NoPermission />
    );
  } else {
    for (let path in siteMap) {
      siteMap[path] = <AccTerminated />;
      console.log(siteMap[path]);
    }
  }

  return (
    <React.Fragment>
      <div className={classes.container}>
        <Sidebar />
        <Layout>
          <Section>
            <Routes>
              <Route path="/" element={<Navigate replace to="/trang-chu" />} />
              <Route path="/trang-chu/*" element={siteMap.home} />
              {/* <Route path="/kinh-doanh" element={siteMap.sale} /> */}
              <Route path="/sale-marketing/*" element={siteMap.snm} />
              <Route path="/sale-marketing/sales" element={<SalesIndexPage />}>
                <Route
                  index
                  element={
                    <DashBoardTemplate title="Sales" siteMap={SalesSiteMap} />
                  }
                />
                {SalesSiteMap.map((page) => (
                  <Route
                    key={page.path}
                    path={page.path.replace(/\s+/g, "-")}
                    element={page.element}
                  />
                ))}
              </Route>
              <Route
                path="/sale-marketing/marketing"
                element={<MktIndexPage />}
              >
                <Route
                  index
                  element={
                    <DashBoardTemplate title="Marketing" siteMap={MktSiteMap} />
                  }
                />
                {MktSiteMap.map((page) => (
                  <Route
                    key={page.path}
                    path={page.path.replace(/\s+/g, "-")}
                    element={page.element}
                  />
                ))}
              </Route>
              <Route
                path="/sale-marketing/chuc-nang-chung/"
                element={<SNMIndexPage />}
              >
                <Route
                  index
                  element={
                    <DashBoardTemplate
                      title="Chức năng chung"
                      siteMap={SNMgeneralSiteMap}
                    />
                  }
                />
                {SNMgeneralSiteMap.map((page) => (
                  <Route
                    key={page.path}
                    path={page.path.replace(/\s+/g, "-")}
                    element={page.element}
                  />
                ))}
              </Route>

              <Route path="/khoi-am-thuc/*" element={siteMap.culinary} />
              <Route path="/khoi-am-thuc/bep" element={siteMap.kit}>
                <Route
                  index
                  element={
                    <DashBoardTemplate heading="Bếp" siteMap={KitSiteMap} />
                  }
                />
                {KitSiteMap.map((page) => (
                  <Route
                    key={page.path}
                    path={page.path.replace(/\s+/g, "-")}
                    element={page.element}
                  />
                ))}
              </Route>
              <Route path="/khoi-am-thuc/fb" element={siteMap.fb}>
                <Route
                  index
                  element={
                    <DashBoardTemplate
                      title="Bộ phận F&B"
                      siteMap={FBSiteMap}
                    />
                  }
                />
                {FBSiteMap.map((page) => (
                  <Route
                    key={page.path}
                    path={page.path.replace(/\s+/g, "-")}
                    element={page.element}
                  />
                ))}
              </Route>
              <Route path="/khoi-am-thuc/steward" element={siteMap.stw} />
              <Route path="/khoi-phong-luu-tru/*" element={siteMap.room} />
              <Route
                path="/khoi-phong-luu-tru/house-keeping"
                element={siteMap.hk}
              />
              <Route
                path="/khoi-phong-luu-tru/font-office"
                element={siteMap.fo}
              />
              <Route path="/khoi-van-phong/*" element={siteMap.office} />
              <Route path="/khoi-van-phong/ke-toan" element={siteMap.acc} />
              <Route path="/khoi-van-phong/nhan-su/" element={<HRindex />}>
                <Route index element={siteMap.hr} />
                {HrSiteMap.map((page) => (
                  <Route
                    key={page.path}
                    path={page.path.replace(/\s+/g, "-")}
                    element={page.element}
                  />
                ))}
              </Route>
              <Route
                path="/khoi-van-phong/chuc-nang-chung"
                element={<BackOfficeIndexPage />}
              >
                <Route
                  index
                  element={
                    <DashBoardTemplate
                      title="Chức năng chung"
                      siteMap={BackOfficeSiteMap}
                    />
                  }
                />
                {BackOfficeSiteMap.map((page) => (
                  <Route
                    key={page.path}
                    path={page.path.replace(/\s+/g, "-")}
                    element={page.element}
                  />
                ))}
              </Route>
              <Route path="/chuc-nang-chung/*" element={siteMap.general} />
              <Route
                path="/chuc-nang-chung/danh-gia/*"
                element={<GeneralFnLanding />}
              >
                <Route index element={<DanhGiaDashBoard />} />
                {DanhGiaSiteMap.map((page) => (
                  <Route
                    key={page.path}
                    path={page.path.replace(/\s+/g, "-")}
                    element={page.element}
                  />
                ))}
                {/* <Route path="/chuc-nang-chung/danh-gia-mon-an/*" element={<DanhGiaMon3 />}/> */}

                <Route path="danh-gia-mon-an/*" element={<DanhGiaMon3 />} />
                <Route
                  path="danh-gia-trang-tri/*"
                  element={<UserXemSiteMap />}
                />
              </Route>

              <Route path="/quan-ly/" element={siteMap.gm}>
                <Route
                  index
                  element={<GMDashBoard title="Quản lý" sitemap={GMSiteMap} />}
                />
                {GMSiteMap.map((page) => (
                  <Route
                    key={page.path}
                    path={page.path.replace(/\s+/g, "-")}
                    element={page.element}
                  />
                ))}
              </Route>

              <Route path="/IT-he-thong/*" element={siteMap.it} />
              <Route path="/cai-dat/*" element={siteMap.it} />
              <Route path="/ho-so/*" element={<ProfileMain />} />
              <Route path="*" element={<Page404 />} />
            </Routes>
          </Section>
        </Layout>
      </div>
    </React.Fragment>
  );
};

export default Home;
