import KitDeXuatDanhGia from '../Kitchen/KitDeXuatDanhGia';

const SalesSiteMap = [
    {
        name: "Đề xuất thử món", 
        path: "de xuat thu mon", 
        element: <KitDeXuatDanhGia />
    }
]

export default SalesSiteMap