import classes from "./HinhAnhThamKhao.module.css";
import { useState, useContext } from "react";
import GalleryGrid from "../UI/gallery_grid/GalleryGrid";
import McpButtonV1 from "../UI/button/McpButtonV1";
import { db } from "../../firebase/firebase";
import { doc, serverTimestamp } from "firebase/firestore";
import { deleteDbDoc, updateDbDoc } from "../../hooks/fetchFirebase";
import { ref as FBSref, deleteObject, getStorage } from "firebase/storage";
import Loader from "../UI/Loader";
import { useSelector } from "react-redux";
import FnContext from "./FunctionContext";

const HinhAnhThamKhao = (props) => {
  const dishDocUID = props.data.docId;
  const [selectingItems, setSelectingItems] = useState([]);
  const [actionType, setActionType] = useState("Đề xuất thay đổi");
  const [submitStatus, setSubmitStatus] = useState({
    type: null,
    message: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const user = useSelector((state) => state.auth.userCredential);
  const fnCtx = useContext(FnContext);

  let isAdmin;
  if (user.role === "admin" || user.position === "Giám Đốc") {
    isAdmin = true;
  } else {
    isAdmin = false;
  }

  const onChangeHandler = (e) => {
    setActionType(e.target.value);
  };

  const checkItemFn = (itemValue) => {
    setSelectingItems((prev) => [...prev, itemValue]);
  };

  const uncheckItemFn = (itemValue) => {
    setSelectingItems((prev) =>
      prev.filter((item) => item.UID !== itemValue.UID)
    );
  };

  const submitCleanUp = (type, msg) => {
    setIsLoading(false);
    setSubmitStatus({
      type: `${type}`,
      message: `${msg}`,
    });
    setTimeout(() => {
      setSubmitStatus({
        type: null,
        message: null,
      });
      setSelectingItems([]);
      setIsSubmitting(false);
    }, [3500]);
  };

  const submitHandler = () => {
    const FBStorage = getStorage();
    setIsLoading(true);
    setIsSubmitting(true);
    // const imgDocRef = doc(db, "dish_list", dishDocUID, "images", item.UID);
    selectingItems.forEach((item) => {
      const imgDocRef = doc(db, "dish_list", dishDocUID, "images", item.UID);
      switch (actionType) {
        case "Xoá":
          deleteDbDoc(imgDocRef).then((response) => {
            if (response.code === 0) {
              const imgRef = FBSref(FBStorage, `dishImg/${item.imgData.name}`);
              deleteObject(imgRef)
                .then(() => {
                  setIsLoading(false);
                  setSubmitStatus({
                    type: "ok",
                    message: "Đã xoá thành công",
                  });
                  fnCtx.countFn();
                  setTimeout(() => {
                    setSubmitStatus({
                      type: null,
                      message: null,
                    });
                    setSelectingItems([]);
                  }, [3500]);
                })
                .catch((error) => {
                  setIsLoading(false);
                  setSubmitStatus({
                    type: "error",
                    message: `${error}`,
                  });
                  setTimeout(() => {
                    setSubmitStatus({
                      type: null,
                      message: null,
                    });
                    setSelectingItems([]);
                  }, [3500]);
                });
            }
          });
          break;

        case "Đề xuất thay đổi":
          updateDbDoc(imgDocRef, {
            category: "Đề xuất thay đổi",
            updatedDate: serverTimestamp(),
            updater: user.fullName,
          })
            .then(() => {
              submitCleanUp("ok", "Đã chuyển sang Đề xuất thay đổi");
            })
            .catch((error) => {
              submitCleanUp("error", error);
            });

          break;

        case "Đang áp dụng":
          updateDbDoc(imgDocRef, {
            category: "Đang áp dụng",
            updatedDate: serverTimestamp(),
            updater: user.fullName,
          })
            .then(() => {
              submitCleanUp("ok", "Đã chuyển sang Đang áp dụng");
            })
            .catch((error) => {
              submitCleanUp("error", error);
            });

          break;

        case "Được duyệt":
          if (isAdmin) {
            updateDbDoc(imgDocRef, {
              category: "Được duyệt",
              updatedDate: serverTimestamp(),
              updater: user.fullName,
            })
              .then(() => {
                submitCleanUp("ok", "Đã chuyển sang Được duyệt");
              })
              .catch((error) => {
                submitCleanUp("error", error);
              });
          } else {
            setIsLoading(false);
            setSubmitStatus({
              type: "error",
              message: "Bạn không có phân quyền",
            });
            setTimeout(() => {
              setSubmitStatus({
                type: null,
                message: null,
              });
            }, [3500]);
          }
          break;
      }
    });
  };

  let submitResponse;

  if (isLoading) {
    submitResponse = <Loader />;
  } else if (!isLoading) {
    if (submitStatus.type !== null) {
      if (submitStatus.type === "ok") {
        submitResponse = <p className={classes.ok}>{submitStatus.message}</p>;
      } else if (submitStatus.type === "error") {
        submitResponse = (
          <p className={classes.error}>{submitStatus.message}</p>
        );
      }
    }
  }

  let toolBarContent;
  if (selectingItems.length > 0) {
    toolBarContent = (
      <div className={classes["tool-container"]}>
        <div className={classes["tool-bar"]}>
          <div>
            <p>
              Chọn:
              <span className={classes.count}>
                {" " + selectingItems.length}
              </span>
            </p>
          </div>
          <div>
            <select onChange={onChangeHandler} defaultValue={actionType}>
              <option value="Xoá">Xoá</option>
              <option value="Đề xuất thay đổi" defaultChecked>
                Đề xuất thay đổi
              </option>
              <option value="Đang áp dụng">Đang áp dụng</option>
              {isAdmin && <option value="Được duyệt">Duyệt</option>}
            </select>
          </div>
          <div>
            <McpButtonV1
              btnType="button"
              content="Thực hiện"
              type="blue"
              onClick={submitHandler}
            />
          </div>
        </div>
        <div className={classes["submit-response"]}>{submitResponse}</div>
      </div>
    );
  }

  let galleryContent;
  if (isLoading) {
    galleryContent = (
      <div>
        <Loader />
      </div>
    );
  } else {
    galleryContent = (
      <GalleryGrid
        dishUID={dishDocUID}
        checkItemFn={checkItemFn}
        uncheckItemFn={uncheckItemFn}
        isSubmitting={isSubmitting}
      />
    );
  }

  return (
    <div className={classes.container}>
      {toolBarContent}
      {/* <GalleryGrid
        dishUID={dishDocUID}
        checkItemFn={checkItemFn}
        uncheckItemFn={uncheckItemFn}
        isSubmitting={isSubmitting}
      /> */}
      {galleryContent}
    </div>
  );
};

export default HinhAnhThamKhao;
