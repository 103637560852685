import HeadingDep from '../../UI/HeadingDep';
import PanelWrapper from '../../UI/PanelWrapper';
import classes from './HrDashBoard.module.css';
import FunctionPanel from '../../panel/FunctionPanel';
import HrSiteMap from '../../../pages/HR/HrSiteMap';

const HrDashBoard = () => {
  return (
    <div className={classes.HrDashBoard}>
      <HeadingDep content='Nhân sự' />
      <PanelWrapper>
        <FunctionPanel data={HrSiteMap}/>
      </PanelWrapper>
    </div>
  )
}

export default HrDashBoard
