/***********
  This version is to move setting modal size function to their components. Reduce sizes  
 *************/

import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import HrFormV2 from "../../components/department/HR/HrFormV2";
import HrListTableV2 from "../../components/department/HR/HrListTableV2";
import { db } from "../../firebase/firebase";
import {
  collection,
  onSnapshot,
  doc,
  deleteDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import Loader from "../../components/UI/Loader";
import EmployeeEditModal from "../../components/department/HR/HrEmployeeEditModal";
import HrProfileModal from "../../components/department/HR/HrProfileModal";
import {
  createDocV2,
  fullUserUpdateDoc2,
  updateDbDoc,
} from "../../hooks/fetchFirebase";
import MainBackdropV3 from "../../components/UI/backdrops/MainBackdropV3";
import { useSelector } from "react-redux";
import AccordionBtnV1 from "../../components/UI/accordion/AccordionBtnV1";
import HrEmployeeSearch from "./HrEmployeeSearch";
import AccordionSM from "./../../components/UI/accordion/AccordionSM";
import { SysNorRightFix } from "../../components/UI/systemNor/SysNorRightFix";
import NortPTag from "../../components/UI/systemNor/NortPTag";

const HrHoSoNhanVienV3 = () => {
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sysNort, setSysNort] = useState([]);
  const [isViewing, setIsViewing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [editData, setEditData] = useState();
  const [editFormNort, setEditFormNort] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [noResult, setNoResult] = useState(false);

  const backdropRef = useRef();
  const editModalRef = useRef();
  const profileModalRef = useRef();

  const user = useSelector((state) => state.auth.userCredential);

  //retrieve data from firestore
  useEffect(() => {
    setIsLoading(true);

    // const dbRef = collection(db, "employees");
    // onSnapshot(dbRef, (snapshot) => {
    //   let dataArr = [];
    //   snapshot.forEach((doc) => {
    //     dataArr.push({
    //       docID: doc.id,
    //       data: doc.data(),
    //     });
    //   });
    //   // const filteredTimeArr = dataArr.sort(function (a, b) {
    //   //   return b.data.timeCreated - a.data.timeCreated;
    //   // });
    //   // console.log(filteredTimeArr);
    //   setEmployees(dataArr);
    //   setIsLoading(false);
    // });

    const dbQuery = query(
      collection(db, "employees"),
      orderBy("timeCreated", "desc")
    );
    onSnapshot(dbQuery, (snapshot) => {
      if (!snapshot.empty) {
        let dataArr = [];
        snapshot.forEach((doc) => {
          dataArr.push({
            docID: doc.id,
            data: doc.data(),
          });
        });
        setEmployees(dataArr);
        setIsLoading(false);
      } else {
        setEmployees([]);
        setIsEditing(false);
      }
    });
    //
  }, [isViewing, isEditing]);

  //search data function
  const searchFN = (dataObject) => {
    setIsLoading(true);
    setNoResult(false);
    const formatKWord = dataObject.keyword.toUpperCase().trim();
    const searchResult = employees.filter((item) => {
      if (dataObject.searchField === "name") {
        const fullName = item.data.familyName + " " + item.data.name;
        const formatFName = fullName.toUpperCase().trim();
        // console.log(fullName.includes(dataObject.keyword));
        return formatFName.includes(formatKWord);
      } else {
        return (
          item.data[`${dataObject.searchField}`].toUpperCase().trim() ===
          formatKWord
        );
      }
      // console.log(item.data[`${dataObject.searchField}`])
    });

    if (searchResult.length > 0) {
      setDisplayData(searchResult);
    } else {
      setDisplayData([]);
      setNoResult(true);
    }
    setTimeout(() => setIsLoading(false), 1000);
    console.log(searchResult);
  };

  //pass down functions
  const deleteHandler = async (docID, employeeId) => {
    console.log("delete");
    try {
      //define database ref for local use
      const queryInSignUp = query(
        collection(db, "signup_list"),
        where("id", "==", employeeId)
      );
      //create function to delete doc from signup list
      const removeSignUp = () => {
        console.log("signup remove");
        getDocs(queryInSignUp).then((res) => {
          if (res.empty) {
            setSysNort((prev) => [
              ...prev,
              {
                type: "error",
                key: Math.floor(Math.random() * 1000),
                msg: "Lỗi: không thấy dữ liệu trong Signup_list",
              },
            ]);
          } else {
            let newArr = [];
            res.forEach((item) =>
              newArr.push({
                docID: item.id,
                item: item.data(),
              })
            );
            if (newArr.length < 2) {
              const docRef = doc(db, "signup_list", newArr[0].docID);
              deleteDoc(docRef).catch((error) => {
                setSysNort((prev) => [
                  ...prev,
                  {
                    type: "error",
                    key: Math.floor(Math.random() * 1000),
                    msg: error.message,
                  },
                ]);
              });
            } else {
              setSysNort((prev) => [
                ...prev,
                {
                  type: "error",
                  key: Math.floor(Math.random() * 1000),
                  msg: "Lỗi: trùng dữ liệu trong Signup_list",
                },
              ]);
            }
          }
        });
      };
      //1-check whether this user existed
      const userDbRef = query(
        collection(db, "employee_users"),
        where("id", "==", employeeId)
      );
      getDocs(userDbRef).then((snapshot) => {
        console.log(snapshot.empty);
        //2-if user existed, update employee_user department to 'remove'
        if (!snapshot.empty) {
          let userData = [];
          snapshot.forEach((item) =>
            userData.push({
              docID: item.id,
              data: item.data(),
            })
          );
          console.log(userData);
          if (userData.length < 2) {
            const userDocID = userData[0].docID;
            const userDocRef = doc(db, "employee_users", userDocID);
            updateDoc(userDocRef, {
              department: "remove",
            })
              .then(() => {
                //3-delete employee profile
                const employeeDocRef = doc(db, "employees", docID);
                deleteDoc(employeeDocRef).then(() => {
                  setSysNort((prev) => [
                    ...prev,
                    {
                      type: "success",
                      key: Math.floor(Math.random() * 1000),
                      msg: "Đã xóa thành công",
                    },
                  ]);
                });
                //4-delete signup list profile
                removeSignUp();
              })
              .catch(() => {
                throw new Error(
                  "Đã có lỗi hệ thống, vui lòng gửi ID nhân viên bạn muốn xoá cho kỹ thuật để kiểm tra"
                );
              });
          } else {
            setSysNort((prev) => [
              ...prev,
              {
                type: "error",
                key: Math.floor(Math.random() * 1000),
                msg: "Đã có lỗi hệ thống, vui lòng gửi ID nhân viên bạn muốn xoá cho kỹ thuật để kiểm tra",
              },
            ]);
          }
        } else {
          console.log("user is empty");
          // start to delete employee doc
          deleteDoc(doc(db, "employees", docID)).then(() => {
            setSysNort((prev) => [
              ...prev,
              {
                type: "success",
                key: Math.floor(Math.random() * 1000),
                msg: "Đã xóa thành công",
              },
            ]);
          });
          //remove from signup list
          removeSignUp();
        }
      });
    } catch (error) {
      setSysNort((prev) => [
        ...prev,
        {
          type: "error",
          key: Math.floor(Math.random() * 1000),
          msg: error.message,
        },
      ]);
    }
  };

  const viewHandler = (docId) => {
    const filterData = employees.filter((value) => value.docID === docId);
    console.log(filterData);
    setViewData(filterData[0].data);
    setIsViewing(true);
  };

  const closeModal = () => {
    setEditFormNort([]);
    setIsViewing(false);
    setIsEditing(false);
  };

  const editHandler = (docId) => {
    //retrive data of modal inital input
    const data = employees.filter((data) => data.docID === docId);
    console.log(data);
    setEditData(data);

    setIsEditing(true);
  };

  //props chain functions
  const updateHandler = (updatedData) => {
    setEditFormNort([]);

    //1-create new sub doc with existing data
    const subDocDbRef = collection(
      db,
      "employees",
      editData[0].docID,
      "version_control"
    );

    console.log(updatedData);

    const dataForUpdate = {
      name: updatedData.name,
      familyName: updatedData.familyName,
      // email: updatedData.email,
      phone: updatedData.phone,
      dob: updatedData.dob,
      startDate: updatedData.startDate,
      position: updatedData.position,
      creator: updatedData.creator,
      timeUpdate: serverTimestamp(),
      department: updatedData.department,
      role: updatedData.role,
      gender: updatedData.gender,
      version: editData[0].data.version
        ? editData[0].data.version + 1
        : parseInt(1),
    };

    console.log(dataForUpdate);

    createDocV2(subDocDbRef, editData[0].data).then(
      (res) => console.log(res) //fine
    );
    //2-update main doc with new data
    const dbRef = doc(db, "employees", editData[0].docID);
    // const dataForUpdate = {
    //   name: updatedData.name,
    //   familyName: updatedData.familyName,
    //   email: updatedData.email,
    //   phone: updatedData.phone,
    //   dob: updatedData.dob,
    //   startDate: updatedData.startDate,
    //   position: updatedData.position,
    //   creator: updatedData.creator,
    //   timeUpdate: serverTimestamp(),
    //   department: updatedData.department,
    //   role: updatedData.role,
    //   version: editData[0].data.version
    //     ? editData[0].data.version + 1
    //     : parseInt(1),
    // };

    try {
      const combineUpdate = async () => {
        updateDbDoc(dbRef, dataForUpdate).then((res) => {
          if (res.code === 1) {
            setEditFormNort((prev) => [
              ...prev,
              {
                type: "error",
                key: Math.floor(Math.random() * 1000),
                msg: res.message,
              },
            ]);
          } else {
            fullUserUpdateDoc2(editData[0].data.id, editData[0].docID, {
              name: updatedData.name,
              position: updatedData.position,
              creator: user.email,
              timeUpdate: serverTimestamp(),
              department: updatedData.department,
              role: dataForUpdate.role,
              version: editData[0].data.version + 1,
            }).then((res) => {
              console.log(res);
              if (res.code === 0) {
                setEditFormNort((prev) => [
                  ...prev,
                  {
                    type: "ok",
                    key: Math.floor(Math.random() * 1000),
                    // msg: "Đã cập nhật thành công",
                    msg: res.message,
                  },
                ]);
                setTimeout(() => closeModal(), 3000);
              } else {
                setEditFormNort((prev) => [
                  ...prev,
                  {
                    type: "error",
                    key: Math.floor(Math.random() * 1000),
                    // msg: "Đã cập nhật thành công",
                    msg: res.message,
                  },
                ]);
                setTimeout(() => closeModal(), 3000);
              }
            });
            // .catch((err) => {
            //   // throw new Error(err.message);
            //   console.log(`phat sinh loi he thong ${err}`);
            // });
          }
        });
      };
      return combineUpdate();
    } catch (error) {
      setEditFormNort((prev) => [
        ...prev,
        {
          type: "error",
          key: Math.floor(Math.random() * 1000),
          msg: error.message,
        },
      ]);
    }

    //3-update signup_list if email change
    //email can only be changed by user -> no change in signup list
  };

  const clearNort = () => {
    setSysNort([]);
  };

  const nortClickHandler = (value) => {
    const nortContent = sysNort;
    let filteredContent = nortContent.filter((data) => data.key !== value);
    setSysNort(filteredContent);

    setTimeout(clearNort, 15000);
  };

  const checkEmailInUse = (email) => {
    const newArr = employees.filter((item) => item.data.email === email);
    if (newArr.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkIdInUse = (id) => {
    const newArr = employees.filter((item) => item.data.id === id);
    if (newArr.length > 0) {
      return "ID này đã được sử dụng, bạn chỉ cần click vào nút 'tạo' 1 lần nữa. Xác suất ID bị trùng là rất thấp, bạn là 1 người rất may mắn";
    } else {
      return false;
    }
  };

  //modal content
  let modalContent;
  if (isEditing) {
    modalContent = (
      <EmployeeEditModal
        reference={editModalRef}
        initInput={editData[0].data}
        onCancel={closeModal}
        onUpdate={updateHandler}
        defaultPosition={editData[0].data.position}
        defaultDepartment={editData[0].data.department}
        defaultGender={editData[0].data.gender}
        nort={editFormNort}
      />
    );
  }
  if (isViewing) {
    modalContent = (
      <HrProfileModal reference={profileModalRef} data={viewData} />
    );
  }

  //data content condition
  let dataContent;
  if (displayData.length > 0) {
    dataContent = (
      <HrListTableV2
        employeesData={displayData}
        onDelete={deleteHandler}
        onView={viewHandler}
        onEdit={editHandler}
      />
    );
  } else {
    if (noResult == true) {
      dataContent = (
        <NortPTag>
          Không có kết quả dựa trên điều kiện tìm kiếm của bạn!
        </NortPTag>
      );
    } else {
      dataContent = (
        <HrListTableV2
          employeesData={employees}
          onDelete={deleteHandler}
          onView={viewHandler}
          onEdit={editHandler}
        />
      );
    }
  }

  return (
    <React.Fragment>
      {(isViewing || isEditing) &&
        ReactDOM.createPortal(
          <MainBackdropV3 onClick={closeModal} reference={backdropRef} />,
          document.getElementById("main-backdrop")
        )}

      {(isViewing || isEditing) &&
        ReactDOM.createPortal(
          modalContent,
          document.getElementById("main-overlay")
        )}

      <AccordionSM>
        <AccordionBtnV1 title="Tạo hồ sơ nhân viên">
          <HrFormV2 emailCheck={checkEmailInUse} idCheck={checkIdInUse} />
        </AccordionBtnV1>
        <AccordionBtnV1 title="Tìm kiếm" onClose={true}>
          <HrEmployeeSearch onSearch={searchFN} />
        </AccordionBtnV1>
      </AccordionSM>

      {/* <SysNorV4 data={sysNort} onClick={nortClickHandler} /> */}
      <SysNorRightFix data={sysNort} onClick={nortClickHandler} />

      {isLoading ? <Loader /> : dataContent}
    </React.Fragment>
  );
};

export default HrHoSoNhanVienV3;
