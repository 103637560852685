import classes from "./AccordionSM.module.css";
import FormWrapper from "../FormWrapper";

const AccordionSM = (props) => {
  return (
    <FormWrapper>
      <div className={classes.accordionWrapper}>{props.children}</div>
    </FormWrapper>
  );
};

export default AccordionSM;
