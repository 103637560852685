import { Fragment } from "react"; 
import { Outlet } from "react-router-dom";


const SNMIndexPage = () => {
  return (
    <Fragment>
        <Outlet />
    </Fragment>
  )
}

export default SNMIndexPage; 
