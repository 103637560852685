import React from "react";
import classes from "./TableDish.module.css";
import AllowedFunctionBasic from "../../panel/AllowedFunctionBasic";

/*
  alternative option to FlextTableDish.js
  Style using row 
  
  props list: {

    data: {
    }

    onDelete
    onEdit
  }
*/

const TableDish = (props) => {
  const header = {
    col1: "STT",
    col2: "Món ăn",
    col3: "Nhóm",
    col4: "Outlet",
    col5: 'ID'
  }; 

  let tableBodyContent; 
  if(props.data.length > 0) {
    tableBodyContent = props.data.map((item, index) => (
      <tr key={item.data.id}>
      <td>{index + 1}</td>
      <td>{item.data.name}</td>
      <td>{item.data.group}</td>
      <td>{item.data.outlet}</td>
      {/* <td>{item.data.id}</td> */}
      <td>{item.data.id.toString().slice(-5)}</td>
      <td>
        <AllowedFunctionBasic
          docID={item.docId}
          onDelete={props.onDelete}
          onEdit={props.onEdit}
        />
      </td>
    </tr>
    ))
  }


  return (
    <table className={classes.table}>
      <thead>
        <tr>
          <th className={classes["top-left"]}>{header.col1}</th>
          <th>{header.col2}</th>
          <th>{header.col3}</th>
          <th>{header.col4}</th>
          <th>{header.col5}</th>
          <th colSpan="1" className={classes["top-right"]}>
            Bảng điều khiển
          </th>
        </tr>
      </thead>
      <tbody>
        {tableBodyContent}
        {/* <tr>
          <td>1</td>
          <td>ca thac lac</td>
          <td>test</td>
          <td>dzo dzo </td>
          <td>
            <AllowedFunctionBasic
              docID='123'
              onDelete={deleteHandler}
              onEdit={editHandler}
            />
          </td>
        </tr> */}
      </tbody>
    </table>
  );
};

export default TableDish;
