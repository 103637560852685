import { useState, useEffect, Fragment } from "react";
import classes from "./FoodRequestModal.module.css";
import ModalWrapperV3 from "../../UI/modals/ModalWrapperV3";
import FlexTable3Col1 from "../../table/FlexTable3Col1";
import McpButtonV1 from "../../UI/button/McpButtonV1";
import { useSelector } from "react-redux";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import {
  setCusDoc,
  deleteDbDoc,
  updateDbDoc,
} from "../../../hooks/fetchFirebase";
import NortPTag from "../../UI/systemNor/NortPTag";
import TasterStatus from "./TasterStatus";
import FlexTable3Col1Start from "../../table/FlexTable3Col1Start";
import Loader from "../../UI/Loader";

const FoodRequestModal = (props) => {
  const user = useSelector((state) => state.auth.userCredential);
  const [formNort, setFormNort] = useState({});
  const [dbApproveStatus, setDbApproveStatus] = useState();
  const [isSubmitting, setIsSubmitting] = useState();

  // let dbSwitch; //global switch to active and deactive onsnapshot

  const foodTableTitle = {
    col1: "STT",
    col2: "Thông tin",
    col3: "ID",
  };

  const tasterTableTitle = {
    col1: "STT",
    col2: "Thông tin",
    col3: "Trạng Thái",
  };

  const formatFood = props.data.dishes.map((item) => {
    return {
      col1: item.name,
      col2: item.group,
      // col3: item.dishID,
      col3: item.dishID.toString().slice(-5),
    };
  });

  const formatTasters =
    props.data.tasters.length > 0 &&
    props.data.tasters.map((item) => {
      return {
        col1: item.fullName,
        col2: item.department,
        col3: item.id, //taster uid
      };
    });

  //approve status
  useEffect(() => {
    const requestDocRef = doc(db, "food_review_request", props.docId);
    const dbSwitch = onSnapshot(requestDocRef, (snapshot) => {
      setDbApproveStatus(snapshot.data().approveStatus);
    });

    // dbSwitch ()
  }, []);

  let statusTag;
  if (dbApproveStatus === "yes") {
    statusTag = (
      <span className={`${classes.tag} ${classes.green}`}>Đã duyệt</span>
    );
  } else if (dbApproveStatus === "waiting") {
    statusTag = (
      <span className={`${classes.tag} ${classes.grey}`}>Chưa duyệt</span>
    );
  } else if (dbApproveStatus === "no") {
    statusTag = (
      <span className={`${classes.tag} ${classes.yellow}`}>
        Không được duyệt
      </span>
    );
  }

  const foodComp = (value) => {
    const comp = (
      <div>
        <p>{value}</p>
      </div>
    );
    return comp;
  };

  const tasterComp = (value) => {
    if (dbApproveStatus !== "yes") {
      return <div>Chưa đánh giá</div>;
    } else {
      return <TasterStatus uid={value} requestID={props.data.id} />;
    }
  };

  const approveHandler = () => {
    //1-update 'approveStatus'
    const requestDocRef = doc(db, "food_review_request", props.docId);
    updateDbDoc(requestDocRef, {
      approveStatus: "yes",
    }).then((res) => {
      //2-create seperate request for each and every taster
      if (res.code === 0) {
        const submitObject = {
          requestId: props.data.id,
          requestName: props.data.name,
          requestDate: props.data.date,
          requestTime: props.data.time,
          dishes: props.data.dishes,
          approveStatus: "đã duyệt",
          reviewStatus: "chưa đánh giá",
        };

        props.data.tasters.forEach((taster) => {
          const tasterRef = collection(
            db,
            "employee_users",
            taster.id,
            "dish_review_request"
          );
          const docRef = doc(tasterRef, props.data.id);
          setIsSubmitting(true);
          setCusDoc(docRef, submitObject).then((res) => {
            if (res.code === 0) {
              setFormNort({
                type: "success",
                message: "Đã duyệt thành công",
              });
              setTimeout(() => {
                setFormNort({});
              }, 4000);
            } else {
              setFormNort({
                type: "error",
                message: res.message,
              });
            }
            setIsSubmitting(false);
            setTimeout(() => {
              setFormNort({});
            }, 4000);
          });
        });
      } else {
        setFormNort({
          type: "error",
          message: res.message,
        });
        setTimeout(() => {
          setFormNort({});
        }, 4000);
      }
    });
  };

  const rejectHandler = () => {
    const requestDocRef = doc(db, "food_review_request", props.docId);
    updateDbDoc(requestDocRef, {
      approveStatus: "no",
    }).then((res) => {
      if (res.code === 0) {
        setFormNort({
          type: "success",
          message: "Không duyệt đề xuất thành công",
        });
      } else {
        setFormNort({
          type: "error",
          message: res.message,
        });
      }
    });
  };

  const deleteHandler = () => {
    //1-delete function only visible when approveStatus is not equal yes
    const deleteDocRef = doc(db, "food_review_request", props.docId);
    deleteDbDoc(deleteDocRef).then((res) => {
      if (res.code === 0) {
        setFormNort({
          type: "success",
          message:
            "Đã xoá thành công, bạn sẽ được điều hướng về trang danh sách đề xuất sau 3 giây",
        });
        setTimeout(() => props.onClose(), [3000]);
        // dbSwitch();
      } else {
        setFormNort({
          type: "error",
          message: res.message,
        });
      }
    });
  };

  let audienceContent;
  if (isSubmitting) {
    audienceContent = (
      <Fragment>
        <Loader />
      </Fragment>
    );
  } else {
    audienceContent = (
      <FlexTable3Col1Start
        title={tasterTableTitle}
        data={formatTasters}
        addOnComp={tasterComp}
      />
    );
  }

  //admin panel
  let adminPanel;
  if (user.isSuperAdmin || user.role === "admin") {
    if (dbApproveStatus === "yes") {
      adminPanel = null;
    } else if (dbApproveStatus === "waiting") {
      adminPanel = (
        <div className={classes["admin-panel"]}>
          <McpButtonV1
            content="Duyệt"
            type="green"
            btnType="button"
            onClick={approveHandler}
          />
          <McpButtonV1
            content="Không duyệt"
            type="yellow"
            btnType="button"
            onClick={rejectHandler}
          />
          <McpButtonV1
            content="Xoá"
            type="red"
            btnType="button"
            onClick={deleteHandler}
          />
        </div>
      );
    } else if (dbApproveStatus === "no") {
      adminPanel = (
        <div className={classes["admin-panel"]}>
          <McpButtonV1
            content="Xoá"
            type="red"
            btnType="button"
            onClick={deleteHandler}
          />
        </div>
      );
    }
  }

  return (
    <ModalWrapperV3>
      <div className={classes.container}>
        <div>
          <h5>Thông tin</h5>
          <p>
            <span className={classes.label}>Tên: </span>
            {props.data.name}
          </p>
          <p>
            <span className={classes.label}>ID: </span>
            {props.data.id}
          </p>
          <p>
            <span className={classes.label}>Ngày diễn ra: </span>
            {props.data.date}
          </p>
          <p>
            <span className={classes.label}>Thời gian ước tính: </span>
            {props.data.time}
          </p>
          <p>
            <span className={classes.label}>Tình trạng: </span>
            {statusTag}
          </p>
        </div>
        {adminPanel}
        <div className={classes["nort-wrapper"]}>
          <NortPTag type={formNort.type}>{formNort.message}</NortPTag>
        </div>
        <div>
          <h5>Món ăn</h5>
          <FlexTable3Col1
            title={foodTableTitle}
            data={formatFood}
            addOnComp={foodComp}
          />
        </div>
        <div>
          <h5>Đối tượng</h5>
          {/* <p>
            <span className={classes.label}>Tổng số lượng người đánh giá:</span>
            <span>{" " + props.data.tasters.length}</span>
          </p>
          <p>
            <span className={classes.label}>Số lượng người đã đánh giá: {rateCount} </span>
          </p> */}
          <FlexTable3Col1Start
            title={tasterTableTitle}
            data={formatTasters}
            addOnComp={tasterComp}
          />
        </div>
      </div>
    </ModalWrapperV3>
  );
};

export default FoodRequestModal;
