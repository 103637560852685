import React from "react";
import classes from './Test.module.css'
import { Route, Routes } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import Section from "../../components/layout/Section";
import Sidebar from "../../components/sidebar/Sidebar";
import TestCreateUser from "./TestCreateUser";

const Test = () => {
  return (
    <React.Fragment>
      <div className={classes.container}>
        <Sidebar />
        <Layout>
          <Section>
            <h3>This is test page</h3>
            <TestCreateUser />
          </Section>
        </Layout>
        <Routes>
          <Route path="/bep" element={<p>bep</p>} />
        </Routes>
      </div>
    </React.Fragment>
  );
};

export default Test;
