import classes from "./GalleryGridNoTick.module.css";
import { useEffect, useRef, useState } from "react";
import { db } from "../../firebase/firebase";
import { collection } from "firebase/firestore";
import { getDbs1 } from "../../hooks/fetchFirebase";
import TrangTriRateBar from "../hinh_anh_mon_an/TrangTriRateBar";

/*
    versions control:
    _this version: to be used as template only, limit logic and retrieve data from props. Originated form GalleryGrid.js
*/

/**
 * props list :{
 * data
 * }
 */
const GalleryGridNoTick = (props) => {
  const galleryContainerRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [ratingData, setRatingData] = useState([]);

  useEffect(() => {
    const ratingData = props.data.map((item) => {
      let newObject = {
        imgUID: item.imgUID,
      };

      const colRef = collection(
        db,
        "dish_list",
        props.dishId,
        "images",
        item.imgUID,
        "decor-rating"
      );

      getDbs1(colRef).then((res) => {
        newObject.ratingDoc = res;
        setIsLoading(false);
      });

      return newObject;
    });

    setRatingData(ratingData);
  }, []);

  const fetchRating = (id) => {
    // return scoreContent;
    let tagClass;
    let avgScore;
    let ratingCheck;

    if (!isLoading) {
      const filterArr = ratingData.filter((item) => item.imgUID === id);
      filterArr[0].ratingDoc.length > 0
        ? (ratingCheck = true)
        : (ratingCheck = false);
      let ratingScore = 0;
      filterArr[0].ratingDoc.map((doc) => {
        ratingScore += doc.data.score;
      });

      avgScore =
        Math.round((ratingScore / filterArr[0].ratingDoc.length) * 100) / 100;

      if (avgScore < 2) {
        tagClass = "red";
      } else if (avgScore < 3) {
        tagClass = "yellow";
      } else if (avgScore < 3.7) {
        tagClass = "blue";
      } else {
        tagClass = "green";
      }
    }

    let returnContent;
    if (ratingCheck) {
      returnContent = (
        <p className={classes.score}>
          Điểm trang trí:{" "}
          <span className={`${classes.tag} ${classes[`${tagClass}`]}`}>
            {avgScore}
          </span>
        </p>
      );
    } else {
      returnContent = <p className={classes.score}>Chưa có đánh giá</p>;
    }

    return returnContent;
  };

  //define main content
  let mainContent;
  if (!isLoading && props.data.length === 0) {
    mainContent = (
      <div className={classes.note}>
        <p>Chưa có dữ liệu hình ảnh cho món ăn này </p>
      </div>
    );
  } else if (!isLoading && props.data.length > 0) {
    mainContent = props.data.map((item) => (
      <div key={item.imgUID}>
        <img src={item.imgData.URL} />
        {/* {fetchRating(item.imgUID)} */}
        <TrangTriRateBar imgUID={item.imgUID} dishId={props.dishId} />
      </div>
    ));
  }

  return (
    <div className={classes.container} ref={galleryContainerRef}>
      {mainContent}
    </div>
  );
};

export default GalleryGridNoTick;
