import { Fragment } from 'react';
import classes from './ThemHinhAnhDetail.module.css'
import ImgUploaderV2 from '../imgUpload/ImgUploaderV2';

const ThemHinhAnhDetail = (props) => {
  return (
    <Fragment>
        <div className={classes['info-container']}>
            <p className={classes.title}>Thông tin món ăn:</p>
            <p><span>Món ăn: </span>{props.data.data.name}</p>
            <p><span>ID: </span>{props.data.data.id}</p>
            <p><span>Nhóm món ăn: </span>{props.data.data.group}</p>
            <p><span>Outlet: </span>{props.data.data.outlet}</p>
        </div>
        <ImgUploaderV2 dishData={props.data}/>
    </Fragment>
  )
}

export default ThemHinhAnhDetail