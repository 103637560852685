import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { auth, db } from "../../firebase/firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import classes from "./LoginFormV4.module.css";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Loader from "../UI/Loader";
import { authActions } from "../../store";
import { setCusDoc } from "../../hooks/fetchFirebase";
import NortPTag from "../UI/systemNor/NortPTag";

/**
 *
 * v3-This version changes login procedure
 * v4-remove unused code and update styles
 */

const LoginFormV4 = () => {
  const userInputRef = useRef();
  const passwordInputRef = useRef();
  const dispatch = useDispatch();
  const [error, setError] = useState([]);
  const [isLoading, setIsLoading] = useState();

  // const { isLoading, error, sendRequest: signInRequest } = useHttp();

  const stateLogin = (auth) => {
    dispatch(
      authActions.login({
        uid: auth.uid,
        email: auth.email,
        displayName: auth.displayName,
        fullName: auth.fullName,
        department: auth.department,
        position: auth.position,
        role: auth.role,
        isSuperAdmin: auth.isSuperAdmin,
      })
    );
  };

  const testBE = async (token) => {
    fetch("http://localhost:3500/test", {
      method: "POST",
      body: JSON.stringify({ token }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setError([]);
    setIsLoading(true);

    const userInput = userInputRef.current.value;
    const passwordInput = passwordInputRef.current.value;

    setPersistence(auth, browserSessionPersistence)
      .then(async () => {
        return signInWithEmailAndPassword(auth, userInput, passwordInput)
          .then((userCredential) => {
            return userCredential;
          })
          .then(async (userCredential) => {
            // --test backend, verify token
            auth.currentUser
              .getIdToken()
              .then((idToken) => {
                console.log(`idToken - ${idToken}`);
                testBE(idToken);
              })
              .catch((err) => console.log(err));
            //check wether employee has user doc
            const userRef = doc(db, "employee_users", userCredential.user.uid);
            getDoc(userRef).then((user) => {
              if (user.exists()) {
                const userData = user.data();
                const dbFullName = userData.familyName + " " + userData.name;
                const formatFullName = dbFullName.replace(/\s+/g, " ");
                stateLogin({
                  uid: userCredential.user.uid,
                  email: userCredential.user.email,
                  displayName: userData.name,
                  fullName: dbFullName,
                  department: userData.department,
                  role: userData.role,
                  position: userData.position,
                  isSuperAdmin: userData.isSuperAdmin ? true : false,
                });
              } else {
                //finding profile using email to retrive info
                let profileData = [];
                const emailProfileRef = collection(db, "employees");
                const profileQuery = query(
                  emailProfileRef,
                  where("email", "==", userCredential.user.email)
                );
                getDocs(profileQuery).then((profileSnapshot) => {
                  profileSnapshot.forEach((doc) => {
                    profileData.push(doc.data());
                  });
                  console.log(profileData);
                  if (profileData.length === 1) {
                    //create new doc in employee_users db
                    let updateProfile;
                    updateProfile = {
                      accActivated: true,
                      name: profileData[0].name,
                      familyName: profileData[0].familyName,
                      id: profileData[0].id,
                      department: profileData[0].department,
                      position: profileData[0].position,
                      role: profileData[0].role,
                      timeCreated: serverTimestamp(),
                      isSuperAdmin: profileData[0].isSuperAdmin ? true : false,
                      version: 1,
                    };
                    const userRef = doc(
                      db,
                      "employee_users",
                      userCredential.user.uid
                    );
                    setCusDoc(userRef, updateProfile).then((res) => {
                      //login if
                      if (res.code === 0) {
                        stateLogin({
                          uid: userCredential.user.uid,
                          email: userCredential.user.email,
                          displayName: profileData[0].name,
                          department: profileData[0].department,
                          position: profileData[0].position,
                          role: profileData[0].role,
                          isSuperAdmin: profileData[0].isSuperAdmin
                            ? true
                            : false,
                        });
                      } else {
                        setError((prev) => [
                          ...prev,
                          "Lỗi: khởi tạo profile lần đầu",
                        ]);
                      }
                    });
                  } else {
                    // throw new Error(
                    //   "Lỗi hệ thống, vui lòng cung cấp ID cho kỹ thuật để kiểm tra"
                    // );
                    setError((prev) => [
                      ...prev,
                      "Lỗi: không có dữ liệu hoặc trùng email, vui lòng liên hệ IT để được hỗ trợ",
                    ]);
                  }
                });
              }
            });
            setIsLoading(false);
          })
          .catch((error) => {
            if (error.code === "auth/invalid-email") {
              setError((prev) => [
                ...prev,
                "ID/email không đúng, vui lòng kiểm tra lại",
              ]);
            } else if (error.code === "auth/user-not-found") {
              setError((prev) => [
                ...prev,
                "Tài khoản không tồn tại, vui lòng kiểm tra lại",
              ]);
            } else if (error.code === "auth/user-not-found") {
              setError((prev) => [
                ...prev,
                "Tài khoản không tồn tại, vui lòng kiểm tra lại",
              ]);
            } else if (error.code === "auth/wrong-password") {
              setError((prev) => [
                ...prev,
                "Mật khẩu không đúng, vui lòng kiểm tra lại",
              ]);
            } else {
              setError((prev) => [
                ...prev,
                "Lỗi hệ thống, vui lòng gửi ID/email cho kỹ thuật để kiểm tra",
              ]);
            }
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setIsLoading(false);
        setError((prev) => [...prev, error.message]);
      });

    // userInputRef.current.value = "";
    passwordInputRef.current.value = "";
  };

  let errorContent = (
    <div>
      {error.length > 0 ? (
        <div className={classes.error}>
          <p>
            <ErrorOutlineIcon />
          </p>
          <p>{error}</p>
        </div>
      ) : null}
    </div>
  );

  return (
    <div className={classes.form}>
      <h3>Đăng nhập</h3>
      <p>Vui lòng điền vào thông tin để đăng nhập</p>
      <form onSubmit={submitHandler}>
        <label htmlFor="user">Tên tài khoản</label>
        <input
          type="email"
          id="user"
          autoComplete="username"
          ref={userInputRef}
        ></input>
        <label htmlFor="password">Mật khẩu</label>
        <input
          type="password"
          id="password"
          autoComplete="current-password"
          ref={passwordInputRef}
        ></input>
        {isLoading ? (
          <Loader />
        ) : (
          <button type="submit" className={classes["login-btn"]}>
            Đăng nhập
          </button>
        )}
        {errorContent}
        <button type="button" className={classes["secondary-btn"]}>
          <Link to="/dang-ky">Tạo tài khoản mới</Link>
        </button>
        <button type="button" className={classes["secondary-btn"]}>
          <Link to="/cap-mat-khau"> Xin cấp lại mật khẩu?</Link>
        </button>
        {/* <button type="button" className={classes["secondary-btn"]}>
          Xin cấp lại mật khẩu?
        </button> */}
      </form>
    </div>
  );
};

export default LoginFormV4;
