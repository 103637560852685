import classes from "./ChevronRight.module.css";

const Chevron = (props) => {
  let classContent = props.state
    ? `${classes.chevron} ${classes.active}`
    : `${classes.chevron}`;

  return (
    <div className={classes.wrapper}>
      <div className={classContent} onClick={props.onClick} />
    </div>
  );
};

export default Chevron;
