import React, {useRef} from "react";
import SearchIcon from "@mui/icons-material/Search";
import classes from "./HrSearchBar.module.css";
import McpButtonV1 from "../../components/UI/button/McpButtonV1";

/*
  originated from SearchBar.js
  props list : {
    onSearch
  }
 */

const HrSearchBar = (props) => {
  const formRef = useRef();

  const submitHandler = (e) => {
    e.preventDefault();
    
    const formData = new FormData(formRef.current)    
    let inputData = Object.fromEntries(formData); 

    console.log(inputData);


    props.onSearch(inputData);
  };

  const loadAll = (e) => {
    formRef.current.reset(); 
    submitHandler(e);
  };

  return (
    <form className={classes["search-container"]} ref={formRef}>
      <div className={classes["text-field"]}>
        <button type="button" onClick={submitHandler}>
          <SearchIcon
            sx={{
              backgroundColor: "#30BE75",
              color: "white",
              borderRadius: "4px",
            }}
          />
        </button>
        <input type="text" placeholder="Nhập từ khoá tìm kiếm" name='keyword'></input>
      </div>
      <div className={classes["options-panel"]}>
        <p>Lựa chọn chủ đề tìm kiếm</p>
        <div>
          <input type="radio" name="searchField" value="name" defaultChecked />
          <label>Tên</label>
        </div>
        <div>
          <input type="radio" name="searchField" value="email" />
          <label>Email</label>
        </div>
        {/* <div>
          <input type="radio" name="searchField" value="id" />
          <label>ID</label>
        </div> */}
      </div>
      <div className={classes["btn-panel"]}>
        <McpButtonV1 type="green" content="Tìm" onClick={submitHandler} />
        <McpButtonV1 type="blue" content="Tải toàn bộ" onClick={loadAll} />
        <McpButtonV1 type="red" btnType="reset" content="Huỷ" />
      </div>
    </form>
  );
};

export default HrSearchBar;
