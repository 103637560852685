import { useState } from "react";
import classes from "./HinhAnhMonAn.module.css";
import KetQuaSearchHinhAnh from "./KetQuaSearchHinhAnh";
import NortContext from "./NortContext";
import { SysNorRightFix } from "../UI/systemNor/SysNorRightFix";
import HinhAnhSearchBar from "./HinhAnhSearchBar";

const HinhAnhMonAn = () => {
  const [pageSelect, setPageSelect] = useState();
  const [searchValue, setSearchValue] = useState({});
  const [nortContent, setNortContent] = useState([]);

  const onClickHanlder = (value) => {
    setPageSelect(value);
  };

  const clickSubHandler = () => {
    console.log("This function is not available for this page");
  };

  const searchHandler = (value) => {
    setSearchValue(value);
  };

  const nortAddHandler = (nort) => {
    setNortContent((prev) => [...prev, nort]);
  };

  const nortRemoveHandler = (key) => {
    setNortContent((prev) => prev.filter((item) => item.key !== key));
  };

  //main content
  // let mainContent;
  // switch (pageSelect) {
  //   case 'Đang áp dụng':
  //       mainContent = <p>Đang áp dụn </p>;
  //       break;
  //   case 'Thêm hình ảnh':
  //       mainContent = <ThemHinhAnhOverview searchValue={searchValue}/>;
  //       break;
  //   case 'Tham khảo':
  //       mainContent = <KetQuaSearchHinhAnh searchValue={searchValue} onSelect={pageSelect}/>;
  //       break;
  //   case 'Đề xuất thay đổi':
  //       mainContent = <p>Đề xuất thay đổi</p>;
  //       break;
  //   case 'Được duyệt':
  //       mainContent= <p>Được duyệt</p>;
  //       break;
  //   default:
  //       mainContent = <p>Đang áp dụng</p> ;
  // }

  let searchResultContent;
  if (JSON.stringify(searchValue) === "{}") {
    searchResultContent = null;
  } else {
    searchResultContent = (
      <KetQuaSearchHinhAnh searchValue={searchValue} onSelect={pageSelect} />
    );
  }

  return (
    <div>
      <NortContext.Provider
        value={{
          nortContent: nortContent,
          onAdd: nortAddHandler,
          page: "Trang hình ảnh món ăn",
        }}
      >
        <SysNorRightFix data={nortContent} onClick={nortRemoveHandler} />

        <div className={classes.search}>
          <p>Chọn món ăn</p>
          <HinhAnhSearchBar onSearch={searchHandler} />
        </div>
        {/* <div className={classes.switcher}>
          <SelectPageContent
            id="view-switch"
            label="Chọn trang trạng thái"
            optionsList={optionList}
            defaultOption="Đang áp dụng"
            onClick={onClickHanlder}
            onClickSub={clickSubHandler}
          />
        </div> */}

        {/* <div>{mainContent}</div> */}
        {/* <KetQuaSearchHinhAnh searchValue={searchValue} onSelect={pageSelect} /> */}
        {searchResultContent}
      </NortContext.Provider>
    </div>
  );
};

export default HinhAnhMonAn;
