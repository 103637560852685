import ModalWrapper from "./ModalWrapper";
import classes from "./EditModal.module.css";
import CloseIcon from '@mui/icons-material/Close';

const EditModal = (props) => {
  return (
    <ModalWrapper>
      <div className={classes['top-nav']}>
        <button onClick={props.onClick}><CloseIcon sx={{size: 16, color: '#30BE75'}}/></button>
      </div>
      <div className={classes['form-section']}>
        <form onSubmit={props.onSubmit}>
          {props.formData &&
            props.formData.map((field) => (
              <div key={field.id}>
                <label>{field.label}</label>
                <input
                  id={field.id}
                  name={field.name}
                  type='text'
                  required
                  defaultValue={field.defaultValue}
                  placeholder={field.placeholder}
                />
              </div>
            ))}
          <div className={classes['button-nav']}>
            <button className={classes['save-button']} type="submit">Lưu</button>
            <button className={classes['cancel-button']} type="button" onClick={props.onClose}>
              Huỷ
            </button>
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};

export default EditModal;
