import ModalWrapperV4 from "../UI/modals/ModalWrapperV4";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./ViewMore.module.css";
import Slider from "../UI/slider/Slider";
import ToolPage from "./ToolPage";
import ImgInfo from "./ImgInfo";

const ViewMore = (props) => {

  let modalContent;
  switch (props.fnType) {
    case "img":
      modalContent = <Slider data={props.data} />;
      break;
    case "info":
      modalContent = <ImgInfo data={props.data.data} score={props.data.teamScore} />
      break;
    case "tool":
      modalContent = <ToolPage data={props.data}/>;
      break;
  }

  return (
    <ModalWrapperV4>
      <div className={classes["close-wrapper"]}>
        <button onClick={props.onClose}>
          <CloseIcon sx={{ fontSize: "16px", color: "#606060" }} />
        </button>
      </div>
      <div className={classes["content-wrapper"]}>{modalContent}</div>
    </ModalWrapperV4>
  );
};

export default ViewMore;
