import classes from "./ScoreComp.module.css";
import { useState, useEffect } from "react";
import { collection, query, where, orderBy, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/firebase";

const ScoreComp = (props) => {
  const [score, setScore] = useState(0);
  const [style, setStyle] = useState()
  console.log(props.dishID, props.userID);

  useEffect(() => {
    const docDbRef = collection(db, "dish_list", props.dishID, "dish_review");
    const dbQuery = query(
      docDbRef,
      where("raterID", "==", props.userID),
      orderBy("timeCreated", "desc")
    );
    // getDocs(dbQuery, (snapshot) => {
    //   console.log("getting data");
    //   if (!snapshot.empty) {
    //     let arr = [];
    //     snapshot.forEach((item) => arr.push(item.data()));
    //     console.log(arr);
    //   }
    // });
    getDocs(dbQuery).then((res) => {
      let scoreArr = [];
      if (!res.empty) {
        res.forEach((item) => scoreArr.push(item.data()));
      }
      console.log(scoreArr);
      const selectScore = scoreArr[0].score; 
      if(selectScore <= 1) {
        setStyle('worst')
      } else if (selectScore <= 2) {
        setStyle('bad')
      } else if (selectScore <= 3) {
        setStyle('mediocre')
      } else if (selectScore <= 4) {
        setStyle('good')
      } else if (selectScore <= 5) {
        setStyle('excellent')
      }

      setScore(scoreArr[0].score);
    });
  }, []);

  return (
    <div>
      <span className={`${classes['score-wrapper']} ${classes[`${style}`]}`}>{score}</span>
    </div>
  );
};

export default ScoreComp;
