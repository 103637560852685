import React, { useEffect, useState } from "react";
import AccordionBtnV2Sm from "../UI/accordion/AccordionBtnV2Sm";
import classes from "./XemDiemDanhGiaMonAnOffice.module.css";
import SelectFieldV3 from "../form/template/SelectFieldV3";
import McpButtonV1 from "../UI/button/McpButtonV1";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { formatOutlet } from "../../hooks/capitalize";
import Loader from "../UI/Loader";

const XemDiemDanhGiaMonAnOffice = () => {
  const [loadedOutlet, setLoadedOutlet] = useState([]);
  const [selectOutlet, setSelectOutlet] = useState();
  const [fetchConclude, setFetchConclude] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [nort, setNort] = useState(null);

  useEffect(() => {
    const fetchOutlet = async () => {
      const outletColRef = collection(db, "outlet");
      const snapshot = await getDocs(outletColRef);
      const data = [];
      snapshot.forEach((doc) => {
        const fetchData = doc.data();
        data.push({
          value: fetchData.outlet,
          textContent: fetchData.outlet,
          key: fetchData.id,
        });
      });
      const filterData = data.filter((item) => !item.value.includes("delete"));
      setLoadedOutlet(filterData);
    };

    fetchOutlet();
  }, []);

  const outletClickHandler = (value) => {
    setSelectOutlet(value);
  };

  const nortTO = () => {
    setTimeout(() => setNort(null), 3500);
  };

  const getDbConclude = async () => {
    setIsLoading(true);
    const concludeRef = collection(db, "dish_review_conclude");
    const fetchOutlet = formatOutlet(selectOutlet).concat("_conclude");
    const docRef = doc(concludeRef, fetchOutlet);
    const res = await getDoc(docRef);
    const data = res.data();
    setFetchConclude(data);
    setIsLoading(false);
    setNort("Đã truy vấn dữ liệu thành công");
    nortTO();
  };

  const onFindHandler = async (e) => {
    e.preventDefault();
    clearTimeout(nortTO);
    setNort(null);
    getDbConclude();
  };

  let scoreStatTable;
  let rewardDetail;
  let totalRewardComment;

  const scoreDesConvert = (descripton) => {
    let formatDes;
    switch (descripton) {
      case "VERY GOOD DISHES":
        formatDes = "Từ 4 trở lên";
        break;
      case "PASS DISHES":
        formatDes = "Từ 3.3 đến 4";
        break;
      case "BAD DISHES":
        formatDes = "Từ 2.5 đến 3.3";
        break;
      case "VERY BAD DISHES":
        formatDes = "Từ 0 đến 2.5";
        break;
      case "NULL DISHES":
        formatDes = "Chưa có đánh giá";
        break;
      default:
        formatDes = "";
    }
    return formatDes;
  };

  if (fetchConclude) {
    const scoreStat = fetchConclude.scoreOverview;
    scoreStatTable = Object.keys(scoreStat).map((key) => {
      const data = scoreStat[key];
      const scoreBar = data.name.trim().toUpperCase();
      const displayBar = scoreDesConvert(scoreBar);
      return (
        <div className={classes.row}>
          <div>{displayBar}</div>
          <div>{data.count}</div>
          <div>{data.numPercent}</div>
        </div>
      );
    });

    rewardDetail = Object.keys(scoreStat).map((key) => {
      const data = scoreStat[key];
      const scoreBar = data.name.trim().toUpperCase();
      const displayBar = scoreDesConvert(scoreBar);
      const totalAmount = (data.reward * data.count).toLocaleString("vi-vn");
      return (
        <div className={classes.padL}>
          <p>{`${displayBar}: ${data.reward.toLocaleString("vi-VN")}/món x ${
            data.count
          } =  ${totalAmount}`}</p>
        </div>
      );
    });

    totalRewardComment = `${fetchConclude.tong_thuong.toLocaleString(
      "vi-VN"
    )} x (100% - ${
      fetchConclude.ty_le_giam_tru_thuong
    }%) = ${fetchConclude.thuong_thuc_dat.toLocaleString("vi-VN")}`;
  }

  let formDetail;
  if (fetchConclude) {
    formDetail = (
      <div className={classes.verdict}>
        <div className={classes.warning}>
          {`(Thông tin tổng quát không thể hiện cho từng nhóm mà chỉ thể hiện cho tất
          cả)`}
        </div>
        <div>
          <p>
            <span className={classes.label}>Ngày: </span>
            {new Date().toLocaleDateString("vi-VN")}
          </p>
          <p>
            <span className={classes.label}>Outlet: </span>
            {fetchConclude.outlet}
          </p>
          <p>
            <span className={classes.label}>Nhóm: </span>Tất cả
          </p>
          <p>
            <span className={classes.label}>Số lượng món: </span>
            {fetchConclude.totalNum}
          </p>
        </div>
        <div>
          <p>
            <span className={classes.label}>Thống kê điểm: </span>
          </p>
          <div className={classes["score-stat-table"]}>
            <div>
              <div className={`${classes.row} ${classes["row-label"]}`}>
                <div>Thang điểm</div>
                <div>Số lượng món</div>
                <div>%</div>
              </div>
              {scoreStatTable}
            </div>
          </div>
        </div>
        <div>
          <p>
            <span className={classes.label}>Kết luận: </span>
          </p>
          <div className={classes.padL}>
            <p>{fetchConclude.ket_luan.nhan_xet.chung}</p>
            <p className={classes.important}>
              {fetchConclude.ket_luan.nhan_xet.cu_the}
            </p>
          </div>
        </div>
        <div>
          <p>
            <span className={classes.label}>Tổng thưởng: </span>
            {fetchConclude.tong_thuong.toLocaleString("vi-VN")}
          </p>
          {rewardDetail}
        </div>
        <div>
          <p>
            <span className={classes.label}>Thưởng thực đạt: </span>{" "}
            {totalRewardComment}
          </p>
        </div>
        <div>
          <p>
            <span className={classes.label}>Mức giảm trưởng bộ phận: </span>{" "}
            {typeof fetchConclude.muc_giam_tru_truong_bo_phan === "string"
              ? fetchConclude.muc_giam_tru_truong_bo_phan
              : fetchConclude.muc_giam_tru_truong_bo_phan * 100 + "%"}
          </p>
        </div>
      </div>
    );
  } else {
    formDetail = <p className={classes.center}>Hiện tại chưa có thông tin!</p>;
  }

  return (
    <div className={classes.wrapper}>
      <AccordionBtnV2Sm onClose={false} title="Lựa chọn tìm kiếm">
        <div className={classes["search-wrapper"]}>
          <form onSubmit={onFindHandler}>
            <SelectFieldV3
              label="Chọn outlet"
              id="outlet-select"
              defaultOption="Banquet"
              optionsList={loadedOutlet}
              defaultText="Banquet"
              onClick={outletClickHandler}
            />
            <div className={classes["btn-nav"]}>
              <McpButtonV1 content="Tìm" type="green" btnType="submit" />
            </div>
          </form>
          <div className={classes["nort-bar"]}>
            {isLoading && <Loader />}
            {nort && <p>{nort}</p>}
          </div>
        </div>
      </AccordionBtnV2Sm>
      <AccordionBtnV2Sm onClose={true} title="Thông tin tổng quát">
        {formDetail}
      </AccordionBtnV2Sm>
    </div>
  );
};

export default XemDiemDanhGiaMonAnOffice;
