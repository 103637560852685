import { useEffect, useState } from "react";
import classes from "./XemDiemDanhGiaMonAn.module.css";
import { collection } from "firebase/firestore";
import { getDbs1 } from "../../hooks/fetchFirebase";
import { db } from "../../firebase/firebase";
import DishScoreTable from "./DishScoreTable";
import McpButtonV1 from "../UI/button/McpButtonV1";
import StatTag from "../UI/stat-tag/StatTag";

// This component is still in working. This allow to see single dish scores and comments from DeXuatThuMon component. This component is hidden from ViewReviewRequest

const XemDiemDanhGiaMonAn = (props) => {
  const [displayList, setdDisplayList] = useState([]);

  const backToViewList = () => {
    props.onSelectRequest({});
  };

  const data = props.selectData.data;

  // const getAvgScore = (dish) => {
  //   return new Promise((resolve) => {
  //     const colRef = collection(db, "dish_list", dish.id, "dish_review");
  //     getDbs1(colRef).then((res) => {
  //       let totalScore = 0;
  //       res.forEach((item) => {
  //         if (typeof item.data.score !== "number") {
  //           totalScore += 0;
  //         } else {
  //           totalScore += item.data.score;
  //         }
  //       });
  //       const avgScore = (totalScore / res.length).toFixed(2);
  //       resolve({
  //         // name: dish.name,
  //         // id: dish.dishID.slice(-5),
  //         // avgScore,
  //         key: dish.dishID.slice(-5),
  //         info: [dish.name],
  //         score: avgScore,
  //       });
  //     });
  //   });
  // };

  const getInfo = (dish) => {
    return new Promise((resolve) => {
      const colRef = collection(db, "dish_list", dish.id, "dish_review");
      getDbs1(colRef).then((res) => {
        let totalScore = 0;
        let commentArr = [];
        if (res.length > 0) {
          // let totalScore = 0;
          // let commentArr = [];
          res.forEach((item) => {
            if (typeof item.data.score !== "number") {
              totalScore += 0;
            } else {
              totalScore += item.data.score;
            }
            item.data.comment &&
              commentArr.push({
                name: item.data.rater,
                comment: item.data.comment,
              });
          });
          const avgScore = (totalScore / res.length).toFixed(2);

          resolve({
            // name: dish.name,
            // id: dish.dishID.slice(-5),
            // avgScore,
            key: dish.dishID.slice(-5),
            info: [dish.name],
            score: avgScore,
            commentArr,
          });
        } else {
          resolve({
            key: dish.dishID.slice(-5),
            info: [dish.name],
            score: 0,
            commentArr,
          });
        }
      });
    });
  };

  useEffect(() => {
    // let arr = [];
    // data.dishes.forEach((dish) => {
    //   console.log(dish.id, dish);
    //   getAvgScore(dish.id).then((res) => {
    //     // setdDisplayList((prev) => [
    //     //   ...prev,
    //     //   {
    //     //     name: dish.name,
    //     //     id: dish.id.slice(-6),
    //     //     avgScore: res,
    //     //   },
    //     // ]);
    //     arr.push({
    //       name: dish.name,
    //       id: dish.id.slice(-6),
    //       avgScore: res,
    //     });
    //     console.log(arr);
    //   });
    // });
    // console.log(arr);
    // setdDisplayList(arr);

    const promisesArr = data.dishes.map((dish) => {
      return getInfo(dish);
    });

    Promise.all(promisesArr).then((res) => setdDisplayList(res));
  }, [data.dishes]);

  let tableContent;
  const tableTitle = {
    col1: "STT",
    col2: "Tên",
    col3: "Điểm",
  };
  if (displayList.length > 0) {
    tableContent = (
      <div className={classes["table-wrapper"]}>
        <DishScoreTable title={tableTitle} data={displayList} />
      </div>
    );
  }

  let commentSection;
  let aggregateComment = [];
  if (displayList.length > 0) {
    displayList.forEach((dish) => {
      dish.commentArr.length > 0 &&
        dish.commentArr.forEach((comment) => {
          aggregateComment.push({
            comment: comment.comment,
            name: comment.name,
          });
        });
    });
  }

  if (aggregateComment.length > 0) {
    commentSection = aggregateComment.map((item) => (
      <div className={classes.comment}>{item.comment}</div>
    ));
  } else {
    commentSection = <div className={classes.note}>Không có góp ý khác</div>;
  }

  return (
    <div className={classes.container}>
      <div className={classes["nav-bar"]}>
        <McpButtonV1
          type="blue"
          onClick={backToViewList}
          content="Quay về danh sách"
          btnType="button"
        />
      </div>

      <div className={classes.info}>
        <h6 className={classes.heading}>Thông tin</h6>
        <ul>
          <li>
            Tên: <span className={classes["list-info"]}>{data.name}</span>
          </li>
          <li>
            ID: <span className={classes["list-info"]}>{data.id}</span>
          </li>
          <li>
            Ngày thử món:{" "}
            <span className={classes["list-info"]}>{data.date}</span>
          </li>
        </ul>
      </div>
      <div>
        <h6 className={classes.heading}>Điểm đánh giá</h6>
        {tableContent}
      </div>
      <div>
        <h6 className={classes.heading}>Nội dung ý kiến khác</h6>
        {commentSection}
      </div>
    </div>
  );
};

export default XemDiemDanhGiaMonAn;
