import React from "react";
import HrSearchBar from "./HrSearchBar";

const HrEmployeeSearch = (props) => {
  return (
    <div>
      <HrSearchBar onSearch={props.onSearch} />
    </div>
  );
};

export default HrEmployeeSearch;
