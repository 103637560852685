import classes from './RateBtn.module.css';

const RateBtn = (props) => {

  const changeHandler = (e) => {
    props.onFetch(props.id, e.target.value)
  }

  return (
    <div>
      <select name="score" ref={props.reference} className={classes.select} onChange={changeHandler} id={props.id} defaultValue={0}>
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5</option>
        <option value={0}>none</option>
      </select>
    </div>
  );
};

export default RateBtn;
