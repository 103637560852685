import React from "react";
import classes from "./FlexTable3Col2.module.css";

/* versions:

  1 - originated from FlexTable3Col.js
  1 - originated from FlexTable3Col2.js
  this version allows to have info column carry more than 2 rows of data

    props list : {
        title: {
            col1, 
            col2,
            col3 - id
        },
        data: {
          key,
          info : []
        }, 
        addOnComp
    }
*/

const FlexTable3Col2 = (props) => {

  let title;
  if (props.title) {
    title = props.title;
  }


  return (
    <div className={classes.container}>
      <div className={`${classes.row} ${classes.header}`}>
        <div>{title.col1}</div>
        <div>{title.col2}</div>
        <div>{title.col3}</div>
      </div>
      {props.data.length > 0 &&
        props.data.map((item, index) => (
          <div className={classes.row} key={item.key}>
            <div className={classes.index}>{index + 1}</div>
            <div className={classes.info}>
              {item.info.map((colItem, index) => (
                <p key={index}>{colItem}</p>
              ))}
            </div>
            {props.addOnComp(item.key)}
          </div>
        ))}
    </div>
  );
};

export default FlexTable3Col2;
