import { useEffect, useRef } from "react";
import classes from "./ImgCardMobile.module.css";
import { useWindowSize } from "../../hooks/layoutDOM";
import CloseIcon from "@mui/icons-material/Close";

const ImgCardMobile = (props) => {
  const windowSize = useWindowSize();
  const imgRef = useRef();

  //format image size;
  let formatSize;
  if (props.data.size > 1000000) {
    formatSize = <span className={classes.error}>Dung lượng vượt 1 MB</span>;
  } else if (props.data.size > 1000) {
    formatSize = (
      <span className={classes.ok}>{`${Math.round(
        props.data.size / 1000
      )}KB`}</span>
    );
  } else {
    formatSize = (
      <span className={classes.ok}>{`${props.data.size}Bytes`}</span>
    );
  }

  //format type
  let formatType = props.data.type.replace("image/", "");
  let formatTypeNote;
  if (formatType === "jpeg" || formatType === "jpg" || formatType === "webp") {
    formatTypeNote = <span>{formatType}</span>;
  } else {
    formatTypeNote = (
      <span className={classes.error}>Định dạng không cho phép</span>
    );
  }

  useEffect(() => {
    const elWidth = imgRef.current.width;
    let elHeight = (elWidth / 4) * 3;
    imgRef.current.style.height = `${elHeight}px`;
  }, [windowSize]);

  return (
    <div className={classes.card}>
      <div className={classes["card-wrapper"]}>
        <img ref={imgRef} src={props.data.url} />
      </div>
      <div>
        <ul>
          <li>{formatTypeNote}</li>
          <li>{formatSize}</li>
        </ul>
      </div>
      <div>
        <button onClick={props.onRemove} className={classes.closeBtn}>
          <CloseIcon sx={{ color: "white", fontSize: "14px" }} />
        </button>
      </div>
    </div>
  );
};

export default ImgCardMobile;
