import { createSlice, configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";
import { auth } from "../firebase/firebase";
import { signOut } from "firebase/auth";

const initialAuthState = {
  // token: initalToken,
  userCredential: {
    uid: null,
    email: null,
    displayName: null,
    fullName: null,
    department: null,
    position: null,
    role: null,
    isSuperAdmin: false,
  },
  userIsLoggin: false,
  isLoading: false,
};

// const fetchProfile =  async (state, uid) => {
//   const profileRef = doc(db, 'employees', uid);
//   const docSnap = await getDoc(profileRef);
//   if(docSnap.exists()) {
//     console.log(docSnap.data());
//     const profile = docSnap.data();
//     state.userCredential.role = profile.role;
//     state.userCredential.department = profile.department;
//   } else {
//     console.log('error')
//   }
// }

const persistConfig = {
  key: "root",
  storage,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    logout(state, action) {
      if (action.payload.type === "manual") {
        state.userIsLoggin = false;
        state.userCredential = initialAuthState.userCredential;
        signOut(auth);
        // .then(() => {
        //   console.log("sign out");
        // })
        // .catch((error) => {
        //   console.log(error.message);
        // });
        // console.log(state.userCredential);
        state.isLoading = false;
      }
      if (action.payload.type === "auto") {
        state.userIsLoggin = false;
        state.userCredential = initialAuthState.userCredential;
      }
    },
    login(state, action) {
      // state.token = action.payload.token;
      // localStorage.setItem("token", action.payload.token);
      // state.userIsLoggin = !state.token ? false : true;
      state.userCredential.uid = action.payload.uid;
      state.userCredential.email = action.payload.email;
      state.userCredential.displayName = action.payload.displayName;
      state.userCredential.fullName = action.payload.fullName;
      state.userIsLoggin = state.userCredential.uid ? true : false;
      // fetchProfile(state, action.payload.uid);
      state.isLoading = false;
      state.userCredential.department = action.payload.department;
      state.userCredential.role = action.payload.role;
      state.userCredential.position = action.payload.position;
      state.userCredential.isSuperAdmin = action.payload.isSuperAdmin;
    },
    // saveProfile(state, action) {
    //   state.userCredential.department = action.payload.department;
    //   state.userCredential.role = action.payload.role;
    // }
    // update(state, action) {
    //   state.userCredential.uid = action.payload.uid;
    //   state.userCredential.email = action.payload.email;
    //   state.userCredential.displayName = action.payload.displayName;
    //   state.userIsLoggin = state.userCredential.uid ? true : false;
    //   state.userCredential.department = action.payload.department;
    //   state.userCredential.role = action.payload.role;
    //   state.userCredential.isSuperAdmin = action.payload.isSuperAdmin;
    // }
  },
});

//create new slice with reducer
//create related persist config
//combine reducer
//put new combined reducer to the confirgueStore

const initialSpecState = {
  modalSpec: {
    top: null,
    left: null,
    width: null,
    height: null,
  },
  nav: {
    width: 0,
  },
};

const specSlice = createSlice({
  name: "spec",
  initialState: initialSpecState,
  reducers: {
    updateSpec(state, action) {
      state.modalSpec.top = action.payload.modalSpec.top
        ? action.payload.modalSpec.top
        : state.modalSpec.top;
      state.modalSpec.left = action.payload.modalSpec.left
        ? action.payload.modalSpec.left
        : state.modalSpec.left;
      state.modalSpec.width = action.payload.modalSpec.width
        ? action.payload.modalSpec.width
        : state.modalSpec.width;
      state.modalSpec.height = action.payload.modalSpec.height
        ? action.payload.modalSpec.height
        : state.modalSpec.height;
      // state.modalSpec = action.payload.modalSpec;
      // console.log('hi', state.modalSpec);
    },
    changeNavSize(state, action) {
      state.nav.width = action.payload.nav.width;
    },
  },
});

const specConfig = {
  key: "spec",
  storage,
};

const rootReducer = combineReducers({
  auth: persistReducer(persistConfig, authSlice.reducer),
  spec: persistReducer(specConfig, specSlice.reducer),
});

//const persistedReducer = persistReducer(persistConfig, authSlice.reducer);

const store = configureStore({
  // reducer: {
  //   // auth: authSlice.reducer,
  // },
  // reducer: persistedReducer,
  reducer: rootReducer,
  //middleware: [thunk],
  middleware: (getDefaultMiddlware) =>
    getDefaultMiddlware({ serializableCheck: false }).concat(thunk),
});

export const authActions = authSlice.actions;

export const specActions = specSlice.actions;

export const persistor = persistStore(store);

export default store;
