import Card from "../card/Card";
import classes from "./PermissionErrModal.module.css";
import ModalWrapperV3 from "./ModalWrapperV3";
import CardV3 from "../card/CardV3";

const PermissionErrModal = (props) => {
  return (
    // <div className={classes["modal-wrapper"]}>
    <ModalWrapperV3>
      <CardV3>
        <div className={classes.container}>
          <p className={classes["err-msg"]}>
            Bạn không đủ phân quyền để thực hiện thao tác này.
            <br />
            Nếu bạn muốn tìm hiểu thêm vui lòng liên hệ với IT.
          </p>
          <button className={classes.closeBtn} onClick={props.onClick}>
            Đóng
          </button>
        </div>
      </CardV3>
    </ModalWrapperV3>
    // </div>
  );
};

export default PermissionErrModal;
