import { useLayoutEffect, useState } from "react";

function getMainSectionSize() {
  const elementData = document
    .getElementById("main-section")
    .getBoundingClientRect();
  const navHeight = document.getElementById("main-header").offsetHeight;
  const scrollHeight = document.getElementById("main-section").scrollHeight;

  return {
    top: `${Math.abs(navHeight)}px`,
    left: `${elementData.left}px`,
    width: `${elementData.width}px`,
    height: `${scrollHeight}px`,
  };
}

function makeSizeRepsonsive(target) {
  const getMainSectionSize = () => {
    const elementData = document
      .getElementById("main-section")
      .getBoundingClientRect();
    const navHeight = document.getElementById("main-header").offsetHeight;
    const scrollHeight = document.getElementById("main-section").scrollHeight;
    return {
      top: `${Math.abs(navHeight)}px`,
      left: `${elementData.left}px`,
      width: `${elementData.width}px`,
      height: `${scrollHeight}px`,
    };
  };

  const calibrateSize = () => {
    const mainSectionSize = getMainSectionSize();
    target.top = mainSectionSize.top;
    target.left = mainSectionSize.left;
    target.width = mainSectionSize.width;
    target.height = mainSectionSize.height;
  };

  calibrateSize();
  // window.addEventListener("resize", calibrateSize);
}

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize(); // ?? not quite sure, have to ask. => after searching, call updateSize() right away so state gets updated with initial window size => may be because of react state
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export { getMainSectionSize, makeSizeRepsonsive, useWindowSize };
